/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions/index';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Input from 'components/atoms/Inputs/Input';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SelectInput from 'components/atoms/Inputs/SelectInput';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import CurrentCoupon from '../components/CurrentCoupon/CurrentCoupon';
import Loading from 'components/atoms/Loading/Loading';

const URLAPI = process.env.REACT_APP_URL_API;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      // role="tabpanel"
      hidden={value !== index}
      id={`ig-name-tabpanel-${index}`}
      aria-labelledby={`ig-name-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const ModalWrapper = styled.div`
  margin: 16px auto;
  position: relative;

  width: 100%;
  @media screen and (min-width: 992px) {
    width: 80%;
  }
`;

const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const Error = styled.p`
  width: 100%;
  color: #ff0000;
  margin-top: 5px;
  margin-right: 5px;
  font-size: 20px;
  top: 60px;
  left: 0;
  text-align: right;
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      outline: 'none',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
  },
  button: {
    width: '100%',
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const FormControl = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: ${({ shorter }) => (shorter ? '50%' : '100%')};
  ${({ border }) =>
    border &&
    css`
      border: 1px solid #c4c4c4;
      border-radius: 0 0 4px 4px;
      border-top: 0;
      margin-bottom: 20px;
    `} 
    ${({ noMarginB }) =>
    noMarginB &&
    css`
        margin-bottom: 0;
      `}
      ${({ file }) =>
    file &&
    css`
          width: 210px;
        `}
  ${({ imgBox }) =>
    imgBox &&
    css`
      width: 210px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `}

`;
const SelectWrapper = styled.div`
  width: 50%;
  position: relative;
  margin-bottom: 20px;
`;

const SubmitBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const initialState = {
  couponId: null,
  code: '',
  description: '',
  discount: '',
  discount_type_id: { id: '', name: '' },
  product_id: { id: '', name: '' },
  is_active: { id: '', name: '' },
  isError: {
    code: false,
    NameOfCoupon_en: null,
    selectedCouponsCategory: null,
  },
  igValidationPassed: false,
  lastEdited: '',
};

const couponsTypesOption = [
  { value: '1', label: 'Wszystim użytkonikom' },
  { value: '2', label: 'Kupon jednorazowy' },
];

const couponsIsActive = [
  { value: 1, label: 'Aktywny' },
  { value: 0, label: 'Nieaktywny' },
];


const EditCoupons = ({
  // onInitCoupons,
  history,
  coupons,
  onSubmitCouponsForm,
  match,
  products,
  onFetchProducts,
  setsSingleCoupon,
  singleCoupon

}) => {
  const [validationPassed, setValidationPassed] = useState(false);
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [discountType, setDiscountType] = useState([]);
  const [isCouponActive, setIsCouponActive] = useState([]);

  const productsOption = products.map(product => {
    return { value: product.id, label: `${product.name} ${product.language} - ${product.price} ${product.currency}` };
  });

  useEffect(() => {
    if (selectedProduct.length === 0) {
      setSelectedProduct(productsOption.filter(prodcut => prodcut.value === singleCoupon.product_id))
    }
  }, [singleCoupon, products])

  useEffect(() => {
    if (isCouponActive.length === 0) {
      setDiscountType(couponsTypesOption.filter(type => type.value === singleCoupon.discount_type_id))
    }
  }, [singleCoupon, products])


  useEffect(() => {
    if (discountType.length === 0) {
      setIsCouponActive(couponsIsActive.filter(type => type.value === singleCoupon.is_active))
    }
  }, [singleCoupon, products])


  useEffect(() => {
    // const endpointCoupons = `${URLAPI}coupon-create/${match.params.id}`;

    setsSingleCoupon(match.params.id);
    onFetchProducts();
  }, []);

  useEffect(() => {
    if (state.singleCoupon === '' || state.singleCoupon === undefined) {
      setsSingleCoupon(match.params.id);
      setState(state => ({
        ...state,
        singleCoupon,
      }));
    }
  }, [match, singleCoupon]);

  useEffect(() => {
    setState(state => ({
      ...state,
      couponId: match.params.id,
      code: singleCoupon.code,
      description: singleCoupon.description,
      discount: singleCoupon.discount,
      discount_type_id: singleCoupon.discount_type_id,
      product_id: singleCoupon.product_id,
      is_active: singleCoupon.is_active,
    }));
  }, [singleCoupon]);

  const isValidationPassed = () => {
    // TODO uruchamianie walidacji przy załadowaniu inputów
    const { isError } = { ...state };
    const isErrorLeft = Object.values(isError).reduce((a, b) => {
      return a === b ? a : NaN;
    });

    if (isErrorLeft === false) {
      setValidationPassed(true);
    } else {
      setValidationPassed(false);
    }
  };

  useEffect(() => {
    isValidationPassed();
  }, [state.isError]);

  const selectValid = key => {
    if (key !== 'selectedCouponsCategory') {
      const curentSelect = state[key];
      const { isError } = { ...state };
      const currentState = isError;
      if (curentSelect !== null) {
        if (curentSelect.value.length === 0) {
          currentState[key] = true;
        } else {
          currentState[key] = false;
        }
        setState({
          ...state,
          isError: currentState,
        });
      } else if (curentSelect === null) {
        currentState[key] = true;
        setState({
          ...state,
          isError: currentState,
        });
      }
    }
  };

  const handleChangeSelect = (inputValue, key) => {
    if (inputValue !== null) {
      setState({
        ...state,
        [key]: inputValue.value,
        lastEdited: key,
      });
    } else {
      setState({
        ...state,
        [key]: null,
        lastEdited: key,
      })
    }
  };


  const handleChangeInput = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setState(initialState);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setState(state => ({
      ...state,
    }));

    const bodyFormData = new window.FormData();
    bodyFormData.set('code', state.code);
    bodyFormData.set('description', state.description);
    bodyFormData.set('discount', state.discount);
    bodyFormData.set('discount_type_id', state.discount_type_id);
    bodyFormData.set('is_active', state.is_active);
    bodyFormData.set('product_id', state.product_id);
    bodyFormData.set('_method', 'PUT');
    const endpoint = `${URLAPI}coupon-create/${state.couponId}`;
    onSubmitCouponsForm(bodyFormData, endpoint);
    history.push('/discount-coupons');
  };

  const inputValidation = (error, name) => {
    const { isError } = { ...state };
    const currentState = isError;
    if (error) {
      currentState[name] = error.error;
    } else {
      currentState[name] = error;
    }
    setState({
      ...state,
      isError: currentState,
    });
  };

  const onCloseHandler = () => {
    resetForm();
    history.goBack();
  };


  if (!productsOption || selectedProduct.length === 0) {
    return <Loading />
  }
  return (
    <>
      <ModalWrapper>
        <BtnHolder>
          <CloseButton handleClick={onCloseHandler} />
        </BtnHolder>
        <div className={classes.paper}>
          <FormControl>
            <Typography variant="h5" color="primary">
              Edytuj kod rabatowy
            </Typography>
          </FormControl>
          <form onSubmit={e => handleSubmit(e)}>
            <FormControl>
              <Input
                isError={state.isError}
                value={state.code}
                id="code"
                onChange={handleChangeInput}
                htmlFor="code"
                label="Kod rabatowy"
                name="code"
                type="text"
                inputValidation={inputValidation}
                handleOnBlur={isValidationPassed}
                validators={[{ check: Validators.required, message: 'pole wymagane' }]}
              />
            </FormControl>
            <FormControl>
              <Input
                isError={state.isError}
                value={state.description}
                id="description"
                onChange={handleChangeInput}
                htmlFor="description"
                label="Opis"
                name="description"
                type="text"
                inputValidation={inputValidation}
                handleOnBlur={isValidationPassed}
              // validators={[{ check: Validators.required, message: 'pole wymagane' }]}
              />
            </FormControl>
            <FormControl>
              <Input
                isError={state.isError}
                value={state.discount}
                id="discount"
                onChange={handleChangeInput}
                htmlFor="discount"
                label="Nowa cena"
                name="discount"
                type="number"
                inputValidation={inputValidation}
                handleOnBlur={isValidationPassed}
                validators={[
                  { check: Validators.required, message: 'pole wymagane' },
                  { check: Validators.number, message: 'tylko liczby dodatnie' },
                ]}
              />
            </FormControl>
            <FormControl>
              <SelectWrapper>
                <SelectInput
                  val={discountType[0]}
                  options={couponsTypesOption}
                  noOptionsText="Nie znaleziono"
                  label="Wybierz kategorię"
                  handleOnChange={handleChangeSelect}
                  onChangeParams={['discount_type_id']}
                  name="discount_type_id"
                  id="discount_type_id"
                  selectValid={selectValid}
                  isError={state.isError.discount_type_id}
                  errorMessage="Pole wymagane"
                />
              </SelectWrapper>
            </FormControl>
            <FormControl>
              <SelectWrapper>
                <SelectInput
                  val={isCouponActive[0]}
                  options={couponsIsActive}
                  noOptionsText="Nie znaleziono"
                  label="Aktywny"
                  handleOnChange={handleChangeSelect}
                  onChangeParams={['is_active']}
                  name="is_active"
                  id="is_active"
                  selectValid={selectValid}
                  isError={state.isError.is_active}
                  errorMessage="Pole wymagane"
                />
              </SelectWrapper>
            </FormControl>
            <FormControl>
              <SelectWrapper>
                <SelectInput
                  options={productsOption}
                  val={selectedProduct[0]}
                  noOptionsText="Nie znaleziono"
                  label="Produkt"
                  handleOnChange={handleChangeSelect}
                  onChangeParams={['product_id']}
                  name="product_id"
                  id="product_id"
                  selectValid={selectValid}
                  isError={state.isError.product_id}
                  errorMessage="Pole wymagane"
                />
              </SelectWrapper>
            </FormControl>

            {validationPassed && <Error>Taki kupon już istnieje</Error>}
            <FormControl>
              <SubmitBtnWrapper>
                <ButtonPrimary isDisabled={validationPassed} type="submit">
                  zapisz zmiany
                </ButtonPrimary>
              </SubmitBtnWrapper>
            </FormControl>
          </form>
        </div>
      </ModalWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    coupons: state.coupons.coupon,
    singleCoupon: state.coupons.singleCoupon,
    products: state.products.products,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onInitCoupons: endpoint => dispatch(actionCreators.setsSingleCoupon(endpoint)),
    setsSingleCoupon: id => dispatch(actionCreators.fetchSingleCoupon(id)),

    onFetchProducts: () => dispatch(actionCreators.fetchProducts()),
    onSubmitCouponsForm: (bodyFormData, endpoint) =>
      dispatch(actionCreators.submitCouponsForm(bodyFormData, endpoint)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCoupons);
