import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';


const URLAPI = process.env.REACT_APP_URL_API;

export const setIngredientsCategoryOptionName = optionName => {
  return {
    type: actionTypes.SET_INGREDIENTS_CATEGORY_OPTION_NAME,
    optionName,
  };
};

export const setIngredientsCategory = ingredientsCategory => {
  return {
    type: actionTypes.SET_INGREDIENTS_CATEGORY,
    ingredientsCategory,
  };
};



export const fetchIngredientsCateogryFailed = (error) => {
  return {
    type: actionTypes.FETCH_INGREDIENTS_CATEGORY_FAILED,
    error
  };
};

export const fetchIngredientsCategory = () => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}ingredients-category`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then(res => {
        const ingredientsCategory = res.data.data;

        dispatch(setIngredientsCategory(ingredientsCategory));
        dispatch(setIngredientsCategoryOptionName(ingredientsCategory));
      })
      .catch(error => {
        dispatch(fetchIngredientsCateogryFailed(error));
      });
  };
};

export const deleteIngredientCategorySuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_INGREDIENT_CATEGORY_SUCCESS,
    id,
    msg: res
  };
};

export const deleteIngredientsFailed = error => {
  return {
    type: actionTypes.DELETE_INGREDIENT_CATEGORY_FAILED,
    error,

  };
};

export const deleteIngredientCategory = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .delete(`${URLAPI}ingredients-category/${id}`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then((res) => {
        dispatch(deleteIngredientCategorySuccess(id, res));
      })
      .catch(error => {
        dispatch(deleteIngredientsFailed(error));
      });
  };
};

export const setSingleIngredientsCategory = ingredientsCategory => {
  return {
    type: actionTypes.SET_SINGLE_INGREDIENTS_CATEGORY,
    ingredientsCategory,
  };
};

export const fetchSingleIngredientsCateogryFailed = () => {
  return {
    type: actionTypes.FETCH_SINGLE_INGREDIENTS_CATEGORY_FAILED,
  };
};


export const fetchSingleIngredientsCategory = (id) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}ingredients-category/${id}`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then(res => {
        const ingredientsCategory = res.data.data;

        dispatch(setSingleIngredientsCategory(ingredientsCategory));
      })
      .catch(error => {
        dispatch(fetchIngredientsCateogryFailed(error));
      });
  };
};


export const submitIngredientCategoryFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_INGREDIENTS_CATEGORY_FORM_SUCCESS,
    msg: res
  };
};

export const submitIngredientCategoryFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_INGREDIENTS_CATEGORY_FORM_FAILED,
    error,
  };
};


export const submitIngredientCategoryForm = (
  name,
  id,
) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .put(`${URLAPI}ingredients-category/${id}`, {
        name,
      }, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        },
      })
      .then(res => {
        dispatch(submitIngredientCategoryFormSuccess(res));
        dispatch(fetchIngredientsCategory());

      })
      .catch(error => {
        dispatch(submitIngredientCategoryFormFailed(error));
      });
  };
};


export const submitAddIngredientCategoryForm = (
  name,
  resetForm

) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .post(`${URLAPI}ingredients-category`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        },
        name,
      }, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        },
      })
      .then(res => {
        dispatch(submitIngredientCategoryFormSuccess(res));
        dispatch(fetchIngredientsCategory());
        resetForm()

      })
      .catch(error => {
        dispatch(submitIngredientCategoryFormFailed(error));
      });
  };
};
