import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
    validators: null,
};


const setValidators = (state, action) => {
    return {
        ...state,
        validators: action.validators,
        error: false,
    };
};

const fetchValidatorsFailed = state => {
    return {
        ...state,
        error: true,
    };
};


const validatorsReducer = (state = initialState, action, key) => {
    switch (action.type) {
        case actionTypes.SET_VALIDATORS:
            return setValidators(state, action, key);
        case actionTypes.FETCH_VALIDATORS_FAILED:
            return fetchValidatorsFailed(state);
        default:
            return state;
    }
};

export default validatorsReducer;