import React, { useEffect, useState } from 'react';
// import history from 'Routs/history'
import { useLocation, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { ReactComponent as MealsCategoryIcon } from 'assets/nav/k_posilkow.svg';
import { ReactComponent as IngredientsCategoryIcon } from 'assets/nav/k_skladnikow.svg';
import { ReactComponent as CustomersIcon } from 'assets/nav/klienci.svg';
import { ReactComponent as PaymentMethodsIcon } from 'assets/nav/m_platnosci.svg';
import { ReactComponent as ShippingMethodsIcon } from 'assets/nav/m_wysylki.svg';
import { ReactComponent as MealsIcon } from 'assets/nav/posilki.svg';
import { ReactComponent as HomePageIcon } from 'assets/nav/s_glowna.svg';
import { ReactComponent as IngredientsIcon } from 'assets/nav/skladniki.svg';
import { ReactComponent as UsersIcon } from 'assets/nav/uzytkownicy.svg';
import { ReactComponent as CurrencyIcon } from 'assets/nav/waluty.svg';
import { ReactComponent as OrdersIcon } from 'assets/nav/zamowienia.svg';
import { ReactComponent as ProductsIcon } from 'assets/nav/produkty.svg';
import { ReactComponent as PackagesIcon } from 'assets/nav/zestaw.svg';
import { ReactComponent as CouponsIcon } from 'assets/nav/kupony.svg';
import { ReactComponent as Invoices } from 'assets/nav/faktury.svg';

const Icon = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
`;
const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.background.default,
  },
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  drawerOpen: {
    width: drawerWidth,
    overflow: 'hidden',

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: theme.palette.primary.main,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    background: theme.palette.primary.main,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const LinkWrapper = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  transition: 0.4s;
  &:hover {
    padding-left: 16px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &:hover {
        padding-left: 0;
      }
      background-color: ${({ theme }) => theme.palette.secondary.main};
      svg {
        .st1,
      .st3,
      .st2,
      .st5,
      .st6,
      .st7,
      .st8,
      .st9,
      .st10,
      .st11,
      .st12,
      .st13,
      .st14,
      .st15,
      /* .st16, */
      /* .st1,
      .st1,
      .st1,
      .st1, */


      .st16 {
          fill: ${({ theme }) => theme.palette.common.white};
        }
      }
      svg {
        .st4,
        .st1 {
          fill: ${({ theme }) => theme.palette.secondary.main};
        }
      }
    `}
`;

const navLinks = [
  { txt: 'Strona główna', path: '/', icon: <HomePageIcon /> },
  { txt: 'Użytkownicy', path: '/users', icon: <UsersIcon /> },
  { txt: 'Dostępy użytkowników', path: '/permissions', icon: <UsersIcon /> },
  { txt: 'Składniki', path: '/ingredients', icon: <IngredientsIcon /> },
  { txt: 'Kategorie Składników', path: '/ingredients-category', icon: <IngredientsCategoryIcon /> },
  { txt: 'Posiłki', path: '/meals', icon: <MealsIcon /> },
  { txt: 'Kategorie Posiłków', path: '/meals-category', icon: <MealsCategoryIcon /> },
  { txt: 'Waluty', path: '/currencies', icon: <CurrencyIcon /> },
  { txt: 'Metody Płatności', path: '/payment-methods', icon: <PaymentMethodsIcon /> },
  { txt: 'Metody Wysyłki', path: '/shipments-method', icon: <ShippingMethodsIcon /> },
  { txt: 'Kupony rabatowe', path: '/discount-coupons', icon: <CouponsIcon /> },
  { txt: 'Produkty', path: '/products', icon: <ProductsIcon /> },
  { txt: 'Klienci', path: '/customers', icon: <CustomersIcon /> },
  { txt: 'Zamówienia', path: '/orders', icon: <OrdersIcon /> },
  { txt: 'Zestawy', path: '/packages', icon: <PackagesIcon /> },
  { txt: 'Faktury', path: '/invoices', icon: <Invoices /> },
];

const Nav = ({ currentUser, match, DebugHistory, history, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('/');

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const location = useLocation();

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton style={{ color: 'white' }} onClick={handleDrawerOpen}>
              {open ? <ChevronLeftIcon style={{ color: 'secondary' }} /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider className={classes.divider} />
          <List>
            {navLinks.map(link => (
              <NavLink exact to={link.path} key={link.txt}>
                <LinkWrapper isActive={currentLocation === link.path}>
                  <ListItem button>
                    <ListItemIcon>
                      <Icon>{link.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={link.txt} />
                  </ListItem>
                </LinkWrapper>
              </NavLink>
            ))}
          </List>
          <Divider className={classes.divider} />
        </Drawer>
        {children}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(Nav);
