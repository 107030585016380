import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
  permissions: [],
  permissionsOptions: [],
};

const setPermissionsOptions = (state, action) => {
  const permissionsOptions = action.options.map(option => {
    return { value: option.id, label: option.name };
  });

  return {
    ...state,
    permissionsOptions,
    error: false,
  };
};

const setPermissions = (state, action) => {
  return {
    ...state,
    permissions: action.permissions,
    error: false,
  };
};

const deletePermissionsSuccess = (state, action) => {
  return {
    ...state,
    permissions: [...state.permissions.filter(permission => permission.id !== action.id)],
  };
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PERMISSIONS:
      return setPermissions(state, action);
    case actionTypes.SET_PERMISSIONS_OPTIONS:
      return setPermissionsOptions(state, action);
    case actionTypes.DELETE_PERMISSIONS_SUCCESS:
      return deletePermissionsSuccess(state, action);
    default:
      return state;
  }
};

export default permissionsReducer;
