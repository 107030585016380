import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions';
import Input from 'components/atoms/Inputs/Input';
import Typography from '@material-ui/core/Typography';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators';
import SelectInput from 'components/atoms/Inputs/SelectInput';

const URLAPI = process.env.REACT_APP_URL_API;

const Wrapper = styled.div`
  display: block;
  margin: 16px auto;
  min-width: 120px;
  width: 100%;
  height: auto;
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
`;

const FormWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  background-color: #ffffff;
  color: #fff;
  z-index: 1;
  width: 100%;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const SingleInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const AddShipmentsMethod = ({
  onAddShipmentsMethodsForm,
  setVisible,
  unieqeShipmentsMethodName,
}) => {
  const [shipmentsName, setShipmentsName] = useState('');
  const [shipmentDescription, setShipmentsDescription] = useState('');
  const [init, setInit] = useState(false);
  const [isEnabled, setIsEnabled] = useState({ value: 0, label: 'nieaktywna' });
  const [price, setPrice] = useState('');

  const selectOptions = [
    { value: true, label: 'Aktywna' },
    { value: false, label: 'nieaktywna' },
  ];

  const [isError, setError] = useState({
    isError: {
      shipmentsName: null,
    },
  });

  const isValidationPassed = () => {
    return true;
  };

  const inputValidation = error => {
    if (error) {
      setError({ isError: { shipmentsName: error.error } });
    } else {
      setError({ isError: { shipmentsName: true } });
    }
  };
  const handleChangeName = e => {
    setShipmentsName(e.target.value);
  };
  const handleChangeDescription = e => {
    setShipmentsDescription(e.target.value);
  };
  const handleChangePrice = e => {
    setPrice(e.target.value);
  };

  const handleChangeSelect = e => {
    setIsEnabled(e);
  };

  const resetForm = () => {
    setVisible();
    setShipmentsName('');
    setShipmentsDescription('');
    setPrice('');
    setIsEnabled(null);
  };

  const handleSubmit = e => {
    e.preventDefault();
    onAddShipmentsMethodsForm(
      shipmentsName,
      shipmentDescription,
      price,
      isEnabled.value,
      resetForm,
    );
  };
  return (
    <Wrapper>
      <Typography variant="h5" color="primary">
        Dodaj nową metodę
      </Typography>
      <FormWrapper>
        <StyledForm onSubmit={e => handleSubmit(e)}>
          <SingleInputWrapper>
            <Input
              onChange={handleChangeName}
              uniqueNames={unieqeShipmentsMethodName}
              htmlFor="shipmentsName"
              label="Nazwa"
              name="shipmentsName"
              id="shipmentsName"
              type="text"
              value={shipmentsName}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
              validators={[
                { check: Validators.required, message: 'pole wymagane' },
                {
                  check: Validators.uniqueName,
                  message: 'Metoda wysyłki o podanej nazwie już  istnieje',
                },
              ]}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <Input
              onChange={handleChangeDescription}
              htmlFor="shipmentDescription"
              label="opis"
              name="shipmentDescription"
              id="shipmentDescription"
              type="text"
              value={shipmentDescription}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
              validators={[{ check: Validators.required, message: 'pole wymagane' }]}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <Input
              onChange={handleChangePrice}
              htmlFor="price"
              label="cena"
              name="price"
              id="price"
              type="number"
              value={price}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
              validators={[{ check: Validators.required, message: 'pole wymagane' }]}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <SelectInput
              options={selectOptions}
              noOptionsText="Nie znaleziono"
              label="Ustaw Status"
              handleOnChange={handleChangeSelect}
              onChangeParams={['unit']}
              name="unit"
              id="unit"
              // selectValid={selectValid}
              // isError={isError.unit}
              errorMessage="Pole wymagane"
            />
          </SingleInputWrapper>
          <ButtonWrapper>
            <ButtonPrimary type="submit"> Dodaj Metodę</ButtonPrimary>
          </ButtonWrapper>
        </StyledForm>
      </FormWrapper>
    </Wrapper>
  );
};

export default AddShipmentsMethod;
