import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';

const PrivateRoute = ({ currentUser, component: Component, wasInitialized, ...rest }) => {
    return (
        <>
            {currentUser.loggedIn === true ? (
                <Route
                    {...rest}
                    render={props =>
                        <Component {...rest}  {...props} />} />

            ) : (
                    <Redirect to="/login" />
                )}
        </>
    )
}
const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
    };
};


export default connect(mapStateToProps, null)(PrivateRoute);