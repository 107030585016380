import * as actionTypes from 'store/actions/actionsTypes';
// import { updateObject } from 'store/utility';

const initialState = {
  ingredients: [],
  optionsNameForSearch: [],
  error: false,
  ingredientsCategory: [],
  ingredientsSingleCategory: [],
  ingredientsConfig: null,
  units: [],
  optionsUnits: [],
  searchQuery: '',
  msg: '',
};

const setIngredientsOptionName = (state, action) => {
  const optionsNameSearch = action.optionName.map(option => {
    return {
      value: option.id,
      label: option.name,
      unit: option.unit,
      name_en: option.name_en,
      name_cz: option.name_cz,
      name_es: option.name_es,
      name_hu: option.name_hu,
      name_ro: option.name_ro,
      name_bg: option.name_bg,
      name_sk: option.name_sk,
      name_pt: option.name_pt,
      name_it: option.name_it,
      name_de: option.name_de,
      name_fr: option.name_fr,
    };
  });

  return {
    ...state,
    optionsNameForSearch: optionsNameSearch,
  };
};

const setIngredients = (state, action) => {
  return {
    ...state,
    ingredientsConfig: action.ingredients,
    ingredients: action.ingredients.data,
    error: false,
  };
};

const fetchIngredientsFailed = state => {
  return {
    ...state,
    error: true,
  };
};

const deleteIngredientsSuccess = (state, action) => {
  return {
    ...state,
    ingredients: [...state.ingredients.filter(ingredient => ingredient.id !== action.id)],
  };
};

const submitIngredientFormSuccess = (state, action) => {
  return {
    ...state,
    msg: action.msg,
  };
};

const submitIngredientFormFailed = (state, action) => {
  return {
    ...state,
    msg: action.error,
  };
};

const deletePhotoOfIngredientSuccess = (state, action) => {
  return {
    ...state,
    msg: action.msg,
  };
};

// UNITS
const setUnits = (state, action) => {
  return {
    ...state,
    units: action.units,
    error: false,
  };
};

const fetchUnitsFailed = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

const setUnitsOptions = (state, action) => {
  const optionsUnits = action.units.map(option => {
    return { value: option.name, label: option.name };
  });

  return {
    ...state,
    optionsUnits,
  };
};

const setIngredientsQueryAfterSearch = (state, action) => {
  return {
    ...state,
    searchQuery: action.query,
  };
};

const ingredientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INGREDIENTS:
      return setIngredients(state, action);
    case actionTypes.FETCH_INGREDIENTS_FAILED:
      return fetchIngredientsFailed(state);
    case actionTypes.SET_INGREDIENTS_OPTION_NAME:
      return setIngredientsOptionName(state, action);
    case actionTypes.DELETE_INGREDIENTS_SUCCESS:
      return deleteIngredientsSuccess(state, action);
    case actionTypes.SUBMIT_FORM_ADD_NEW_INGREDIENT_SUCCESS:
      return submitIngredientFormSuccess(state, action);
    case actionTypes.SUBMIT_FORM_ADD_NEW_INGREDIENT_FAILED:
      return submitIngredientFormFailed(state, action);
    case actionTypes.DELETE_PHOTO_OF_INGREDIENT_SUCCESS:
      return deletePhotoOfIngredientSuccess(action);
    case actionTypes.SET_UNITS:
      return setUnits(state, action);
    case actionTypes.FETCH_UNITS_FAILED:
      return fetchUnitsFailed(state, action);
    case actionTypes.SET_UNITS_OPTIONS:
      return setUnitsOptions(state, action);
    case actionTypes.SET_INGREDIENTS_QUERY_AFTER_SEARCH:
      return setIngredientsQueryAfterSearch(state, action);

    default:
      return state;
  }
};

export default ingredientsReducer;
