import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions/index';
import Input from 'components/atoms/Inputs/Input'
import Typography from '@material-ui/core/Typography';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators'

const Wrapper = styled.div`
    display: block;
    margin: 16px auto;
    min-width: 120px;
    width: 100%;
    height:auto;
    padding: 32px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
`

const FormWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  background-color: #ffffff;
  color: #fff;
  z-index: 1;
  width: 100%;
`
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonWrapper = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
`

const SingleInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const URLAPI = process.env.REACT_APP_URL_API;


const AddIngredientCategory = ({
  igCategory,
  onSubmitAddIngredientCategoryForm,
  setVisible,
  onInitValidators,
  unieqeIngredientsCategoryNames,
}) => {
  const [categoryName, seCategoryName] = useState('');
  const [isError, setError] = useState({
    isError: {
      categoryName: null
    }
  }
  );








  const isValidationPassed = () => {
    return true
  }

  const inputValidation = (error) => {
    if (error) {
      setError({ isError: { categoryName: error.error } })
    } else {
      setError({ isError: { categoryName: true } })
    }
  }
  const handleChange = (e) => {
    seCategoryName(e.target.value)
  }

  const resetForm = () => {
    setVisible();
    seCategoryName('');
    setError({
      isError: {
        categoryName: true
      }
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitAddIngredientCategoryForm(categoryName, resetForm)
  }



  return (
    <Wrapper>
      <Typography variant="h5" color="primary">Dodaj nową kategorię</Typography>
      <FormWrapper>
        <StyledForm
          onSubmit={(e) => handleSubmit(e)}
        >
          <SingleInputWrapper>
            <Input
              onChange={handleChange}
              uniqueNames={unieqeIngredientsCategoryNames}
              htmlFor='categoryName'
              label="Nazwa"
              name='CategoryName'
              id="CategoryName"
              type='text'
              value={categoryName}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
              validators={[
                { check: Validators.required, message: 'pole wymagane' },
                { check: Validators.uniqueName, message: 'Kategoria o podanej nazwie już  istnieje' },
              ]}
            />
          </SingleInputWrapper>

          <ButtonWrapper>

            <ButtonPrimary type="submit">Dodaj kategorię</ButtonPrimary>
          </ButtonWrapper>
        </StyledForm>
      </FormWrapper>

    </Wrapper>
  );
}

export default AddIngredientCategory;

