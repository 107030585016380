// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

export const EditorTxtConfig = {
  // plugins: [Alignment],
  uiColor: '#ff2058',
  // table: {
  //   contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  // },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
    ],
  },
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    'blockQuote',
    // 'tableRow',
    // 'tableColumn',
    // 'mergeTableCells',
    // 'insertTable',
    'alignment:left',
    'alignment:right',
    'alignment:center',
    'alignment:justify',
  ],
};

export default EditorTxtConfig;
