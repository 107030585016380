import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
    'overrides': {
        'MuiIconButton': {
            'root': {
                color: '#2E2D41',

                '&:hover': {
                    backgroundColor: 'rgba(216, 234, 106, 1)',
                    color: '#ffff'
                }
            }
        },
    },
    "palette": {
        "common": {
            "black": "#000",
            "white": "#fff",
            'border': '#e0e0e0',
        },
        "background": {
            "paper": "#fff",
            "default": "#ffffff",
            "hover": "#BBCE3F",
        },
        "primary": {
            "light": "rgba(84, 84, 91, 1)",
            "main": "#2E2D41",
            "dark": "rgba(34, 33, 48, 1)",
            "contrastText": "#fff"
        },
        "secondary": {
            "light": "rgba(216, 234, 106, 1)",
            "main": "#BBCE3F",
            "dark": "rgba(166, 186, 39, 1)",
            "contrastText": "#fff"
        },
        "error": {
            "light": "#e57373",
            "main": "#f44336",
            "dark": "#d32f2f",
            "contrastText": "#fff"
        },
        "success": {
            "light": "#28a745",
            "main": "#28a745",
            "dark": "#28a745",
            "contrastText": "#fff"
        },
        "warning": {
            "light": "#ffc107",
            "main": "#ffc107",
            "dark": "#ffc107",
            "contrastText": "#fff"
        },
        "text": {
            "primary": "rgba(46, 45, 65, 1)",
            "secondary": "#c4c4c4",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "rgba(0, 0, 0, 0.38)"
        }
    }
});

export default theme