import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;
// GET
export const setCoupon = (coupon, couponCounter) => {
  return {
    type: actionTypes.SET_COUPON,
    coupon,
    couponCounter,

  };
};


export const fetchCouponFailed = error => {
  return {
    type: actionTypes.FETCH_COUPON_FAILED,
    error,
  };
};

export const initCoupons = endpoint => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const coupons = res.data.data;

        dispatch(setCoupon(coupons));
      })
      .catch(error => {
        dispatch(fetchCouponFailed(error));
      });
  };
};

export const fetchCoupon = (endpoint) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .get(`${URLAPI}${endpoint}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })

      .then(res => {
        const coupon = res.data.data;
        const couponCounter = res.data.numbers;
        dispatch(setCoupon(coupon, couponCounter));
      })
      .catch(error => {
        dispatch(fetchCouponFailed(error));
      });
  };
};

export const fetchAllCoupon = () => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .get(`${URLAPI}coupon-create?per_page=1000000`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })

      .then(res => {
        const coupon = res.data.data;
        const couponCounter = res.data.numbers;
        dispatch(setCoupon(coupon, couponCounter));
      })
      .catch(error => {
        dispatch(fetchCouponFailed(error));
      });
  };
};

// GET ONE
export const setsSingleCoupon = singleCoupon => {
  return {
    type: actionTypes.SET_SINGLE_COUPON,
    singleCoupon,
  };
};

export const fetchSingleCouponFailed = error => {
  return {
    type: actionTypes.FETCH_SINGLE_COUPON_FAILED,
    error,
  };
};

export const fetchSingleCoupon = (id) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .get(`${URLAPI}coupon-create/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })

      .then(res => {
        const Coupon = res.data.data;

        dispatch(setsSingleCoupon(Coupon));
      })
      .catch(error => {
        dispatch(fetchSingleCouponFailed(error));
      });
  };
};

// ADD SHIPMENT METHOD

export const submitCouponsFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_COUPON_FORM_SUCCESS,
    msg: res,
  };
};

export const submitCouponsFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_COUPON_FORM_FAILED,
    error,
  };
};

export const addCouponsForm = (code, discount, type, is_active, resetForm) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .post(
        `${URLAPI}coupon-create`,
        {
          code,
          discount,
          type,
          is_active,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(submitCouponsFormSuccess(res));
        dispatch(fetchCoupon());
        resetForm();
      })
      .catch(error => {
        dispatch(submitCouponsFormFailed(error));
      });
  };
};

// EDIT Coupons

export const submitCouponsForm = (bodyFormData, endpoint) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios({
      method: 'post',
      url: endpoint,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authKey}`,
      },
    })
      .then(res => {
        dispatch(submitCouponsFormSuccess(res));
        dispatch(fetchCoupon());
      })
      .catch(error => {
        dispatch(submitCouponsFormFailed(error));
      });
  };
};

// DELETE

export const deleteCouponsSuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_COUPON_SUCCESS,
    id,
    msg: res,
  };
};

export const deleteCouponsFailed = error => {
  return {
    type: actionTypes.DELETE_COUPON_FAILED,
    error,
  };
};

export const deleteCoupons = id => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .delete(`${URLAPI}coupon-create/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        dispatch(deleteCouponsSuccess(id, res));
        dispatch(fetchCoupon());
      })
      .catch(error => {
        dispatch(deleteCouponsFailed(error));
      });
  };
};
