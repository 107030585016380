import React from 'react';
import { IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const CloseButton = ({ handleClick, validationPassed, runValidation, funcVal, itemId }) => {
  const clickHandler = (e) => {
    handleClick(itemId, e)
    if (runValidation) {
      runValidation()
    }
    if (funcVal) {
      handleClick(funcVal)
    }
  }

  return (
    <IconButton aria-label="delete"
      onClick={() => clickHandler()}
    >
      <HighlightOffIcon fontSize='large' />
    </IconButton>
  );
}
CloseButton.defaultProps = { validationPassed: true }


export default CloseButton;


