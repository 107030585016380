import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';


const URLAPI = process.env.REACT_APP_URL_API;
// GET
export const setCountries = countries => {
    return {
        type: actionTypes.SET_COUNTRIES,
        countries,
    };
};

export const fetchCountriesFailed = (error) => {
    return {
        type: actionTypes.FETCH_COUUNTRIES_FAILED,
        error
    };
};

export const fetchCountries = () => {
    return dispatch => {
        const authKey = Cookies.getJSON('authKey');

        axios
            .get(`${URLAPI}countries`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                }
            })
            .then(res => {
                const countries = res.data.data;
                dispatch(setCountries(countries));

            })
            .catch(error => {
                dispatch(fetchCountriesFailed(error));

            });
    };
};

// GET ONE 
export const setsSingleCoutries = coutries => {
    return {
        type: actionTypes.SET_SINGLE_COUNTRY,
        singleCountry: coutries,
    };
};

export const fetchSinglecoutriesFailed = (error) => {
    return {
        type: actionTypes.FETCH_SINGLE_COUNTRY_FAILED,
        error
    };
};

export const fetchSinglecoutries = (id) => {
    return dispatch => {
        const authKey = Cookies.getJSON('authKey');

        axios
            .get(`${URLAPI}countries/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                }
            })
            .then(res => {
                const coutries = res.data.data;

                dispatch(setsSingleCoutries(coutries));
            })
            .catch(error => {
                dispatch(fetchSinglecoutriesFailed(error));
            });
    };
};
