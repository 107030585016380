import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
  ingredientsCategory: [],

  optionsNameCategoryForSearch: [],
};

const setIngredientsCategoryOptionName = (state, action) => {

  const optionsNameSearch = action.optionName.map(option => {
    return { value: option.id, label: option.name };
  });

  return {
    ...state,
    optionsNameCategoryForSearch: optionsNameSearch,
    error: false,
  };
};

const setIngredientsCategory = (state, action) => {
  return {
    ...state,
    ingredientsCategory: action.ingredientsCategory,
    error: false,
  };
};

const deleteIngredientsCategorySuccess = (state, action) => {
  return {
    ...state,
    ingredientsCategory: [...state.ingredientsCategory.filter(ingredientCategory => ingredientCategory.id !== action.id)],
  };
};

const setSingleIngredientsCategory = (state, action) => {
  return {
    ...state,
    singleingredientsCategory: action.singleIngredientsCategory,
    error: false,
  };
};

const ingredientsCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INGREDIENTS_CATEGORY:
      return setIngredientsCategory(state, action);
    case actionTypes.SET_INGREDIENTS_CATEGORY_OPTION_NAME:
      return setIngredientsCategoryOptionName(state, action);
    case actionTypes.DELETE_INGREDIENT_CATEGORY_SUCCESS:
      return deleteIngredientsCategorySuccess(state, action);
    case actionTypes.SET_SINGLE_INGREDIENTS_CATEGORY:
      return setSingleIngredientsCategory(state, action);
    default:
      return state;
  }
};

export default ingredientsCategoryReducer;
