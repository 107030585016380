const regex = {
  email: new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  ),
  number: new RegExp('^[0-9]*$'),
};
const minLength = 6;

export class Validators {
  static email(value, message) {
    if (value) {
      const result = regex.email.test(value);
      if (!result) return { error: true, message };
    }
    return false;
  }

  static required(value, message) {
    if (!value || !value.toString().trim().length) {
      return { error: true, message };
    }
    return false;
  }

  static number(value, message) {
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regex.number.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static length(value, message) {
    const length = value ? value.toString().length : 0;
    if (length < minLength && length !== 0) {
      return { error: true, message };
    }
    return false;
  }

  static uniqueLabel(value, message, uniqueNames) {
    const searchQery = value.toLowerCase();
    let reslut = null;
    uniqueNames.find(el => {
      const searchValue = el.label.toLowerCase();
      reslut = searchValue === searchQery;
      return reslut;
    });
    if (reslut) {
      return { error: true, message };
    }
    return false;
  }

  static uniqueName(value, message, uniqueNames) {
    const searchQery = value.toLowerCase();

    let reslut = null;
    uniqueNames.find(el => {
      const searchValue = el.name.toLowerCase();
      reslut = searchValue === searchQery;
      return reslut;
    });
    if (reslut) {
      return { error: true, message };
    }
    return false;
  }

  static uniqueNameEdit(value, message, uniqueNames, oldValue) {
    const searchQery = value.toLowerCase();
    let reslut = null;
    if (value === oldValue) {
      return reslut;
    }
    uniqueNames.find(el => {
      const searchValue = el.name.toLowerCase();
      reslut = searchValue === searchQery;
      return reslut;
    });

    if (reslut) {
      return { error: true, message };
    }
    return false;
  }

  static uniqueEmail(value, message, uniqueNames) {
    const searchQery = value.toLowerCase();
    let reslut = null;
    uniqueNames.find(el => {
      const searchValue = el.email.toLowerCase();
      reslut = searchValue === searchQery;
      return reslut;
    });
    if (reslut) {
      return { error: true, message };
    }
    return false;
  }

  static uniqueEmailEdit(value, message, uniqueNames, oldValue) {
    const searchQery = value.toLowerCase();
    let reslut = null;
    if (value === oldValue) {
      return reslut;
    }
    uniqueNames.find(el => {
      const searchValue = el.email.toLowerCase();
      reslut = searchValue === searchQery;
      return reslut;
    });
    if (reslut) {
      return { error: true, message };
    }
    return false;
  }

  static notEmpty(value, message) {
    if (value === '') {
      return { error: true, message };
    }
    return false;
  }

  static threeLetters(value, message) {
    const length = value ? value.toString().length : 0;
    if (length !== 3 && length !== 0) {
      return { error: true, message };
    }
    return false;
  }
}

export const validateInput = (validators, value, uniqueNames, oldValue, isError) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i += 1) {
      const error = validators[i].check(
        value,
        validators[i].message,
        uniqueNames,
        oldValue,
        isError,
      );
      if (error) {
        return error;
      }
    }
  }
  return false;
};
