// post /invoice (year, month, last_day)
// put /invoice/id (first_name, last_name, city, country, street, house_number, post_code, vat)
// get /invoice (month, year)


import * as actionTypes from 'store/actions/actionsTypes';

import axios from 'axios';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;
// GET
export const setInvoices = invoices => {
    return {
        type: actionTypes.SET_INVOICES,
        invoices,
    };
};

export const fetchInvoicesFailed = error => {
    return {
        type: actionTypes.FETCH_INVOICES_FAILED,
        error,
    };
};

export const initInvoices = endpoint => {
    const authKey = Cookies.getJSON('authKey');
    return dispatch => {
        axios
            .get(endpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authKey}`,
                },
            })
            .then(res => {
                const invoices = res.data.data;

                dispatch(setInvoices(invoices));
            })
            .catch(error => {
                dispatch(fetchInvoicesFailed(error));
            });
    };
};

export const fetchInvoices = () => {
    const authKey = Cookies.getJSON('authKey');
    return dispatch => {
        axios
            .get(`${URLAPI}invoices`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authKey}`,
                },
            })
            .then(res => {
                const invoices = res.data.data;
                dispatch(setInvoices(invoices));
            })
            .catch(error => {
                dispatch(fetchInvoicesFailed(error));
            });
    };
};

// GET ONE
export const setSingleInvoice = invoice => {
    return {
        type: actionTypes.SET_SINGLE_INVOICES,
        invoice,
    };
};

export const fetchSingleInvoiceFailed = error => {
    return {
        type: actionTypes.FETCH_SINGLE_INVOICES_FAILED,
        error,
    };
};

export const fetchSingleInvoice = () => {
    const authKey = Cookies.getJSON('authKey');
    return dispatch => {
        axios
            .get(`${URLAPI}invoices`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authKey}`,
                },
            })
            .then(res => {
                const invoice = res.data.data;

                dispatch(setSingleInvoice(invoice));
            })
            .catch(error => {
                dispatch(fetchSingleInvoiceFailed(error));
            });
    };
};

// EDIT Invoices

export const submitInvoicesFormSuccess = res => {
    return {
        type: actionTypes.SUBMIT_INVOICES_FORM_SUCCESS,
        msg: res,
    };
};

export const submitInvoicesFormFailed = error => {
    return {
        type: actionTypes.SUBMIT_INVOICES_FORM_FAILED,
        error,
    };
};

export const submitInvoicesForm = (bodyFormData, endpoint, history) => {
    const authKey = Cookies.getJSON('authKey');
    return dispatch => {
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authKey}`,
            },
        })
            .then(res => {
                dispatch(submitInvoicesFormSuccess(res));
                dispatch(fetchInvoices());
                if (history !== null) {
                    history.push('/invoices/add-invoices/');
                }
            })
            .catch(error => {
                dispatch(submitInvoicesFormFailed(error));
            });
    };
};


// GENERETE INVOICES

export const createInvoicesSuccess = res => {
    return {
        type: actionTypes.CREATE_INVOICES_SUCCESS,
        msg: res,
    };
};

export const createInvoicesFailed = error => {
    return {
        type: actionTypes.CREATE_INVOICES_FAILED,
        error,
    };
};

export const createInvoices = (year, month, lastDay, history) => {
    const authKey = Cookies.getJSON('authKey');
    const endpoint = `${URLAPI}invoice/create`
    return dispatch => {
        axios
            .post(
                endpoint,
                {
                    year,
                    month,
                    last_day: lastDay,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authKey}`,
                    },
                },
            )
            .then(res => {
                dispatch(createInvoicesSuccess(res));
                // dispatch(fetchInvoices());
                // if (history !== null) {
                //     history.push('/invoices/add-invoices/');
                // }
            })
            .catch(error => {
                dispatch(createInvoicesFailed(error));
            });
    };
};