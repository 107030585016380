/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
// import Select from 'react-select';
import { IconButton, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import { pl } from 'date-fns/esm/locale';
import * as actionCreators from 'store/actions';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import SelectInput from 'components/atoms/Inputs/SelectInput';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import Input from 'components/atoms/Inputs/Input';

const URLAPI = process.env.REACT_APP_URL_API;
const avalibleCountries = [
  { label: 'Polska', value: 'PL' },
  { label: 'USA', value: 'US' },
];

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Form = styled.form`
  display: ${props => (props.showSearch === true ? 'block' : 'none')};
`;

const SingleInputWrapper = styled.div`
  width: 98%;
  margin-bottom: 16px;
  flex-basis: 48%;
  @media screen and (min-width: 992px) {
    flex-basis: 49%;
  }
`;

const InputWrapperSM = styled(SingleInputWrapper)`
  width: 32%;
`;

const useStyles = makeStyles(theme => ({
  root: {
    color: '#000000',
    label: 'color: #000000',
  },
  formControl: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: 120,
    width: '80%',
    height: 'auto',
    padding: theme.spacing(2),
    paddingBottom: 1,
    borderRadius: 5,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SearchUsers = ({
  onSetUsersQueryAfterSearch,
  usersOptionsNameForSearch,
  showSearch,
  onInitAllUsersForOptions,
}) => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    usersOptionsName: [],
    createtAtStart: null,
    createtAtEnd: null,
    siteLang: null,
  });

  useEffect(() => {
    const endpoint = `${URLAPI}users/?records=all`;

    onInitAllUsersForOptions(endpoint);
  }, []);

  useEffect(() => {
    const newOptions = [];
    if (state.usersOptionsName.length === 0 && usersOptionsNameForSearch != null) {
      usersOptionsNameForSearch.map(el =>
        newOptions.push({ value: el.id, label: `${el.first_name} ${el.last_name}` }),
      );
    }
    setState(state => ({
      ...state,
      usersOptionsName: newOptions,
    }));
  }, [usersOptionsNameForSearch]);

  const handleSearch = (inputValue, key, type) => {
    let value = '';
    if (inputValue) {
      if (type === 'text' || type === 'number') {
        value = inputValue.target.value.toLowerCase();
      } else if (type === 'select') {
        value = inputValue;
      } else {
        value = inputValue;
      }
      setState(state => ({
        ...state,
        [key]: value,
      }));
    } else {
      setState(state => ({
        ...state,
        [key]: { value: '' },
      }));
      return null;
    }
    return null;
  };

  const handleUserCreatetAtStart = date => {
    setState(state => ({
      ...state,
      createtAtStart: date,
    }));
  };

  const handleUserCreatetEnd = date => {
    setState(state => ({
      ...state,
      createtAtEnd: date,
    }));
  };

  const parseDate = date => {
    const currentDate = new Date(date);
    const yr = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    return `${yr}-${month}-${day}`;
  };

  const handleSubmitSearchForm = e => {
    e.preventDefault();
    let query = '';

    const nameQuery = state.firstName !== '' ? `&first_name=${state.firstName}` : ``;
    const lastNameQuery = `${state.lastName !== '' ? `&last_name=${state.lastName}` : ''}`;
    const emailQuery = `${state.email !== '' ? `&email=${encodeURIComponent(state.email)}` : ''}`;
    const dateStart =
      state.createtAtStart !== null
        ? `&created_at_start=${parseDate(state.createtAtStart)} 00:00:00`
        : '';
    const dateEnd =
      state.createtAtEnd !== null
        ? `&created_at_end=${parseDate(state.createtAtEnd)} 23:59:59`
        : '';
    const userLanguage = state.siteLang !== null ? `&site_lang=${state.siteLang}` : '';

    query = `${nameQuery}${lastNameQuery}${emailQuery}${dateStart}${dateEnd}${userLanguage}`;
    onSetUsersQueryAfterSearch(`${query}`);
  };

  const handleChangeSiteLang = inputValue => {
    if (inputValue != null) {
      setState(state => ({
        ...state,
        siteLang: inputValue.value,
      }));
    }
  };
  const classes = useStyles();

  return (
    <>
      <Form showSearch={showSearch} onSubmit={e => handleSubmitSearchForm(e)}>
        <FormControl className={classes.formControl}>
          <SingleInputWrapper htmlFor="firstName">
            <Input
              label="Imię"
              name="firstName"
              type="text"
              id="firstName"
              onChange={handleSearch}
              onChangeParams={['firstName', 'text']}
            // value={state.customersOptionsName}
            />
          </SingleInputWrapper>
          <SingleInputWrapper htmlFor="lastName">
            <Input
              label="Nazwisko"
              name="lastName"
              type="text"
              id="lastName"
              onChange={handleSearch}
              onChangeParams={['lastName', 'text']}
            // value={state.customersOptionsName}
            />
          </SingleInputWrapper>
          <SingleInputWrapper htmlFor="email">
            <Input
              label="E-mail"
              name="email"
              type="text"
              id="email"
              onChange={handleSearch}
              onChangeParams={['email', 'text']}
            // value={state.customersOptionsName}
            />
          </SingleInputWrapper>
          <InputWrapperSM>
            <SelectInput
              options={avalibleCountries}
              optionType="label"
              noOptionsText="Nie znaleziono"
              label="Kraj"
              handleOnChange={handleChangeSiteLang}
              onChangeParams={['siteLang', 'select']}
              // value={orderType}
              id="siteLang"
            />
          </InputWrapperSM>

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
            <InputWrapperSM>
              <KeyboardDatePicker
                clearable
                // variant="inline"
                autoOk
                ampm={false}
                disableFuture
                value={state.createtAtStart}
                onChange={handleUserCreatetAtStart}
                label="Data utworzenia konta od:"
                showTodayButton
                format="yyyy/MM/dd"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <EventIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </InputWrapperSM>
            <InputWrapperSM>
              <KeyboardDatePicker
                clearable
                // variant="inline"
                minDate={state.createtAtStart}
                autoOk
                ampm={false}
                disableFuture
                value={state.createtAtEnd}
                onChange={handleUserCreatetEnd}
                label="Data utworzenia konta do:"
                showTodayButton
                format="yyyy/MM/dd"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <EventIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </InputWrapperSM>
          </MuiPickersUtilsProvider>

          <ButtonWrapper>
            <ButtonPrimary type="submit"> Szukaj</ButtonPrimary>
          </ButtonWrapper>
        </FormControl>
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  return {
    users: state.users,
    usersOptionsNameForSearch: state.users.usersOptionsNameForSearch,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetUsersQueryAfterSearch: query => dispatch(actionCreators.setUsersQueryAfterSearch(query)),
    onInitAllUsersForOptions: () => dispatch(actionCreators.initAllUsersForOptions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchUsers);
