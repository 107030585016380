/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const URLBASE = process.env.REACT_APP_URL_BASE;


const FlagIcon = styled.img`
  margin-right: 8px;
`;

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.black,

  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    '&:hover': {
      background: theme.palette.background.hover,
      color: theme.palette.common.white
    }
  },
  noOptions: {
    minHeight: 48,
    cursor: 'pointer',
    boxSizing: 'border-box',
    alignItems: 'center',

    marginTop: 6,
    marginBottom: 6,
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.background.hover,
    },
  },

}));
const SelectInputIcon = ({
  options,
  noOptionsText,
  label,
  handleOnChange,
  onChangeParams,
  isError,
  id,
  errorMessage,
  val,
  optionType,
  disabledOption,
  multiple,
  name,


}) => {
  const classes = useStyles();

  const onChangeHandler = (e, value) => {
    if (handleOnChange) {
      handleOnChange(value);
    }
    if (handleOnChange && onChangeParams) {
      handleOnChange(value, ...onChangeParams);
    }
  };

  return (
    <>
      <Autocomplete
        multiple={multiple}
        value={val}
        loading={!options}
        options={options}
        openOnFocus
        blurOnSelect="mouse"
        id={id}
        getOptionDisabled={disabledOption}
        noOptionsText={noOptionsText}
        getOptionLabel={option => option[optionType] || option.name}
        // eslint-disable-next-line react/jsx-boolean-value
        fullWidth
        error={typeof isError === 'boolean' ? isError : false}
        helperText={isError ? errorMessage : ''}
        renderOption={option => {
          if (name === 'currency') {
            return (
              <>
                <FlagIcon
                  src={`${URLBASE}storage/flags/${option.code}`}
                  alt={option.iso_3166_2}
                />
                {option[optionType]} &nbsp;
                {optionType === 'currency_code' ? option.name : ''}
                {optionType === 'country' ? option.name : ''}
              </>
            )
          }
          return (
            <>
              <FlagIcon
                src={`${URLBASE}storage/flags/${option.flag}`}
                alt={option.iso_3166_2}
              />
              {option[optionType]} &nbsp;
              {optionType === 'currency_code' ? option.name : ''}
              {optionType === 'country' ? option.name : ''}
            </>
          );
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            color="secondary"
            error={typeof isError === 'boolean' ? isError : false}
            helperText={isError ? errorMessage : ''}

          />
        )}
        onChange={(e, value) => onChangeHandler(e, value)}
        classes={{
          root: classes.root,
          noOptions: classes.noOptions,
          option: classes.option,
        }}
      />
    </>
  );
};

export default SelectInputIcon;
