/* eslint-disable no-else-return */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.black,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    '&:hover': {
      background: theme.palette.background.hover,
      color: theme.palette.common.white,
    },
  },

  noOptions: {
    minHeight: 48,
    cursor: 'pointer',
    boxSizing: 'border-box',
    alignItems: 'center',
    marginTop: 6,
    marginBottom: 6,
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.background.hover,
      color: theme.palette.common.white,
    },
  },
}));
const SelectInput = ({
  options,
  noOptionsText,
  label,
  handleOnChange,
  onChangeParams,
  isError,
  id,
  errorMessage,
  val,
  optionType,
  disabledOption,
  multiple,
  disabled,
  handleSelectAll,
  name,
}) => {
  const classes = useStyles();

  const onChangeHandler = (e, value) => {
    if (handleOnChange) {
      handleOnChange(value);
    }
    if (handleOnChange && onChangeParams) {
      handleOnChange(value, ...onChangeParams);
    }
  };

  return (
    <>
      <Autocomplete
        disabled={disabled}
        multiple={multiple}
        value={val}
        loading={!options}
        options={options}
        getOptionDisabled={disabledOption}
        openOnFocus
        blurOnSelect="mouse"
        id={id}
        // options={options || initOptions}
        noOptionsText={noOptionsText}
        getOptionLabel={option => option[optionType] || option.label}
        // eslint-disable-next-line react/jsx-boolean-value
        fullWidth
        error={typeof isError === 'boolean' ? isError : false}
        helperText={isError ? errorMessage : ''}
        renderOption={option => {
          if (optionType) {
            return (
              <>
                {optionType === 'last_name' ? `${option.id} | ${option.first_name} ` : ''}
                {option[optionType]}
                {optionType === 'currency_code' ? `${option.name}` : ''}
              </>
            );
          } else {
            return <>{option.label}</>;
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            color="secondary"
            error={typeof isError === 'boolean' ? isError : false}
            helperText={isError ? errorMessage : ''}
            name={name}
          />
        )}
        onChange={(e, value) => onChangeHandler(e, value)}
        classes={{
          root: classes.root,
          noOptions: classes.noOptions,
          option: classes.option,
        }}
      />
    </>
  );
};

export default SelectInput;
