/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions';
import { connect } from 'react-redux';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import Typography from '@material-ui/core/Typography';
import Input from 'components/atoms/Inputs/Input';
import { Validators } from 'components/atoms/Inputs/Validators';
import SelectImput from 'components/atoms/Inputs/SelectInput';
import Loading from 'components/atoms/Loading/Loading';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';

const URLAPI = process.env.REACT_APP_URL_API;

const ModalWrapper = styled.div`
  margin: 16px auto;
  position: relative;
  width: 80%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[5]};
  padding: ${({ theme }) => theme.spacing(2, 4, 3)};
  border-radius: 5px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputsWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const Col = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterWrapper = styled.div`
  width: 80%;
  margin-left: auto;
`;
const SelectWrapper = styled.div`
  width: 49%;
  margin-bottom: 16px;
`;

const EditPackages = ({
  history,
  match,
  packageToEdit,
  onSubmitPackageForm,
  newKey,
  mealsListData,
  onInitMeals,
  onFetchSinglePackage,
  onInitValidators,
  uniquePackagesNames,
  onFetchMealsCategoryDiets,
  mealsCategoryDietsOptions,
}) => {
  const [mealsList, setMealsList] = useState([]);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (match) {
      onFetchSinglePackage(match.params.id);
    }
    onFetchMealsCategoryDiets();
  }, []);

  useEffect(() => {
    const endpoint = `${URLAPI}package/name`;
    onInitValidators(endpoint, 'packages');
  }, [mealsListData]);

  const setElementsForCurrentPage = () => {
    const endpoint = `${URLAPI}meals?records=all`;
    // onInitAllMealsForOptions()
    onInitMeals(endpoint);
  };

  useEffect(() => {
    setMealsList(mealsListData);
  }, [mealsListData]);

  useEffect(() => {
    if (mealsList !== null && init === false) {
      setElementsForCurrentPage();
      setInit(true);
    }
  }, [mealsList]);

  const [inputsValue, setInputValue] = useState({
    newPackageName: '',
    newBreakfast: null,
    newSecondBreakfast: null,
    newDinner: null,
    newSupper: null,
    dairy: null,
    fish: null,
    gluten: null,
    lactose: null,
    meat: null,
    seafood: null,
    selectedDietsCategory: { value: '', label: '' },
    newSelectedDietsCategory: { value: '', label: '' },
    categoryDiets: [],
  });

  const [searchValue, setSearchValue] = useState({
    dairy: null,
    fish: null,
    gluten: null,
    lactose: null,
    meat: null,
    seafood: null,
  });

  useEffect(() => {
    if (packageToEdit !== undefined) {
      console.log('package', packageToEdit);
      setInputValue({
        ...inputsValue,
        newPackageName: packageToEdit[0].name,
        newBreakfast: packageToEdit[0].meals[0],
        newSecondBreakfast: packageToEdit[0].meals[1],
        newDinner: packageToEdit[0].meals[2],
        newSupper: packageToEdit[0].meals[3],
        newSelectedDietsCategory: {
          value: packageToEdit[0].category_diets[0].id,
          label: packageToEdit[0].category_diets[0].name,
        },
      });

      setSearchValue({
        ...searchValue,
        dairy: +packageToEdit[0]?.dairy,
        fish: +packageToEdit[0]?.fish,
        gluten: +packageToEdit[0]?.gluten,
        lactose: +packageToEdit[0]?.lactose,
        meat: +packageToEdit[0]?.meat,
        seafood: +packageToEdit[0]?.seafood,
      });
    }
  }, [packageToEdit]);

  const handleSubmitForm = e => {
    e.preventDefault();
    const packageId = match.params.id;
    const mealsArray = [
      { meal_id: inputsValue.newBreakfast.id },
      { meal_id: inputsValue.newSecondBreakfast.id },
      { meal_id: inputsValue.newDinner.id },
      { meal_id: inputsValue.newSupper.id },
    ];

    const categoryDietId = inputsValue.newSelectedDietsCategory.value;

    onSubmitPackageForm(packageId, inputsValue.newPackageName, mealsArray, categoryDietId, history);
  };

  const handleInputChange = e => {
    setInputValue({
      ...inputsValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeSelect = (e, key) => {
    console.log('key', key);
    setInputValue({
      ...inputsValue,
      [key]: e,
    });
  };

  const handleOpen = () => {
    history.push('/packages');
  };

  const filterOptions = [
    { value: 0, label: 'Nie zawiera' },
    { value: 1, label: 'Zawiera' },
  ];

  const filtredMealsOptions = mealsCategoryId => {
    if (mealsList?.length === 0 || mealsList !== null) {
      const newMealsList = mealsList;
      // .filter(searchValue.meat === 0 ? meal => meal.meat === searchValue.meat : meal => meal)
      // .filter(searchValue.dairy === 0 ? meal => meal.dairy === searchValue.dairy : meal => meal)
      // .filter(searchValue.fish === 0 ? meal => meal.fish === searchValue.fish : meal => meal)
      // .filter(searchValue.gluten === 0 ? meal => meal.gluten === searchValue.gluten : meal => meal)
      // .filter(searchValue.lactose === 0 ? meal => meal.lactose === searchValue.lactose : meal => meal)
      // .filter(searchValue.seafood === 0 ? meal => meal.seafood === searchValue.seafood : meal => meal)

      const filtredByCategory = newMealsList?.filter(el =>
        el.category_meals.every(() => el.category_meals.some(x => x.id === mealsCategoryId)),
      );

      const { dairy, fish, gluten, lactose, meat, seafood } = inputsValue;

      // DAIRY
      const filtredByDairy = () => {
        if (dairy !== null) {
          return filtredByCategory.filter(el => el.dairy === dairy?.value);
        } else {
          return filtredByCategory;
        }
      };

      // FISH
      const filterByFish = () => {
        if (fish !== null) {
          return filtredByDairy().filter(el => el.fish === fish?.value);
        } else {
          return filtredByDairy();
        }
      };

      // GLUTEN
      const filterByGluten = () => {
        if (gluten !== null) {
          return filterByFish().filter(el => el.gluten === gluten?.value);
        } else {
          return filterByFish();
        }
      };

      //  LACTOSE
      const filterByLactose = () => {
        if (lactose !== null) {
          return filterByGluten().filter(el => el.lactose === lactose?.value);
        } else {
          return filterByGluten();
        }
      };

      // MEAT
      const filterByMeat = () => {
        if (meat !== null) {
          return filterByLactose().filter(el => el.meat === meat?.value);
        } else {
          return filterByLactose();
        }
      };

      // SEAFOOD
      const filterBySeafood = () => {
        if (seafood !== null) {
          return filterByMeat().filter(el => el.seafood === seafood?.value);
        } else {
          return filterByMeat();
        }
      };
      return filterBySeafood();
    }
  };

  const breakfastOptions = filtredMealsOptions('58e42f4b-d7aa-471c-a4bc-e4f5e59a57c9');
  const secondBreakfastOptions = filtredMealsOptions('70150f29-d2ef-4d2a-b676-1e6abbf61959');
  const dinerOptions = filtredMealsOptions('a26f8326-65d5-46ff-ad29-52f3354d1537');
  const supperOptions = filtredMealsOptions('13500b18-93c0-4a4a-bb31-46c5754fa94b');

  if (
    packageToEdit === undefined ||
    mealsListData === null ||
    mealsList?.lenght === 0 ||
    breakfastOptions === undefined ||
    secondBreakfastOptions === undefined ||
    dinerOptions === undefined ||
    supperOptions === undefined
  ) {
    return <Loading />;
  } else {
    return (
      <ModalWrapper>
        <BtnHolder>
          <CloseButton handleClick={handleOpen} funcVal={newKey} />
        </BtnHolder>
        <StyledForm onSubmit={e => handleSubmitForm(e)}>
          <InputsWrapper>
            <Typography variant="h5" color="primary">
              Edytuj Pakiet
            </Typography>
          </InputsWrapper>
          <InputsWrapper>
            <Input
              id="newPackageName"
              uniqueNames={uniquePackagesNames}
              onChange={handleInputChange}
              value={inputsValue.newPackageName}
              oldValue={packageToEdit[0].name}
              label="Edytuj nazwę"
              name="newPackageName"
              type="text"
              validators={[
                { check: Validators.required, message: 'pole wymagane' },
                {
                  check: Validators.uniqueNameEdit,
                  message: 'Składnik o podanej nazwie już istnieje',
                },
              ]}
            />
          </InputsWrapper>
          <Box>
            <Col>
              <Row>
                <InputsWrapper>
                  <SelectImput
                    options={mealsCategoryDietsOptions}
                    val={inputsValue.newSelectedDietsCategory}
                    noOptionsText="Brak danych"
                    label="Przypisz Dietę"
                    handleOnChange={handleChangeSelect}
                    onChangeParams={['newSelectedDietsCategory']}
                    name="newSelectedDietsCategory"
                    id="newSelectedDietsCategory"
                    isError={null}
                    errorMessage="Pole wymagane"
                    // optionType="value"
                    // val={{ label: 'test' }}

                    // id="categoryDiets"
                  />
                </InputsWrapper>
              </Row>
              <Row>
                <InputsWrapper>
                  <SelectImput
                    options={filtredMealsOptions('58e42f4b-d7aa-471c-a4bc-e4f5e59a57c9')}
                    optionType="name"
                    val={inputsValue.newBreakfast}
                    noOptionsText="Brak danych"
                    label="Śniadania"
                    handleOnChange={handleChangeSelect}
                    onChangeParams={['newBreakfast']}
                    id="newBreakfast"
                  />
                </InputsWrapper>
              </Row>
              <Row>
                <InputsWrapper>
                  <SelectImput
                    options={filtredMealsOptions('70150f29-d2ef-4d2a-b676-1e6abbf61959')}
                    optionType="name"
                    val={inputsValue.newSecondBreakfast}
                    noOptionsText="Brak danych"
                    label="II Śniadania"
                    handleOnChange={handleChangeSelect}
                    onChangeParams={['newSecondBreakfast']}
                    id="newSecondBreakfast"
                  />
                </InputsWrapper>
              </Row>
              <Row>
                <InputsWrapper>
                  <SelectImput
                    options={filtredMealsOptions('a26f8326-65d5-46ff-ad29-52f3354d1537')}
                    optionType="name"
                    val={inputsValue.newDinner}
                    noOptionsText="Brak danych"
                    label="Obiady"
                    handleOnChange={handleChangeSelect}
                    onChangeParams={['newDinner']}
                    id="newDinner"
                  />
                </InputsWrapper>
              </Row>
              <Row>
                <InputsWrapper>
                  <SelectImput
                    options={filtredMealsOptions('13500b18-93c0-4a4a-bb31-46c5754fa94b')}
                    optionType="name"
                    val={inputsValue.newSupper}
                    noOptionsText="Brak danych"
                    label="Kolacje"
                    handleOnChange={handleChangeSelect}
                    onChangeParams={['newSupper']}
                    id="newSupper"
                  />
                </InputsWrapper>
              </Row>
            </Col>
            <Col>
              <FilterWrapper>
                <InputsWrapper>
                  <Typography variant="h6" color="primary">
                    Filtruj dania
                  </Typography>
                </InputsWrapper>

                <Row>
                  <SelectWrapper>
                    <SelectImput
                      options={filterOptions}
                      // disabled={!packageToEdit[0]?.meat}
                      // val={searchValue.meat}
                      noOptionsText="Brak danych"
                      label="Mięso"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['meat']}
                      id="meat"
                    />
                  </SelectWrapper>
                  <SelectWrapper>
                    <SelectImput
                      // disabled={!packageToEdit[0]?.dairy}
                      // val={searchValue.dairy}
                      options={filterOptions}
                      noOptionsText="Brak danych"
                      label="Nabiał"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['dairy']}
                      id="dairy"
                    />
                  </SelectWrapper>
                </Row>
                <Row>
                  <SelectWrapper>
                    <SelectImput
                      // disabled={!packageToEdit[0]?.fish}
                      // val={searchValue.fish}
                      options={filterOptions}
                      noOptionsText="Brak danych"
                      label="Ryby"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['fish']}
                      id="fish"
                    />
                  </SelectWrapper>
                  <SelectWrapper>
                    <SelectImput
                      // disabled={!packageToEdit[0]?.seafood}
                      // val={searchValue.seafood}
                      options={filterOptions}
                      noOptionsText="Brak danych"
                      label="Owoce morza"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['seafood']}
                      id="seafood"
                    />
                  </SelectWrapper>
                </Row>
                <Row>
                  <SelectWrapper>
                    <SelectImput
                      // disabled={!packageToEdit[0]?.gluten}
                      // val={searchValue.gluten}
                      options={filterOptions}
                      noOptionsText="Brak danych"
                      label="Gluten"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['gluten']}
                      id="gluten"
                    />
                  </SelectWrapper>
                  <SelectWrapper>
                    <SelectImput
                      // disabled={!packageToEdit[0]?.lactose}
                      // val={searchValue.lactose}
                      options={filterOptions}
                      noOptionsText="Brak danych"
                      label="Laktoza"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['lactose']}
                      id="lactose"
                    />
                  </SelectWrapper>
                </Row>
              </FilterWrapper>
            </Col>
          </Box>
          <ButtonWrapper>
            <ButtonPrimary type="submit">Zapisz zmainy</ButtonPrimary>
          </ButtonWrapper>
        </StyledForm>
      </ModalWrapper>
    );
  }
};

const mapStateToProps = state => {
  return {
    packageToEdit: state.packages.singlePackage,
    mealsListData: state.packages.meals,
    uniquePackagesNames: state.validators.validators,
    mealsCategoryDiets: state.mealsCategoryDiets.mealsCategoryDiets,
    mealsCategoryDietsOptions: state.mealsCategoryDiets?.mealsCategoryDietsOptions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitMeals: endpoint => dispatch(actionCreators.initMealsPackages(endpoint)),
    onInitValidators: endpoint => dispatch(actionCreators.initValidators(endpoint)),
    onFetchSinglePackage: id => dispatch(actionCreators.fetchSinglePackage(id)),
    onFetchMealsCategoryDiets: () => dispatch(actionCreators.fetchMealsCategoryDiets()),
    onSubmitPackageForm: (id, name, meals, categoryDietId, history) =>
      dispatch(actionCreators.submitPackageForm(id, name, meals, categoryDietId, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPackages);
