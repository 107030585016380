// INGREDIENTS
export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const FETCH_INGREDIENTS_FAILED = 'FETCH_INGREDIENTS_FAILED';
export const SET_INGREDIENTS_OPTION_NAME = 'SET_INGREDIENTS_OPTION_NAME';

export const DELETE_INGREDIENTS_SUCCESS = 'DELETE_INGREDIENTS_SUCCESS';
export const DELETE_INGREDIENTS_FAILED = 'DELETE_INGREDIENTS_FAILED';

export const SUBMIT_FORM_ADD_NEW_INGREDIENT = 'SUBMIT_FORM_ADD_NEW_INGREDIENT';
export const SUBMIT_FORM_ADD_NEW_INGREDIENT_SUCCESS = 'SUBMIT_FORM_ADD_NEW_INGREDIENT_SUCCESS';
export const SUBMIT_FORM_ADD_NEW_INGREDIENT_FAILED = 'SUBMIT_FORM_ADD_NEW_INGREDIENT_FAILED';

export const DELETE_PHOTO_OF_INGREDIENT = 'DELETE_PHOTO_OF_INGREDIENT';
export const DELETE_PHOTO_OF_INGREDIENT_SUCCESS = 'DELETE_PHOTO_OF_INGREDIENT_SUCCESS';
export const DELETE_PHOTO_OF_INGREDIENT_FAILED = 'DELETE_PHOTO_OF_INGREDIENT_FAILED';

export const SET_UNITS = 'SET_UNITS';
export const FETCH_UNITS_FAILED = 'FETCH_UNITS_FAILED';
export const SET_UNITS_OPTIONS = 'SET_UNITS_OPTIONS';

export const SET_INGREDIENTS_QUERY_AFTER_SEARCH = 'SET_INGREDIENTS_QUERY_AFTER_SEARCH';

// INGREDIENTS CATEGORY
export const SET_INGREDIENTS_CATEGORY = 'SET_INGREDIENTS_CATEGORY';
export const FETCH_INGREDIENTS_CATEGORY_FAILED = 'FETCH_INGREDIENTS_CATEGORY_FAILED';
export const SET_INGREDIENTS_CATEGORY_OPTION_NAME = 'SET_INGREDIENTS_CATEGORY_OPTION_NAME';
export const DELETE_INGREDIENT_CATEGORY_FAILED = 'DELETE_INGREDIENT_CATEGORY_FAILED';
export const DELETE_INGREDIENT_CATEGORY_SUCCESS = 'DELETE_INGREDIENT_CATEGORY_SUCCESS';
export const SET_SINGLE_INGREDIENTS_CATEGORY = 'SET_SINGLE_INGREDIENTS_CATEGORY';
export const FETCH_SINGLE_INGREDIENTS_CATEGORY_FAILED = 'FETCH_SINGLE_INGREDIENTS_CATEGORY_FAILED';
export const SUBMIT_INGREDIENTS_CATEGORY_FORM_SUCCESS = 'SUBMIT_INGREDIENTS_CATEGORY_FORM_SUCCESS';
export const SUBMIT_INGREDIENTS_CATEGORY_FORM_FAILED = 'SUBMIT_INGREDIENTS_CATEGORY_FORM_FAILED';

// MEALS
export const SET_MEALS = 'SET_MEALS';
export const FETCH_MEALS_FAILED = 'FETCH_MEALS_FAILED';
export const SET_MEALS_OPTION_NAME = 'SET_MEALS_OPTION_NAME';

export const DELETE_MEALS_SUCCESS = 'DELETE_MEALS_SUCCESS';
export const DELETE_MEALS_FAILED = 'DELETE_MEALS_FAILED';

export const GET_SINGLE_MEALS_TO_EDIT_SUCCESS = 'GET_SINGLE_MEALS_TO_EDIT_SUCCESS';

export const SUBMIT_FORM_ADD_NEW_MEAL_SUCCESS = 'SUBMIT_FORM_ADD_NEW_MEAL_SUCCESS';
export const SUBMIT_FORM_ADD_NEW_MEAL_FAILED = 'SUBMIT_FORM_ADD_NEW_MEAL_FAILED';

export const SET_MEALS_QUERY_AFTER_SEARCH = 'SET_MEALS_QUERY_AFTER_SEARCH';

// MEALS CATEGORY

export const SET_MEALS_CATEGORY = 'SET_MEALS_CATEGORY';
export const FETCH_MEALS_CATEGORY_FAILED = 'FETCH_MEALS_CATEGORY_FAILED';
export const SET_MEALS_CATEGORY_OPTION_NAME = 'SET_MEALS_CATEGORY_OPTION_NAME';
export const SET_MEALS_CATEGORY_OPTIONS = 'SET_MEALS_CATEGORY_OPTIONS';

export const SUBMIT_MEALS_FORM_SUCCESS = 'SUBMIT_MEALS_FORM_SUCCESS';
export const SUBMIT_MEALS_FORM_FAILED = 'SUBMIT_MEALS_FORM_FAILED';
export const DELETE_MEAL_CATEGORY_SUCCESS = 'DELETE_MEAL_CATEGORY_SUCCESS';
export const DELETE_MEAL_CATEGORY_FAILED = 'DELETE_MEAL_CATEGORY_FAILED';

export const FETCH_SINGLE_MEALS_CATEGORY_FAILED = 'FETCH_SINGLE_MEALS_CATEGORY_FAILED';
export const SET_SINGLE_MEALS_CATEGORY = 'SET_SINGLE_MEALS_CATEGORY';
export const SUBMIT_MEALS_CATEGORY_FORM_SUCCESS = 'SUBMIT_MEALS_CATEGORY_FORM_SUCCSS';
export const SUBMIT_MEALS_CATEGORY_FORM_FAILED = 'SUBMIT_MEALS_CATEGORY_FORM_FAILED';

// categoryDiets
export const SET_MEALS_CATEGORY_DIETS = 'SET_MEALS_CATEGORY_DIETS';
export const FETCH_MEALS_CATEGORY_DIETS_FAILED = 'FETCH_MEALS_CATEGORY_DIETS_FAILED';
export const SET_MEALS_CATEGORY_DIETS_OPTION_NAME = 'SET_MEALS_CATEGORY_DIETS_OPTION_NAME';
export const SET_MEALS_CATEGORY_DIETS_OPTIONS = 'SET_MEALS_CATEGORY_DIETS_OPTIONS';

export const DELETE_MEAL_CATEGORY_DIETS_SUCCESS = 'DELETE_MEAL_CATEGORY_DIETS_SUCCESS';
export const DELETE_MEAL_CATEGORY_DIETS_FAILED = 'DELETE_MEAL_CATEGORY_DIETS_FAILED';

export const FETCH_SINGLE_MEALS_CATEGORY_DIETS_FAILED = 'FETCH_SINGLE_MEALS_CATEGORY_DIETS_FAILED';
export const SET_SINGLE_MEALS_CATEGORY_DIETS = 'SET_SINGLE_MEALS_CATEGORY_DIETS';
export const SUBMIT_MEALS_CATEGORY_DIETS_FORM_SUCCESS = 'SUBMIT_MEALS_CATEGORY_DIETS_FORM_SUCCSS';
export const SUBMIT_MEALS_CATEGORY_DIETS_FORM_FAILED = 'SUBMIT_MEALS_CATEGORY_DIETS_FORM_FAILED';

// CURRENCIES
export const SET_CURRENCIES = 'SET_CURRENCIES';
export const FETCH_CURRENCIES_FAILED = 'FETCH_CURRENCIES_FAILED';
export const SET_SINGLE_CURRENCY = 'SET_SINGLE_CURRENCY';
export const FETCH_SINGLE_CURRENCY_FAILED = 'FETCH_SINGLE_CURRENCY_FAILED';
export const SUBMIT_CURRENCY_FORM_SUCCESS = 'SUBMIT_CURRENCY_FORM_SUCCESS';
export const SUBMIT_CURRENCY_FORM_FAILED = 'SUBMIT_CURRENCY_FORM_FAILED';
export const DELETE_CURRENCY_SUCCESS = 'DELETE_CURRENCY_SUCCESS';
export const DELETE_CURRENCY_FAILED = 'DELETE_CURRENCY_FAILED';

// PAYMENT METHODS
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const FETCH_PAYMENT_METHOD_FAILED = 'FETCH_PAYMENT_METHOD_FAILED';
export const FETCH_SINGLE_PAYMENT_MEHTODS_FAILED = 'FETCH_SINGLE_PAYMENT_MEHTODS_FAILED';
export const SET_SINGLE_PAYMENT_MEHTODS = 'SET_SINGLE_PAYMENT_MEHTODS';
export const SUBMIT_PAYMENT_MEHTODS_FORM_FAILED = 'SUBMIT_PAYMENT_MEHTODS_FORM_FAILED';
export const SUBMIT_PAYMENT_MEHTODS_FORM_SUCCESS = 'SUBMIT_PAYMENT_MEHTODS_FORM_SUCCESS';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const SET_SINGLE_PAYMENT_METHOD = 'SET_SINGLE_PAYMENT_METHOD';
export const DELETE_PAYMENT_MEHTODS_FAILED = 'DELETE_PAYMENT_MEHTODS_FAILED';
export const DELETE_PAYMENT_MEHTODS_SUCCESS = 'DELETE_PAYMENT_MEHTODS_SUCCESS';

// SHIPMENTS METHOD
export const SUBMIT_SHIPMENTS_METHOD_FORM_FAILED = 'SUBMIT_SHIPMENTS_METHOD_FORM_FAILED';
export const SUBMIT_SHIPMENTS_METHOD_FORM_SUCCESS = 'SUBMIT_SHIPMENTS_METHOD_FORM_SUCCESS';
export const FETCH_SINGLE_SHIPMENTS_METHOD_FAILED = 'FETCH_SINGLE_SHIPMENTS_METHOD_FAILED';
export const SET_SINGLE_SHIPMENTS_METHOD = 'SET_SINGLE_SHIPMENTS_METHOD';
export const FETCH_SHIPMENTS_METHOD_FAILED = 'FETCH_SHIPMENTS_METHOD_FAILED';
export const SET_SHIPMENTS_METHOD = 'SET_SHIPMENTS_METHOD';
export const DELETE_SHIPMENTS_METHOD_SUCCESS = 'DELETE_SHIPMENTS_METHOD_SUCCESS';
export const DELETE_SHIPMENTS_METHOD_FAILED = 'DELETE_SHIPMENTS_METHOD_FAILED';

// PRODUCTS

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED';
export const SET_SINGLE_PRODUCTS = 'SET_SINGLE_PRODUCTS';
export const FETCH_SINGLE_PRODUCTS_FAILED = 'FETCH_SINGLE_PRODUCTS_FAILED';
export const SUBMIT_PRODUCTS_FORM_SUCCESS = 'SUBMIT_PRODUCTS_FORM_SUCCESS';
export const SUBMIT_PRODUCTS_FORM_FAILED = 'SUBMIT_PRODUCTS_FORM_FAILED';
export const DELETE_PRODUCTS_FAILED = 'DELETE_PRODUCTS_FAILED';
export const DELETE_PRODUCTS_SUCCESS = 'DELETE_PRODUCTS_SUCCESS';

// users

// export const SET_USERS = 'SET_USERS';
// export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
// export const SET_SINGLE_USERS = 'SET_SINGLE_USERS';
// export const FETCH_SINGLE_USERS_FAILED = 'FETCH_SINGLE_USERS_FAILED';
// export const SUBMIT_USERS_FORM_SUCCESS = 'SUBMIT_USERS_FORM_SUCCESS';
// export const SUBMIT_USERS_FORM_FAILED = 'SUBMIT_USERS_FORM_FAILED';
// export const DELETE_USERS_FAILED = 'DELETE_USERS_FAILED';
// export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';

// USERS

export const SET_USERS = 'SET_USERS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_FAILED = 'DELETE_USERS_FAILED';
export const SUBMIT_FORM_ADD_NEW_USERS_SUCCESS = 'SUBMIT_FORM_ADD_NEW_USERS_SUCCESS';
export const SUBMIT_FORM_ADD_NEW_USERS_FAILED = 'SUBMIT_FORM_ADD_NEW_USERS_FAILED';
export const GET_SINGLE_USERS = 'GET_SINGLE_USERS';
export const GET_SINGLE_USERS_ORDER = 'GET_SINGLE_USERS_ORDER';
export const SET_USERS_QUERY_AFTER_SEARCH = 'SET_USERS_QUERY_AFTER_SEARCH';
export const SUBMIT_USERS_FORM_FAILED = 'SUBMIT_USERS_FORM_FAILED';
export const SET_USERS_OPTION_NAME = 'SET_USERS_OPTION_NAME';
export const GENERETE_DIET_SUCCESS = 'GENERETE_DIET_SUCCESS';
export const GENERETE_DIET_FAILED = 'GENERETE_DIET_FAILED';
export const USER_DIET_SUCCESS = 'USER_DIET_SUCCESS';
export const USER_DIET_FAILED = 'USER_DIET_FAILED';
export const USER_DIET_EXIST = 'USER_DIET_EXIST';
export const SUBMIT_EXCLUDE_FORM = 'SUBMIT_EXCLUDE_FORM';

export const GET_SINGLE_USERS_INFO = 'GET_SINGLE_USERS_INFO';
export const SUBMIT_FORM_ADD_NEW_USERS_INFO_FAILED = 'SUBMIT_FORM_ADD_NEW_USERS_INFO_FAILED';
export const SUBMIT_EXCLUDE_FORM_FAILED = 'SUBMIT_EXCLUDE_FORM_FAILED';
export const SUBMIT_EMAIL_FORM_FAILED = 'SUBMIT_EMAIL_FORM_FAILED';
export const SUBMIT_EMAIL_FORM = 'SUBMIT_EMAIL_FORM';
export const SUBMIT_KCAL_FORM_FAILED = 'SUBMIT_KCAL_FORM_FAILED';
export const SUBMIT_KCAL_FORM = 'SUBMIT_KCAL_FORM';
export const SUBMIT_START_DIET_FORM_FAILED = 'SUBMIT_START_DIET_FORM_FAILED';
export const SUBMIT_START_DIET_FORM = 'SUBMIT_START_DIET_FORM';

// COUNTIRES

export const SET_COUNTRIES = 'SET_COUNTRIES';
export const FETCH_COUUNTRIES_FAILED = 'FETCH_COUUNTRIES_FAILED';
export const SET_SINGLE_COUNTRY = 'SET_SINGLE_COUNTRY';
export const FETCH_SINGLE_COUNTRY_FAILED = 'FETCH_SINGLE_COUNTRY_FAILED';

// orders
export const SET_ORDERS = 'SET_ORDERS';
export const FETCH_ORDERS_FAILED = 'FETCH_ORDERS_FAILED';
export const SET_SINGLE_ORDERS = 'SET_SINGLE_ORDERS';
export const FETCH_SINGLE_ORDERS_FAILED = 'FETCH_SINGLE_ORDERS_FAILED';
export const SUBMIT_ORDERS_FORM_SUCCESS = 'SUBMIT_ORDERS_FORM_SUCCESS';
export const SUBMIT_ORDERS_FORM_FAILED = 'SUBMIT_ORDERS_FORM_FAILED';
export const DELETE_ORDERS_SUCCESS = 'DELETE_ORDERS_SUCCESS';
export const DELETE_ORDERS_FAILED = 'DELETE_ORDERS_FAILED';
export const DELETE_ORDERS_MANY_SUCCESS = 'DELETE_ORDERS_MANY_SUCCESS';
export const DELETE_ORDERS_MANY_FAILED = 'DELETE_ORDERS_MANY_FAILED';
export const GET_SINGLE_ORDERS_TO_EDIT_SUCCESS = 'GET_SINGLE_ORDERS_TO_EDIT_SUCCESS';
export const SET_ORDERS_QUERY_AFTER_SEARCH = 'SET_ORDERS_QUERY_AFTER_SEARCH';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILED = 'UPDATE_STATUS_FAILED';

// validationPassed
export const SET_VALIDATORS = 'SET_VALIDATORS';
export const FETCH_VALIDATORS_FAILED = 'FETCH_VALIDATORS_FAILED';

// PACKAGE

export const SET_PACKAGES = 'SET_PACKAGES';
export const FETCH_PACKAGES_FAILED = 'FETCH_PACKAGES_FAILED';
export const SET_SINGLE_PACKAGE = 'SET_SINGLE_PACKAGE';
export const FETCH_SINGLE_PACKAGE_FAILED = 'FETCH_SINGLE_PACKAGE_FAILED';
export const SUBMIT_PACKAGE_FORM_SUCCESS = 'SUBMIT_PACKAGE_FORM_SUCCESS';
export const SUBMIT_PACKAGE_FORM_FAILED = 'SUBMIT_PACKAGE_FORM_FAILED';
export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS';
export const DELETE_PACKAGE_FAILED = 'DELETE_PACKAGE_FAILED';
export const SET_MEALS_PACKAGES = 'SET_MEALS_PACKAGES';
export const FETCH_MEALS_PACKAGES_FAILED = 'FETCH_MEALS_PACKAGES_FAILED';

// LOGIN
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

// NOTIFICATIONS

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_UNUSED_NOTIFICATIONS = 'REMOVE_UNUSED_NOTIFICATIONS';

// COUPONS
export const SUBMIT_COUPON_FORM_FAILED = 'SUBMIT_COUPON_FORM_FAILED';
export const SUBMIT_COUPON_FORM_SUCCESS = 'SUBMIT_COUPON_FORM_SUCCESS';
export const FETCH_SINGLE_COUPON_FAILED = 'FETCH_SINGLE_COUPON_FAILED';
export const SET_SINGLE_COUPON = 'SET_SINGLE_COUPON';
export const FETCH_COUPON_FAILED = 'FETCH_COUPON_FAILED';
export const SET_COUPON = 'SET_COUPON';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAILED = 'DELETE_COUPON_FAILED';

// MEALS CATEGORY

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const FETCH_PERMISSIONS_FAILED = 'FETCH_PERMISSIONS_FAILED';
export const SET_PERMISSIONS_OPTION_NAME = 'SET_PERMISSIONS_OPTION_NAME';
export const SET_PERMISSIONS_OPTIONS = 'SET_PERMISSIONS_OPTIONS';

export const SUBMIT_PERMISSIONS_FORM_SUCCESS = 'SUBMIT_PERMISSIONS_FORM_SUCCESS';
export const SUBMIT_PERMISSIONS_FORM_FAILED = 'SUBMIT_PERMISSIONS_FORM_FAILED';
export const DELETE_PERMISSIONS_SUCCESS = 'DELETE_PERMISSIONS_SUCCESS';
export const DELETE_PERMISSIONS_FAILED = 'DELETE_PERMISSIONS_FAILED';

export const FETCH_SINGLE_PERMISSIONS_FAILED = 'FETCH_SINGLE_PERMISSIONS_FAILED';
export const SET_SINGLE_PERMISSIONS = 'SET_SINGLE_PERMISSIONS';

// invoices
export const SET_INVOICES = 'SET_INVOICES';
export const FETCH_INVOICES_FAILED = 'FETCH_INVOICES_FAILED';
export const GET_SINGLE_INVOICES_TO_EDIT_SUCCESS = 'GET_SINGLE_INVOICES_TO_EDIT_SUCCESS';
export const SET_SINGLE_INVOICES = 'SET_SINGLE_INVOICES';
export const FETCH_SINGLE_INVOICES_FAILED = 'FETCH_SINGLE_INVOICES_FAILED';
export const SUBMIT_INVOICES_FORM_SUCCESS = 'SUBMIT_INVOICES_FORM_SUCCESS';
export const SUBMIT_INVOICES_FORM_FAILED = 'SUBMIT_INVOICES_FORM_FAILED';
export const CREATE_INVOICES_SUCCESS = 'CREATE_INVOICES_SUCCESS';
export const CREATE_INVOICES_FAILED = 'CREATE_INVOICES_FAILED';
