import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Input from 'components/atoms/Inputs/Input';
import Typography from '@material-ui/core/Typography';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators';
import SelectImput from 'components/atoms/Inputs/SelectInput';
import SelectInputIcon from 'components/atoms/Inputs/SelectInputIcon';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import avatar from 'assets/no-photo.png';
import pdfLogo from 'assets/pdfLogo.svg';

const Wrapper = styled.div`
  display: block;
  margin: 16px auto;
  min-width: 120px;
  width: 100%;
  height: auto;
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
`;

const FormWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  background-color: #ffffff;
  color: #fff;
  z-index: 1;
  width: 100%;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const SingleInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  ${({ border }) =>
    border &&
    css`
      border: 1px solid #c4c4c4;
      border-radius: 0 0 4px 4px;
      border-top: 0;
      margin-bottom: 20px;
    `}
  ${({ file }) =>
    file &&
    css`
      width: 210px;
    `}
  ${({ imgBox }) =>
    imgBox &&
    css`
      width: 210px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-color: #c4c4c4;
    `}
  ${({ noMarginB }) =>
    noMarginB &&
    css`
      margin-bottom: 0;
    `}
`;

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const ImgWrapper = styled.div`
  width: 48%;
`;
const ProductImg = styled.img`
  width: 72px;
  height: auto;
  margin: 12px;
`;

const ProductName = styled.div`
  color: ${({ theme }) => theme.palette.common.black};
`;

const initialState = {
  productName: '',
  shortName: '',
  category: '',
  price: '',
  productSubName: '',
  image: '',
  currency: '',
  language: '',
  type: '',
  quantity: '',
  description: '',
  listTitle: '',
  listElements: [],
  tmpImgURL: '',
  avatarImg: true,
  file: '',
  productLang: '',
  oldPrice: '',
  tmpPdf: '',
  avatarPdf: true,
  pdf: '',
  pdfName: '',
};

const AddProducts = ({
  onAddProductsForm,
  setVisible,
  unieqeNames,
  countries,
  productsCategories,
}) => {
  const [state, setState] = useState(initialState);
  const [numbersOFListElements, setNumbersOFListElements] = useState([0]);
  const [isError, setError] = useState({
    isError: {
      productName: null,
    },
  });
  const classes = useStyles();

  const isValidationPassed = () => {
    return true;
  };

  const inputValidation = error => {
    if (error) {
      setError({ isError: { productName: error.error } });
    } else {
      setError({ isError: { productName: true } });
    }
  };
  const handleChangeInput = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeListElInput = e => {
    const { listElements } = { ...state };
    const currentState = listElements;
    currentState[e.target.name] = e.target.value;
    setState({
      ...state,
      listElements: currentState,
    });
  };

  const handleChangeSelect = (e, key) => {
    setState({
      ...state,
      [key]: e,
    });
  };

  const resetForm = () => {
    setState(initialState);
    setVisible();
  };

  const handleSubmit = e => {
    const listElementsArray = Object.values(state.listElements);
    e.preventDefault();
    const listToJson = JSON.stringify(listElementsArray);
    const bodyFormData = new window.FormData();
    bodyFormData.set('name', state.productName);
    bodyFormData.set('subname', state.productSubName);
    bodyFormData.set('short_name', state.shortName);
    bodyFormData.set('price', state.price);
    bodyFormData.set('price_old', state.oldPrice);
    bodyFormData.set('currency', state.currency.currency_code);
    bodyFormData.set('type', state.type.name);
    bodyFormData.set('language', state.language.iso_3166_2);
    bodyFormData.set('product_lang', state.language.iso_3166_2);
    bodyFormData.set('description', state.description);
    bodyFormData.set('list_title', state.listTitle);
    bodyFormData.set('list_elements', listToJson);
    bodyFormData.set('quantity', state.quantity);
    bodyFormData.set('category_product_id', state.category.value);

    if (state.file !== '') {
      bodyFormData.append('image', state.file);
    }
    if (state.pdf !== '') {
      bodyFormData.set('ebook', state.pdf);
      bodyFormData.set('file_name', state.pdfName);
    }
    onAddProductsForm(bodyFormData, resetForm);
  };

  const selectValues = [
    { value: 1, name: 'PDF' },
    { value: 2, name: 'VIDEO' },
  ];

  const onAddElement = e => {
    setNumbersOFListElements([...numbersOFListElements, numbersOFListElements.length]);
  };

  const handleChangeFile = e => {
    const reader = new window.FileReader(e);
    reader.onload = () => {
      const dataURL = reader.result;

      setState(state => ({
        ...state,
        tmpImgURL: dataURL,
      }));
    };

    setState(state => ({
      ...state,
      file: e.target.files[0],
      avatarImg: false,
    }));
  };

  const removeImg = () => {
    setState({
      ...state,
      file: '',
      avatarImg: true,
      tmpImgURL: '',
    });
  };

  const handleChangePdf = e => {
    const reader = new window.FileReader(e);
    reader.onload = eve => {
      const dataURL = reader.result;

      setState(state => ({
        ...state,
        tmpPdf: dataURL,
      }));
    };

    setState(state => ({
      ...state,
      pdf: e.target.files[0],
      pdfName: e.target.files[0].name,
      avatarPdf: false,
    }));
  };

  const removePdf = () => {
    setState({
      ...state,
      pdf: '',
      avatarPdf: true,
      pdfName: '',
      tmpPdf: '',
    });
  };

  return (
    <Wrapper>
      <Typography variant="h5" color="primary">
        Dodaj nowy produkt
      </Typography>
      <FormWrapper>
        <StyledForm onSubmit={e => handleSubmit(e)}>
          <SingleInputWrapper>
            <Input
              onChange={handleChangeInput}
              uniqueNames={unieqeNames}
              htmlFor="productName"
              label="Nazwa"
              name="productName"
              id="productName"
              type="text"
              value={state.productName}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
              validators={[
                { check: Validators.required, message: 'pole wymagane' },
                { check: Validators.uniqueName, message: 'Produkt o podanej nazwie już  istnieje' },
              ]}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <Input
              onChange={handleChangeInput}
              uniqueNames={unieqeNames}
              htmlFor="productSubName"
              label="Nazwa podrzędna"
              name="productSubName"
              id="productSubName"
              type="text"
              value={state.productSubName}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <SelectImput
              options={productsCategories}
              optionType="category"
              noOptionsText="Brak danych"
              label="Kategoria produktu"
              handleOnChange={handleChangeSelect}
              onChangeParams={['category']}
              id="category"
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <Input
              onChange={handleChangeInput}
              htmlFor="shortName"
              label="Skrócona nazwa"
              name="shortName"
              id="shortName"
              type="text"
              value={state.shortName}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
            />
          </SingleInputWrapper>
          <ImgWrapper>
            <SingleInputWrapper noMarginB file>
              <label styles={{ width: '100%' }} htmlFor="raised-button-file">
                <input
                  accept="image/*"
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={event => handleChangeFile(event)}
                />
                <Button variant="outlined" component="span" className={classes.button} full-width>
                  Dodaj zdjęcie
                </Button>
              </label>
            </SingleInputWrapper>
            <SingleInputWrapper file imgBox border>
              {state.avatarImg ? (
                <ProductImg src={avatar} alt="empty" />
              ) : (
                  <>
                    <ProductImg src={URL.createObjectURL(state.file)} alt="name" />
                    <CloseButton handleClick={e => removeImg(e)} />
                  </>
                )}
            </SingleInputWrapper>
          </ImgWrapper>

          <SingleInputWrapper>
            <Input
              onChange={handleChangeInput}
              htmlFor="price"
              label="Cena upsell"
              name="price"
              id="price"
              type="number"
              value={state.price}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <Input
              onChange={handleChangeInput}
              htmlFor="oldPrice"
              label="Cena Panel użytkownika"
              name="oldPrice"
              id="oldPrice"
              type="number"
              value={state.oldPrice}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <SelectInputIcon
              options={countries}
              optionType="currency_code"
              disabledOption={option =>
                option.currency === '' || option.currency_code === '' || option.currency === null
              }
              noOptionsText="Nie znaleziono"
              label="Waluta"
              handleOnChange={handleChangeSelect}
              onChangeParams={['currency']}
              name="currency1"
              id="currency"
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <SelectImput
              options={selectValues}
              optionType="name"
              noOptionsText="Brak danych"
              label="Typ pliku"
              handleOnChange={handleChangeSelect}
              onChangeParams={['type']}
              id="type"
            />
          </SingleInputWrapper>
          {state.type?.value === 1 && (
            <ImgWrapper>
              <SingleInputWrapper noMarginB file>
                <label styles={{ width: '100%' }} htmlFor="pdf">
                  <input
                    accept="application/pdf"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="pdf"
                    multiple
                    type="file"
                    onChange={event => handleChangePdf(event)}
                  />
                  <Button variant="outlined" component="span" className={classes.button} full-width>
                    Dodaj plik
                  </Button>
                </label>
              </SingleInputWrapper>
              <SingleInputWrapper file imgBox border>
                {state.avatarPdf ? (
                  <ProductImg src={pdfLogo} alt="empty" />
                ) : (
                    <>
                      <ProductImg src={pdfLogo} alt="empty" />
                      <ProductName>{state.pdf.name}</ProductName>

                      <CloseButton handleClick={e => removePdf(e)} />
                    </>
                  )}
              </SingleInputWrapper>
            </ImgWrapper>
          )}
          <SingleInputWrapper>
            <Input
              onChange={handleChangeInput}
              htmlFor="quantity"
              label="Ilość (stron, dł. video)"
              name="quantity"
              id="quantity"
              type="text"
              value={state.quantity}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <SelectInputIcon
              options={countries}
              optionType="country"
              disabledOption={option => option.name === ''}
              noOptionsText="Nie znaleziono"
              label="Język"
              handleOnChange={handleChangeSelect}
              onChangeParams={['language']}
              name="country"
              id="country"
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <Input
              id="description"
              onChange={handleChangeInput}
              value={state.description}
              // htmlFor={name}
              label="Opis produktu"
              name="description"
              type="text"
              multiline
              inputProps={{ rows: 5 }}
              htmlFor="description"
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <Input
              id="listTitle"
              onChange={handleChangeInput}
              value={state.listTitle}
              // htmlFor={name}
              label="Nagłówek listy"
              name="listTitle"
              type="text"
              htmlFor="listTitle"
            />
          </SingleInputWrapper>
          {numbersOFListElements.map((el, index) => (
            <SingleInputWrapper key={el}>
              <Input
                onChange={handleChangeListElInput}
                // value={state.el}
                // htmlFor={name}
                label="Element listy"
                name={`listEl${index}`}
                type="text"
                htmlFor="listEl"
              />
            </SingleInputWrapper>
          ))}
          <SingleInputWrapper>
            <ButtonPrimary handleClick={onAddElement}>Dodaj Element</ButtonPrimary>
          </SingleInputWrapper>

          <ButtonWrapper>
            <ButtonPrimary type="submit">Dodaj Produkt</ButtonPrimary>
          </ButtonWrapper>
        </StyledForm>
      </FormWrapper>
    </Wrapper>
  );
};

export default AddProducts;
