import { combineReducers } from 'redux';
import ingredientsReducer from 'store/reducers/ingredientsReducer';
import ingredientsCategoryReducer from 'store/reducers/ingredientsCategoryReducer';
import mealsCategoryReducer from 'store/reducers/mealsCategoryReducer';
import mealsCategoryDietsReducer from 'store/reducers/mealsCategoryDietsReducer';
import notificationReducer from 'store/reducers/notificationReducer';
import currenciesReducer from 'store/reducers/currenciesReducer';
import mealsReducer from 'store/reducers/mealsReducer';
import paymentMethodReducer from 'store/reducers/PaymentMethodReducer';
import shipmentsMethodReducer from 'store/reducers/shipmentsMethodReducer';
import productsReducer from 'store/reducers/productsReducer';
import usersReducer from 'store/reducers/usersReducer';
// import customersReducer from 'store/reducers/customersReducer';
import countreisReducer from 'store/reducers/countreisReducer';
import ordersReducer from 'store/reducers/ordersReducer';
import invoicesReducer from 'store/reducers/invoicesReducer';
import validationReducer from 'store/reducers/validationReducer';
import packagesReducer from 'store/reducers/packagesReducer';
import loginReducer from 'store/reducers/loginReducer';
import couponsReducer from 'store/reducers/couponsReducer';
import permissionsReducer from 'store/reducers/permissionsReducer';

const rootReducer = combineReducers({
  ig: ingredientsReducer,
  igCat: ingredientsCategoryReducer,
  meals: mealsReducer,
  mealsCat: mealsCategoryReducer,
  mealsCategoryDiets: mealsCategoryDietsReducer,
  notifications: notificationReducer,
  currencies: currenciesReducer,
  paymentMethods: paymentMethodReducer,
  shipmentsMethod: shipmentsMethodReducer,
  products: productsReducer,
  users: usersReducer,
  countries: countreisReducer,
  orders: ordersReducer,
  validators: validationReducer,
  packages: packagesReducer,
  currentUser: loginReducer,
  coupons: couponsReducer,
  permissions: permissionsReducer,
  invoices: invoicesReducer,
});

export default rootReducer;
