import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddUsersPermissions from 'views/UsersPermissions/AddUsersPermissions/AddUsersPermissions';
import UsersPermissionsTable from 'components/atoms/Table/UsersPermissionsTable'
import * as actionCreators from 'store/actions';
import { connect } from 'react-redux';

const URLAPI = process.env.REACT_APP_URL_API;

const SectionAddCategory = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px; 
  padding-bottom: 20px;
  position: relative;
`
const UsersPermissionsComponent = ({
    onFetchUsersPermissions,
    userPermissionsStateRedux,
    onDeleteUsersPermissions,
    onInitValidators,
    unieqeUsersPermissionsName,
    onAddUserPermissionsForm
}) => {
    const [visible, setVisible] = useState(true)
    const [init, setInit] = useState(false);
    const [usersPermissions, setUsersPermissions] = useState([]);
    const showAddUserPermissions = () => {
        setVisible(!visible)
    }

    const setElementsForCurrentPage = () => {
        onFetchUsersPermissions()
    }

    useEffect(() => {
        if (usersPermissions !== null && init === false) {
            setElementsForCurrentPage()
            setInit(true)
        }
    }, [usersPermissions])

    useEffect(() => {
        const endpoint = `${URLAPI}roles`
        onInitValidators(endpoint, 'userPermissionsStateRedux')
    }, [])


    return (
        <SectionAddCategory>
            <IconButton
                onClick={() => showAddUserPermissions()}
            >
                <AddIcon fontSize="large" />
            </IconButton>
            {visible && <AddUsersPermissions
                setVisible={setVisible}
                unieqeUsersPermissionsName={unieqeUsersPermissionsName}
                onAddUserPermissionsForm={onAddUserPermissionsForm}
            />}

            <UsersPermissionsTable
                setUsersPermissions={setUsersPermissions}
                userPermissionsStateRedux={userPermissionsStateRedux}
                usersPermissions={usersPermissions}
                onDeleteUsersPermissions={onDeleteUsersPermissions}

            />
        </SectionAddCategory>
    );
}

const mapStateToProps = state => {
    return {
        userPermissionsStateRedux: state.permissions.permissions,
        unieqeUsersPermissionsName: state.validators.validators
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
        onFetchUsersPermissions: () => dispatch(actionCreators.fetchPermissions()),
        onDeleteUsersPermissions: id => dispatch(actionCreators.deleteMealCategory(id)),
        onAddUserPermissionsForm: (bodyFormData, resetForm) => dispatch(actionCreators.AddPermissionsForm(bodyFormData, resetForm)),
        onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(UsersPermissionsComponent);
