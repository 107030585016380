/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Loading from 'components/atoms/Loading/Loading';
import ConfirmDelete from 'components/atoms/ConfirmDelete/ConfirmDelete';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const tableHeaders = [
  { id: 'id', label: 'id', width: '1%', align: 'center', type: 'id' },
  { id: 'code', label: 'Kod promocyjny', width: '7.6%', align: 'center', type: 'string' },
  { id: 'description', label: 'Opis', width: '19%', align: 'center', type: 'string' },
  { id: 'discount', label: 'Nowa cena', width: '7.6%', align: 'center', type: 'price' },
  { id: 'counter', label: 'Ilośc użyć', width: '7.6%', align: 'center', type: 'string' },
  { id: 'type', label: 'Typ kuponu', width: '7.6%', align: 'center', type: 'type' },
  { id: 'is_active', label: 'Aktywny', width: '7.6%', align: 'center', type: 'isEnabled' },
  { id: 'product_id,', label: 'Produkt', width: '19%', align: 'center', type: 'string' },
  { id: 'actions,', label: 'Akcje', width: '10%', align: 'center', type: 'buttons' },
];

const IconsWrapper = styled.div`
  position: relative;
`;

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const useStyles1 = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CouponTable = ({
  onDeleteCoupons,
  isLoading,
  couponCounter,
  couponList,
  products,
  setDeleteConfirmVisable,
  deleteConfirmVisable,
  setRefresh,
  coupon,
  couponsConfig,
  setCouponsConfig,

}) => {
  const classes = useStyles();
  const createDataFuncVals = tableHeaders.map(el => el.id);




  const createData = (...params) => {
    const obj = {};
    // eslint-disable-next-line no-return-assign
    createDataFuncVals.map((el, index) => (obj[el] = params[index]));
    return obj;
  };

  let data = [];

  if (couponList && products.length > 0) {
    data = couponList.map(el => {
      return {
        id: el.id,
        code: el.code,
        description: el.description,
        discount: `${el.discount} ${products.find(product => product.id === el.product_id)?.currency}`,
        counter: couponCounter.filter(counter => counter[0] === el.id)[0][1],
        type: el.discount_type_id,
        is_active: el.is_active,
        product_id: products.find(product => product.id === el.product_id)?.name,
      };
    });
  }

  const rows = data.map(el => {
    const funcParams = [];
    Object.keys(el).map(key => {
      funcParams.push(el[key]);
      return funcParams;
    });
    return createData(...funcParams);
  });

  const handleChangePage = (event, newPage) => {
    setCouponsConfig(state => ({
      ...state,
      current_page: newPage,
    }));
    setRefresh(Math.random());
  };

  const handleChangeRowsPerPage = event => {
    setCouponsConfig(state => ({
      ...state,
      per_page: event.target.value * 1,
      current_page: 1,
    }));
    setRefresh(Math.random());
  };

  // const handleOpen = key => {
  //   setOpen(open => ({
  //     ...open,
  //     [key]: !open[key],
  //   }));
  // };

  const openDeleteConfirm = key => {
    setDeleteConfirmVisable(deleteConfirmVisable => ({
      ...deleteConfirmVisable,
      [key]: !deleteConfirmVisable[key],
    }));
  };

  const couponsTypesOption = [
    { value: '1', label: 'Wszystim użytkonikom' },
    { value: '2', label: 'Kupon jednorazowy' },
    { value: '3', label: 'kupon Procentowy' },
  ];

  if (isLoading === true && couponList.length === 0) {
    return <Loading />;
  } else {
    return (
      <>
        <Paper className={classes.root}>
          <TableContainer>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map(head => {
                    if (head.type === 'id') {
                      return null;
                    } return (
                      <React.Fragment key={head.id}>
                        <StyledTableCell align={head.align} style={{ width: head.width }}>
                          {head.label}
                        </StyledTableCell>
                      </React.Fragment>)
                  })
                  }




                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => {
                  const newKey = row.id
                    .toString()
                    .split('')
                    .filter(e => e.trim().length)
                    .join('');
                  return (
                    <React.Fragment key={row.id}>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {tableHeaders.map(head => {
                          const value = row[head.id];
                          if (head.type === 'id') {
                            return null;
                          }
                          if (head.type === 'isEnabled') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  {value === 1 && <CheckOutlinedIcon color='secondary' />}
                                  {value === 0 && <CloseOutlinedIcon color='error' />}
                                </TableCell>
                              </React.Fragment>
                            );
                          } else if (head.type === 'buttons') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  <IconsWrapper>
                                    <IconButton
                                      aria-label="delete"
                                      // onClick={() => onDeleteCoupons(row.id)}
                                      onClick={() => openDeleteConfirm(newKey)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    <Link to={`/discount-coupons/edit/${row.id}`}>
                                      <IconButton aria-label="edit">
                                        <EditIcon />
                                      </IconButton>
                                    </Link>
                                    {deleteConfirmVisable[newKey] && (
                                      <ConfirmDelete
                                        width="500%"
                                        newKey={newKey}
                                        name={row.name}
                                        onDeleteHandler={onDeleteCoupons}
                                        id={row.id}
                                        rejectHandler={openDeleteConfirm}
                                        isOpen={deleteConfirmVisable}
                                      >
                                        Czy napewno chcesz skasować
                                      </ConfirmDelete>
                                    )}
                                  </IconsWrapper>
                                </TableCell>
                              </React.Fragment>
                            );
                          } else if (head.type === 'price') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>{value}</TableCell>
                              </React.Fragment>
                            );
                          } else if (head.type === 'type') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  {couponsTypesOption.filter(coupon => coupon.value === value)[0]?.label}
                                </TableCell>
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>{value}</TableCell>
                              </React.Fragment>
                            );
                          }
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {isLoading !== true ? (
                    <TablePagination
                      rowsPerPageOptions={[
                        2,
                        6,
                        10,
                        15,
                        25,
                        { label: 'All', value: couponsConfig.total },
                      ]}
                      count={couponsConfig.total}
                      rowsPerPage={couponsConfig.per_page}
                      page={couponsConfig.current_page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  ) : (
                    ''
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
};

export default CouponTable;
