import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import Loading from 'components/atoms/Loading/Loading';
import InvoicesTable from 'components/atoms/Table/InvoicesTable';

const URLAPI = process.env.REACT_APP_URL_API;

const Wrapper = styled.section`
    margin: 16px 0;
`;

const Invoices = ({
    onInitInvoices,
    invoices,
    invoicesCfg,
    onCreateInvoices,
    onFetchProducts,
    products,
}) => {
    const [selectedDate, handleDateChange] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(0);

    const [invoicesConfig, setInvoicesConfig] = useState({
        per_page: 100,
        current_page: 1,
        jumpToPage: null,
        mealsSearchQuery: '',
        // total: -1,
        data: [],
    });

    useEffect(() => {
        setInvoicesConfig(invoicesCfg);
    }, [invoicesCfg]);



    const setElementsForCurrentPage = () => {
        const currentDate = new Date();
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth() + 1;
        // const endpoint = `${URLAPI}invoice?month=${month}&year=${year}`
        const endpoint = `${URLAPI}invoice?month=${11}&year=${2020}`
        setTimeout(() => {
            onInitInvoices(endpoint)

        }, 1000);
    };





    useEffect(() => {
        setIsLoading(true)
        setElementsForCurrentPage();

    }, [selectedDate])

    useEffect(() => {
        setElementsForCurrentPage();
    }, [refresh]);

    useEffect(() => {
        if (invoices !== null) {
            setIsLoading(false)
        }
    })
    useEffect(() => {
        if (products.length === 0) {
            onFetchProducts()
        }
    }, [products])

    const createInvoices = (e) => {
        e.preventDefault()
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth() + 1;
        const lastDay = lastDayOfMonth(selectedDate).getDate();
        onCreateInvoices(year, month, lastDay)
    }


    if (isLoading) {
        return <Loading />
    }

    return (
        <>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                <DatePicker
                    autoOk
                    variant="inline"
                    openTo="year"
                    views={["year", "month"]}
                    label="Faktury z"
                    // helperText="Start from year selection"
                    value={selectedDate}
                    onChange={handleDateChange}
                />
            </MuiPickersUtilsProvider>
            <Wrapper>
                {invoices === null || invoices.length === 0 ?
                    <form onSubmit={e => createInvoices(e)}>
                        <ButtonPrimary type='submit'>Generuj FV</ButtonPrimary>
                    </form>
                    :
                    <>
                        <InvoicesTable
                            invoicesList={invoices}
                            invoicesConfig={invoicesConfig}
                            setInvoicesConfig={setInvoicesConfig}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            products={products}
                        />
                    </>
                }
            </Wrapper>
        </>
    );
}

const mapStateToProps = state => {
    return {
        invoices: state.invoices.invoices,
        invoicesCfg: state.invoices.invoicesConfig,
        products: state.products.products,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onInitInvoices: endpoint => dispatch(actionCreators.initInvoices(endpoint)),
        onCreateInvoices: (year, month, lastDay) => dispatch(actionCreators.createInvoices(year, month, lastDay)),
        onFetchProducts: () => dispatch(actionCreators.fetchProducts()),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);