/* eslint-disable no-else-return */
import React from 'react';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import { Link } from 'react-router-dom';

const tableHeaders = [
  { id: 'id', label: 'id', width: '4%', align: 'left', type: 'id' },
  { id: 'orderNumber', label: 'Nr zamówienia', width: '32%', align: 'center', type: 'link' },
  { id: 'orderDate,', label: 'Data zamówienia', width: '32%', align: 'center', type: 'string' },
  { id: 'total,', label: 'Wartość zamówienia', width: '32%', align: 'center', type: 'string' },
];
const StyledTableCell = withStyles(theme => ({
  container: {
    maxHeight: 440,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});
const StyledLink = styled.span`
  color: blue;
  text-decoration: underline;
`;

const UserOrdersTable = ({ ordersList }) => {
  const classes = useStyles();
  const createDataFuncVals = tableHeaders.map(el => el.id);
  const createData = (...params) => {
    const obj = {};
    // eslint-disable-next-line no-return-assign
    createDataFuncVals.map((el, index) => (obj[el] = params[index]));
    return obj;
  };

  let data = [];
  if (ordersList) {
    data = ordersList.map(el => {
      return {
        id: el.id,
        orderNumber: el.number,
        orderDate: el.date_order,
        total: `${el.total} ${el.currency}`,
      };
    });
  }

  const rows = data.map(el => {
    const funcParams = [];
    Object.keys(el).map(key => {
      funcParams.push(el[key]);
      return funcParams;
    });
    return createData(...funcParams);
  });

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer>
          <Table className={classes.root} stickyHeader fullWidth aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHeaders.map(head => (
                  <React.Fragment key={head.value + Math.random() * 1000}>
                    <StyledTableCell align={head.align} style={{ width: head.width }}>
                      {head.label}
                    </StyledTableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                return (
                  <React.Fragment key={row.code + Math.random() * 1000}>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      {tableHeaders.map(head => {
                        const value = row[head.id];
                        if (head.type === 'link') {
                          return (
                            <React.Fragment key={head.id + Math.random() * 1000}>
                              <TableCell align={head.align}>
                                <Link to={`/orders/${row.id}`}>
                                  <StyledLink>{value}</StyledLink>
                                </Link>
                              </TableCell>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment key={head.id + Math.random() * 1000}>
                              <TableCell align={head.align}>{value}</TableCell>
                            </React.Fragment>
                          );
                        }
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colspan="5" />
                {/* </TableCell> */}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default UserOrdersTable;
