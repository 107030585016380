import React, { useEffect, useState } from 'react';
import * as actionCreators from 'store/actions';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Timer from 'react-compound-timer'
import history from 'Routs/history'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 4,
        height: 40,
        padding: '0 30px',
        cursor: 'pointer',
        pointerEvents: 'auto',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            color: theme.palette.common.white,
            background: theme.palette.background.hover,
        },
        '&:disabled': {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
            '&:hover': {
                background: theme.palette.error.main,
                color: theme.palette.common.white,
            }
        },
    },

}));


const Wrapper = styled.div`
z-index: 10;
background-color: white;
position: fixed;
width: 100%;
height: 65px;
padding: 20px 40px;
border-bottom: 1px solid ${({ theme }) => theme.palette.common.border};
`
const UserWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
`
const UserLogin = styled.div`
margin-right: 16px;
`

const TimerWrapper = styled.div`
    display: flex;
    flex-direction:  row;
    align-items: center;
    margin-right: 16px;
    font-weight: 700;
    color: ${({ infoTimeLeft, theme }) => infoTimeLeft ? theme.palette.error.main : theme.palette.primary.main};
`

const Bold = styled.span`
    font-weight: 700;
`

const StyledButton = styled(Button)`
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.common.white};
    &:hover {
        background: ${({ theme }) => theme.palette.background.hover};
    }
`



const UserPanel = ({ currentUser, onLogOut, onAddMessage }) => {
    const classes = useStyles();

    const [expiresTime, setExpiresTime] = useState('');
    const [createdTime, setCreatedTime] = useState('');
    const [infoTimeLeft, setInfoTimeLeft] = useState(false);
    const [timerInit, setTimerInit] = useState(false);
    const [time, setTime] = useState(false);

    const clickHandler = () => {
        onLogOut(history)
        setExpiresTime('');
        setCreatedTime('')
        setInfoTimeLeft(false)
        setTime(false)
    }

    useEffect(() => {
        setExpiresTime(currentUser.currentUser.tokenExpires)
        setCreatedTime(currentUser.currentUser.tokenCreated)
    }, [currentUser])

    const timeCalulator = () => {
        setTimeout(() => {
            setTimerInit(true)
        }, 1000);
        const difference = ((expiresTime) - +new Date()) - 300000;
        return difference
    }

    useEffect(() => {
        setTime(timeCalulator())
    }, [expiresTime, createdTime])

    const displayInfo10 = () => {
        setInfoTimeLeft(true)
        onAddMessage(`Za 10 min zostaniesz wylogowany`, 'warning')
    }

    const displayInfo5 = () => {
        setInfoTimeLeft(true)
        onAddMessage(`Za 5 min zostaniesz wylogowany`, 'warning')
    }

    return (
        <>
            <Wrapper>
                <UserWrapper>
                    <TimerWrapper infoTimeLeft={infoTimeLeft}>
                        <Typography color="primary" >Koniec sesji:&nbsp;</Typography>
                        {timerInit && <Timer
                            formatValue={value => value < 10 ? `0${value}` : value}
                            initialTime={time}
                            // initialTime={10000}
                            direction="backward"
                            checkpoints={[
                                {
                                    time: 600000,
                                    callback: () => displayInfo10(),
                                },
                                {
                                    time: 300000,
                                    callback: () => displayInfo5(),
                                },
                                {
                                    time: 1000,
                                    callback: () => onLogOut(),
                                },
                            ]}

                        >
                            <Timer.Hours />:
                            <Timer.Minutes />:
                            <Timer.Seconds />
                        </Timer>}
                    </TimerWrapper>
                    <UserLogin>
                        <Typography>
                            Jesteś zalogowany jako: <Bold>{currentUser.currentUser.email}</Bold>
                        </Typography>
                    </UserLogin>
                    <StyledButton
                        classes={{
                            root: classes.root,
                        }}
                        variant="outlined"
                        endIcon={<ExitToAppIcon />}
                        onClick={() => clickHandler()}
                    >Wyloguj</StyledButton>
                </UserWrapper>
            </Wrapper>
        </>
    );
}



const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogOut: () => dispatch(actionCreators.logOut(history)),
        onAddMessage: (msg, status) => dispatch(actionCreators.addMessage(msg, status)),

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
