import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Input from 'components/atoms/Inputs/Input'
import Typography from '@material-ui/core/Typography';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators'
import SelectInputIcon from 'components/atoms/Inputs/SelectInputIcon'
import CheckBox from 'components/atoms/CheckBox/CheckBox'
import Button from '@material-ui/core/Button';
import CloseButton from 'components/atoms/Buttons/CloseButton'

import avatar from 'assets/no-photo.png';

const useStyles = makeStyles((theme) => ({

  button: {
    width: '100%',
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  }
}));

const Wrapper = styled.div`
    display: block;
    margin: 16px auto;
    min-width: 120px;
    width: 100%;
    height:auto;
    padding: 32px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
`

const FormWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  background-color: #ffffff;
  color: #fff;
  z-index: 1;
  width: 100%;
`
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonWrapper = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
`

const SingleInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  ${({ noMarginB }) => noMarginB && css`
  margin-bottom: 0;
  `}
      ${({ file }) => file && css`
  width: 210px;
  `}
  ${({ imgBox }) => imgBox && css`
  width: 210px;
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  `}

  ${({ border }) => border && css`
    border: 1px solid #c4c4c4;
    border-radius: 0 0 4px 4px;
    border-top: 0;
    margin-bottom: 20px;
  `}
`

const SelectAndCheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content:space-between;
`

const SelectWrapper = styled.div`
  width: 79%;
`
const CheckboxWrapper = styled.div`
  width: 19%;
`

const ImgWrapper = styled.div`
width: 50%;
`

const IconImg = styled.img`
  width: 72px;
  height: auto;
  margin: 12px;
`


const AddPaymentMethods = ({
  onAddPaymentMethodsForm,
  setVisible,
  uniquePaymentMethodsNames,
  countries,
}) => {

  const [paymentName, setPaymentName] = useState('');
  const [paymentDescription, setPaymentDescription] = useState('');
  const [selectedCountrues, setSelectedCountrues] = useState([]);
  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const [file, setFile] = useState({
    file: '',
    avatarImg: true,
  })
  const [isError, setError] = useState({
    isError: {
      paymentName: null
    }
  }
  );
  const classes = useStyles();


  const isValidationPassed = () => {
    return true
  }

  const inputValidation = (error) => {
    if (error) {
      setError({ isError: { paymentName: error.error } })
    } else {
      setError({ isError: { paymentName: true } })
    }
  }
  const handleChangeName = (e) => {
    setPaymentName(e.target.value)
  }
  const handleChangeDescription = (e) => {
    setPaymentDescription(e.target.value)
  }


  useEffect(() => {
    if (selectedCountrues.length !== countries.length) {
      setCheckboxChecked(false)
    } else {
      setCheckboxChecked(true)

    }
  }, [selectedCountrues])

  const handleChangeSelect = (inputValue) => {

    setSelectedCountrues(inputValue)
  }

  const resetForm = () => {
    setPaymentName('');
    setPaymentDescription('')
    setSelectedCountrues([]);
    setVisible()
  };

  const handleSubmit = (e) => {
    const countriesList = []
    selectedCountrues.map(country => countriesList.push({ 'id': country.id }))

    e.preventDefault();

    const bodyFormData = new window.FormData();
    bodyFormData.set('name', paymentName);
    bodyFormData.set('description', paymentDescription);
    bodyFormData.set('countries', JSON.stringify(countriesList));
    if (file.file !== '') {
      bodyFormData.append('icon', file.file);
    }
    onAddPaymentMethodsForm(bodyFormData, resetForm)
  }

  const handleSelectAll = (event) => {
    setCheckboxChecked(event.target.checked)
    if (event.target.checked) {
      setSelectedCountrues(countries)
    }
    else {
      setSelectedCountrues([])
    }
  };

  const handleChangeFile = (e) => {


    const reader = new window.FileReader();
    reader.onload = () => {
      const dataURL = reader.result;

      setFile(file => ({
        ...file,
        tmpImgURL: dataURL,

      }));
    };

    setFile(file => ({
      ...file,
      file: e.target.files[0],
      avatarImg: false,
    }));
  };

  const removeImg = () => {
    setFile({
      ...file,
      file: '',
      avatarImg: true
    })
  }

  return (
    <Wrapper>

      <Typography variant="h5" color="primary">Dodaj nową metodę</Typography>
      <FormWrapper>
        <StyledForm
          onSubmit={(e) => handleSubmit(e)}
        >
          <SingleInputWrapper>
            <Input
              onChange={handleChangeName}
              uniqueNames={uniquePaymentMethodsNames}
              htmlFor='paymentName'
              label="Nazwa"
              name='paymentName'
              id="paymentName"
              type='text'
              value={paymentName}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
              validators={[
                { check: Validators.required, message: 'pole wymagane' },
                { check: Validators.uniqueName, message: 'Metoda płatności o podanej nazwie już  istnieje' },
              ]}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <Input
              onChange={handleChangeDescription}
              htmlFor='paymentDescription'
              label="opis"
              name='paymentDescription'
              id="paymentDescription"
              type='text'
              value={paymentDescription}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
              validators={[
                { check: Validators.required, message: 'pole wymagane' },

              ]}
            />

          </SingleInputWrapper>

          <ImgWrapper>
            <SingleInputWrapper noMarginB file>
              <label
                styles={{ width: '100%' }}
                htmlFor="mealFile">
                <input
                  accept="image/*"
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="mealFile"
                  multiple
                  type="file"
                  onChange={(event) => handleChangeFile(event)}
                />
                <Button
                  variant="outlined"
                  component="span"
                  className={classes.button}
                  full-width
                >
                  Dodaj zdjęcie
                                            </Button>
              </label>
            </SingleInputWrapper>
            <SingleInputWrapper file imgBox border>
              {file.avatarImg ?
                <IconImg src={avatar} alt="empty" /> :
                <>
                  <IconImg src={URL.createObjectURL(file.file)} alt="name" />
                  <CloseButton
                    handleClick={(e) => removeImg(e)}
                  />
                </>
              }

            </SingleInputWrapper>
          </ImgWrapper>
          <SelectAndCheckboxWrapper>
            {countries &&
              <SelectWrapper>
                <SelectInputIcon
                  multiple
                  options={countries}
                  val={selectedCountrues}
                  optionType="currency_code"
                  disabledOption={option =>
                    option.currency === '' ||
                    option.currency_code === '' ||
                    option.currency === null
                  }
                  noOptionsText="Nie znaleziono"
                  label="Dostępne w krajach:"
                  handleOnChange={handleChangeSelect}
                  onChangeParams={['currency', 'select']}
                  name="currency1"
                  id="currency"
                />
              </SelectWrapper>
            }
            <CheckboxWrapper>
              <CheckBox
                name="selectAllCountries"
                handleChange={handleSelectAll}
                checked={checkboxChecked}
                id="hasLactose"
                label='Zaznacz wszystkie kraje'
              />
            </CheckboxWrapper>
          </SelectAndCheckboxWrapper>
          <ButtonWrapper>
            <ButtonPrimary type="submit"> Dodaj Metodę</ButtonPrimary>
          </ButtonWrapper>
        </StyledForm>
      </FormWrapper>

    </Wrapper>
  );
}

export default AddPaymentMethods;

