import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
    products: [],
    singleProducts: null
};
// GET
const setProducts = (state, action) => {
    return {
        ...state,
        products: action.products,
        error: false,
    }
}

// GET ONE 
const setSingleProducts = (state, action) => {
    return {
        ...state,
        singleProducts: action.singleProducts,
        error: false,
    };
};

// DELETE
const deleteProductsSuccess = (state, action) => {
    return {
        ...state,
        products: [...state.products.filter(product => product.name !== action.name)],
    };
};


const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PRODUCTS:
            return setProducts(state, action);
        case actionTypes.DELETE_PRODUCTS_SUCCESS:
            return deleteProductsSuccess(state, action);
        case actionTypes.SET_SINGLE_PRODUCTS:
            return setSingleProducts(state, action);
        default:
            return state;
    }
};

export default productsReducer;
