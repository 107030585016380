import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 4,
        height: 48,
        padding: '0 30px',
        cursor: 'pointer',
        pointerEvents: 'auto',
        width: '100%',
        marginBottom: '8px',
        maxWidth: '300px;',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            color: theme.palette.common.white,
            background: theme.palette.background.hover,
        },
        '&:disabled': {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
            color: theme.palette.common.white,
            '&:hover': {
                background: theme.palette.error.main,
                color: theme.palette.common.white,
            }
        },

    },
    disabled: {
        pointerEvents: 'auto',
        cursor: 'not-allowed',
    },

}));

const ButtonPrimary = ({ children, handleClick, type, isDisabled }) => {
    const classes = useStyles();
    const handleOnClick = (e) => {
        if (handleClick)
            handleClick(e)
        if (isDisabled) {
            // console.log('uzupełnij wszystkie pola')
        }
    }

    return (
        <>
            <Button
                variant="outlined"
                type={type || 'button'}
                onClick={(e) => handleOnClick(e)}
                disabled={isDisabled}
                classes={{
                    root: classes.root,
                    disabled: classes.disabled
                }}
            >
                {children}
            </Button>
        </>
    );
}

export default ButtonPrimary;