import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Input from 'components/atoms/Inputs/Input'


const Wrapper = styled.div`
position: absolute;
display: flex;
top: 0;
right: 28%;
background-color: #ffffff;
color: #fff;
z-index: 1;
width: 500%;
&:hover {
    background-color: #f5f5f5;
}
`
const StyledForm = styled.form`
display: flex;
width: 100%;
`

const EditIngredientsCategory = ({
    id,
    isOpen,
    handleOpen,
    name,
    value,
    onSubmitMealsCategoryForm,
    newKey

}) => {
    const [newValue, setNewValue] = useState('')


    useEffect(() => {
        setNewValue(value)
    }, [value])

    const handleClose = () => {
        handleOpen(newKey)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        onSubmitMealsCategoryForm(newValue, id)
    }

    const handleChange = (e) => {
        setNewValue(e.target.value)
    }

    return (
        <>
            {isOpen && <Wrapper>
                <StyledForm onSubmit={(e) => handleSubmit(e)}>
                    <Input
                        onChange={handleChange}
                        value={newValue}
                        htmlFor={name}
                        label="Nowa nazwa"
                        name={name}
                        type='text'
                        inputProps={{ style: { textAlign: 'center' } }}

                    />

                    <IconButton
                        aria-label="confirm"
                        type="submit"
                    // onClick={() => handleSubmit(row.name)}
                    >
                        <CheckIcon color="secondary" fontSize="large" />
                    </IconButton>
                </StyledForm>
                <IconButton aria-label="confirm"
                    onClick={() => handleClose()}
                >
                    <ClearIcon color="error" fontSize="large" />
                </IconButton>
            </Wrapper>
            }
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onfetchSingleMealsCategory: id => dispatch(actionCreators.fetchSingleMealsCategory(id)),
        onSubmitMealsCategoryForm: (name, id) => dispatch(actionCreators.submitMealsCategoryForm(name, id))
    };
};

export default connect(null, mapDispatchToProps)(EditIngredientsCategory);