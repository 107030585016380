import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Input from 'components/atoms/Inputs/Input'
import { Validators } from 'components/atoms/Inputs/Validators'
import SelectInput from 'components/atoms/Inputs/SelectInput'


const Wrapper = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    background-color: #ffffff;
    color: #fff;
    z-index: 1;
    width: 550%;
    &:hover {
        background-color: #f5f5f5;
    }
`
const StyledForm = styled.form`
    display: flex;
    width: 100%;
`

const InputsWrapper = styled.div`
    display: flex;
    width: 80%;
`
const SelectWrapper = styled.div`
    width: 20%;
`

const EditShipmentsMethod = ({
    id,
    isOpen,
    handleOpen,
    name,
    value,
    onSubmitShipmentsMethodsForm,
    newKey,
    descValue,
    isEnabledValue,
    shipmentPrice,
    unieqeShipmentsMethodName,
}) => {
    const [newValue, setNewValue] = useState('')
    const [newPrice, setNewPrice] = useState('')
    const [newDescription, setNewDescription] = useState('')
    const [isEnabled, setIsEnabled] = useState({ value: 0, label: "nieaktywna" })
    const selectOptions = [
        { value: true, label: "Aktywna" },
        { value: false, label: "nieaktywna" }
    ]

    useEffect(() => {
        setNewValue(value);
        setNewDescription(descValue);
        setNewPrice(shipmentPrice);
        setIsEnabled({ value: isEnabledValue, label: isEnabledValue ? 'Aktywna' : 'nieaktywna' });
    }, [value, descValue, shipmentPrice, isEnabledValue])

    const handleClose = () => {
        handleOpen(newKey)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        onSubmitShipmentsMethodsForm(id, newValue, newDescription, newPrice, isEnabled.value)
    }

    const handleChange = (e) => {
        setNewValue(e.target.value)
    }
    const handleChangeDescription = (e) => {
        setNewDescription(e.target.value)
    }
    const handleChangePrice = (e) => {
        setNewPrice(e.target.value)
    }
    const handleChangeSelect = (e) => {
        setIsEnabled(e)
    }

    return (
        <>
            {isOpen && <Wrapper>
                <StyledForm onSubmit={(e) => handleSubmit(e)}>
                    <InputsWrapper>
                        <Input
                            id="name"
                            onChange={handleChange}
                            uniqueNames={unieqeShipmentsMethodName}
                            oldValue={name}
                            value={newValue}
                            htmlFor={name}
                            label="Nowa nazwa"
                            name={name}
                            type='text'
                            inputProps={{ style: { textAlign: 'center' } }}
                            validators={[
                                { check: Validators.required, message: 'pole wymagane' },
                                { check: Validators.uniqueNameEdit, message: 'Metoda wysyłki o podanej nazwie już  istnieje' },
                            ]}
                        />
                        <Input
                            id="description"
                            onChange={handleChangeDescription}
                            value={newDescription}
                            htmlFor='description'
                            label="Nowy opis"
                            name='description'
                            type='text'
                            inputProps={{ style: { textAlign: 'center' } }}
                            validators={[
                                { check: Validators.required, message: 'pole wymagane' },
                                // { check: Validators.uniqueName, message: 'Waluta o podanej nazwie już  istnieje' },
                            ]}
                        />
                        <Input
                            id="newPrice"
                            onChange={handleChangePrice}
                            value={newPrice}
                            htmlFor='newPrice'
                            label="Nowa cena"
                            name="newPrice"
                            type='number'
                            inputProps={{ style: { textAlign: 'center' } }}
                            validators={[
                                { check: Validators.required, message: 'pole wymagane' },
                                // { check: Validators.uniqueName, message: 'Waluta o podanej nazwie już  istnieje' },
                            ]}
                        />
                    </InputsWrapper>
                    <SelectWrapper>
                        <SelectInput
                            options={selectOptions}
                            val={isEnabled}
                            noOptionsText='Nie znaleziono'
                            label="Ustaw Status"
                            handleOnChange={handleChangeSelect}
                            onChangeParams={['unit']}
                            name='unit'
                            id="unit"
                            // selectValid={selectValid}
                            // isError={isError.unit}
                            errorMessage="Pole wymagane"
                        />
                    </SelectWrapper>
                    <IconButton
                        aria-label="confirm"
                        type="submit"
                    // onClick={() => handleSubmit(row.name)}
                    >
                        <CheckIcon color="secondary" fontSize="large" />
                    </IconButton>
                </StyledForm>
                <IconButton aria-label="reject"
                    onClick={() => handleClose()}
                >
                    <ClearIcon color="error" fontSize="large" />
                </IconButton>
            </Wrapper>
            }
        </>
    );
}



const mapDispatchToProps = dispatch => {
    return {
        onSubmitShipmentsMethodsForm: (id, newValue, newDescription, newPrice, isEnabled) =>
            dispatch(actionCreators.submitShipmentsMethodsForm(id, newValue, newDescription, newPrice, isEnabled)),

    };
};

export default connect(null, mapDispatchToProps)(EditShipmentsMethod);