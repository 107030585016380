import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import publicKey from 'Jwt/jwt';
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;

export const loginSuccess = (currentUser, history) => {
  history.push('/');

  return {
    type: actionTypes.LOGIN_SUCCESS,
    currentUser,
    msg: currentUser,
  };
};

export const loginFailed = (error, history) => {
  history.push('/login');

  return {
    type: actionTypes.LOGIN_FAILED,
    error,
  };
};

function setCookie(cname, cvalue, emin) {
  const d = new Date((emin - 300) * 1000).toUTCString();
  // d.setTime(d.getTime() + emin);
  const expires = `expires=${d}`;
  document.cookie = `${cname}=${cvalue}; ${expires}`;
}
function deleteCookie(cname) {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const login = (password, email, history) => {
  return dispatch => {
    axios
      .post(`${URLAPI}login`, {
        password,
        email,
      })
      .then(res => {
        let nbf;
        if (res.data.access_token) {
          nbf = jwt.decode(res.data.access_token).nbf * 1000;
        } else {
          nbf = 1;
          history.push('/login');
        }
        setTimeout(() => {
          jwt.verify(res.data.access_token, publicKey);

          try {
            if (jwt.decode(res.data.access_token).role !== '851df67c-2070-48e7-89a6-a3bb4f7b28be') {
              const decodedUserInfo = {
                email: jwt.decode(res.data.access_token).email,
                id: jwt.decode(res.data.access_token).sub,
                tokenExpires: jwt.decode(res.data.access_token).exp * 1000,
                roleId: jwt.decode(res.data.access_token).role,
                // tokenCreated: startingDate.setTime(startingDate.getTime()),
                tokenCreated: Date.now(),
              };
              setCookie('authKey', res.data.access_token, jwt.decode(res.data.access_token).exp);
              setCookie(
                'currentUser',
                JSON.stringify(decodedUserInfo),
                jwt.decode(res.data.access_token).exp,
              );
              dispatch(loginSuccess(decodedUserInfo, history));
              history.push('/');
            } else {
              const unauthorizedRole = {
                response: {
                  data: {
                    error: true,
                    message: {
                      login: ['Access denied'],
                    },
                  },
                },
              };
              dispatch(loginFailed(unauthorizedRole, history));
            }
          } catch (error) {
            history.push('/login');
            dispatch(loginFailed(error, history));
          }
        }, nbf - Date.now() + 800);
      })
      .catch(err => {
        dispatch(loginFailed(err, history));
        history.push('/login');
      });
  };
};

// LOGOUT
export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
  };
};

export const logoutFailed = () => {
  return {
    type: actionTypes.LOGOUT_FAILED,
  };
};

export const logOut = history => {
  return dispatch => {
    const authKey = Cookies.get('authKey');
    axios
      .post(
        `${URLAPI}logout`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        history.push('/login');
        dispatch(logoutSuccess(res));
        deleteCookie('authKey');
        deleteCookie('currentUser');
      })
      .catch(error => {
        dispatch(logoutFailed(error));
      });
  };
};
