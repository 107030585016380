import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
  mealsCategoryDiets: [],
  mealsCategoryDietsOptions: [],
};

const setMealsCategoryDietsOptions = (state, action) => {
  const mealsCategoryDietsOptions = action.options.map(option => {
    return { value: option.id, label: option.name };
  });

  return {
    ...state,
    mealsCategoryDietsOptions,
    error: false,
  };
};

const setMealsCategoryDiets = (state, action) => {
  console.log(action);
  return {
    ...state,
    mealsCategoryDiets: action.mealsCategoryDiets,
    error: false,
  };
};

const deleteMealsCategoryDietsSuccess = (state, action) => {
  return {
    ...state,
    mealsCategory: [...state.mealsCategory.filter(mealCategory => mealCategory.id !== action.id)],
  };
};

const mealsCategoryDietsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MEALS_CATEGORY_DIETS:
      return setMealsCategoryDiets(state, action);
    case actionTypes.SET_MEALS_CATEGORY_DIETS_OPTIONS:
      return setMealsCategoryDietsOptions(state, action);
    case actionTypes.DELETE_MEAL_CATEGORY_DIETS_SUCCESS:
      return deleteMealsCategoryDietsSuccess(state, action);
    default:
      return state;
  }
};

export default mealsCategoryDietsReducer;
