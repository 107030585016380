import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;

export const setMealsCategoryDietsOptions = options => {
  return {
    type: actionTypes.SET_MEALS_CATEGORY_DIETS_OPTIONS,
    options,
  };
};

export const setMealsCategoryDiets = mealsCategoryDiets => {
  return {
    type: actionTypes.SET_MEALS_CATEGORY_DIETS,
    mealsCategoryDiets,
  };
};

export const fetchMealsCateogryDietsFailed = () => {
  return {
    type: actionTypes.FETCH_MEALS_CATEGORY_DIETS_FAILED,
  };
};

export const fetchMealsCategoryDiets = () => {
  const authKey = Cookies.getJSON('authKey');

  return dispatch => {
    axios
      .get(`${URLAPI}category-diets`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const mealsCategoryDiets = res.data.data;

        dispatch(setMealsCategoryDiets(mealsCategoryDiets));
        dispatch(setMealsCategoryDietsOptions(mealsCategoryDiets));
      })
      .catch(error => {
        dispatch(fetchMealsCateogryDietsFailed(error));
      });
  };
};

export const deleteMealCategoryDietsSuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_MEAL_CATEGORY_DIETS_SUCCESS,
    id,
    msg: res,
  };
};

export const deleteMealCategoryDietsFailed = error => {
  return {
    type: actionTypes.DELETE_MEAL_CATEGORY_DIETS_FAILED,
    error,
  };
};

export const deleteMealCategoryDiets = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .delete(`${URLAPI}category-diets/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        dispatch(deleteMealCategoryDietsSuccess(id, res));
      })
      .catch(error => {
        dispatch(deleteMealCategoryDietsFailed(error));
      });
  };
};

export const setSingleMealsCategoryDiets = mealsCategoryDiets => {
  return {
    type: actionTypes.SET_SINGLE_MEALS_CATEGORY_DIETS,
    mealsCategoryDiets,
  };
};

export const fetchSingleMealsCateogryDietsFailed = error => {
  return {
    type: actionTypes.FETCH_SINGLE_MEALS_CATEGORY_DIETS_FAILED,
    error,
  };
};

export const fetchSingleMealsCategoryDiets = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}category-diets/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const mealCategoryDiets = res.data.data;

        dispatch(setSingleMealsCategoryDiets(mealCategoryDiets));
      })
      .catch(error => {
        dispatch(fetchMealsCateogryDietsFailed(error));
      });
  };
};

export const submitMealsCategoryDietsFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_MEALS_CATEGORY_DIETS_FORM_SUCCESS,
    msg: res,
  };
};

export const submitMealsCategoryFormDietsFailed = error => {
  return {
    type: actionTypes.SUBMIT_MEALS_CATEGORY_DIETS_FORM_FAILED,
    error,
  };
};

export const AddMealsCategoryDietsForm = (name, resetForm) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .post(
        `${URLAPI}category-diets`,
        {
          name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(submitMealsCategoryDietsFormSuccess(res));
        dispatch(fetchMealsCategoryDiets());
        resetForm();
      })
      .catch(error => {
        // dispatch(submitMealsCategoryFormFailed(error));
        console.error(error);
      });
  };
};

// EDIT Meals category diets

export const submitMealsCategoryDietsForm = (name, id) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .put(
        `${URLAPI}category-diets/${id}`,
        {
          name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(submitMealsCategoryDietsFormSuccess(res));
        dispatch(fetchMealsCategoryDiets());
      })
      .catch(error => {
        console.error(error);
        // dispatch(submitMealsCategoryDietsFormFailed(error));
      });
  };
};
