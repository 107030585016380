/* eslint-disable no-else-return */
import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import avatar from 'assets/no-photo.png';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Loading from 'components/atoms/Loading/Loading';
import { Link } from 'react-router-dom';
import DescriptionPopup from 'components/atoms/DescriptionPopup/DescriptionPopup';
import ConfirmDelete from 'components/atoms/ConfirmDelete/ConfirmDelete';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { Typography } from '@material-ui/core';

const URLBASE = process.env.REACT_APP_URL_BASE;
const URLAPI = process.env.REACT_APP_URL_API;

const tableHeaders = [
  { id: 'id', label: 'id', width: '4%', align: 'left', type: 'id' },
  { id: 'source', label: 'Zdjęcie', width: '5%', align: 'center', type: 'img' },
  { id: 'name', label: 'Nazwa', width: '23%', align: 'left', type: 'string' },
  { id: 'calories', label: 'Kalorie', width: '23%', align: 'center', type: 'string' },
  { id: 'category', label: 'kategoria', width: '23%', align: 'center', type: 'category' },
  {
    id: 'has_translations',
    label: 'Tłumaczenia',
    width: '4%',
    align: 'center',
    type: 'hasTranslations',
  },
  { id: 'actions,', label: 'Akcje', width: '23%', align: 'center', type: 'buttons' },
];

const Img = styled.img`
  width: 40px;
`;

const IconsWrapper = styled.div`
  position: relative;
`;

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const useStyles1 = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 1);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage)));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const MealsTable = ({
  onDeleteMeals,
  mealsList,
  setMealsConfig,
  setRefresh,
  mealsConfig,
  descriptionVisable,
  deleteConfirmVisable,
  setDescriptionVisable,
  setDeleteConfirmVisable,
  mealsSearchQuery,
}) => {
  const classes = useStyles();

  const createDataFuncVals = tableHeaders.map(el => el.id);

  const createData = (...params) => {
    const obj = {};
    // eslint-disable-next-line no-return-assign
    createDataFuncVals.map((el, index) => (obj[el] = params[index]));
    return obj;
  };

  let data = [];

  if (mealsList) {
    data = mealsList.map(el => {
      return {
        id: el.id,
        source: el.image,
        name: el.name,
        calories: el.kcal_min,
        category_meals: el.category_meals,
        has_translations:
          el.name_en !== null &&
          el.description_en !== null &&
          el.name_en !== '' &&
          el.name_en !== 'null' &&
          el.description_en !== '' &&
          el.description_en !== 'null',
        description: el.description,
        ingredients: el.ingredients,
      };
    });
  }
  const rows = data.map(el => {
    const funcParams = [];
    Object.keys(el).map(key => {
      funcParams.push(el[key]);
      return funcParams;
    });
    return createData(...funcParams);
  });

  const handleChangePage = (event, newPage) => {
    setMealsConfig(state => ({
      ...state,
      current_page: newPage,
    }));
    setRefresh(Math.random());
  };

  const handleChangeRowsPerPage = event => {
    setMealsConfig(state => ({
      ...state,
      per_page: event.target.value * 1,
      current_page: 1,
    }));
    setRefresh(Math.random());
  };

  const handleShowDescription = key => {
    setDescriptionVisable(descriptionVisable => ({
      ...descriptionVisable,
      [key]: !descriptionVisable[key],
    }));
  };

  const handleCopyMeal = mealId => {
    const API_URL = process.env.REACT_APP_URL_API;
    fetch(`${API_URL}meals/copy/${mealId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        alert(response.message);
      })
      .catch(error => alert(error));
  };

  const openDeleteConfirm = key => {
    setDeleteConfirmVisable(deleteConfirmVisable => ({
      ...deleteConfirmVisable,
      [key]: !deleteConfirmVisable[key],
    }));
  };

  if (mealsConfig === null && mealsList === null) {
    return <Loading />;
  } else {
    return (
      <>
        <Paper className={classes.root}>
          <TableContainer component={Paper} style={{ overflowX: 'unset' }}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map(head => {
                    if (head.type === 'id') {
                      return null;
                    } else {
                      return (
                        <React.Fragment key={head.value}>
                          <StyledTableCell align={head.align} style={{ width: head.width }}>
                            {head.label}
                          </StyledTableCell>
                        </React.Fragment>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => {
                  return (
                    <React.Fragment key={row.id}>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {tableHeaders.map(head => {
                          const value = row[head.id];
                          const newKey = (row.name + row.id.toString())
                            .split('')
                            .filter(e => e.trim().length)
                            .join('');

                          if (head.type === 'hasTranslations') {
                            if (!value) {
                              return (
                                <React.Fragment key={head.id}>
                                  <TableCell align={head.align}>
                                    <CloseOutlinedIcon color="error" />
                                    <Typography variant="subtitle2" color="error">
                                      brak tłumaczenia
                                    </Typography>
                                  </TableCell>
                                </React.Fragment>
                              );
                            }
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  <CheckOutlinedIcon color="secondary" />
                                </TableCell>
                              </React.Fragment>
                            );
                          }

                          if (head.type === 'id') {
                            return null;
                          }

                          if (head.type === 'img') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  {value === null ? (
                                    <Img src={avatar} alt="empty" />
                                  ) : (
                                    <Img
                                      src={`${URLBASE}storage/${value}`}
                                      alt={`${row.name} image`}
                                    />
                                  )}
                                </TableCell>
                              </React.Fragment>
                            );
                          }
                          if (head.type === 'category') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  {value.length !== 0 ? value[0].name : 'brak danych'}
                                </TableCell>
                              </React.Fragment>
                            );
                          }
                          if (head.type === 'buttons') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  <IconsWrapper>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => openDeleteConfirm(newKey)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    <Link to={`/meals/edit/${row.id}`}>
                                      <IconButton aria-label="edit">
                                        <EditIcon />
                                      </IconButton>
                                    </Link>
                                    <IconButton
                                      aria-label="preview"
                                      onClick={() => handleShowDescription(newKey)}
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                    <DescriptionPopup
                                      open={descriptionVisable[newKey]}
                                      onCloseHandler={handleShowDescription}
                                      funcVal={newKey}
                                      productInfo={mealsList.find(x => x.id === row.id)}
                                    />
                                    <IconButton
                                      aria-label="copy"
                                      onClick={() => handleCopyMeal(row.id)}
                                    >
                                      <FileCopyIcon />
                                    </IconButton>
                                    {deleteConfirmVisable[newKey] && (
                                      <ConfirmDelete
                                        width="550%"
                                        newKey={newKey}
                                        id={row.id}
                                        name={row.name}
                                        onDeleteHandler={onDeleteMeals}
                                        rejectHandler={openDeleteConfirm}
                                        isOpen={deleteConfirmVisable}
                                        endpointSearch={`${URLAPI}meals?page=${mealsConfig.current_page}&per_page=${mealsConfig.per_page}${mealsSearchQuery}`}
                                      >
                                        Czy napewno chcesz skasować
                                      </ConfirmDelete>
                                    )}
                                  </IconsWrapper>
                                </TableCell>
                              </React.Fragment>
                            );
                          }

                          return (
                            <React.Fragment key={head.id}>
                              <TableCell align={head.align}>{value}</TableCell>
                            </React.Fragment>
                          );
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {mealsConfig.current_page !== null ||
                  mealsConfig.per_page !== null ||
                  mealsConfig.total !== null ||
                  mealsConfig.current_page !== undefined ||
                  mealsConfig.per_page !== undefined ||
                  mealsConfig.total !== undefined ? (
                    <TablePagination
                      rowsPerPageOptions={[
                        2,
                        6,
                        10,
                        15,
                        25,
                        100,
                        { label: 'All', value: mealsConfig.total },
                      ]}
                      count={mealsConfig.total * 1}
                      rowsPerPage={mealsConfig.per_page}
                      page={mealsConfig.current_page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  ) : (
                    ''
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
};

export default MealsTable;
