/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Loading from 'components/atoms/Loading/Loading';
import ConfirmDelete from 'components/atoms/ConfirmDelete/ConfirmDelete';
import { Link } from 'react-router-dom';
import avatar from 'assets/no-photo.png';

const URLBASE = process.env.REACT_APP_URL_BASE;

const tableHeaders = [
  { id: 'id', label: 'id', width: '4%', align: 'left', type: 'id' },
  { id: 'img', label: 'Zdjęcie', width: '12.6%', align: 'center', type: 'img' },
  { id: 'name', label: 'Nazwa', width: '12.6%', align: 'center', type: 'string' },
  { id: 'language', label: 'Język', width: '12.6%', align: 'center', type: 'string' },
  { id: 'short_name', label: 'Nazwa skrócona', width: '12.6%', align: 'center', type: 'string' },
  { id: 'category', label: 'Kategoria', width: '12.6%', align: 'center', type: 'category' },
  { id: 'priceUpsell', label: 'Cena Upsell', width: '12.6%', align: 'center', type: 'price' },
  { id: 'pricePanel', label: 'Cena Panel', width: '12.6%', align: 'center', type: 'price' },

  { id: 'actions,', label: 'Akcje', width: '20%', align: 'center', type: 'buttons' },
];

const IconsWrapper = styled.div`
  position: relative;
`;
const Img = styled.img`
  width: 40px;
`;

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const useStyles1 = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ProductsTable = ({
  isLoading,
  productsList,
  onDeleteProducts,
  deleteConfirmVisable,
  setDeleteConfirmVisable,
}) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const createDataFuncVals = tableHeaders.map(el => el.id);

  const createData = (...params) => {
    const obj = {};
    // eslint-disable-next-line no-return-assign
    createDataFuncVals.map((el, index) => (obj[el] = params[index]));
    return obj;
  };

  let data = [];

  if (productsList) {
    data = productsList.map(el => {
      return {
        id: el.id,
        img: el.image,
        name: el.name,
        language: el.product_lang,
        short_name: el.short_name,
        category: el.category_product_id,
        priceUpsell: `${el.price} ${el.currency}`,
        pricePanel: `${el.price_old} ${el.currency}`,
      };
    });
  }

  const rows = data.map(el => {
    const funcParams = [];
    Object.keys(el).map(key => {
      funcParams.push(el[key]);
      return funcParams;
    });
    return createData(...funcParams);
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openDeleteConfirm = key => {
    setDeleteConfirmVisable(deleteConfirmVisable => ({
      ...deleteConfirmVisable,
      [key]: !deleteConfirmVisable[key],
    }));
  };

  if (isLoading === true) {
    return <Loading />;
  } else {
    return (
      <>
        <Paper className={classes.root}>
          <TableContainer>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map(head => (
                    <React.Fragment key={head.code + (Math.random() * 1000).toString()}>
                      <StyledTableCell align={head.align} style={{ width: head.width }}>
                        {head.label}
                      </StyledTableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  const newKey = (row.name + row.id.toString())
                    .split('')
                    .filter(e => e.trim().length)
                    .join('');
                  return (
                    <React.Fragment key={row.code + (Math.random() * 1000).toString()}>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {tableHeaders.map(head => {
                          const value = row[head.id];
                          if (head.type === 'img') {
                            return (
                              <React.Fragment key={head.id + Math.random() * Math.random()}>
                                <TableCell align={head.align}>
                                  {value === null ? (
                                    <Img src={avatar} alt="empty" />
                                  ) : (
                                      <Img
                                        src={`${URLBASE}storage/${value}`}
                                        alt={`${row.name}image`}
                                      />
                                    )}
                                </TableCell>
                              </React.Fragment>
                            );
                          }
                          if (head.type === 'category') {
                            return (
                              <React.Fragment key={head.id + Math.random() * Math.random()}>
                                <TableCell align={head.align}>
                                  {value === 'fde18974-b712-4eb8-a650-1b848f4e015a' && 'Dieta'}
                                  {value === 'fb3453c5-4cb3-4f0f-be1f-2a05fdf55d86' && 'Suplement'}
                                  {value === '87e83e6a-5143-46f2-9c46-d3a33131e3ce' && 'Wiedza'}
                                  {value === '8337cbe2-653e-4d50-b400-58cd4ab5cf40' && 'Trening'}
                                </TableCell>
                              </React.Fragment>
                            );
                          }
                          if (head.type === 'buttons') {
                            return (
                              <React.Fragment key={head.id + (Math.random() * 1000).toString()}>
                                <TableCell align={head.align}>
                                  <IconsWrapper>
                                    <IconButton
                                      aria-label="delete"
                                      // onClick={() => onDeleteProducts(row.id)}
                                      onClick={() => openDeleteConfirm(newKey)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    <Link to={`/products/edit/${row.id}`}>
                                      <IconButton aria-label="edit">
                                        <EditIcon />
                                      </IconButton>
                                    </Link>
                                    {deleteConfirmVisable[newKey] && (
                                      <ConfirmDelete
                                        width="500%"
                                        newKey={newKey}
                                        name={row.name}
                                        onDeleteHandler={onDeleteProducts}
                                        id={row.id}
                                        rejectHandler={openDeleteConfirm}
                                        isOpen={deleteConfirmVisable}
                                      >
                                        Czy napewno chcesz skasować
                                      </ConfirmDelete>
                                    )}
                                  </IconsWrapper>
                                </TableCell>
                              </React.Fragment>
                            );
                          } else if (head.type === 'price') {
                            return (
                              <React.Fragment key={head.id + (Math.random() * 1000).toString()}>
                                <TableCell align={head.align}>{value}</TableCell>
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <React.Fragment key={head.id + (Math.random() * 1000).toString()}>
                                <TableCell align={head.align}>{value}</TableCell>
                              </React.Fragment>
                            );
                          }
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {isLoading !== true ? (
                    <TablePagination
                      rowsPerPageOptions={[
                        2,
                        6,
                        10,
                        15,
                        25,
                        100,
                        { label: 'All', value: productsList.length },
                      ]}
                      count={productsList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  ) : (
                      ''
                    )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
};

export default ProductsTable;
