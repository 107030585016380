import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions/index';
import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import Input from 'components/atoms/Inputs/Input';
import SelectInput from 'components/atoms/Inputs/SelectInput';
import { Validators } from 'components/atoms/Inputs/Validators';
import avatar from 'assets/no-photo.png';
import Button from '@material-ui/core/Button';
import CheckBox from 'components/atoms/CheckBox/CheckBox';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import Loading from 'components/atoms/Loading/Loading';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const URLBASE = process.env.REACT_APP_URL_BASE;
const URLAPI = process.env.REACT_APP_URL_API;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      // role="tabpanel"
      hidden={value !== index}
      id={`ig-name-tabpanel-${index}`}
      aria-labelledby={`ig-name-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `ig-name-tab-${index}`,
    'aria-controls': `ig-name-tabpanel-${index}`,
  };
}

const HeadingWrapper = styled.div`
  margin-bottom: 32px;
`;

const AddIgRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ModalWrapper = styled.div`
  position: relative;
  margin: 16px auto;
  width: 100%;
  @media screen and (min-width: 992px) {
    width: 80%;
  }
`;
const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const AddIgInputName = styled.h3`
  width: 100%;
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
`;

const SingleInputWrapper = styled.div`
  width: 48%;
  margin-bottom: 20px;
`;
const SingleInputWrapperSm = styled.div`
  width: 100%;
  /* margin-bottom: 20px; */
  @media screen and (min-width: 992px) {
    width: 19%;
  }
`;

const SelectWrapper = styled.div`
  width: 48%;
  position: relative;
  margin-bottom: 20px;
`;
const ImgWrapper = styled.div`
  width: 48%;
`;

const CheckboxWrapper = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`;

const ContainerForFewInputs = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    flex-basis: 15%;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const MealImage = styled.img`
  width: 72px;
  height: auto;
  margin: 12px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledParagraph = styled.p`
  color: red;
  display: ${({ isHovered }) => (isHovered === true ? 'block' : 'none')};
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      outline: 'none',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
  },
  button: {
    width: '100%',
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const initialState = {
  // optionsUnits: [],
  optionsNameCategoryForSearch: [],
  ig: '',
  AllIngredients: [],
  igId: '',
  name: '',
  name_en: '',
  name_cz: '',
  name_es: '',
  name_hu: '',
  name_ro: '',
  name_bg: '',
  name_sk: '',
  name_pt: '',
  name_it: '',
  name_de: '',
  name_fr: '',
  calories: '',
  carbohydrates: '',
  protein: '',
  sugar: '',
  fats: '',
  optionsIgCat: [],
  optionsName: [],
  // unit: null,
  category_ingredient_id: null,
  has_lactose: false,
  has_gluten: false,
  isError: {
    name: false,
    name_en: false,
    name_cz: false,
    name_es: false,
    name_hu: false,
    name_ro: false,
    name_bg: false,
    name_sk: false,
    name_pt: false,
    name_it: false,
    name_de: false,
    name_fr: false,
    calories: false,
    protein: false,
    carbohydrates: false,
    sugar: false,
    fats: false,
    // unit: false,
    category_ingredient_id: false,
  },
  avatarImg: true,
  file: null,
  temporaryImage: null,
  oldImage: null,
  newImage: null,
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const useCompare = val => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

const EditIngredients = ({
  // onFetchUnits,
  onInitAllIngredientsForOptions,
  onSubmitIngredientForm,
  ig,
  AllIngredients,
  igCat,
  // optionsUnits,
  optionsName,
  optionsNameCategoryForSearch,
  onfetchIngredientsCategory,
  match,
  onInitIngredients,
  history,
  onDeletePhotoOfIngredient,
  unieqeIngredientsNames,
  onInitValidators,
}) => {
  const [validationPassed, setValidationPassed] = useState(true);
  const [isHovered, setHover] = useState(false);
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const hasIgChanged = useCompare(ig);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const endpoint = `${URLAPI}ingredient/name`;
    onInitValidators(endpoint, 'ingredients');
  }, [ig]);

  useEffect(() => {
    if (
      (state.AllIngredients && state.AllIngredients.length === 0) ||
      state.AllIngredients === null
    ) {
      const endpoint = `${URLAPI}ingredients?records=all`;
      onInitIngredients(endpoint);
    }
  }, [AllIngredients]);

  useEffect(() => {
    if (match) {
      setState(state => ({
        ...state,
        igId: match.params.id,
      }));
    }

    if (hasIgChanged) {
      onInitAllIngredientsForOptions();
    }
    // if (state.optionsUnits.length === 0) {
    //   onFetchUnits()
    // }
    if (state.optionsNameCategoryForSearch.length === 0) {
      onfetchIngredientsCategory();
    }

    setState(state => ({
      ...state,
      ig,
      AllIngredients,
      optionsIgCat: igCat,
      // optionsUnits,
      optionsName,
      optionsNameCategoryForSearch,
    }));
  }, [
    ig,
    igCat,
    // optionsUnits,
    optionsName,
    optionsNameCategoryForSearch,
  ]);

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const isValidationPassed = () => {
    const { isError } = { ...state };
    const isErrorLeft = Object.values(isError).reduce((a, b) => {
      return a === b ? a : NaN;
    });

    if (isErrorLeft === false) {
      setValidationPassed(true);
    } else {
      setValidationPassed(false);
    }
  };

  const selectValid = key => {
    const curentSelect = state[key];
    const { isError } = { ...state };
    const currentState = isError;
    if (curentSelect !== null) {
      if (curentSelect.value.length === 0) {
        currentState[key] = true;
      } else {
        currentState[key] = false;
      }
      setState({
        ...state,
        isError: currentState,
      });
    } else if (curentSelect === null) {
      currentState[key] = true;
      setState({
        ...state,
        isError: currentState,
      });
    }
    isValidationPassed();
  };

  const handleChangeSelect = (inputValue, key) => {
    setState({
      ...state,
      [key]: inputValue,
      lastEdited: key,
    });
  };
  useEffect(() => {
    if (state.lastEdited) {
      selectValid(state.lastEdited);
    }
  }, [state.lastEdited, state.isError, state.category_ingredient_id]);

  const handleChangeCheckbox = e => {
    const item = e.target.name;
    setState({
      ...state,
      [item]: !state[item],
    });
  };

  const resetForm = () => {
    setState(initialState);
  };

  const inputValidation = (error, name) => {
    const { isError } = { ...state };
    const currentState = isError;
    if (error) {
      currentState[name] = error.error;
    } else {
      currentState[name] = error;
    }
    setState({
      ...state,
      isError: currentState,
    });
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubmitEdit = e => {
    e.preventDefault();
    // if (validationPassed) {
    const bodyFormData = new window.FormData();
    bodyFormData.set('name', state.name);
    bodyFormData.set('name_en', state.name_en);
    bodyFormData.set('name_cz', state.name_cz);
    bodyFormData.set('name_es', state.name_es);
    bodyFormData.set('name_hu', state.name_hu);
    bodyFormData.set('name_ro', state.name_ro);
    bodyFormData.set('name_bg', state.name_bg);
    bodyFormData.set('name_sk', state.name_sk);
    bodyFormData.set('name_pt', state.name_pt);
    bodyFormData.set('name_it', state.name_it);
    bodyFormData.set('name_de', state.name_de);
    bodyFormData.set('name_fr', state.name_fr);
    bodyFormData.set('calories', state.calories);
    bodyFormData.set('protein', state.protein);
    bodyFormData.set('fats', state.fats);
    bodyFormData.set('carbohydrates', state.carbohydrates);
    bodyFormData.set('sugar', state.sugar);
    bodyFormData.set('category_ingredient_id', state.category_ingredient_id.value);
    bodyFormData.set('has_lactose', state.has_lactose / 1);
    bodyFormData.set('has_gluten', state.has_gluten / 1);
    // bodyFormData.set('unit', state.unit.value);
    bodyFormData.set('_method', 'PUT');

    if (state.newImage !== null) {
      bodyFormData.append('source', state.source);
    } else if (state.newImage === null && state.source === null) {
      const endpoint = `${URLAPI}ingredients/source/${match.params.id * 1}`;
      onDeletePhotoOfIngredient(endpoint);
    }

    const endpoint = `${URLAPI}ingredients/${match.params.id}`;
    onSubmitIngredientForm(history, bodyFormData, endpoint, null, resetForm);
  };

  useEffect(() => {
    if (state.igId !== '' && state.AllIngredients && state.AllIngredients.length !== 0) {
      if (state.AllIngredients !== null && state.AllIngredients.length > 0) {
        const allIngredients = state.AllIngredients;
        const currentEditId = state.igId;
        const igToEdit = allIngredients.find(x => x.id === currentEditId);
        if (igToEdit !== null && igToEdit !== undefined) {
          setState(state => ({
            ...state,
            name: igToEdit.name,
            name_en: igToEdit.name_en,
            name_cz: igToEdit.name_cz,
            name_es: igToEdit.name_es,
            name_hu: igToEdit.name_hu,
            name_ro: igToEdit.name_ro,
            name_bg: igToEdit.name_bg,
            name_sk: igToEdit.name_sk,
            name_pt: igToEdit.name_pt,
            name_it: igToEdit.name_it,
            name_de: igToEdit.name_de,
            name_fr: igToEdit.name_fr,
            oldValue: igToEdit.name,
            oldValue_en: igToEdit.name_en,
            oldValue_cz: igToEdit.name_cz,
            oldValue_es: igToEdit.name_es,
            oldValue_hu: igToEdit.name_hu,
            oldValue_ro: igToEdit.name_ro,
            oldValue_bg: igToEdit.name_bg,
            oldValue_sk: igToEdit.name_sk,
            oldValue_pt: igToEdit.name_pt,
            oldValue_it: igToEdit.name_it,
            oldValue_de: igToEdit.name_de,
            oldValue_fr: igToEdit.name_fr,
            calories: igToEdit.calories,
            carbohydrates: igToEdit.carbohydrates,
            category_ingredient_id: {
              value: igToEdit.category_ingredient.id,
              label: igToEdit.category_ingredient.name,
            },
            fats: igToEdit.fats,
            has_gluten: igToEdit.has_gluten,
            has_lactose: igToEdit.has_lactose,
            protein: igToEdit.protein,
            oldImage: igToEdit.source,
            sugar: igToEdit.sugar,
            // unit: { value: igToEdit.unit, label: igToEdit.unit },
          }));
        }
      }
    }
  }, [state.AllIngredients]);
  const onCloseHandler = () => {
    resetForm();
    history.goBack();
  };

  const {
    name,
    name_en,
    name_cz,
    name_es,
    name_hu,
    name_ro,
    name_bg,
    name_sk,
    name_pt,
    name_it,
    name_de,
    name_fr,
    calories,
    protein,
    carbohydrates,
    sugar,
    fats,
    isError,
  } = state;

  if (state.AllIngredients && state.AllIngredients.length === 0) {
    return <Loading />;
  }
  return (
    <>
      <ModalWrapper>
        <BtnHolder>
          <CloseButton handleClick={onCloseHandler} />
        </BtnHolder>
        <div className={classes.paper}>
          <HeadingWrapper>
            <AddIgRow>
              <h2>Edytuj składnik</h2>
            </AddIgRow>
          </HeadingWrapper>
          <form onSubmit={e => handleSubmitEdit(e)}>
            <AddIgRow>
              <SingleInputWrapper htmlFor="nameEdit">
                <Tabs
                  value={tabValue}
                  onChange={handleChangeTab}
                  aria-label="simple tabs example"
                  variant="scrollable"
                >
                  <Tab label="PL" {...a11yProps(0)} />
                  <Tab label="EN" {...a11yProps(1)} />
                  <Tab label="CZ" {...a11yProps(2)} />
                  <Tab label="ES" {...a11yProps(3)} />
                  <Tab label="HU" {...a11yProps(4)} />
                  <Tab label="RO" {...a11yProps(5)} />
                  <Tab label="BG" {...a11yProps(6)} />
                  <Tab label="SK" {...a11yProps(7)} />
                  <Tab label="PT" {...a11yProps(8)} />
                  <Tab label="IT" {...a11yProps(9)} />
                  <Tab label="DE" {...a11yProps(10)} />
                  <Tab label="FR" {...a11yProps(11)} />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name}
                    oldValue={name}
                    id="name"
                    onChange={handleChange}
                    htmlFor="name"
                    label="Nazwa - PL"
                    name="name"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_en}
                    oldValue={name_en}
                    id="name_en"
                    onChange={handleChange}
                    htmlFor="name_en"
                    label="Nazwa - EN"
                    name="name_en"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_cz}
                    oldValue={name_cz}
                    id="name_cz"
                    onChange={handleChange}
                    htmlFor="name_cz"
                    label="Nazwa - CZ"
                    name="name_cz"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_es}
                    oldValue={name_es}
                    id="name_es"
                    onChange={handleChange}
                    htmlFor="name_es"
                    label="Nazwa - ES"
                    name="name_es"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_hu}
                    oldValue={name_hu}
                    id="name_hu"
                    onChange={handleChange}
                    htmlFor="name_hu"
                    label="Nazwa - HU"
                    name="name_hu"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_ro}
                    oldValue={name_ro}
                    id="name_ro"
                    onChange={handleChange}
                    htmlFor="name_ro"
                    label="Nazwa - RO"
                    name="name_ro"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={6}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_bg}
                    oldValue={name_bg}
                    id="name_bg"
                    onChange={handleChange}
                    htmlFor="name_bg"
                    label="Nazwa - BG"
                    name="name_bg"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={7}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_sk}
                    oldValue={name_sk}
                    id="name_sk"
                    onChange={handleChange}
                    htmlFor="name_sk"
                    label="Nazwa - SK"
                    name="name_sk"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={8}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_pt}
                    oldValue={name_pt}
                    id="name_pt"
                    onChange={handleChange}
                    htmlFor="name_pt"
                    label="Nazwa - PT"
                    name="name_pt"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={9}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_it}
                    oldValue={name_it}
                    id="name_it"
                    onChange={handleChange}
                    htmlFor="name_it"
                    label="Nazwa - IT"
                    name="name_it"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={10}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_de}
                    oldValue={name_de}
                    id="name_de"
                    onChange={handleChange}
                    htmlFor="name_de"
                    label="Nazwa - DE"
                    name="name_de"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>
                
                <TabPanel value={tabValue} index={11}>
                  <Input
                    uniqueNames={unieqeIngredientsNames}
                    isError={state.isError}
                    value={name_fr}
                    oldValue={name_fr}
                    id="name_fr"
                    onChange={handleChange}
                    htmlFor="name_fr"
                    label="Nazwa - FR"
                    name="name_fr"
                    type="text"
                    inputValidation={inputValidation}
                    handleOnBlur={isValidationPassed}
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Składnik o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </TabPanel>
              </SingleInputWrapper>

              <SingleInputWrapper htmlFor="caloriesEdit">
                <ContainerForFewInputs>
                  <SingleInputWrapperSm>
                    <Input
                      value={calories}
                      isError={state.isError}
                      onChange={handleChange}
                      handleOnBlur={isValidationPassed}
                      inputValidation={inputValidation}
                      htmlFor="caloriesEdit"
                      label="calories"
                      name="calories"
                      type="number"
                      validators={[
                        { check: Validators.required, message: 'pole wymagane' },
                        { check: Validators.number, message: 'tylko liczby' },
                      ]}
                    />
                  </SingleInputWrapperSm>
                  <SingleInputWrapperSm>
                    <Input
                      value={protein}
                      isError={state.isError}
                      handleOnBlur={isValidationPassed}
                      onChange={handleChange}
                      htmlFor="proteinEdit"
                      label="protein"
                      name="protein"
                      type="number"
                      inputValidation={inputValidation}
                      validators={[
                        { check: Validators.required, message: 'pole wymagane' },
                        { check: Validators.number, message: 'tylko liczby' },
                      ]}
                    />
                  </SingleInputWrapperSm>
                  <SingleInputWrapperSm>
                    <Input
                      value={carbohydrates}
                      isError={state.isError}
                      handleOnBlur={isValidationPassed}
                      onChange={handleChange}
                      htmlFor="carbohydratesEdit"
                      label="Carbs"
                      name="carbohydrates"
                      type="number"
                      inputValidation={inputValidation}
                      validators={[
                        { check: Validators.required, message: 'pole wymagane' },
                        { check: Validators.number, message: 'tylko liczby' },
                      ]}
                    />
                  </SingleInputWrapperSm>
                  <SingleInputWrapperSm>
                    <Input
                      value={sugar}
                      isError={state.isError}
                      handleOnBlur={isValidationPassed}
                      onChange={handleChange}
                      htmlFor="sugarEdit"
                      label="sugar"
                      name="sugar"
                      type="number"
                      inputValidation={inputValidation}
                      validators={[
                        { check: Validators.required, message: 'pole wymagane' },
                        { check: Validators.number, message: 'tylko liczby' },
                      ]}
                    />
                  </SingleInputWrapperSm>
                  <SingleInputWrapperSm>
                    <Input
                      value={fats}
                      isError={state.isError}
                      handleOnBlur={isValidationPassed}
                      onChange={handleChange}
                      htmlFor="fats"
                      label="fats"
                      name="fats"
                      type="number"
                      inputValidation={inputValidation}
                      validators={[
                        { check: Validators.required, message: 'pole wymagane' },
                        { check: Validators.number, message: 'tylko liczby' },
                      ]}
                    />
                  </SingleInputWrapperSm>
                </ContainerForFewInputs>
              </SingleInputWrapper>
            </AddIgRow>
            <AddIgRow>
              {/* <SelectWrapper>
                <SelectInput
                  options={state.optionsUnits}
                  val={state.unit}
                  noOptionsText='Nie znaleziono'
                  label="Wybierz jednostkę"
                  handleOnChange={handleChangeSelect}
                  onChangeParams={['unit']}
                  name='unit'
                  id="unit"
                  selectValid={selectValid}
                  isError={isError.unit}
                  errorMessage="Pole wymagane"
                />
              </SelectWrapper> */}
              <SelectWrapper>
                <SelectInput
                  options={state.optionsNameCategoryForSearch}
                  val={state.category_ingredient_id}
                  // defaultValue={state.optionsNameCategoryForSearch[state.optionsNameCategoryForSearch.id]}
                  noOptionsText="Nie znaleziono"
                  label="Kategoria składnika"
                  handleOnChange={handleChangeSelect}
                  onChangeParams={['category_ingredient_id']}
                  name="category_ingredient_id"
                  id="category_ingredient_id"
                  isError={isError.category_ingredient_id}
                  errorMessage="Pole wymagane"
                />
              </SelectWrapper>
              <CheckboxWrapper>
                <AddIgInputName>Czy składnik zawiera:</AddIgInputName>
                <CheckBox
                  name="has_lactose"
                  handleChange={handleChangeCheckbox}
                  checked={state.has_lactose}
                  id="has_lactose"
                  label="Laktoza"
                />
                <CheckBox
                  name="has_gluten"
                  handleChange={handleChangeCheckbox}
                  checked={state.has_gluten}
                  id="has_gluten"
                  label="Gluten"
                />
              </CheckboxWrapper>
            </AddIgRow>
            {/* <AddIgRow>
              <ImgWrapper>
                <FormControl file>
                  <label
                    styles={{ width: '100%' }}
                    htmlFor="raised-button-file">
                    <input
                      accept="image/*"
                      className={classes.input}
                      style={{ display: 'none' }}
                      id="raised-button-file"
                      multiple
                      type="file"
                      onChange={(event) => handleChangeFile(event)}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      className={classes.button}
                      full-width
                    >
                      Dodaj zdjęcie
                                            </Button>
                  </label>
                </FormControl>
                <FormControl file imgBox border>
                  {currentPhoto()}
                  {state.oldImage || state.temporaryImage !== null ?
                    <CloseButton
                      handleClick={removeImg}
                    /> : ''
                  }
                </FormControl>
              </ImgWrapper>

            </AddIgRow> */}
            <AddIgRow>
              <ButtonWrapper>
                {!validationPassed && (
                  <StyledParagraph isHovered={isHovered}>Uzupełnij wymagane pola</StyledParagraph>
                )}
              </ButtonWrapper>
            </AddIgRow>
            <AddIgRow>
              <ButtonWrapper onMouseEnter={() => setHover(true)}>
                <ButtonPrimary type="submit">Zapisz zmiany</ButtonPrimary>
              </ButtonWrapper>
            </AddIgRow>
          </form>
        </div>
      </ModalWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ig: state.ig.ingredients,
    igCat: state.igCat.ingredientsCategory,
    // optionsUnits: state.ig.optionsUnits,
    optionsName: state.ig.optionsNameForSearch,
    optionsNameCategoryForSearch: state.igCat.optionsNameCategoryForSearch,
    AllIngredients: state.ig.ingredientsConfig,
    unieqeIngredientsNames: state.validators.validators,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),

    onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
    // onFetchUnits: () => dispatch(actionCreators.fetchUnits()),
    onInitIngredients: endpoint => dispatch(actionCreators.initIngredients(endpoint)),
    onDeletePhotoOfIngredient: endpoint =>
      dispatch(actionCreators.deletePhotoOfIngredient(endpoint)),
    onfetchIngredientsCategory: () => dispatch(actionCreators.fetchIngredientsCategory()),
    onSubmitIngredientForm: (
      history,
      bodyFormData,
      endpoint,
      currentEndpointForPagination,
      resetForm,
    ) =>
      dispatch(
        actionCreators.submitIngredientForm(
          history,
          bodyFormData,
          endpoint,
          currentEndpointForPagination,
          resetForm,
        ),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditIngredients);
