import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Input from 'components/atoms/Inputs/Input'
import Typography from '@material-ui/core/Typography';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators'
import Loading from 'components/atoms/Loading/Loading';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { makeStyles } from '@material-ui/core/styles';
import TabPanel from 'components/atoms/TabPanel/TabPanel'
import CheckBox from 'components/atoms/CheckBox/CheckBox'

const Wrapper = styled.div`
    display: block;
    margin: 16px auto;
    min-width: 120px;
    width: 100%;
    height:auto;
    padding: 32px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
`

const FormWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  background-color: #ffffff;
  color: #fff;
  z-index: 1;
  width: 100%;
`
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonWrapper = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
`

const SingleInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const TabsWrapper = styled.div`
    width: 100%;
    color: #000;
    display: flex;
`

const CheckboxGroup = styled.div`
  margin-left:64px;
  display: flex;
  flex-wrap: wrap;
`

const ChcekBoxWrapper = styled.div`
  width: 100%;
`

function a11yProps(index) {
  return {
    id: `permisions-tab-${index}`,
    'aria-controls': `permisions-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      outline: 'none'
    }

  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    width: '80%',
    height: '50%',
    overflow: 'auto',

  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
}));

const AddUsersPermissions = ({
  onAddUserPermissionsForm,
  unieqeUsersPermissionsName,
  setVisible,
}) => {
  const [permissionName, setPermissionName] = useState('');
  const [isError, setError] = useState({
    isError: {
      permissionName: null
    }
  }
  );
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();
  const [userPermisions, setUserPermission] = useState({
    users: {
      pageView: false,
      add: false,
      edit: false,
      delete: false,
      personalData: false
    },
    usersPermissions: {
      pageView: false,
    },
    ingredients: {
      pageView: false,
      add: false,
      edit: false,
      delete: false,
    },
    ingredientsCategory: {
      pageView: false,
      add: false,
      edit: false,
      delete: false,
    },
    meals: {
      pageView: false,
      add: false,
      edit: false,
      delete: false,
      perview: false,
    },
    mealsCategory: {
      pageView: false,
      add: false,
      edit: false,
      delete: false,
    },
    currencies: {
      pageView: false,
      add: false,
      delete: false,
    },
    paymentMethods: {
      pageView: false,
      add: false,
      edit: false,
      delete: false,
    },
    shipmentsMethods: {
      pageView: false,
      add: false,
      edit: false,
      delete: false,
    },
    products: {
      pageView: false,
      add: false,
      edit: false,
      delete: false,
    },
    customers: {
      pageView: false,
      preview: false,
      delete: false,
      addOrder: false,
      generateDiet: false,
      userData: false,
      email: false,
      phoneNumber: false,
      address: false,
    },
    orders: {
      pageView: false,
      delete: false,
      addOrder: false,
      userData: false,
      email: false,
      phoneNumber: false,
      address: false,
      orderedProducts: false,
      orderStatus: false,
      shipmetStatus: false,
      paymentStatus: false,
      returnStatus: false,
    },
    packages: {
      pageView: false,
      add: false,
      edit: false,
      delete: false,
    }
  })

  const [isDisabled, setIsDisabled] = useState({
    users: {
      add: true,
      edit: true,
      delete: true,
      personalData: true,
    },
    usersPermissions: {
      pageView: true,
    },
    ingredients: {
      pageView: true,
      add: true,
      edit: true,
      delete: true,
    },
    ingredientsCategory: {
      pageView: true,
      add: true,
      edit: true,
      delete: true,
    },
    meals: {
      pageView: true,
      add: true,
      edit: true,
      delete: true,
      perview: true,
    },
    mealsCategory: {
      pageView: true,
      add: true,
      edit: true,
      delete: true,
    },
    currencies: {
      pageView: true,
      add: true,
      delete: true,
    },
    paymentMethods: {
      pageView: true,
      add: true,
      edit: true,
      delete: true,
    },
    shipmentsMethods: {
      pageView: true,
      add: true,
      edit: true,
      delete: true,
    },
    products: {
      pageView: true,
      add: true,
      edit: true,
      delete: true,
    },
    customers: {
      pageView: true,
      preview: true,
      delete: true,
      addOrder: true,
      generateDiet: true,
      userData: true,
      email: true,
      phoneNumber: true,
      address: true,
    },
    orders: {
      pageView: true,
      delete: true,
      addOrder: true,
      userData: true,
      email: true,
      phoneNumber: true,
      address: true,
      orderedProducts: true,
      orderStatus: true,
      shipmetStatus: true,
      paymentStatus: true,
      returnStatus: true,
    },
    packages: {
      pageView: true,
      add: true,
      edit: true,
      delete: true,
    }

  })

  const handleChangeCheckbox = (key, page) => {
    const tempObj = userPermisions[page]
    tempObj[key] = !tempObj[key]
    setUserPermission({
      ...userPermisions,
      [page]: { ...tempObj }
    })
    if (tempObj.pageView === true) {
      const temp = isDisabled[page]
      Object.keys(temp).forEach(function (key, value) {
        return temp[key] = false;
      })
      setIsDisabled({
        ...isDisabled,
        [page]: temp
      })
    }
    if (tempObj.pageView === false) {
      const temp2 = isDisabled[page]
      Object.keys(temp2).forEach(function (key, value) {
        return temp2[key] = true;
      })
      setIsDisabled({
        ...isDisabled,
        [page]: { ...temp2 }
      })

      Object.keys(tempObj).forEach(function (key, value) {
        return tempObj[key] = false;
      })
      setUserPermission({
        ...userPermisions,
        tempObj
      })
    };
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const isValidationPassed = () => {
    return true
  }

  const inputValidation = (error) => {
    if (error) {
      setError({ isError: { permissionName: error.error } })
    } else {
      setError({ isError: { permissionName: true } })
    }
  }
  const handleChange = (e) => {
    setPermissionName(e.target.value)
  }

  const resetForm = () => {
    setPermissionName('');
    setVisible()
    setError({
      isError: {
        permissionName: true
      }
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const bodyFormData = new window.FormData();
    bodyFormData.set('name', permissionName);
    bodyFormData.set('permissions', userPermisions);
  }

  if (unieqeUsersPermissionsName?.lenght === 0) {
    return <Loading />

  }
  return (
    <Wrapper>
      <Typography variant="h5" color="primary">Dostępy adminów</Typography>
      <FormWrapper>
        <StyledForm
          onSubmit={(e) => handleSubmit(e)}
        >
          <SingleInputWrapper>
            <Input
              onChange={handleChange}
              uniqueNames={unieqeUsersPermissionsName}
              htmlFor='permissionName'
              label="Nazwa"
              name='permissionName'
              id="permissionName"
              type='text'
              value={permissionName}
              isError={isError}
              handleOnBlur={isValidationPassed}
              inputValidation={inputValidation}
              validators={[
                { check: Validators.required, message: 'pole wymagane' },
                { check: Validators.uniqueName, message: 'Kategoria o podanej nazwie już  istnieje' },
              ]}
            />
          </SingleInputWrapper>

          <div className={classes.root}>
            <TabsWrapper>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="Vertical tabs example"
                className={classes.tabs}
              >
                <Tab label="Użytkownicy" {...a11yProps(0)} />
                <Tab label="Permissions Użytkowników" {...a11yProps(1)} />
                <Tab label="Składniki" {...a11yProps(2)} />
                <Tab label="Kategorie Składników" {...a11yProps(3)} />
                <Tab label="Posiłki" {...a11yProps(4)} />
                <Tab label="Kategorie posiłków" {...a11yProps(5)} />
                <Tab label="Waluty" {...a11yProps(6)} />
                <Tab label="Metody płatności" {...a11yProps(7)} />
                <Tab label="Metody Wysyłki" {...a11yProps(8)} />
                <Tab label="Produky" {...a11yProps(9)} />
                <Tab label="Klienci" {...a11yProps(10)} />
                <Tab label="Zamówienia" {...a11yProps(11)} />
                <Tab label="Zestawy" {...a11yProps(12)} />
              </Tabs>

              {/* USERS */}
              <TabPanel value={tabValue} index={0}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.users.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='users'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.users.add}
                      disabled={isDisabled.users.add}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie'
                      name='add'
                      page='users'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.users.edit}
                      disabled={isDisabled.users.edit}

                      handleChange={handleChangeCheckbox}
                      label='Edycja'
                      name='edit'
                      page='users'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.users.delete}
                      disabled={isDisabled.users.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='users'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.users.personalData}
                      disabled={isDisabled.users.personalData}
                      handleChange={handleChangeCheckbox}
                      label='Dane osobwe'
                      name='personalData'
                      page='users'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>

              {/* UeserPermissions */}

              <TabPanel value={tabValue} index={1}>

                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.usersPermissions.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='usersPermissions'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>

              {/* INGREDIENTS */}
              <TabPanel value={tabValue} index={2}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.ingredients.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='ingredients'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.ingredients.add}
                      disabled={isDisabled.ingredients.add}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie'
                      name='add'
                      page='ingredients'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.ingredients.edit}
                      disabled={isDisabled.ingredients.edit}

                      handleChange={handleChangeCheckbox}
                      label='Edycja'
                      name='edit'
                      page='ingredients'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.ingredients.delete}
                      disabled={isDisabled.ingredients.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='ingredients'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>

              {/* INGREDIENTS CATEGORY */}
              <TabPanel value={tabValue} index={3}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.ingredientsCategory.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='ingredientsCategory'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.ingredientsCategory.add}
                      disabled={isDisabled.ingredientsCategory.add}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie'
                      name='add'
                      page='ingredientsCategory'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.ingredientsCategory.edit}
                      disabled={isDisabled.ingredientsCategory.edit}

                      handleChange={handleChangeCheckbox}
                      label='Edycja'
                      name='edit'
                      page='ingredientsCategory'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.ingredientsCategory.delete}
                      disabled={isDisabled.ingredientsCategory.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='ingredientsCategory'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>


              {/* MEALS */}
              <TabPanel value={tabValue} index={4}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.meals.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='meals'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.meals.add}
                      disabled={isDisabled.meals.add}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie'
                      name='add'
                      page='meals'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.meals.edit}
                      disabled={isDisabled.meals.edit}

                      handleChange={handleChangeCheckbox}
                      label='Edycja'
                      name='edit'
                      page='meals'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.meals.delete}
                      disabled={isDisabled.meals.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='meals'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.meals.perview}
                      disabled={isDisabled.meals.perview}
                      handleChange={handleChangeCheckbox}
                      label='Podgląd'
                      name='perview'
                      page='meals'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>

              {/* MEALS CATEGORY */}
              <TabPanel value={tabValue} index={5}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.mealsCategory.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='mealsCategory'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.mealsCategory.add}
                      disabled={isDisabled.mealsCategory.add}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie'
                      name='add'
                      page='mealsCategory'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.mealsCategory.edit}
                      disabled={isDisabled.mealsCategory.edit}

                      handleChange={handleChangeCheckbox}
                      label='Edycja'
                      name='edit'
                      page='mealsCategory'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.mealsCategory.delete}
                      disabled={isDisabled.mealsCategory.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='mealsCategory'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>

              {/* CURRENCYCIES */}
              <TabPanel value={tabValue} index={6}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.currencies.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='currencies'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.currencies.add}
                      disabled={isDisabled.currencies.add}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie'
                      name='add'
                      page='currencies'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.currencies.delete}
                      disabled={isDisabled.currencies.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='currencies'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>

              {/* PAYMENT METHODS */}
              <TabPanel value={tabValue} index={7}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.paymentMethods.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='paymentMethods'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.paymentMethods.add}
                      disabled={isDisabled.paymentMethods.add}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie'
                      name='add'
                      page='paymentMethods'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.paymentMethods.edit}
                      disabled={isDisabled.paymentMethods.edit}

                      handleChange={handleChangeCheckbox}
                      label='Edycja'
                      name='edit'
                      page='paymentMethods'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.paymentMethods.delete}
                      disabled={isDisabled.paymentMethods.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='paymentMethods'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>

              {/* SHIPMENTS METHODS */}
              <TabPanel value={tabValue} index={8}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.shipmentsMethods.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='shipmentsMethods'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.shipmentsMethods.add}
                      disabled={isDisabled.shipmentsMethods.add}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie'
                      name='add'
                      page='shipmentsMethods'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.shipmentsMethods.edit}
                      disabled={isDisabled.shipmentsMethods.edit}

                      handleChange={handleChangeCheckbox}
                      label='Edycja'
                      name='edit'
                      page='shipmentsMethods'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.shipmentsMethods.delete}
                      disabled={isDisabled.shipmentsMethods.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='shipmentsMethods'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>


              {/* PRODUCTS */}
              <TabPanel value={tabValue} index={9}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.products.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='products'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.products.add}
                      disabled={isDisabled.products.add}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie'
                      name='add'
                      page='products'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.products.edit}
                      disabled={isDisabled.products.edit}

                      handleChange={handleChangeCheckbox}
                      label='Edycja'
                      name='edit'
                      page='products'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.products.delete}
                      disabled={isDisabled.products.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='products'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>


              {/* CUSTOMERS */}
              <TabPanel value={tabValue} index={10}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.customers.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='customers'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.customers.preview}
                      disabled={isDisabled.customers.preview}

                      handleChange={handleChangeCheckbox}
                      label='Preview'
                      name='Podgląd'
                      page='customers'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.customers.delete}
                      disabled={isDisabled.customers.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='customers'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.customers.addOrder}
                      disabled={isDisabled.customers.addOrder}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie zamówień'
                      name='addOrder'
                      page='customers'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.customers.generateDiet}
                      disabled={isDisabled.customers.generateDiet}

                      handleChange={handleChangeCheckbox}
                      label='Generowanie diet'
                      name='generateDiet'
                      page='customers'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.customers.userData}
                      disabled={isDisabled.customers.userData}
                      handleChange={handleChangeCheckbox}
                      label='Dane osobowe'
                      name='userData'
                      page='customers'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.customers.email}
                      disabled={isDisabled.customers.email}
                      handleChange={handleChangeCheckbox}
                      label='Email'
                      name='email'
                      page='customers'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.customers.phoneNumber}
                      disabled={isDisabled.customers.phoneNumber}
                      handleChange={handleChangeCheckbox}
                      label='Telefon'
                      name='phoneNumber'
                      page='customers'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.customers.address}
                      disabled={isDisabled.customers.address}
                      handleChange={handleChangeCheckbox}
                      label='Adres'
                      name='address'
                      page='customers'
                    />
                  </ChcekBoxWrapper>

                </CheckboxGroup>
              </TabPanel>



              {/* ORDERS */}
              <TabPanel value={tabValue} index={11}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='orders'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.delete}
                      disabled={isDisabled.orders.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='orders'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.addOrder}
                      disabled={isDisabled.orders.addOrder}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie zamówień'
                      name='addOrder'
                      page='orders'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.userData}
                      disabled={isDisabled.orders.userData}
                      handleChange={handleChangeCheckbox}
                      label='Dane osobowe'
                      name='userData'
                      page='orders'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.email}
                      disabled={isDisabled.orders.email}
                      handleChange={handleChangeCheckbox}
                      label='Email'
                      name='email'
                      page='orders'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.phoneNumber}
                      disabled={isDisabled.orders.phoneNumber}
                      handleChange={handleChangeCheckbox}
                      label='Telefon'
                      name='phoneNumber'
                      page='orders'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.address}
                      disabled={isDisabled.orders.address}
                      handleChange={handleChangeCheckbox}
                      label='Adres'
                      name='address'
                      page='orders'
                    />
                  </ChcekBoxWrapper>


                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.orderedProducts}
                      disabled={isDisabled.orders.orderedProducts}
                      handleChange={handleChangeCheckbox}
                      label='Zamówione produkty'
                      name='orderedProducts'
                      page='orders'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.orderStatus}
                      disabled={isDisabled.orders.orderStatus}
                      handleChange={handleChangeCheckbox}
                      label='Status zamówienia'
                      name='orderStatus'
                      page='orders'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.shipmetStatus}
                      disabled={isDisabled.orders.shipmetStatus}
                      handleChange={handleChangeCheckbox}
                      label='Status dostawy'
                      name='shipmetStatus'
                      page='orders'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.paymentStatus}
                      disabled={isDisabled.orders.paymentStatus}
                      handleChange={handleChangeCheckbox}
                      label='Status płatności'
                      name='paymentStatus'
                      page='orders'
                    />
                  </ChcekBoxWrapper>

                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.orders.returnStatus}
                      disabled={isDisabled.orders.returnStatus}
                      handleChange={handleChangeCheckbox}
                      label='Status zwrotu'
                      name='returnStatus'
                      page='orders'
                    />
                  </ChcekBoxWrapper>

                </CheckboxGroup>
              </TabPanel>

              {/* PACKAGES */}
              <TabPanel value={tabValue} index={12}>
                <CheckboxGroup>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.packages.pageView}
                      handleChange={handleChangeCheckbox}
                      label='Dostęp do strony'
                      name='pageView'
                      page='packages'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.packages.add}
                      disabled={isDisabled.packages.add}

                      handleChange={handleChangeCheckbox}
                      label='Dodawanie'
                      name='add'
                      page='packages'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.packages.edit}
                      disabled={isDisabled.packages.edit}

                      handleChange={handleChangeCheckbox}
                      label='Edycja'
                      name='edit'
                      page='packages'
                    />
                  </ChcekBoxWrapper>
                  <ChcekBoxWrapper>
                    <CheckBox
                      checked={userPermisions.packages.delete}
                      disabled={isDisabled.packages.delete}

                      handleChange={handleChangeCheckbox}
                      label='Usuwanie'
                      name='delete'
                      page='packages'
                    />
                  </ChcekBoxWrapper>
                </CheckboxGroup>
              </TabPanel>





            </TabsWrapper>
          </div>
          <ButtonWrapper>

            <ButtonPrimary type="submit">Dodaj</ButtonPrimary>
          </ButtonWrapper>
        </StyledForm>
      </FormWrapper>

    </Wrapper>
  );
}

export default AddUsersPermissions;

