import React from 'react';
import styled from 'styled-components';
import CloseButton from 'components/atoms/Buttons/CloseButton';

const IngredientsInUse = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid #000;
  padding: 0;
  overflow: hidden;
  span {
    height: 120%;
    width: 30%;
    border: 0;
  }
`;

const ingredientsUseInMeal = ({
  ingredientsInMeal,
  optionsName,
  deleteIngredientFromList,
  isValidationPassed,
  lang,
}) => {
  if (ingredientsInMeal.length > 0 && optionsName.length > 0) {
    const igUseinMeal = ingredientsInMeal.map((singleIngredient, index) => {
      const nameOfIngredient = optionsName.filter(singleOption => {
        return singleOption.value === singleIngredient.ingredient_id;
      })[0];

      const nameIngredients = () => {
        let nameIngredient = '';
        if (lang === 'PL') {
          nameIngredient = nameOfIngredient.label;
        } else if (lang === 'EN') {
          nameIngredient = nameOfIngredient.name_en;
        } else if (lang === 'CZ') {
          nameIngredient = nameOfIngredient.name_cz;
        } else if (lang === 'ES') {
          nameIngredient = nameOfIngredient.name_es;
        } else if (lang === 'HU') {
          nameIngredient = nameOfIngredient.name_hu;
        } else if (lang === 'RO') {
          nameIngredient = nameOfIngredient.name_ro;
        } else if (lang === 'BG') {
          nameIngredient = nameOfIngredient.name_bg;
        } else if (lang === 'SK') {
          nameIngredient = nameOfIngredient.name_sk;
        } else if (lang === 'PT') {
          nameIngredient = nameOfIngredient.name_pt;
        } else if (lang === 'IT') {
          nameIngredient = nameOfIngredient.name_it;
        } else if (lang === 'DE') {
          nameIngredient = nameOfIngredient.name_de;
        } else if (lang === 'FR') {
          nameIngredient = nameOfIngredient.name_fr;
        } else {
          nameIngredient = nameOfIngredient.label;
        }

        return nameIngredient;
      };
      return (
        <IngredientsInUse key={nameOfIngredient.value}>
          <span>{nameIngredients()}</span>
          <span>
            {singleIngredient.quantity} {nameOfIngredient?.unit}{' '}
          </span>
          <CloseButton
            handleClick={deleteIngredientFromList}
            runValidation={isValidationPassed}
            itemId={index}
          />
        </IngredientsInUse>
      );
    });
    return <>{igUseinMeal}</>;
  }
  return null;
};
export default ingredientsUseInMeal;
