import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddMealsCategory from 'views/MealsCategory/AddMealsCategory/AddMealsCategory';
import MealsCategoryTable from 'components/atoms/Table/MealsCategoryTable'
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';

const URLAPI = process.env.REACT_APP_URL_API;

const SectionAddCategory = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px; 
  padding-bottom: 20px;
  position: relative;
`
const MealsCategory = ({
  onFetchMealsCategory,
  mealsCategory,
  onDeleteMealCategory,
  onInitValidators,
  unieqeMealsCategoryNames,
  onAddMealsCategoryForm


}) => {
  const [visible, setVisible] = useState(false)
  const [init, setInit] = useState(false);
  const [mealsCat, setMealsCat] = useState([]);
  const showAddIgCategory = () => {
    setVisible(!visible)
  }

  const setElementsForCurrentPage = () => {
    onFetchMealsCategory()
  }

  useEffect(() => {
    if (mealsCat !== null && init === false) {
      setElementsForCurrentPage()
      setInit(true)
    }
  }, [mealsCat])

  useEffect(() => {
    const endpoint = `${URLAPI}meal-category/name`
    onInitValidators(endpoint, 'mealsCategory')
  }, [])


  return (
    <SectionAddCategory>
      <IconButton
        onClick={() => showAddIgCategory()}
      >
        <AddIcon fontSize="large" />
      </IconButton>
      {visible && <AddMealsCategory
        setVisible={setVisible}
        unieqeMealsCategoryNames={unieqeMealsCategoryNames}
        onAddMealsCategoryForm={onAddMealsCategoryForm}
      />}

      <MealsCategoryTable
        setMealsCat={setMealsCat}
        mealsCategory={mealsCategory}
        mealsCat={mealsCat}
        onDeleteMealCategory={onDeleteMealCategory}

      />
    </SectionAddCategory>
  );
}

const mapStateToProps = state => {
  return {
    mealsCategory: state.mealsCat.mealsCategory,
    unieqeMealsCategoryNames: state.validators.validators

  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
    onFetchMealsCategory: () => dispatch(actionCreators.fetchMealsCategory()),
    onDeleteMealCategory: id => dispatch(actionCreators.deleteMealCategory(id)),
    onAddMealsCategoryForm: (name, resetForm) => dispatch(actionCreators.AddMealsCategoryForm(name, resetForm)),
    onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(MealsCategory);
