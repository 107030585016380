import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
// import Select from 'react-select';
import PropTypes from 'prop-types';

import * as actionCreators from 'store/actions';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import SelectImput from 'components/atoms/Inputs/SelectInput'
import InListButton from 'components/atoms/Buttons/InListButton'
import Input from 'components/atoms/Inputs/Input'

const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`


const Form = styled.form`
`

const ContainerForFewInputs = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }

`
const TwoImputs = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  &:first-child {
  align-items: flex-start;
  }
  &:last-child {
  align-items: flex-end;
  }
  @media screen and (min-width: 992px) {
    align-items: flex-start;
    flex-direction: row;
    flex-basis: 49%;
    justify-content: space-between;
}
`

const SingleInputWrapper = styled.div`
  width: 98%;
  margin-bottom: 16px;
  flex-basis: 48%;
  @media screen and (min-width: 992px) {
    flex-basis: 49%;
}
`

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#000000',
    label: 'color: #000000'
  },
  formControl: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: 120,
    width: '100%',
    height: 'auto',
    padding: theme.spacing(2),
    paddingBottom: 1,
    borderRadius: 5,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),

  },
}));

const SearchIngredients = ({ onSetIngredientsQueryAfterSearch, optionsName, optionsIgCat, showAddIngredients, visibleForm }) => {
  const [state, setState] = useState({
    minCaloriesSearch: '',
    maxCaloriesSearch: '',
    nameSearch: { value: '', label: '' },
    categorySearch: { value: '' },
    hasLactoseSearch: { value: '' },
    hasGlutenSearch: { value: '' },
  })
  const handleSearch = (inputValue, key, type) => {
    let value = '';
    if (inputValue) {
      if (type === 'text' || type === 'number') {
        value = (inputValue.target.value).toLowerCase();

      } else if (type === 'select') {
        value = inputValue;
      } else {
        value = inputValue;
      }
      setState(state => ({
        ...state,
        [key]: value,
      }))
    } else {
      setState(state => ({
        ...state,
        [key]: { value: '' },
      }))
      return null
    };
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      let query = '';

      const nameQuery = `${state.nameSearch.value !== '' ? `&name=${state.nameSearch.label}` : ``}`;
      const minCaloriesQuery = `${state.minCaloriesSearch !== '' ? `&calories_from=${state.minCaloriesSearch}` : ''
        }`;
      const maxCaloriesQuery = `${state.maxCaloriesSearch !== '' ? `&calories_to=${state.maxCaloriesSearch}` : ''
        }`;
      const categoryQuery = `${state.categorySearch.value !== '' ? `&category_ingredient_id=${state.categorySearch.value}` : ''
        }`;
      const hasGlutenQuery = `${state.hasGlutenSearch.value !== '' ? `&has_gluten=${state.hasGlutenSearch.value}` : ''
        }`;
      const hasLactoseQuery = `${state.hasLactoseSearch.value !== '' ? `&has_lactose=${state.hasLactoseSearch.value}` : ''
        }`;
      const nameToLowerCase = nameQuery.toLowerCase();
      query = `${nameToLowerCase}${minCaloriesQuery}${maxCaloriesQuery}${categoryQuery}${hasGlutenQuery}${hasLactoseQuery}`;
      onSetIngredientsQueryAfterSearch(`${query}`);
      // }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [state])


  const classes = useStyles();

  const lactoseOption = [
    { value: '', label: 'bez znaczenia' },
    { value: 0, label: 'Nie zawiera laktozy' },
    { value: 1, label: 'Zawiera laktozę' },
  ];

  const glutenOption = [
    { value: '', label: 'bez znaczenia' },
    { value: 0, label: 'Nie zawiera glutenu' },
    { value: 1, label: 'Zawiera gluten' },
  ];

  return (
    <>
      <Form  >
        <FormControl className={classes.formControl}
        >
          <SelectWrapper>
            <SelectImput
              options={optionsName}
              noOptionsText={
                <InListButton handleOnClick={showAddIngredients}>Dodaj brakujący składnik</InListButton>}
              label="Nazwa"
              handleOnChange={handleSearch}
              onChangeParams={['nameSearch', 'select']}
              // value={nameSearch}
              id="nameSearch"

            />
          </SelectWrapper>
          <ContainerForFewInputs>

            <TwoImputs>
              <SingleInputWrapper htmlFor="caloriesMin">
                <Input
                  label='Min kalorie'
                  name="minCaloriesSearch"
                  type="number"
                  id="caloriesMin"
                  onChange={handleSearch}
                  onChangeParams={['minCaloriesSearch', 'text']}
                  value={state.minCaloriesSearch}
                />
              </SingleInputWrapper>
              <SingleInputWrapper htmlFor="maxCaloriesSearch">
                <Input
                  label='Max kalorie'
                  name="maxCaloriesSearch"
                  id="maxCaloriesSearch"
                  type="number"
                  onChange={handleSearch}
                  onChangeParams={['maxCaloriesSearch', 'text']}
                  value={state.maxCaloriesSearch}

                />
              </SingleInputWrapper>
            </TwoImputs>
            <TwoImputs>

              <SingleInputWrapper>
                <SelectImput
                  options={lactoseOption}
                  noOptionsText='Nie znaleziono'
                  label="Laktoza"
                  handleOnChange={handleSearch}
                  onChangeParams={['hasLactoseSearch', 'select']}
                  // value={hasLactoseSearch}
                  id='hasLactoseSearch'
                />
              </SingleInputWrapper>
              <SingleInputWrapper>
                <SelectImput
                  options={glutenOption}
                  noOptionsText='Nie znaleziono'
                  label="Gluten"
                  handleOnChange={handleSearch}
                  onChangeParams={['hasGlutenSearch', 'select']}
                  // value={hasGlutenSearch}
                  id='hasGlutenSearch'
                />
              </SingleInputWrapper>
            </TwoImputs>

          </ContainerForFewInputs>
          <SelectWrapper>
            <SelectImput
              options={optionsIgCat}
              noOptionsText='Nie znaleziono kategorii'
              label="Kategoria"
              handleOnChange={handleSearch}
              onChangeParams={['categorySearch', 'select']}
              // value={categorySearch}
              id='categorySearch'

            />
          </SelectWrapper>

        </FormControl>
      </Form>
    </>
  );
}

const mapStateToProps = state => {
  return {
    optionsName: state.ig.optionsNameForSearch,
    optionsIgCat: state.igCat.optionsNameCategoryForSearch,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetIngredientsQueryAfterSearch: query =>
      dispatch(actionCreators.setIngredientsQueryAfterSearch(query)),
  };
};

SearchIngredients.propTypes = {
  onSetIngredientsQueryAfterSearch: PropTypes.func.isRequired,

  optionsName: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionsIgCat: PropTypes.arrayOf(PropTypes.object).isRequired,
  showSearch: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchIngredients);
