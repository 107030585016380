/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import * as actionCreators from 'store/actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Loading from 'components/atoms/Loading/Loading';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import { Typography } from '@material-ui/core';
import SelectInput from 'components/atoms/Inputs/SelectInput';
import Input from 'components/atoms/Inputs/Input';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators';

const URLAPI = process.env.REACT_APP_URL_API;

const Wrapper = styled.div`
  margin: 16px auto;
  position: relative;
  padding: 32px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[5]};
  border-radius: 4px;
`;

const ClientData = styled.div`
  margin: 16px 0 32px 16px;
`;
const DataTtile = styled.p`
  font-weight: 700;
`;
const Data = styled.span`
  font-weight: normal;
`;

const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const FormControl = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: ${({ shorter }) => (shorter ? '50%' : '100%')};
`;

const SubmitBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormWrapper = styled.div``;

const initialState = {
  notes: '',
  currency: { value: null, code: '' },
  paymentMethod: { value: null, id: '', name: '' },
  shipmentsMethod: { value: null, id: '', name: '' },
  porductsName: [],
  isError: {
    currency: null,
    paymentMethod: null,
    shipmentsMethod: null,
    porductsName: null,
  },
};

const AddOrder = ({
  match,
  onGetSingleUsers,
  users,
  history,
  onAddOrdersForm,
  onFetchCurrencies,
  currencies,
  onFetchCountries,
  countries,
  onFetchPaymentMethod,
  paymentMethods,
  onFetchShipmentsMethod,
  shipmentsMethod,
  onFetchProducts,
  products,
}) => {
  const [userId, setUserId] = useState(null);
  const [validationPassed, setValidationPassed] = useState(true);
  const [state, setState] = useState(initialState);
  const [lastEdited, setLastEdited] = useState('');
  const [productOptions, setProductOptions] = useState(null);

  useEffect(() => {
    setUserId(match.params.userId);
  }, []);

  useEffect(() => {
    const productsOptions = products.map(product => {
      return {
        ...product,
        name: `${product.name} - ${product.language}`,
      };
    });
    setProductOptions(productsOptions);
  }, [products]);

  useEffect(() => {
    if (userId !== null) {
      onGetSingleUsers(match.params.userId);
    }
  }, [userId]);

  useEffect(() => {
    if (currencies.length === 0) {
      onFetchCurrencies();
    }
  }, [currencies]);

  useEffect(() => {
    if (countries.length === 0) {
      onFetchCountries();
    }
  }, [countries]);
  useEffect(() => {
    if (paymentMethods.length === 0) {
      onFetchPaymentMethod();
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (shipmentsMethod.length === 0) {
      onFetchShipmentsMethod();
    }
  }, [shipmentsMethod]);

  useEffect(() => {
    if (products.length === 0) {
      onFetchProducts();
    }
  }, [products]);

  const resetForm = () => {
    setState(initialState);
    history.goBack();
  };
  const onCloseHandler = () => {
    resetForm();
  };
  const isValidationPassed = () => {
    const { isError } = { ...state };
    const isErrorLeft = Object.values(isError).reduce((a, b) => {
      return a === b ? a : NaN;
    });

    if (isErrorLeft === false) {
      setValidationPassed(false);
    } else {
      setValidationPassed(true);
    }
  };

  const selectValid = key => {
    const curentSelect = state[key];
    const { isError } = { ...state };
    const currentState = isError;
    if (key === 'porductsName') {
      if (curentSelect.length !== 0) {
        currentState.porductsName = false;
      } else {
        currentState.porductsName = true;
      }
    } else if (curentSelect !== null) {
      if (curentSelect.value?.length === '' || curentSelect.value === null) {
        currentState[key] = true;
      } else {
        currentState[key] = false;
      }
      setState({
        ...state,
        isError: currentState,
      });
    } else if (curentSelect === null) {
      currentState[key] = true;
      setState({
        ...state,
        isError: currentState,
      });
    }
    isValidationPassed();
  };

  useEffect(() => {
    if (lastEdited !== '') {
      selectValid(lastEdited);
    }
  }, [lastEdited, state.isError]);

  const handleChangeSelect = (inputValue, key) => {
    setState({
      ...state,
      [key]: inputValue,
    });
    setLastEdited(key);
  };

  const handleChangeInput = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const idGenerator = array => {
    const products = [];
    array.map(el => products.push({ product_id: el.id }));
    return products;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const productsArray = idGenerator(state.porductsName);
    onAddOrdersForm(
      userId,
      state.notes,
      state.currency.code,
      state.paymentMethod.id,
      state.paymentMethod.name,
      state.shipmentsMethod.id,
      state.shipmentsMethod.name,
      state.shipmentsMethod.price,
      productsArray,
      resetForm,
    );
  };

  if (users === null || users === undefined) {
    return <Loading />;
  }
  return (
    <Wrapper>
      <BtnHolder>
        <CloseButton handleClick={onCloseHandler} />
      </BtnHolder>
      <Typography variant="h5">Dane Klienta:</Typography>
      <ClientData>
        <DataTtile>
          Id klienta: <Data>{users.id}</Data>
        </DataTtile>
        <DataTtile>
          Klient:
          <Data>
            {users.first_name}
            {users.last_name}
          </Data>
        </DataTtile>
        <DataTtile>
          E-mail: <Data>{users.email}</Data>
        </DataTtile>
        <DataTtile>
          Nr telefonu: <Data>{users.phone_number}</Data>
        </DataTtile>
        <DataTtile>
          Kraj: <Data>{users.country}</Data>
        </DataTtile>
        <DataTtile>
          Adres:&nbsp;
          <Data>{users.street} {users.house_number}&nbsp;</Data>
          <Data>{users.post_code} {users.city}</Data>
        </DataTtile>
        <DataTtile>
          Ip klienta: <Data>{users.user_ip}</Data>
        </DataTtile>
      </ClientData>
      <Typography variant="h5">Zamówienie:</Typography>

      <form onSubmit={e => handleSubmit(e)}>
        <FormWrapper>
          <FormControl>
            <SelectInput
              multiple
              options={productOptions}
              optionType="name"
              noOptionsText="Nie znaleziono"
              label="Produkty"
              handleOnChange={handleChangeSelect}
              selectValid={selectValid}
              onChangeParams={['porductsName', 'select']}
              id="porductsName"
              isError={state.isError.porductsName}
              errorMessage="Dodaj przynajmniej jeden produkt"
              htmlFor="porductsName"
            />
          </FormControl>
          <FormControl>
            <SelectInput
              options={currencies}
              disabledOption={option => option.disabled === 1}
              optionType="code"
              noOptionsText="Brak szukanej frazy"
              label="Waluta"
              handleOnChange={handleChangeSelect}
              onChangeParams={['currency']}
              name="currency"
              id="currency"
              selectValid={selectValid}
              isError={state.isError.currency}
              errorMessage="Pole wymagane"
              htmlFor="currency"
            />
          </FormControl>
          <FormControl>
            <SelectInput
              options={paymentMethods}
              optionType="name"
              noOptionsText="Brak szukanej frazy"
              label="Metoda płatności"
              handleOnChange={handleChangeSelect}
              onChangeParams={['paymentMethod']}
              name="paymentMethod"
              id="paymentMethod"
              selectValid={selectValid}
              isError={state.isError.paymentMethod}
              errorMessage="Pole wymagane"
              htmlFor="paymentMethod"
            />
          </FormControl>
          <FormControl>
            <SelectInput
              options={shipmentsMethod}
              optionType="name"
              noOptionsText="Brak szukanej frazy"
              label="Metoda dostawy"
              handleOnChange={handleChangeSelect}
              onChangeParams={['shipmentsMethod']}
              name="shipmentsMethod"
              id="shipmentsMethod"
              selectValid={selectValid}
              isError={state.isError.shipmentsMethod}
              errorMessage="Pole wymagane"
              htmlFor="shipmentsMethod"
            />
          </FormControl>
          <FormControl>
            <Input
              id="notesMain"
              onChange={handleChangeInput}
              value={state.notes}
              // htmlFor={name}
              label="Notatka"
              name="notes"
              type="text"
              multiline
              inputProps={{ rows: 5 }}
              htmlFor="notes"
            />
          </FormControl>
        </FormWrapper>
        <SubmitBtnWrapper>
          <ButtonPrimary isDisabled={validationPassed} type="submit">
            Dodaj zamówienie
          </ButtonPrimary>
        </SubmitBtnWrapper>
      </form>
    </Wrapper>
  );
};

const mapStateToProps = state => {
  return {
    users: state.users.userToEdit,
    currencies: state.currencies.currencies,
    countries: state.countries.countries,
    paymentMethods: state.paymentMethods.paymentMethods,
    shipmentsMethod: state.shipmentsMethod.shipmentsMethod,
    products: state.products.products,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSingleUsers: id => dispatch(actionCreators.getSingleUsers(id)),
    onAddOrdersForm: (
      userId,
      notes,
      currency,
      paymentMethodId,
      paymentMethodName,
      shipmentMethodId,
      shipmentMethodName,
      shipmentTotal,
      productsArray,
      resetForm,
    ) =>
      dispatch(
        actionCreators.addOrdersForm(
          userId,
          notes,
          currency,
          paymentMethodId,
          paymentMethodName,
          shipmentMethodId,
          shipmentMethodName,
          shipmentTotal,
          productsArray,
          resetForm,
        ),
      ),
    onFetchCurrencies: () => dispatch(actionCreators.fetchCurrencies()),
    onFetchCountries: () => dispatch(actionCreators.fetchCountries()),
    onFetchPaymentMethod: () => dispatch(actionCreators.fetchPaymentMethod()),
    onFetchShipmentsMethod: () => dispatch(actionCreators.fetchShipmentsMethod()),
    onFetchProducts: () => dispatch(actionCreators.fetchProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrder);
