import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddUsers from 'views/Users/AddUsers/AddUsers';
import UsersTable from 'components/atoms/Table/UsersTable';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import SearchCustomers from 'views/Customers/SearchCustomers/SearchCustomers';

const URLAPI = process.env.REACT_APP_URL_API;

const Wrapper = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  position: relative;
  /* width: 70%;
  margin: 0 auto; */
`;
const Users = ({
  onInitValidators,
  onFetchUsers,
  isLoading,
  users,
  unieqeEmails,
  notifications,
  onAddUsersForm,
  onDeleteUsers,
  usersCfg,
  usersSearchQuery,
  onInitAllUsersForOptions,
}) => {
  const [visible, setVisible] = useState(false);
  const [visibleSearch, setVisibleSearch] = useState(false);
  const [init, setInit] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [usersConfig, setUsersConfig] = useState({
    per_page: 100,
    current_page: 1,
    jumpToPage: null,
    usersSearchQuery: '',
    total: 1,
    data: [],
  });
  const [page, setPage] = useState(0);
  const [isOpen, setOpen] = useState([]);
  const [deleteConfirmVisable, setDeleteConfirmVisable] = useState([]);

  useEffect(() => {
    setUsersConfig(usersCfg);
  }, [usersCfg]);

  const showAddUsers = () => {
    setVisible(!visible);
  };

  const showSearch = () => {
    setVisibleSearch(!visibleSearch);
  };

  useEffect(() => {
    const endpoint = `${URLAPI}user/email`;
    onInitValidators(endpoint, 'usersEmail');
  }, []);

  const setElementsForCurrentPage = () => {
    const endpoint = `${URLAPI}users/admin?page=${usersConfig.current_page}&per_page=${
      usersConfig.per_page
    }${usersSearchQuery === undefined ? '' : usersSearchQuery}`;

    onFetchUsers(endpoint);
  };

  useEffect(() => {
    if (usersList !== null && init === false) {
      setElementsForCurrentPage();
      setInit(true);
    }
  }, [users]);

  useEffect(() => {
    const openArray = {};
    setUsersList(users);
    users.map(el => {
      const key = (el.name + el.id.toString())
        .split('')
        .filter(e => e.trim().length)
        .join('');
      openArray[key] = false;
    });
    setOpen(openArray);
    setDeleteConfirmVisable(openArray);
  }, [users]);

  useEffect(() => {
    const endpoint = `${URLAPI}users/admin?records=all`;
    onInitAllUsersForOptions(endpoint);
  }, []);

  useEffect(() => {
    setElementsForCurrentPage();
  }, [usersSearchQuery]);

  return (
    <Wrapper>
      <IconButton onClick={() => showAddUsers()}>
        <AddIcon fontSize="large" />
      </IconButton>
      <IconButton onClick={() => showSearch()}>
        <SearchIcon fontSize="large" />
      </IconButton>
      {visible && (
        <AddUsers
          setVisible={setVisible}
          notifications={notifications}
          users={users}
          unieqeEmails={unieqeEmails}
          onAddUsersForm={onAddUsersForm}
        />
      )}
      {visibleSearch && <SearchCustomers showSearch={visibleSearch} visibleForm={showSearch} />}

      <UsersTable
        setUsersList={setUsersList}
        usersList={usersList}
        users={users}
        isLoading={isLoading}
        unieqeEmails={unieqeEmails}
        onInitValidators={onInitValidators}
        onAddUsersForm={onAddUsersForm}
        onDeleteUsers={onDeleteUsers}
        usersConfig={usersConfig}
        setPage={setPage}
        setOpen={setOpen}
        page={page}
        isOpen={isOpen}
        deleteConfirmVisable={deleteConfirmVisable}
        setDeleteConfirmVisable={setDeleteConfirmVisable}
      />
    </Wrapper>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.error,
    unieqeEmails: state.validators.validators,
    notifications: state.notifications.notifications,
    users: state.users.users,
    usersCfg: state.users.usersConfig,
    usersSearchQuery: state.users.usersSearchQuery,
    usersOptionsNameForSearch: state.users.usersOptionsNameForSearch,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUsers: endpoint => dispatch(actionCreators.initUsers(endpoint)),
    onDeleteUsers: id => dispatch(actionCreators.deleteUsers(id)),
    onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),
    onAddUsersForm: (history, bodyFormData, endpoint, resetForm, endpointDownload) =>
      dispatch(
        actionCreators.submitUsersForm(
          history,
          bodyFormData,
          endpoint,
          resetForm,
          endpointDownload,
        ),
      ),
    onSetUsersQueryAfterSearch: query => dispatch(actionCreators.setUsersQueryAfterSearch(query)),
    onInitAllUsersForOptions: endpoint => dispatch(actionCreators.initAllUsersForOptions(endpoint)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
