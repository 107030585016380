import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Loading from 'components/atoms/Loading/Loading';
import Typography from '@material-ui/core/Typography';

const DataTtile = styled.p`
  font-weight: 700;
  padding-bottom: 8px;
`;
const Data = styled.span`
  font-weight: normal;
`;

const StyledUl = styled.ul`
    margin-left: 24px; 
`


const getCurrentDate = data => {
  const newDate = new Date(data);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const hour = newDate.getHours();
  const min = newDate.getMinutes();
  const sec = newDate.getSeconds();
  return `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`
    } ${hour < 10 ? `0${hour}` : `${hour}`}:${min < 10 ? `0${min}` : `${min}`}:${sec < 10 ? `0${sec}` : `${sec}`}`;
};

const QuizAnswers = ({
  userInfo,
  igCategory,
  startDiet
}) => {
  const [quizAnswer, setQuizAnswer] = useState(null)
  const excludedRef = useRef(null);

  useEffect(() => {
    // if (excludedRef.current) {
    console.log(excludedRef, 'xxx')
    // }
  }, [excludedRef])

  useEffect(() => {
    if (userInfo !== null) {
      const quiz = userInfo.quizes
      const { profiles } = userInfo


      setQuizAnswer({
        email: userInfo.email,
        heightCM: quiz.height_cm,
        heightFT: quiz.height_ft,
        sex: quiz.sex,
        weightKG: quiz.weight_kg,
        weightLB: quiz.weight_lb,
        answers: quiz.answers.map(element => {
          return { [element.question]: element.answer }
        }),
        bmi: profiles.bmi,
        calories: profiles.calories,
        maxProperWeight: profiles.max_proper_weight,
        minProperWeight: profiles.min_proper_weight,
        metabolicAge: profiles.metabolic_age,
        userAchivment: profiles.user_achivment,
        weightTargetKG: profiles.weight_target_kg,
        weightTargetLB: profiles.weight_target_lb,
        type: quiz.type_form
      })
    }

  }, [userInfo])

  if (quizAnswer === null) {
    return <Loading />
  }
  return (
    <>
      <DataTtile>
        E-mail:{" "}
        <Data>
          {quizAnswer.email}
        </Data>
      </DataTtile>
      <DataTtile>
        Data rozpoczęcia diety:{" "}
        <Data>
          {startDiet != null ? getCurrentDate(startDiet) : "brak danych"}
        </Data>
      </DataTtile>

      <DataTtile>
        BMI:{" "}
        <Data>
          {quizAnswer.bmi}
        </Data>
      </DataTtile>
      <DataTtile>
        Kcal:{" "}
        <Data>
          {quizAnswer.calories}
        </Data>
      </DataTtile>
      <DataTtile>
        Wzrost:{" "}
        <Data>
          {quizAnswer.heightCM && `${quizAnswer.heightCM} cm`}
          {quizAnswer.heightFT && `${quizAnswer.heightFT} ft`}
        </Data>
      </DataTtile>
      <DataTtile>
        Waga obecna:{" "}
        <Data>
          {quizAnswer.weightKG && `${quizAnswer.weightKG} kg`}
          {quizAnswer.weightLB && `${quizAnswer.weightLB} lb`}
        </Data>
      </DataTtile>
      <DataTtile>
        Waga docelowa:{" "}
        <Data>
          {quizAnswer.weightTargetKG && `${quizAnswer.weightTargetKG} kg`}
          {quizAnswer.weightTargetLB && `${quizAnswer.weightTargetLB} lb`}
        </Data>
      </DataTtile>

      <Typography variant="h6" color="primary">
        Wykluczone produkty:
            </Typography>
      <StyledUl ref={excludedRef}>

        {quizAnswer.answers.map(el => Object.keys(el)[0] === 'excluded_category' ? <li >{igCategory.filter(category => category.id === Object.values(el)[0])[0].name}</li> : null)}
        {quizAnswer.answers.map(el => Object.keys(el)[0] === 'lactose' ? <li >laktoza</li> : null)}
        {quizAnswer.answers.map(el => Object.keys(el)[0] === 'gluten' ? <li >gluten</li> : null)}
      </StyledUl>

      <DataTtile >
        Tryp formularza:
            </DataTtile>
      <StyledUl>
        {quizAnswer.type}
      </StyledUl>
    </>
  );
}

export default QuizAnswers;