/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';

const tableHeaders = [
  { id: 'id', label: 'id', width: '4%', align: 'left', type: 'id' },
  { id: 'name', label: 'Produkt', width: '27%', align: 'center', type: 'currencyCode' },
  { id: 'language,', label: 'Język', width: '5%', align: 'center', type: 'string' },
  { id: 'price,', label: 'Cena', width: '27%', align: 'center', type: 'price' },
  { id: 'quantity,', label: 'ilość', width: '10%', align: 'center', type: 'string' },
  { id: 'sum,', label: 'Razem', width: '27%', align: 'center', type: 'price' },
];
const StyledTableCell = withStyles(theme => ({
  container: {
    maxHeight: 440,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const Sum = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  font-weight: 700;
  margin-right: 50px;
`;

const EditOrdersProductsTable = ({ productsList, currency, fullPrice }) => {
  const classes = useStyles();
  const createDataFuncVals = tableHeaders.map(el => el.id);
  const createData = (...params) => {
    const obj = {};
    // eslint-disable-next-line no-return-assign
    createDataFuncVals.map((el, index) => (obj[el] = params[index]));
    return obj;
  };

  let data = [];

  if (productsList) {
    data = productsList.map(el => {
      return {
        id: el.id,
        name: el.name,
        language: el.language,
        price: el.product_order.price,
        quantity: el.product_order.quantity,
        sum: el.product_order.price * el.product_order.quantity,
      };
    });
  }

  const rows = data.map(el => {
    const funcParams = [];
    Object.keys(el).map(key => {
      funcParams.push(el[key]);
      return funcParams;
    });
    return createData(...funcParams);
  });

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer>
          <Table className={classes.root} stickyHeader fullWidth aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHeaders.map(head => (
                  <React.Fragment key={head.value}>
                    <StyledTableCell align={head.align} style={{ width: head.width }}>
                      {head.label}
                    </StyledTableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                return (
                  <React.Fragment key={row.code}>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      {tableHeaders.map(head => {
                        const value = row[head.id];
                        if (head.type === 'price') {
                          return (
                            <React.Fragment key={head.id}>
                              <TableCell align={head.align}>
                                {value} {currency}
                              </TableCell>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment key={head.id}>
                              <TableCell align={head.align}>{value}</TableCell>
                            </React.Fragment>
                          );
                        }
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colspan="5">
                  <Sum>
                    {fullPrice} {currency}
                  </Sum>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default EditOrdersProductsTable;
