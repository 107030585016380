export const excludedStuff = forExclude => {
  let whatWasExcludedArray = Object.entries(forExclude);

  whatWasExcludedArray = whatWasExcludedArray.filter(singleExcludeItem => {
    return singleExcludeItem[1] === true;
  });

  const seaFoodID = process.env.REACT_APP_INGREDIENTS_CATEGORY_ID_SEAFOOD;
  const meatID = process.env.REACT_APP_INGREDIENTS_CATEGORY_ID_MEAT;
  const dairyID = process.env.REACT_APP_INGREDIENTS_CATEGORY_ID_DAIRY;
  const fishID = process.env.REACT_APP_INGREDIENTS_CATEGORY_ID_FISH;

  let singleObject = {};
  let quizesArr = [];
  const quizes = whatWasExcludedArray.map(singleExcludeItem => {
    const assigningNameToId = singleExcludeItem[0];

    switch (assigningNameToId) {
      case 'meat':
        singleObject = {
          question: 'excluded_category',
          answer: meatID,
        };
        quizesArr = quizesArr.concat(singleObject);
        return quizesArr;
      case 'fish':
        singleObject = {
          question: 'excluded_category',
          answer: fishID,
        };
        quizesArr = quizesArr.concat(singleObject);
        return quizesArr;
      case 'lactose':
        singleObject = {
          question: 'lactose',
          answer: 1,
        };
        quizesArr = quizesArr.concat(singleObject);
        return quizesArr;
      case 'gluten':
        singleObject = {
          question: 'gluten',
          answer: 1,
        };
        quizesArr = quizesArr.concat(singleObject);
        return quizesArr;
      case 'dairy':
        singleObject = {
          question: 'excluded_category',
          answer: dairyID,
        };
        quizesArr = quizesArr.concat(singleObject);
        return quizesArr;
      case 'seafood':
        singleObject = {
          question: 'excluded_category',
          answer: seaFoodID,
        };
        quizesArr = quizesArr.concat(singleObject);
        return quizesArr;

      case 'clear':
        singleObject = {
          question: 'clear',
          answer: true,
        };
        quizesArr = quizesArr.concat(singleObject);
        return quizesArr;

      case 'resetCategory':
        singleObject = undefined;
        return quizesArr;
      default:
        singleObject = {};
    }

    return quizesArr;
  });

  const quizesAfterSwitch = quizes[quizes.length - 1];

  return quizesAfterSwitch;
};
