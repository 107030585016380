import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions/index';

import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import Input from 'components/atoms/Inputs/Input';
import SelectInput from 'components/atoms/Inputs/SelectInput';
import { Validators } from 'components/atoms/Inputs/Validators';
import CheckBox from 'components/atoms/CheckBox/CheckBox';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      // role="tabpanel"
      hidden={value !== index}
      id={`ig-name-tabpanel-${index}`}
      aria-labelledby={`ig-name-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `ig-name-tab-${index}`,
    'aria-controls': `ig-name-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      outline: 'none',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
  },
  button: {
    width: '100%',
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const TabsWrapper = styled.div`
  width: 48%;
`;

const AddIgRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
const ModalWrapper = styled.div`
  position: relative;

  width: 100%;
  @media screen and (min-width: 992px) {
    width: 80%;
  }
`;

const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const AddIgInputName = styled.h3`
  width: 100%;
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
`;

const SingleInputWrapper = styled.div`
  width: 48%;
  margin-bottom: 20px;
`;
const SingleInputWrapperSm = styled.div`
  width: 100%;
  /* margin-bottom: 20px; */
  @media screen and (min-width: 992px) {
    width: 19%;
  }
`;

const SelectWrapper = styled.div`
  width: 48%;
  position: relative;
  margin-bottom: 20px;
`;

const CheckboxWrapper = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`;

const ContainerForFewInputs = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    flex-basis: 15%;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledParagraph = styled.p`
  color: red;
  display: ${({ isHovered }) => (isHovered === true ? 'block' : 'none')};
`;

const URLAPI = process.env.REACT_APP_URL_API;
const initialState = {
  // optionsUnits: [],
  optionsNameCategoryForSearch: [],
  ig: '',
  name: '',
  name_en: '',
  name_cz: '',
  name_es: '',
  name_hu: '',
  name_ro: '',
  name_sk: '',
  name_bg: '',
  name_pt: '',
  name_it: '',
  name_de: '',
  name_fr: '',
  calories: '',
  carbohydrates: '',
  protein: '',
  sugar: '',
  fats: '',
  file: '',
  avatarImg: true,
  tmpImgURL: '',
  optionsIgCat: [],
  optionsName: [],
  // unit: null,
  category_ingredient_id: null,
  hasLactose: false,
  hasGluten: false,
  isError: {
    name: null,
    name_en: null,
    name_cz: null,
    name_es: null,
    name_hu: null,
    name_ro: null,
    name_sk: null,
    name_bg: null,
    name_it: null,
    name_pt: null,
    name_de: null,
    name_fr: null,
    calories: null,
    protein: null,
    carbohydrates: null,
    sugar: null,
    fats: null,
    // unit: null,
    category_ingredient_id: null,
  },
};

const AddIngredients = ({
  perPage,
  currentPage,
  open,
  onInitAllIngredientsForOptions,
  onSubmitIngredientForm,
  closeAddIngredients,
  ig,
  igCat,
  optionsName,
  optionsNameCategoryForSearch,
  onfetchIngredientsCategory,
  unieqeIngredientsNames,
  onInitValidators,
}) => {
  const [validationPassed, setValidationPassed] = useState(true);
  const [isHovered, setHover] = useState(false);
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    const endpoint = `${URLAPI}ingredient/name`;
    onInitValidators(endpoint, 'ingredients');
  }, [ig]);

  useEffect(() => {
    onInitAllIngredientsForOptions();
    // onFetchUnits()
    onfetchIngredientsCategory();
  }, []);

  useEffect(() => {
    setState(state => ({
      ...state,
      ig,
      optionsIgCat: igCat,
      // optionsUnits,
      optionsName,
      optionsNameCategoryForSearch,
    }));
  }, [
    ig,
    // optionsUnits,
    optionsName,
    optionsNameCategoryForSearch,
  ]);

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const isValidationPassed = () => {
    const { isError } = { ...state };
    const isErrorLeft = Object.values(isError).reduce((a, b) => {
      return a === b ? a : NaN;
    });

    if (isErrorLeft === false) {
      setValidationPassed(true);
    } else {
      setValidationPassed(false);
    }
  };

  const selectValid = key => {
    const curentSelect = state[key];
    const { isError } = { ...state };
    const currentState = isError;
    if (curentSelect !== null) {
      if (curentSelect.value.length === 0) {
        currentState[key] = true;
      } else {
        currentState[key] = false;
      }
      setState({
        ...state,
        isError: currentState,
      });
    } else if (curentSelect === null) {
      currentState[key] = true;
      setState({
        ...state,
        isError: currentState,
      });
    }
    isValidationPassed();
  };

  const handleChangeSelect = (inputValue, key) => {
    setState({
      ...state,
      [key]: inputValue,
      lastEdited: key,
    });
  };
  useEffect(() => {
    if (state.lastEdited) {
      selectValid(state.lastEdited);
    }
  }, [state.lastEdited, state.isError, state.category_ingredient_id]);

  const handleChangeCheckbox = e => {
    const item = e.target.name;
    setState({
      ...state,
      [item]: !state[item],
    });
  };

  const resetForm = () => {
    setState(initialState);
    closeAddIngredients();
  };

  const inputValidation = (error, name) => {
    const { isError } = { ...state };
    const currentState = isError;
    if (error) {
      currentState[name] = error.error;
    } else {
      currentState[name] = error;
    }
    setState({
      ...state,
      isError: currentState,
    });
  };

  const onCloseHandler = () => {
    resetForm();
    closeAddIngredients();
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validationPassed) {
      const bodyFormData = new window.FormData();
      bodyFormData.set('name', state.name);
      bodyFormData.set('name_en', state.name_en);
      bodyFormData.set('name_cz', state.name_cz);
      bodyFormData.set('name_es', state.name_es);
      bodyFormData.set('name_hu', state.name_hu);
      bodyFormData.set('name_ro', state.name_ro);
      bodyFormData.set('name_bg', state.name_bg);
      bodyFormData.set('name_sk', state.name_sk);
      bodyFormData.set('name_pt', state.name_pt);
      bodyFormData.set('name_it', state.name_it);
      bodyFormData.set('name_de', state.name_de);
      bodyFormData.set('name_fr', state.name_fr);
      bodyFormData.set('calories', state.calories);
      bodyFormData.set('protein', state.protein);
      bodyFormData.set('fats', state.fats);
      bodyFormData.set('carbohydrates', state.carbohydrates);
      bodyFormData.set('sugar', state.sugar);
      bodyFormData.set('category_ingredient_id', state.category_ingredient_id.value);
      bodyFormData.set('has_lactose', state.hasLactose / 1);
      bodyFormData.set('has_gluten', state.hasGluten / 1);
      // bodyFormData.set('unit', state.unit.value);
      if (state.file !== '') {
        bodyFormData.append('source', state.file);
      }

      const endpoint = `${URLAPI}ingredients`;
      const currentEndpointForPagination = `${URLAPI}ingredients?page=${currentPage ||
        1}&per_page=${perPage || 6}`;

      onSubmitIngredientForm(null, bodyFormData, endpoint, currentEndpointForPagination, resetForm);
    }
  };

  const {
    name,
    name_en,
    name_cz,
    name_es,
    name_hu,
    name_ro,
    name_pt,
    name_it,
    name_de,
    name_sk,
    name_bg,
    name_fr,
    calories,
    tmpImgURL,
    protein,
    carbohydrates,
    sugar,
    fats,
    isError,
  } = state;

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onCloseHandler}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <ModalWrapper>
            <BtnHolder>
              <CloseButton handleClick={onCloseHandler} />
            </BtnHolder>
            <div className={classes.paper}>
              <AddIgRow>
                <Typography variant="h5" color="primary">
                  Dodaj nowy Składnik
                </Typography>
              </AddIgRow>
              <form onSubmit={e => handleSubmit(e)}>
                <AddIgRow>
                  <TabsWrapper>
                    <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      aria-label="simple tabs example"
                      variant="scrollable"
                    >
                      <Tab label="PL" {...a11yProps(0)} />
                      <Tab label="EN" {...a11yProps(1)} />
                      <Tab label="CZ" {...a11yProps(2)} />
                      <Tab label="ES" {...a11yProps(3)} />
                      <Tab label="HU" {...a11yProps(4)} />
                      <Tab label="RO" {...a11yProps(5)} />
                      <Tab label="BG" {...a11yProps(6)} />
                      <Tab label="SK" {...a11yProps(7)} />
                      <Tab label="PT" {...a11yProps(8)} />
                      <Tab label="IT" {...a11yProps(9)} />
                      <Tab label="DE" {...a11yProps(10)} />
                      <Tab label="FR" {...a11yProps(11)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                      {/* <SingleInputWrapper htmlFor="name"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name}
                        id="name"
                        onChange={handleChange}
                        htmlFor="name"
                        label="Nazwa"
                        name="name"
                        type="text"
                        inputValidation={inputValidation}
                        // handleOnBlur={isValidationPassed}

                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />
                      {/* </SingleInputWrapper> */}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_en}
                        id="name_en"
                        onChange={handleChange}
                        htmlFor="name_en"
                        label="Nazwa_en"
                        name="name_en"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>

                    <TabPanel value={tabValue} index={2}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_cz}
                        id="name_cz"
                        onChange={handleChange}
                        htmlFor="name_cz"
                        label="Nazwa_cz"
                        name="name_cz"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>

                    <TabPanel value={tabValue} index={3}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_es}
                        id="name_es"
                        onChange={handleChange}
                        htmlFor="name_es"
                        label="Nazwa_es"
                        name="name_es"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>

                    <TabPanel value={tabValue} index={4}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_hu}
                        id="name_hu"
                        onChange={handleChange}
                        htmlFor="name_hu"
                        label="Nazwa_hu"
                        name="name_hu"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>

                    <TabPanel value={tabValue} index={5}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_ro}
                        id="name_ro"
                        onChange={handleChange}
                        htmlFor="name_ro"
                        label="Nazwa_ro"
                        name="name_ro"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>

                    <TabPanel value={tabValue} index={6}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_bg}
                        id="name_bg"
                        onChange={handleChange}
                        htmlFor="name_bg"
                        label="Nazwa_bg"
                        name="name_bg"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>

                    <TabPanel value={tabValue} index={7}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_sk}
                        id="name_sk"
                        onChange={handleChange}
                        htmlFor="name_sk"
                        label="Nazwa_sk"
                        name="name_sk"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>

                    <TabPanel value={tabValue} index={8}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_pt}
                        id="name_pt"
                        onChange={handleChange}
                        htmlFor="name_pt"
                        label="Nazwa_pt"
                        name="name_pt"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>

                    <TabPanel value={tabValue} index={9}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_it}
                        id="name_it"
                        onChange={handleChange}
                        htmlFor="name_it"
                        label="Nazwa_it"
                        name="name_it"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>

                    <TabPanel value={tabValue} index={10}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_de}
                        id="name_de"
                        onChange={handleChange}
                        htmlFor="name_de"
                        label="Nazwa_de"
                        name="name_de"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>
                    
                    <TabPanel value={tabValue} index={11}>
                      {/* <SingleInputWrapper htmlFor="name_en"> */}
                      <Input
                        uniqueNames={unieqeIngredientsNames}
                        isError={state.isError}
                        value={name_fr}
                        id="name_fr"
                        onChange={handleChange}
                        htmlFor="name_fr"
                        label="Nazwa_fr"
                        name="name_fr"
                        type="text"
                        inputValidation={inputValidation}
                        handleOnBlur={isValidationPassed}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          {
                            check: Validators.uniqueName,
                            message: 'Składnik o podanej nazwie już istnieje',
                          },
                        ]}
                      />

                      {/* </SingleInputWrapper> */}
                    </TabPanel>
                  </TabsWrapper>
                  <SingleInputWrapper htmlFor="calories">
                    <ContainerForFewInputs>
                      <SingleInputWrapperSm>
                        <Input
                          value={calories}
                          isError={state.isError}
                          onChange={handleChange}
                          handleOnBlur={isValidationPassed}
                          inputValidation={inputValidation}
                          htmlFor="calories"
                          label="Kalorie"
                          name="calories"
                          type="number"
                          validators={[
                            { check: Validators.required, message: 'pole wymagane' },
                            { check: Validators.number, message: 'tylko liczby' },
                          ]}
                        />
                      </SingleInputWrapperSm>
                      <SingleInputWrapperSm>
                        <Input
                          value={protein}
                          isError={state.isError}
                          handleOnBlur={isValidationPassed}
                          onChange={handleChange}
                          htmlFor="protein"
                          label="Proteiny"
                          name="protein"
                          type="number"
                          inputValidation={inputValidation}
                          validators={[
                            { check: Validators.required, message: 'pole wymagane' },
                            { check: Validators.number, message: 'tylko liczby' },
                          ]}
                        />
                      </SingleInputWrapperSm>
                      <SingleInputWrapperSm>
                        <Input
                          value={carbohydrates}
                          isError={state.isError}
                          handleOnBlur={isValidationPassed}
                          onChange={handleChange}
                          htmlFor="carbohydrates"
                          label="Węglowodany"
                          name="carbohydrates"
                          type="number"
                          inputValidation={inputValidation}
                          validators={[
                            { check: Validators.required, message: 'pole wymagane' },
                            { check: Validators.number, message: 'tylko liczby' },
                          ]}
                        />
                      </SingleInputWrapperSm>
                      <SingleInputWrapperSm>
                        <Input
                          value={sugar}
                          isError={state.isError}
                          handleOnBlur={isValidationPassed}
                          onChange={handleChange}
                          htmlFor="sugar"
                          label="Cukry"
                          name="sugar"
                          type="number"
                          inputValidation={inputValidation}
                          validators={[
                            { check: Validators.required, message: 'pole wymagane' },
                            { check: Validators.number, message: 'tylko liczby' },
                          ]}
                        />
                      </SingleInputWrapperSm>
                      <SingleInputWrapperSm>
                        <Input
                          value={fats}
                          isError={state.isError}
                          handleOnBlur={isValidationPassed}
                          onChange={handleChange}
                          htmlFor="fats"
                          label="Tłuszcze"
                          name="fats"
                          type="number"
                          inputValidation={inputValidation}
                          validators={[
                            { check: Validators.required, message: 'pole wymagane' },
                            { check: Validators.number, message: 'tylko liczby' },
                          ]}
                        />
                      </SingleInputWrapperSm>
                    </ContainerForFewInputs>
                  </SingleInputWrapper>
                </AddIgRow>
                <AddIgRow>
                  {/* <SelectWrapper>
                                        <SelectInput
                                            options={state.optionsUnits}
                                            noOptionsText='Nie znaleziono'
                                            label="Wybierz jednostkę"
                                            handleOnChange={handleChangeSelect}
                                            onChangeParams={['unit']}
                                            name='unit'
                                            id="unit"
                                            selectValid={selectValid}
                                            isError={isError.unit}
                                            errorMessage="Pole wymagane"
                                        />
                                    </SelectWrapper> */}

                  <SelectWrapper>
                    <SelectInput
                      options={state.optionsNameCategoryForSearch}
                      noOptionsText="Nie znaleziono"
                      label="Kategoria składnika"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['category_ingredient_id']}
                      name="category_ingredient_id"
                      id="category_ingredient_id"
                      isError={isError.category_ingredient_id}
                      errorMessage="Pole wymagane"
                    />
                  </SelectWrapper>
                  <CheckboxWrapper>
                    <AddIgInputName>Czy składnik zawiera:</AddIgInputName>
                    <CheckBox
                      name="hasLactose"
                      handleChange={handleChangeCheckbox}
                      checked={state.hasLactose}
                      id="hasLactose"
                      label="Laktoza"
                    />
                    <CheckBox
                      name="hasGluten"
                      handleChange={handleChangeCheckbox}
                      checked={state.hasGluten}
                      id="hasGluten"
                      label="Gluten"
                    />
                  </CheckboxWrapper>
                </AddIgRow>
                {/* <AddIgRow>
                                    <ImgWrapper>
                                        <FormControl noMarginB file>
                                            <label
                                                styles={{ width: '100%' }}
                                                htmlFor="raised-button-file">
                                                <input
                                                    accept="image/*"
                                                    className={classes.input}
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file"
                                                    multiple
                                                    type="file"
                                                    onChange={(event) => handleChangeFile(event)}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    component="span"
                                                    className={classes.button}
                                                    full-width
                                                >
                                                    Dodaj zdjęcie
                                            </Button>
                                            </label>
                                        </FormControl>
                                        <FormControl file imgBox border>
                                            {state.avatarImg ?
                                                <MealImage src={avatar} alt="empty" /> :
                                                <>
                                                    <MealImage src={URL.createObjectURL(state.file)} alt="name" />
                                                    <CloseButton
                                                        handleClick={(e) => removeImg(e)}
                                                    />
                                                </>
                                            }

                                        </FormControl>
                                    </ImgWrapper>

                                </AddIgRow> */}
                <AddIgRow>
                  <ButtonWrapper>
                    {!validationPassed && (
                      <StyledParagraph isHovered={isHovered}>
                        Uzupełnij wymagane pola
                      </StyledParagraph>
                    )}
                  </ButtonWrapper>
                </AddIgRow>
                <AddIgRow>
                  <ButtonWrapper onMouseEnter={() => setHover(true)}>
                    <ButtonPrimary
                      type="submit"
                      // validationPassed={!validationPassed}
                    >
                      Dodaj Składnik
                    </ButtonPrimary>
                  </ButtonWrapper>
                </AddIgRow>
              </form>
            </div>
          </ModalWrapper>
        </Fade>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ig: state.ig.ingredients,
    igCat: state.igCat.ingredientsCategory,
    // optionsUnits: state.ig.optionsUnits,
    optionsName: state.ig.optionsNameForSearch,
    optionsNameCategoryForSearch: state.igCat.optionsNameCategoryForSearch,
    igAll: state.ig,
    unieqeIngredientsNames: state.validators.validators,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
    // onFetchUnits: () => dispatch(actionCreators.fetchUnits()),
    onInitIngredients: endpoint => dispatch(actionCreators.initIngredients(endpoint)),
    onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),
    onfetchIngredientsCategory: () => dispatch(actionCreators.fetchIngredientsCategory()),
    onSubmitIngredientForm: (
      history,
      bodyFormData,
      endpoint,
      currentEndpointForPagination,
      resetForm,
    ) =>
      dispatch(
        actionCreators.submitIngredientForm(
          history,
          bodyFormData,
          endpoint,
          currentEndpointForPagination,
          resetForm,
        ),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIngredients);
