import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;
// GET
export const setPaymentMethod = paymentMethods => {
  return {
    type: actionTypes.SET_PAYMENT_METHOD,
    paymentMethods,
  };
};

export const fetchPaymentMethodFailed = error => {
  return {
    type: actionTypes.FETCH_PAYMENT_METHOD_FAILED,
    error,
  };
};

export const fetchPaymentMethod = () => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}payment-methods`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const paymentMethods = res.data.data;
        dispatch(setPaymentMethod(paymentMethods));
      })
      .catch(error => {
        dispatch(fetchPaymentMethodFailed(error));
      });
  };
};

// GET ONE
export const setSinglePaymentMehtods = paymentMehtods => {
  return {
    type: actionTypes.SET_SINGLE_PAYMENT_METHOD,
    paymentMehtods,
  };
};

export const fetchSinglePaymentMehtodsFailed = error => {
  return {
    type: actionTypes.FETCH_SINGLE_PAYMENT_MEHTODS_FAILED,
    error,
  };
};

export const fetchSinglepaymentMehtods = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}payment-methods/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const paymentMehtods = res.data.data;
        dispatch(setSinglePaymentMehtods(paymentMehtods[0]));
      })
      .catch(error => {
        dispatch(fetchSinglePaymentMehtodsFailed(error));
      });
  };
};

// ADD PAYMENT METHOD

export const submitPaymentMethodsFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_PAYMENT_MEHTODS_FORM_SUCCESS,
    msg: res,
  };
};

export const submitPaymentMethodsFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_PAYMENT_MEHTODS_FORM_FAILED,
    error,
  };
};

export const addPaymentMethodsForm = (bodyFormData, resetForm) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios({
      method: 'post',
      url: `${URLAPI}payment-methods`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authKey}`,
      },
    })
      .then(res => {
        dispatch(submitPaymentMethodsFormSuccess(res));
        dispatch(fetchPaymentMethod());
        resetForm();
      })
      .catch(error => {
        dispatch(submitPaymentMethodsFormFailed(error));
      });
  };
};

// EDIT PaymentMethods

export const submitPaymentMethodsForm = (id, bodyFormData, resetForm) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios({
      method: 'post',
      url: `${URLAPI}payment-methods/${id}`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authKey}`,
      },
    })
      .then(res => {
        dispatch(submitPaymentMethodsFormSuccess(res));
        dispatch(fetchPaymentMethod());
        resetForm();
      })
      .catch(error => {
        dispatch(submitPaymentMethodsFormFailed(error));
      });
  };
};

// DELETE

export const deletePaymentMethodsSuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_PAYMENT_MEHTODS_SUCCESS,
    id,
    msg: res,
  };
};

export const deletePaymentMethodsFailed = error => {
  return {
    type: actionTypes.DELETE_PAYMENT_MEHTODS_FAILED,
    error,
  };
};

export const deletePaymentMethods = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .delete(`${URLAPI}payment-methods/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        dispatch(deletePaymentMethodsSuccess(id, res));
        dispatch(fetchPaymentMethod());
      })
      .catch(error => {
        dispatch(deletePaymentMethodsFailed(error));
      });
  };
};
