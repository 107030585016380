/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Loading from 'components/atoms/Loading/Loading';
import ConfirmDelete from 'components/atoms/ConfirmDelete/ConfirmDelete';
import EditPaymentMethods from 'components/EditPaymentMethods/EditPaymentMethods';
import { Typography } from '@material-ui/core';

const URLBASE = process.env.REACT_APP_URL_BASE;

const tableHeaders = [
  { id: 'id', label: 'id', width: '4%', align: 'left', type: 'id' },
  { id: 'name', label: 'Metody Płatności', width: '10%', align: 'center', type: 'string' },
  { id: 'description', label: 'Opis', width: '10%', align: 'center', type: 'string' },
  { id: 'icon', label: 'Ikona', width: '10%', align: 'center', type: 'icon' },
  {
    id: 'countrieAavailable',
    label: 'Dostępny w krajach',
    width: '46%',
    align: 'center',
    type: 'isAavailable',
  },
  { id: 'actions,', label: 'Akcje', width: '20%', align: 'center', type: 'buttons' },
];
const CountryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 110px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const FlagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
  width: 35px;
`;

const Flag = styled.img`
  width: 30px;
  height: 20px;
`;

const CountryIso = styled.span`
  text-transform: uppercase;
`;

const IconsWrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  width: 64px;
  height: auto;
`;

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const useStyles1 = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const PaymentMethodsTable = ({
  paymentMethod,
  onDeletePaymentMethods,
  isLoading,

  setPaymentMehtodsList,
  paymentMehtodsList,
}) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const [deleteConfirmVisable, setDeleteConfirmVisable] = useState([]);
  const createDataFuncVals = tableHeaders.map(el => el.id);

  useEffect(() => {
    setPaymentMehtodsList(paymentMethod);
  }, [paymentMethod]);

  const createData = (...params) => {
    const obj = {};
    // eslint-disable-next-line no-return-assign
    createDataFuncVals.map((el, index) => (obj[el] = params[index]));
    return obj;
  };

  let data = [];

  if (paymentMehtodsList) {
    data = paymentMehtodsList.map(el => {
      return {
        id: el.id,
        name: el.name,
        description: el.description,
        icon: el.icon,
        coutries: el.countries,
      };
    });
  }

  const rows = data.map(el => {
    const funcParams = [];
    Object.keys(el).map(key => {
      funcParams.push(el[key]);
      return funcParams;
    });
    return createData(...funcParams);
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openDeleteConfirm = key => {
    setDeleteConfirmVisable(deleteConfirmVisable => ({
      ...deleteConfirmVisable,
      [key]: !deleteConfirmVisable[key],
    }));
  };

  if (isLoading === true) {
    return <Loading />;
  } else {
    return (
      <>
        <Paper className={classes.root}>
          <TableContainer>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map(head => {
                    if (head.type === 'id') {
                      return null;
                    } else {
                      return (
                        <React.Fragment key={head.value}>
                          <StyledTableCell align={head.align} style={{ width: head.width }}>
                            {head.label}
                          </StyledTableCell>
                        </React.Fragment>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  const newKey = (row.name + row.id.toString())
                    .split('')
                    .filter(e => e.trim().length)
                    .join('');
                  return (
                    <React.Fragment key={row.code + (Math.random() * 1000).toString()}>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {tableHeaders.map(head => {
                          const value = row[head.id];

                          if (head.type === 'id') {
                            return null;
                          }
                          if (head.type === 'isEnabled') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  {value.length !== 0 ? (
                                    <Typography color="primary">Aktywna</Typography>
                                  ) : (
                                    <Typography color="error">Nieaktywna</Typography>
                                  )}
                                </TableCell>
                              </React.Fragment>
                            );
                          } else if (head.type === 'buttons') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  <IconsWrapper>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => openDeleteConfirm(newKey)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    <Link to={`/payment-methods/edit/${row.id}`}>
                                      <IconButton aria-label="edit">
                                        <EditIcon />
                                      </IconButton>
                                    </Link>

                                    {deleteConfirmVisable[newKey] && (
                                      <ConfirmDelete
                                        width="500%"
                                        newKey={newKey}
                                        name={row.name}
                                        onDeleteHandler={onDeletePaymentMethods}
                                        id={row.id}
                                        rejectHandler={openDeleteConfirm}
                                        isOpen={deleteConfirmVisable}
                                      >
                                        Czy napewno chcesz skasować
                                      </ConfirmDelete>
                                    )}
                                  </IconsWrapper>
                                </TableCell>
                              </React.Fragment>
                            );
                          } else if (head.type === 'isAavailable') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell>
                                  <CountryWrapper>
                                    {value.map(country => (
                                      <FlagWrapper key={country.id}>
                                        <Flag
                                          src={`${URLBASE}storage/flags/${country.flag}`}
                                          alt={country.iso_3166_2}
                                        />
                                        <CountryIso>{country.iso_3166_2}</CountryIso>
                                      </FlagWrapper>
                                    ))}
                                  </CountryWrapper>
                                </TableCell>
                              </React.Fragment>
                            );
                          } else if (head.type === 'icon') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell>
                                  <IconWrapper>
                                    <Icon src={`${URLBASE}storage/${value}`} alt="" />
                                  </IconWrapper>
                                </TableCell>
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>{value}</TableCell>
                              </React.Fragment>
                            );
                          }
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {isLoading !== true ? (
                    <TablePagination
                      rowsPerPageOptions={[
                        2,
                        6,
                        10,
                        15,
                        25,
                        100,
                        { label: 'All', value: paymentMehtodsList.length },
                      ]}
                      count={paymentMehtodsList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  ) : (
                    ''
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
};

export default PaymentMethodsTable;
