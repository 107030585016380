import React from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    // color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.background.hover,
    }
  },
  labelRoot: {
    color: "#2E2D41",
  }
}));


const CheckBoxButton = ({ checked, handleChange, label, name, page = null, disabled = false, toReset = null }) => {
  const classes = useStyles();
  const style = {
    // color: "#2E2D41",
  };
  const handleOnChange = (e) => {
    if (page !== null && toReset === null) {
      return handleChange(name, page)
    }
    if (handleChange && page === null && toReset === null) {
      return handleChange(e)
    }
    if (toReset !== null) {
      return handleChange(e, toReset)
    }


  }

  return (
    <FormControlLabel
      // style={style}
      classes={{
        root: classes.labelRoot,
      }}
      control={
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={(e) => handleOnChange(e)}
          name={name}
          color="secondary"
          classes={{
            root: classes.root,
          }}
        />
      }
      label={label}
    />
  );
}

export default CheckBoxButton;