import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import theme from 'Theme/Theme';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import UserPanel from 'components/UserPanel/UserPanel';
import Nav from 'components/Nav/Nav';
import HomePage from 'views/HomePage/HomePage';
import Login from 'views/Login/Login';

import Meals from 'views/Meals/Meals';
import AddMeals from 'views/Meals/AddMeals/AddMeals';
import EditMeals from 'views/Meals/EditMeals/EditMeals';

import { routes } from 'Routs/Routs';
import IngredientsCategory from 'views/IngredientsCategory/IngredientsCategory';
import Currencies from 'views/Currencies/Currencies';

import Ingredients from 'views/Ingredients/Ingredients';
import Coupons from 'views/Coupons/Coupons';
import EditCoupons from 'views/Coupons/EditCoupons/EditCoupons';
import AddCoupons from 'views/Coupons/AddCoupons/AddCoupons';
import EditIngredients from 'views/Ingredients/EditIngredients/EditIngredients';
import MealsCategory from 'views/MealsCategory/MealsCategory';

import MainWrapper from 'components/MainWrapper/MainWrapper';
import ContentWrapper from 'components/ContentWrapper/ContentWrapper';
import NotificationsList from 'components/Notifications/NotificationsList';
import PaymentMethods from 'views/PaymentMethods/PaymentMethods';
import ShipmentsMethod from 'views/ShipmentsMethod/ShipmentsMethod';
import Products from 'views/Products/Products';
import Users from 'views/Users/Users';
import AddUsers from 'views/Users/AddUsers/AddUsers';
import Orders from 'views/Orders/Orders';
import EditOrders from 'views/Orders/EditOrders/EditOrders';
import ChoseUser from 'views/Orders/AddOrder/ChoseUser';
import AddOrder from 'views/Orders/AddOrder/AddOrder';
import Packages from 'views/Packages/Packages';
import EditPackages from 'components/EditPackages/EditPackages';
import AddPackages from 'views/Packages/AddPackages/AddPackages';
import PreviewUser from 'views/Users/PreviewUser/PreviewUser';
import EditUser from 'views/Users/EditCustomer/EditCustomer';
import history from 'Routs/history';
import EditProducts from 'components/EditProducts/EditProducts';
import EditPaymentMethods from 'components/EditPaymentMethods/EditPaymentMethods';
import Invoices from 'views/Invoices/Invoices';

// import PreviewUser from 'views/Users/PreviewUser/PreviewUser';

import Customers from 'views/Customers/Customers';
import AddCustomers from 'views/Customers/AddCustomers/AddCustomers';
import PreviewCustomer from 'views/Customers/PreviewCustomer/PreviewCustomer';
import UsersPermissions from 'views/UsersPermissions/UsersPermissions';

const Root = ({ currentUser }) => {
  const { users } = routes;

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <NotificationsList />
          <Router history={history}>
            {currentUser.loggedIn === true ? <UserPanel /> : null}
            <MainWrapper>
              {currentUser.loggedIn === true ? <Nav /> : null}
              <ContentWrapper>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <PrivateRoute exact path="/" component={HomePage} />
                  {/* <PrivateRoute exact path={users} component={ShowingUsers} /> */}
                  <PrivateRoute
                    exact
                    path="/ingredients-category"
                    component={IngredientsCategory}
                  />
                  <PrivateRoute exact path="/ingredients" component={Ingredients} />
                  <PrivateRoute exact path="/ingredients/edit/:id" component={EditIngredients} />
                  <PrivateRoute exact path="/meals" component={Meals} />
                  <PrivateRoute exact path="/meals/add-meals" component={AddMeals} />
                  <PrivateRoute exact path="/meals/edit/:id" component={EditMeals} />
                  <PrivateRoute exact path="/meals-category" component={MealsCategory} />
                  <PrivateRoute exact path="/currencies" component={Currencies} />
                  <PrivateRoute exact path="/payment-methods" component={PaymentMethods} />
                  <PrivateRoute
                    exact
                    path="/payment-methods/edit/:id"
                    component={EditPaymentMethods}
                  />

                  <PrivateRoute exact path="/shipments-method" component={ShipmentsMethod} />
                  <PrivateRoute exact path="/discount-coupons" component={Coupons} />
                  <PrivateRoute exact path="/discount-coupons/edit/:id" component={EditCoupons} />
                  <PrivateRoute exact path="/discount-coupons/add-coupon" component={AddCoupons} />
                  <PrivateRoute exact path="/products/edit/:id" component={EditProducts} />
                  <PrivateRoute exact path="/products" component={Products} />
                  <PrivateRoute exact path="/users" component={Users} />
                  <PrivateRoute exact path="/permissions" component={UsersPermissions} />
                  <PrivateRoute exact path="/users/user/:userId" component={PreviewUser} />
                  <PrivateRoute exact path="/users/user-edit/:userId" component={EditUser} />
                  <PrivateRoute exact path="/users/add-users" component={AddUsers} />
                  <PrivateRoute exact path="/customers" component={Customers} />
                  <PrivateRoute
                    exact
                    path="/customers/customer/:userId"
                    component={PreviewCustomer}
                  />
                  <PrivateRoute exact path="/cusomers/add-cusomers" component={AddCustomers} />
                  <PrivateRoute exact path="/orders" component={Orders} />
                  <PrivateRoute exact path="/orders/add-orders" component={ChoseUser} />
                  <PrivateRoute exact path="/orders/:id" component={EditOrders} />
                  <PrivateRoute exact path="/orders/add-orders/:userId" component={AddOrder} />
                  <PrivateRoute exact path="/packages" component={Packages} />
                  <PrivateRoute exact path="/packages/add" component={AddPackages} />
                  <PrivateRoute exact path="/packages/edit/:id" component={EditPackages} />
                  <PrivateRoute exact path="/invoices" component={Invoices} />

                </Switch>
              </ContentWrapper>
            </MainWrapper>
          </Router>
        </ThemeProvider>
      </MuiThemeProvider>
    </>
  );
};

// export default Root

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(Root);
