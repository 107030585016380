/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
// import Select from 'react-select';


import * as actionCreators from 'store/actions';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import Input from 'components/atoms/Inputs/Input';



const Form = styled.form``;

const SingleInputWrapper = styled.div`
  width: 98%;
  margin-bottom: 16px;
  flex-basis: 48%;
  @media screen and (min-width: 992px) {
    flex-basis: 49%;
  }
`;



const useStyles = makeStyles(theme => ({
  root: {
    color: '#000000',
    label: 'color: #000000',
  },
  formControl: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: 120,
    width: '80%',
    height: 'auto',
    padding: theme.spacing(2),
    paddingBottom: 1,
    borderRadius: 5,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SearchUsers = ({ onSetUsersQueryAfterSearch, usersOptionsNameForSearch }) => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    usersOptionsName: [],
  });

  useEffect(() => {
    const newOptions = [];
    if (state.usersOptionsName.length === 0 && usersOptionsNameForSearch !== null) {
      usersOptionsNameForSearch.map(el =>
        newOptions.push({ value: el.id, label: `${el.first_name} ${el.last_name}` }),
      );
    }
    setState(state => ({
      ...state,
      usersOptionsName: newOptions,
    }));
  }, [usersOptionsNameForSearch]);

  const handleSearch = (inputValue, key, type) => {
    let value = '';
    if (inputValue) {
      if (type === 'text' || type === 'number') {
        value = inputValue.target.value.toLowerCase();
      } else if (type === 'select') {
        value = inputValue;
      } else {
        value = inputValue;
      }
      setState(state => ({
        ...state,
        [key]: value,
      }));
    } else {
      setState(state => ({
        ...state,
        [key]: { value: '' },
      }));
      return null;
    }
    return null;
  };



  useEffect(() => {
    const timer = setTimeout(() => {
      let query = '';

      const nameQuery = state.firstName.value !== '' ? `&first_name=${state.firstName}` : ``;
      const lastNameQuery = state.lastName !== '' ? `&last_name=${state.lastName}` : '';
      const emailQuery = state.email !== '' ? `&email=${encodeURIComponent(state.email)}` : '';


      query = `${nameQuery}${lastNameQuery}${emailQuery}`;
      onSetUsersQueryAfterSearch(`${query}`);
      // }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  const classes = useStyles();

  return (
    <>
      <Form>
        <FormControl className={classes.formControl}>
          <SingleInputWrapper htmlFor="firstName">
            <Input
              label="Imię"
              name="firstName"
              type="text"
              id="firstName"
              onChange={handleSearch}
              onChangeParams={['firstName', 'text']}
            // value={state.customersOptionsName}
            />
          </SingleInputWrapper>
          <SingleInputWrapper htmlFor="lastName">
            <Input
              label="Nazwisko"
              name="lastName"
              type="text"
              id="lastName"
              onChange={handleSearch}
              onChangeParams={['lastName', 'text']}
            // value={state.customersOptionsName}
            />
          </SingleInputWrapper>
          <SingleInputWrapper htmlFor="email">
            <Input
              label="E-mail"
              name="email"
              type="text"
              id="email"
              onChange={handleSearch}
              onChangeParams={['email', 'text']}
            // value={state.customersOptionsName}
            />
          </SingleInputWrapper>
        </FormControl>
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  return {
    users: state.users,
    usersOptionsNameForSearch: state.users.usersOptionsNameForSearch,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetUsersQueryAfterSearch: query => dispatch(actionCreators.setUsersQueryAfterSearch(query)),
    onInitAllUsersForOptions: endpoint => dispatch(actionCreators.initAllUsersForOptions(endpoint)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchUsers);
