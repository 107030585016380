import React, { useState } from 'react';
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { Typography } from '@material-ui/core';

const Wrapper = styled.div`
    display: block;
`

const useStyles = makeStyles((theme) => ({
  icon: {
    alignItems: 'center',
  },
  root: {
    position: 'relative',
    marginBottom: '8px',
    display: 'flex',
    zIndex: 1400,
    alignItems: 'center',
  }
}));


const NotificationElement = ({ notification }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" classes={{ icon: classes.icon }} {...props} />;
  }


  if (notification && notification.status === 200 || notification.status === 201) {
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ root: classes.root }}
      >
        <Alert onClose={handleClose} severity="success">
          {notification.msg.map(msg => <Typography>{msg}</Typography>)}

        </Alert>
      </Snackbar>
    )
  }

  if (notification && notification.status === 'warning') {
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ root: classes.root }}

      >
        <Alert onClose={handleClose} severity="warning">
          {notification.msg.map(msg => <Typography>{msg}</Typography>)}

        </Alert>
      </Snackbar>
    )
  }

  return (
    <>
      {notification && <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ root: classes.root }}
      >
        <Alert onClose={handleClose} severity="error">
          {notification !== undefined ? notification.msg.map(msg => <Typography>{msg}</Typography>) : null}
        </Alert>
      </Snackbar>}
    </>
  )


}

export default NotificationElement;