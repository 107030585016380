import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
    orders: [],
    ordersConfig: null,
    orderToEdit: null,
};

const setOrders = (state, action) => {
    return {
        ...state,
        orders: action.orders.data,
        ordersConfig: action.orders,
        error: false,
    };
};

const fetchOrdersFailed = state => {
    return {
        ...state,
        error: true,
    };
};

const deleteOrdersSuccess = (state, action) => {
    return {
        ...state,
        orders: [...state.orders.filter(order => order.id !== action.id)],
    };
};

const deleteManyOrdersSuccess = (state, action) => {
    return {
        ...state,
        orders: [...state.orders.filter(order => action.ids.map(el => el !== order.id))],
    };
};

const submitOrdersFormSuccess = (state, action) => {
    return {
        ...state,
        msg: action.msg,
    };
};

const submitOrdersFormFailed = (state, action) => {
    return {
        ...state,
        msg: action.error,
    };
};

const getSingleOrdersToEditSuccess = (state, action) => {
    return {
        ...state,
        orderToEdit: action.order,
    };
};


const setOrdersQueryAfterSearch = (state, action) => {
    return {
        ...state,
        ordersSearchQuery: action.query,
    };
};

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ORDERS:
            return setOrders(state, action);
        case actionTypes.FETCH_ORDERS_FAILED:
            return fetchOrdersFailed(state);
        case actionTypes.DELETE_ORDERS_SUCCESS:
            return deleteOrdersSuccess(state, action);
        case actionTypes.DELETE_ORDERS_MANY_SUCCESS:
            return deleteManyOrdersSuccess(state, action);
        case actionTypes.SUBMIT_FORM_ADD_NEW_MEAL_SUCCESS:
            return submitOrdersFormSuccess(state, action);
        case actionTypes.SUBMIT_ORDERS_FORM_FAILED:
            return submitOrdersFormFailed(state, action);
        case actionTypes.SET_SINGLE_ORDERS:
            return getSingleOrdersToEditSuccess(state, action);
        case actionTypes.SET_ORDERS_QUERY_AFTER_SEARCH:
            return setOrdersQueryAfterSearch(state, action);

        default:
            return state;
    }
};

export default ordersReducer;
