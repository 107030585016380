import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions';
import Typography from '@material-ui/core/Typography';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import SelectInputIcon from 'components/atoms/Inputs/SelectInputIcon'

const URLAPI = process.env.REACT_APP_URL_API;

const Wrapper = styled.div`
    display: block;
    margin: 16px auto;
    min-width: 120px;
    width: 100%;
    height:auto;
    padding: 32px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
`

const FormWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  background-color: #ffffff;
  color: #fff;
  z-index: 1;
  width: 100%;
`
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonWrapper = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
`

const SingleInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`



const AddIngredientCategory = ({
  onFetchCurrencies,
  onAddCurrencyForm,
  onFetchCountries,
  countries,
  onInitValidators,
  setVisible
}) => {
  const [country, setCountry] = useState('');
  const [state, setState] = useState({
    isError: {
      country: null
    }
  }
  );
  const [init, setInit] = useState(false);
  const [selectOptions, setSelectOptions] = useState()

  const setElementsForCurrentPage = () => {
    onFetchCurrencies()
    onFetchCountries()
  }

  useEffect(() => {
    if (init === false) {
      setElementsForCurrentPage()
      setInit(true)
    }
  }, [countries])

  useEffect(() => {
    if (countries) {
      setSelectOptions(countries)
    }
  }, [countries])

  useEffect(() => {
    const endpoint = `${URLAPI}currency/code`
    onInitValidators(endpoint, 'currencyCode')
  }, [countries])


  const selectValid = (key) => {
    const curentSelect = state[key]
    const { isError } = { ...state };
    const currentState = isError;
    if (curentSelect !== null) {
      if (curentSelect.id.length === 0) {
        currentState[key] = true

      } else {
        currentState[key] = false
      }
      setState({
        ...state,
        isError: currentState
      })
    } else if (curentSelect === null) {
      currentState[key] = true
      setState({
        ...state,
        isError: currentState
      })
    }
  }


  const handleChangeSelect = (inputValue) => {
    setCountry(inputValue)
  };
  useEffect(() => {
    if (state.lastEdited) {
      selectValid(state.lastEdited)
    }
  }, [state.lastEdited, state.isError, state.unit, state.category_ingredient_id])


  const resetForm = () => {
    setCountry(null);
    setVisible()
    setState({
      isError: {
        country: true
      }
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddCurrencyForm(country.currency_code, resetForm)
  }
  return (
    <Wrapper>
      <Typography variant="h5" color="primary">Dodaj nową walutę</Typography>
      <FormWrapper>
        <StyledForm
          onSubmit={(e) => handleSubmit(e)}
        >
          <SingleInputWrapper>
            <SelectInputIcon
              options={selectOptions}
              optionType='currency_code'
              disabledOption={option => option.currency === '' || option.currency_code === '' || option.currency === null}
              noOptionsText='Nie znaleziono'
              label="Wybierz kraj"
              handleOnChange={handleChangeSelect}
              onChangeParams={['country']}
              name='country'
              id="country"
              selectValid={selectValid}
              isError={state.isError.country}
              errorMessage="Pole wymagane"
            />
          </SingleInputWrapper>

          <ButtonWrapper>

            <ButtonPrimary type="submit"> Dodaj Walutę</ButtonPrimary>
          </ButtonWrapper>
        </StyledForm>
      </FormWrapper>

    </Wrapper>
  );
}



const mapStateToProps = state => {
  return {
    countries: state.countries.countries,
    unieqeCurrencyCode: state.validators.validators

  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
    onFetchCurrencies: () => dispatch(actionCreators.fetchCurrencies()),
    onAddCurrencyForm: (code, resetForm) => dispatch(actionCreators.addCurrencyForm(code, resetForm)),
    onFetchCountries: () => dispatch(actionCreators.fetchCountries()),
    onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIngredientCategory);

