import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import rootReducer from 'store/reducers/rootReducer';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import Cookies from 'js-cookie';


import 'index.scss';

function deleteCookie(cname) {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
const currentUser = Cookies.getJSON('currentUser');

if (currentUser) {
  if (currentUser.tokenExpires < currentUser.tokenCreated) {
    deleteCookie('authKey');
    deleteCookie('currentUser');
  }
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
}



const store = createStore(rootReducer, composeEnhancer(applyMiddleware(...middleware)));

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
