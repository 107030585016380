import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';
import { excludedStuff } from 'helpers/excludedStuff';

const URLAPI = process.env.REACT_APP_URL_API;

export const setUsers = users => {
  return {
    type: actionTypes.SET_USERS,
    users,
  };
};

export const fetchUsersFailed = () => {
  return {
    type: actionTypes.FETCH_USERS_FAILED,
  };
};

export const initUsers = endpoint => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');
    axios
      .get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const users = res.data.data;

        dispatch(setUsers(users));
      })
      .catch(error => {
        dispatch(fetchUsersFailed(error));
      });
  };
};

// DELETE
export const deleteUsersSuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_USERS_SUCCESS,
    id,
    msg: res,
  };
};

export const deleteUsersFailed = error => {
  return {
    type: actionTypes.DELETE_USERS_FAILED,
    error,
  };
};

export const deleteUsers = id => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');
    axios
      .delete(`${URLAPI}users/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        dispatch(deleteUsersSuccess(id, res));
      })
      .catch(error => {
        dispatch(deleteUsersFailed(error));
      });
  };
};

// FORM
export const submitUsersFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_FORM_ADD_NEW_USERS_SUCCESS,
    msg: res,
  };
};

export const submitUsersFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_FORM_ADD_NEW_USERS_FAILED,
    error,
  };
};

export const submitUsersForm = (history, bodyFormData, endpoint, resetForm, endpointDownload) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');
    axios({
      method: 'post',
      url: endpoint,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authKey}`,
      },
    })
      .then(res => {
        dispatch(initUsers(endpointDownload));
        dispatch(submitUsersFormSuccess(res));
        resetForm();
        // if (history) {
        //   history.push(historyPush)
        // }
      })
      .catch(error => {
        console.log(error);
        // dispatch(submitUsersFormFailed(error));
      });
  };
};

// EDIT CUSTOMERS

const getSingleUsersSuccess = data => {
  return {
    type: actionTypes.GET_SINGLE_USERS,
    data,
  };
};
const getSingleUsersFailed = error => {
  return {
    type: actionTypes.SUBMIT_FORM_ADD_NEW_USERS_FAILED,
    error,
  };
};
const getUserOrdersSuccess = data => {
  return {
    type: actionTypes.GET_SINGLE_USERS_ORDER,
    data,
  };
};
const getUserOrdersFailed = error => {
  return {
    type: actionTypes.SUBMIT_FORM_ADD_NEW_USERS_FAILED,
    error,
  };
};

export const getSingleUsers = id => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');
    axios
      .get(`${URLAPI}users/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        dispatch(getSingleUsersSuccess(res.data.data));
      })
      .catch(error => {
        dispatch(getSingleUsersFailed(error));
      });
  };
};

export const searchUsers = url => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');
    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const users = res.data.data;

        dispatch(setUsers(users));
      })
      .catch(error => {
        dispatch(fetchUsersFailed(error));
      });
  };
};

export const setUsersQueryAfterSearch = query => {
  return {
    type: actionTypes.SET_USERS_QUERY_AFTER_SEARCH,
    query,
  };
};

export const setUsersOptionName = optionName => {
  return {
    type: actionTypes.SET_USERS_OPTION_NAME,
    optionName,
  };
};

export const initAllUsersForOptions = endpoint => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');
    axios
      .get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const allUsers = res.data.data;
        dispatch(setUsersOptionName(allUsers));
      })
      .catch(error => {
        dispatch(fetchUsersFailed(error));
      });
  };
};

export const getUserOrders = id => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');
    axios
      .get(`${URLAPI}users/${id}/orders`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const { data } = res.data;

        dispatch(getUserOrdersSuccess(data));
      })
      .catch(error => {
        // dispatch(getSingleUsersFailed(error));
        dispatch(getUserOrdersFailed(error));
      });
  };
};

const getUserInfoSuccess = data => {
  return {
    type: actionTypes.GET_SINGLE_USERS_INFO,
    data,
  };
};
const getUserInfoFailed = error => {
  return {
    type: actionTypes.SUBMIT_FORM_ADD_NEW_USERS_INFO_FAILED,
    error,
  };
};

export const getUserInfo = id => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');
    axios
      .get(`${URLAPI}users/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const { data } = res.data;
        dispatch(getUserInfoSuccess(data));
      })
      .catch(error => {
        dispatch(getUserInfoFailed(error));
      });
  };
};

export const genereteDietSuccess = res => {
  return {
    type: actionTypes.GENERETE_DIET_SUCCESS,
    data: res,
  };
};

export const genereteDietFailed = error => {
  return {
    type: actionTypes.USER_DIET_FAILED,
    error,
  };
};

export const genereteDiet = (userId, dietName, dietLenght) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .post(
        `${URLAPI}diets`,
        {
          user_id: userId,
          name: dietName,
          days_number: dietLenght,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(genereteDietSuccess(res));
      })
      .catch(error => {
        dispatch(genereteDietFailed(error));
      });
  };
};

export const getUserDiet = res => {
  return {
    type: actionTypes.USER_DIET_SUCCESS,
    data: res,
  };
};

export const getUserDietFailed = error => {
  return {
    type: actionTypes.USER_DIET_FAILED,
    error,
  };
};

export const dietExist = error => {
  return {
    type: actionTypes.USER_DIET_EXIST,
    error,
  };
};

export const userDiet = (userId, dietName, dietLenght) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}users/${userId}/diets`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        if (res.data === null) {
          dispatch(genereteDiet(userId, dietName, dietLenght));
        } else {
          dispatch(genereteDiet(userId, dietName, dietLenght));
        }
      })
      .catch(error => {
        dispatch(getUserDietFailed(error));
      });
  };
};

export const submitExcludeFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_EXCLUDE_FORM_FAILED,
    error,
  };
};

export const submitExcludeSuccess = res => {
  return {
    type: actionTypes.SUBMIT_EXCLUDE_FORM,
    msg: res,
  };
};

export const editExclude = (exclude, userId, setToInitialState) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    // const quizes = [];
    // quizes.push(excludedStuff(exclude))
    axios
      .post(
        `${URLAPI}users/update-exclude`,
        {
          quizes: excludedStuff(exclude),
          user_id: userId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        setToInitialState();
        dispatch(getSingleUsers(res.data.data));
        dispatch(getUserOrders(res.data.data));
        dispatch(getUserInfo(res.data.data));
        dispatch(getUserInfo(res.data.data));
        dispatch(submitExcludeSuccess(res));

        // dispatch(fetchMealsCategory());
      })
      .catch(error => {
        console.log(error);
        dispatch(submitExcludeFormFailed(error));
      });
  };
};

// email

export const submitEmailFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_EMAIL_FORM_FAILED,
    error,
  };
};

export const submitEmailSuccess = res => {
  return {
    type: actionTypes.SUBMIT_EMAIL_FORM,
    msg: res,
  };
};

export const editEmail = (userId, email) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .put(
        `${URLAPI}users/email/${userId}`,
        {
          email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(getSingleUsers(res.data.id));
        dispatch(getUserOrders(res.data.id));
        dispatch(getUserInfo(res.data.id));
        dispatch(getUserInfo(res.data.id));
        dispatch(submitEmailSuccess(res));

        // dispatch(fetchMealsCategory());
      })
      .catch(error => {
        console.log(error);
        dispatch(submitEmailFormFailed(error));
      });
  };
};

// kcal

export const submitKcalFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_KCAL_FORM_FAILED,
    error,
  };
};

export const submitKcalSuccess = res => {
  return {
    type: actionTypes.SUBMIT_KCAL_FORM,
    msg: res,
  };
};

export const editKcal = (userId, kcal) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .post(
        `${URLAPI}users/update-calories`,
        {
          user_id: userId,
          calories: kcal,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(getSingleUsers(res.data.data.id));
        dispatch(getUserOrders(res.data.data.id));
        dispatch(getUserInfo(res.data.data.id));
        dispatch(getUserInfo(res.data.data.id));
        dispatch(submitKcalSuccess(res));

        // dispatch(fetchMealsCategory());
      })
      .catch(error => {
        console.log(error);
        dispatch(submitKcalFormFailed(error));
      });
  };
};
// start diet

export const submitStartDietFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_START_DIET_FORM_FAILED,
    error,
  };
};

export const submitStartDietSuccess = res => {
  return {
    type: actionTypes.SUBMIT_START_DIET_FORM,
    msg: res,
  };
};

export const editStartDiet = (orderId, startDiet) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .put(
        `${URLAPI}orders/${orderId}/update-startdiet`,
        {
          start_diet: startDiet,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(getSingleUsers(res.data.data.user_id));
        dispatch(getUserOrders(res.data.data.user_id));
        dispatch(getUserInfo(res.data.data.user_id));
        dispatch(getUserInfo(res.data.data.user_id));
        dispatch(submitStartDietSuccess(res));

        // dispatch(fetchMealsCategory());
      })
      .catch(error => {
        console.log(error);
        dispatch(submitStartDietFormFailed(error));
      });
  };
};
