import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddProducts from 'views/Products/AddProducts/AddProducts';
import ProductsTable from 'components/atoms/Table/ProductsTable';

const URLAPI = process.env.REACT_APP_URL_API;

const Wrapper = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  position: relative;
  /* width: 70%;
  margin: 0 auto; */
`;

const productsCategories = [
  { value: 'fde18974-b712-4eb8-a650-1b848f4e015a', category: 'Dieta' },
  { value: 'b3453c5-4cb3-4f0f-be1f-2a05fdf55d86', category: 'Suplement' },
  { value: '87e83e6a-5143-46f2-9c46-d3a33131e3ce', category: 'Wiedza' },
  { value: '8337cbe2-653e-4d50-b400-58cd4ab5cf40', category: 'Trening' },
];
const Products = ({
  onFetchProducts,
  products,
  onDeleteProducts,
  isLoading,
  unieqeNames,
  onInitValidators,
  onFetchCountries,
  countries,
  onAddProductsForm,
}) => {
  const [visible, setVisible] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [init, setInit] = useState(false);
  const [deleteConfirmVisable, setDeleteConfirmVisable] = useState([]);

  const showAddProducts = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (countries.length === 0) {
      onFetchCountries();
    }
  }, [countries]);

  useEffect(() => {
    const endpoint = `${URLAPI}product/name`;
    onInitValidators(endpoint, 'userEmail');
  }, []);

  useEffect(() => {
    const openArray = {};
    setProductsList(products);
    products.map(el => {
      const key = (el.name + el.name.toString())
        .split('')
        .filter(e => e.trim().length)
        .join('');
      openArray[key] = false;
    });
    setDeleteConfirmVisable(openArray);
  }, [products]);

  const setElementsForCurrentPage = () => {
    onFetchProducts();
  };

  useEffect(() => {
    if (productsList !== null && init === false) {
      setElementsForCurrentPage();
      setInit(true);
    }
  }, [productsList]);

  return (
    <Wrapper>
      <IconButton onClick={() => showAddProducts()}>
        <AddIcon fontSize="large" />
      </IconButton>
      {visible && (
        <AddProducts
          productsCategories={productsCategories}
          setVisible={setVisible}
          countries={countries}
          unieqeNames={unieqeNames}
          onAddProductsForm={onAddProductsForm}
        />
      )}

      <ProductsTable
        productsCategories={productsCategories}
        isLoading={isLoading}
        productsList={productsList}
        onDeleteProducts={onDeleteProducts}
        setDeleteConfirmVisable={setDeleteConfirmVisable}
        deleteConfirmVisable={deleteConfirmVisable}
      />
    </Wrapper>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.error,
    products: state.products.products,
    unieqeNames: state.validators.validators,
    countries: state.countries.countries,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
    onFetchProducts: () => dispatch(actionCreators.fetchProducts()),
    onDeleteProducts: id => dispatch(actionCreators.deleteProducts(id)),
    onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),
    onAddProductsForm: (bodyFormData, resetForm) =>
      dispatch(actionCreators.addProductsForm(bodyFormData, resetForm)),
    onFetchCountries: () => dispatch(actionCreators.fetchCountries()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
