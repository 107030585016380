import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { validateInput } from './Validators';

const Input = ({
  label,
  type,
  inputValidation,
  onChange,
  onChangeParams,
  id,
  value,
  name,
  validators,
  uniqueNames,
  handleOnBlur,
  isError,
  inputProps,
  multiline,
  oldValue,
}) => {
  const [error, setError] = useState(false);
  const handleChange = e => {
    if (oldValue) {
      setError(validateInput(validators, e.target.value, uniqueNames, oldValue));
    } else if (uniqueNames) {
      setError(validateInput(validators, e.target.value, uniqueNames));
    } else {
      setError(validateInput(validators, e.target.value));
    }
    if (onChangeParams) {
      onChange(e, ...onChangeParams);
    } else {
      onChange(e);
    }
    if (inputValidation) {
      inputValidation(error, name);
      onChange(e);
    }

    if (handleOnBlur) {
      handleOnBlur(e);
    }
    onChange(e);
  };

  // useEffect(() => {
  //     if (inputValidation) {
  //         inputValidation(error, name)
  //     }
  // }, [error])

  useEffect(() => {
    if (isError) {
      if (isError[name] === true) {
        setError(true);
      }
    }
  }, [isError]);

  const handleBlur = e => {
    if (handleOnBlur) {
      handleOnBlur(e, error, name);
      handleChange(e);
      if (inputValidation) {
        inputValidation(error, name);
      }
    }
  };
  return (
    <>
      <TextField
        color="secondary"
        onChange={e => handleChange(e)}
        onBlur={e => handleBlur(e)}
        // error={isError ? isError[name] : false}
        error={error}
        multiline={multiline || false}
        id={id}
        label={label}
        variant="outlined"
        type={type}
        helperText={error.message}
        // eslint-disable-next-line react/jsx-boolean-value
        fullWidth
        value={value}
        name={name}
        validators={validators}
        inputProps={inputProps}
      />
    </>
  );
};

export default Input;
