import * as actionTypes from 'store/actions/actionsTypes';


export const removeUnusedNotifiaction = () => {
    return {
        type: actionTypes.REMOVE_UNUSED_NOTIFICATIONS,
    }

}

export const setMessage = () => {
    return {
        type: actionTypes.ADD_MESSAGE,
    }
}


export const addMessage = (msg, status) => {
    return {
        type: actionTypes.ADD_MESSAGE,
        statusText: msg,
        status,
    }
}

