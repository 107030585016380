import React from 'react';
import styled from 'styled-components';
import loading from 'assets/loading.gif';

const Wrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoadingIcon = styled.img`
  width: 64px;
`;

const Loading = () => {
    return (
        <>
            <Wrapper>
                <LoadingIcon src={loading} alt="loading" />
            </Wrapper>
        </>
    );
}

export default Loading;