import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import CustomersTable from 'components/atoms/Table/CustomersTable';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import SearchCustomers from 'views/Customers/SearchCustomers/SearchCustomers';
import AddCustomers from 'views/Customers/AddCustomers/AddCustomers';

const CustomersSection = styled.section`
  min-height: 100vh;
  width: 100%;
`;

const initialState = {
  showSearch: false,
  addUserPopup: false,
};

const Customers = () => {
  const [state, setState] = useState(initialState);

  const visibleForm = name => {
    setState(state => ({
      ...state,
      [name]: !state[name],
    }));
  };

  return (
    <CustomersSection>
      <IconButton onClick={() => visibleForm('showSearch')}>
        <SearchIcon fontSize="large" />
      </IconButton>
      <IconButton onClick={() => visibleForm('addUserPopup')}>
        <AddIcon fontSize="large" />
      </IconButton>
      <SearchCustomers
        showSearch={state.showSearch}
        visibleForm={visibleForm}
        resetSearchQuery={false}
      />
      {state.addUserPopup && (
        <AddCustomers
          showSearch={state.addUserPopup}
          visibleForm={visibleForm}
          resetSearchQuery={false}
        />
      )}
      <CustomersTable />
    </CustomersSection>
  );
};

export default Customers;
