/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Loading from 'components/atoms/Loading/Loading';
import { Link } from 'react-router-dom';
import ConfirmDelete from 'components/atoms/ConfirmDelete/ConfirmDelete';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { CSVLink, CSVDownload } from 'react-csv';
import XlsxIcon from 'assets/xlsx.png';
import axios from 'axios';
import Cookies from 'js-cookie';
import Xlsx from 'json-as-xlsx';
import SelectInput from 'components/atoms/Inputs/SelectInput';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import EditIcon from '@material-ui/icons/Edit';

const URLAPI = process.env.REACT_APP_URL_API;

const ButtonsWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const CSVicon = styled.img`
  width: 32px;
  height: 32px;
`;

const tableHeaders = [
  { id: 'id', label: '', width: '1px', align: 'left', type: 'id' },
  { id: 'name', label: 'Nazwa', width: '11.42%', align: 'left', type: 'name' },
  { id: 'email', label: 'E-mail', width: '11.42%', align: 'left', type: 'string' },
  { id: 'Date', label: 'Data utworzenia', width: '11.42%', align: 'left', type: 'date' },
  { id: 'phoneNumber', label: 'Telefon', width: '11.42%', align: 'left', type: 'string' },
  { id: 'country', label: 'Kraj', width: '11.42%', align: 'left', type: 'country' },
  { id: 'city', label: 'Miasto', width: '11.42%', align: 'left', type: 'string' },
  { id: 'street', label: 'Adres', width: '11.42%', align: 'left', type: 'address' },
  { id: 'actions,', label: 'Akcje', width: '20%', align: 'center', type: 'buttons' },
];

const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const AddDietPopup = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background: #ffff;
  width: 400px;
  height: 230px;
  padding: 64px 16px;
  border: 1px solid #2e2d41;
  border-radius: 8px;
`;

const IconsWrapper = styled.div`
  position: relative;
`;
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const useStyles1 = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 1);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage)));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableComponent = ({
  onDeleteUsers,
  usersCfg,
  users,
  onInitUsers,
  usersSearchQuery,
  onUserDiet,
  onfetchIngredientsCategory,
  igCategory,
}) => {
  const classes = useStyles();
  const [refresh, setRefresh] = useState(0);
  const [init, setInit] = useState(false);
  const [usersList, setUserList] = useState([]);
  const [deleteConfirmVisable, setDeleteConfirmVisable] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [sendGridData, setSendGridData] = useState([]);
  const [showAddDietPopup, setShowAddDietPopup] = useState(false);

  const [dietToGenerate, setDietToGenerate] = useState(null);
  const [dietTypeToGenerate, setDietTypeToGenerate] = useState(null);
  const [isError, setError] = useState(false);
  const [usersConfig, setUsersConfig] = useState({
    per_page: 100,
    current_page: 1,
    jumpToPage: null,
    usersSearchQuery: '',
    total: 1,
    data: [],
  });
  const createDataFuncVals = tableHeaders.map(el => el.id);

  const dietsList = [
    { value: 30, label: '30 dni' },
    { value: 90, label: '90 dni' },
    { value: 180, label: '180 dni' },
  ];

  const dietsType = [
    { value: 1, label: 'Keto' },
    { value: 2, label: 'Zbilansowana' },
    { value: 3, label: 'Dla cukrzyków' },
    { value: 4, label: 'Post przerywany' },
  ];

  useEffect(() => {
    onfetchIngredientsCategory();
  }, []);

  useEffect(() => {
    setUsersConfig(usersCfg);
  }, [usersCfg]);

  useEffect(() => {
    const openArray = {};

    setUserList(users.filter(user => user.role.id === '851df67c-2070-48e7-89a6-a3bb4f7b28be'));

    if (users && usersCfg) {
      users.map(el => {
        const key = el.id
          .toString()
          .split('')
          .filter(e => e.trim().length)
          .join('');
        openArray[key] = false;
      });
      setDeleteConfirmVisable(openArray);
      setShowAddDietPopup(openArray);
    }
  }, [users, usersCfg]);

  const setElementsForCurrentPage = () => {
    const endpoint = `${URLAPI}users?page=${usersConfig.current_page}&per_page=${
      usersConfig.per_page
    }${usersSearchQuery === undefined ? '' : usersSearchQuery}`;
    onInitUsers(endpoint);
  };

  useEffect(() => {
    if (usersConfig !== null && init === false) {
      setElementsForCurrentPage();
      setInit(true);
    }
  }, [users]);

  const createData = (...params) => {
    const obj = {};
    // eslint-disable-next-line no-return-assign
    createDataFuncVals.map((el, index) => (obj[el] = params[index]));
    return obj;
  };

  let data = [];
  if (usersList.length > 0) {
    data = usersList.map(el => {
      return {
        id: el.id,
        name: el.first_name,
        email: el.email,
        date: el.created_at,
        phoneNumber: el.phone_number,
        country: el.country,
        city: el.city,
        street: `${el.street} ${el.house_number}`,
      };
    });
  }

  const userDay = answer => {
    let userAnswer = '';
    switch (answer) {
      case 1:
        userAnswer = 'siedząca praca - brak ruchu';
        break;
      case 2:
        userAnswer = 'siedząca praca - często wychodzę na zewnątrz';
        break;
      case 3:
        userAnswer = 'większość dnia spędzam w ruchu';
        break;
      case 4:
        userAnswer = 'praca fizyczna';
        break;

      default:
        userAnswer = 'brak danych';
        break;
    }
    return userAnswer;
  };

  const userActivity = answer => {
    let userAnswer = '';
    switch (answer) {
      case 1.15:
        userAnswer = 'brak aktywności';
        break;
      case 1.16:
        userAnswer = 'raz na dwa tyg / raz na miesiąc';
        break;
      case 1.28:
        userAnswer = 'raz lub dwa razy w tyg';
        break;
      case 1.38:
        userAnswer = 'trzy / cztery razy w tyg';
        break;
      case 1.5:
        userAnswer = 'niemal codziennie';
        break;

      default:
        userAnswer = 'brak danych';
        break;
    }
    return userAnswer;
  };

  const HandleCreateXlsx = () => {
    const settings = {
      fileName: 'Users',
      extraLength: 3,
      writeOptions: {},
    };
    const download = true;
    const columns = [
      { label: 'name', value: 'name' },
      { label: 'last_name', value: 'last_name' },
      { label: 'email', value: 'email' },
      { label: 'Date', value: 'Date' },
      { label: 'DateTime', value: 'DateTime' },
      { label: 'phoneNumber', value: 'phoneNumber' },
      { label: 'country', value: 'country' },
      { label: 'city', value: 'city' },
      { label: 'street', value: 'street' },
      { label: 'house_number', value: 'house_number' },
      { label: 'bmi', value: 'bmi' },
      { label: 'bmr', value: 'bmr' },
      { label: 'calories', value: 'calories' },
      { label: 'cpm', value: 'cpm' },
      { label: 'weight', value: 'weight' },
      { label: 'weight_target', value: 'weight_target' },
      { label: 'water', value: 'water' },
      { label: 'age', value: 'age' },
      { label: 'height', value: 'height' },
      { label: 'sex', value: 'sex' },
      { label: 'lang', value: 'lang' },
      { label: 'excluded_category', value: 'excluded_category' },
      { label: 'lactose', value: 'lactose' },
      { label: 'gluten', value: 'gluten' },
      { label: 'lifeStyle', value: 'lifeStyle' },
      { label: 'typicalDay', value: 'typicalDay' },
      { label: 'activity', value: 'activity' },
      { label: 'status subskrypcji', value: 'subscription_status' },
      { label: 'data rozpoczęcia subskrypcji', value: 'subscription_last_start_date' },
      { label: 'czas rozpoczęcia subskrypcji', value: 'subscription_last_start_time' },
    ];

    const dataCsv = usersList.map(el => {
      const date = el.created_at.split(' ');
      const subscriptionDate =
        el.subscription_last_start_date !== null
          ? el.subscription_last_start_date.split(' ')
          : ['', ''];

      return {
        name: el?.first_name,
        last_name: el?.last_name,
        email: el?.email,
        Date: date[0],
        DateTime: date[1],
        phoneNumber: el?.phone_number,
        country: el?.country,
        city: el?.city,
        street: el?.street,
        house_number: el?.house_number,
        bmi: el?.profiles?.bmi,
        bmr: el?.profiles?.bmr,
        calories: el?.profiles?.calories,
        cpm: el?.profiles?.cpm,
        weight: el?.quizes?.weight_kg,
        weight_target: el?.profiles?.weight_target_kg,
        water: el?.profiles?.water,
        age: el?.quizes?.age,
        height: el?.quizes?.height_cm,
        sex: el?.quizes?.sex,
        lang: el?.site_lang,
        excluded_category: el?.quizes?.answers
          ?.filter(a => a.question === 'excluded_category')
          .map(answer => igCategory.filter(igCat => igCat.id === answer.answer)[0]?.name)
          ?.join(', '),
        lactose: el?.quizes?.answers.filter(a => a.question === 'lactose') === 1 ? 'NO' : '',
        gluten: el?.quizes?.answers.filter(a => a.question === 'gluten') === 1 ? 'NO' : '',
        lifeStyle: el?.quizes?.answers
          .filter(a => a.question === 'life_style')
          .map(answer => answer.answer)
          .join(', '),
        typicalDay: userDay(
          el?.quizes?.answers
            .filter(a => a.question === 'typical-day')
            .map(answer => answer.answer)[0] * 1,
        ),
        activity: userActivity(
          el?.quizes?.answers
            .filter(a => a.question === 'activity')
            .map(answer => answer.answer)[0] * 1,
        ),
        subscription_status: el?.subscription_status,
        subscription_last_start_date: subscriptionDate[0],
        subscription_last_start_time: subscriptionDate[1],
      };
    });
    return Xlsx(columns, dataCsv, settings, download); // Will download the excel file
  };

  const createDataForSendGrid = () => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .get(`${URLAPI}send-grid-base`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })

      .then(res => {
        setSendGridData(res.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const rows = data.map(el => {
    const funcParams = [];
    Object.keys(el).map(key => {
      funcParams.push(el[key]);
      return funcParams;
    });
    return createData(...funcParams);
  });

  const handleChangePage = (event, newPage) => {
    setUsersConfig(state => ({
      ...state,
      current_page: newPage,
    }));
    setRefresh(Math.random());
  };

  const handleChangeRowsPerPage = event => {
    setUsersConfig(state => ({
      ...state,
      per_page: event.target.value * 1,
      current_page: 1,
    }));
    setRefresh(Math.random());
  };

  useEffect(() => {
    setElementsForCurrentPage();
  }, [refresh]);

  useEffect(() => {
    setElementsForCurrentPage();
  }, [usersSearchQuery]);

  const openDeleteConfirm = key => {
    setDeleteConfirmVisable(deleteConfirmVisable => ({
      ...deleteConfirmVisable,
      [key]: !deleteConfirmVisable[key],
    }));
  };

  const handleSetDietToGenerate = e => {
    setDietToGenerate(e);
  };

  const handleSetDietTypeToGenerate = e => {
    setDietTypeToGenerate(e);
  };

  const handleGenereteDitet = userId => {
    setError(false);
    if (dietToGenerate !== null) {
      onUserDiet(userId, dietTypeToGenerate.value, dietToGenerate.value);
      setDietToGenerate(null);
    } else {
      setError(true);
    }
  };

  const openAddDietPopup = key => {
    setShowAddDietPopup(showAddDietPopup => ({
      ...showAddDietPopup,
      [key]: !showAddDietPopup[key],
    }));
  };

  if (usersConfig === null) {
    return <Loading />;
  } else {
    return (
      <>
        <Paper className={classes.root}>
          <TableContainer component={Paper} style={{ overflowX: 'unset' }}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map(head => {
                    if (head.type === 'id') {
                      return null;
                    } else {
                      return (
                        <React.Fragment key={head.value}>
                          <StyledTableCell align={head.align} style={{ width: head.width }}>
                            {head.label}
                          </StyledTableCell>
                        </React.Fragment>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => {
                  return (
                    <React.Fragment key={row.id}>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {tableHeaders.map(head => {
                          const value = row[head.id];
                          const newKey = row.id
                            .toString()
                            .split('')
                            .filter(e => e.trim().length)
                            .join('');
                          if (head.type === 'id') {
                            return null;
                          }
                          if (head.type === 'name') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  {value} {usersList.find(x => x.id === row.id).last_name}
                                </TableCell>
                              </React.Fragment>
                            );
                          }
                          if (head.type === 'country') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  {value}, {usersList.find(x => x.id === row.id).country_iso}
                                </TableCell>
                              </React.Fragment>
                            );
                          }
                          if (head.type === 'address') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  {value}, {usersList.find(x => x.id === row.id).post_code}
                                </TableCell>
                              </React.Fragment>
                            );
                          }
                          if (head.type === 'buttons') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  <IconsWrapper>
                                    <Link to={`/users/user-edit/${row.id}`}>
                                      <IconButton aria-label="edit">
                                        <EditIcon />
                                      </IconButton>
                                    </Link>

                                    <Link to={`/users/user/${row.id}`}>
                                      <IconButton aria-label="delete">
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Link>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => openDeleteConfirm(newKey)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    <Link to={`/orders/add-orders/${row.id}`}>
                                      <ButtonPrimary>Dodaj zamówienie</ButtonPrimary>
                                    </Link>
                                    <ButtonPrimary
                                      // handleClick={() => handleGenereteDitet(row.id)}
                                      handleClick={() => openAddDietPopup(newKey)}
                                    >
                                      Wygeneruj dietę
                                    </ButtonPrimary>
                                    {showAddDietPopup[newKey] && (
                                      <AddDietPopup>
                                        <BtnHolder>
                                          <CloseButton
                                            handleClick={openAddDietPopup}
                                            funcVal={newKey}
                                          />
                                        </BtnHolder>
                                        <SelectInput
                                          options={dietsList}
                                          // val={isEnabled}
                                          noOptionsText="Nie znaleziono"
                                          label="wybierz dietę"
                                          handleOnChange={handleSetDietToGenerate}
                                          onChangeParams={['unit']}
                                          name="unit"
                                          id="unit"
                                          // selectValid={selectValid}
                                          isError={isError}
                                          errorMessage="Pole wymagane"
                                        />
                                        <SelectInput
                                          options={dietsType}
                                          // val={isEnabled}
                                          noOptionsText="Nie znaleziono"
                                          label="wybierz dietę"
                                          handleOnChange={handleSetDietTypeToGenerate}
                                          onChangeParams={['unit']}
                                          name="unit"
                                          id="unit"
                                          // selectValid={selectValid}
                                          isError={isError}
                                          errorMessage="Pole wymagane"
                                        />
                                        <ButtonsWrapper>
                                          <ButtonPrimary
                                            handleClick={() => handleGenereteDitet(row.id)}
                                          >
                                            Wygeneruj
                                          </ButtonPrimary>
                                        </ButtonsWrapper>
                                      </AddDietPopup>
                                    )}
                                    {deleteConfirmVisable[newKey] && (
                                      <ConfirmDelete
                                        width="550%"
                                        newKey={newKey}
                                        name={`${row.name} ${
                                          usersList.find(x => x.id === row.id).last_name
                                        }`}
                                        onDeleteHandler={onDeleteUsers}
                                        id={row.id}
                                        rejectHandler={openDeleteConfirm}
                                        isOpen={deleteConfirmVisable}
                                      >
                                        Czy napewno chcesz skasować
                                      </ConfirmDelete>
                                    )}
                                  </IconsWrapper>
                                </TableCell>
                              </React.Fragment>
                            );
                          }

                          return (
                            <React.Fragment key={head.id}>
                              <TableCell align={head.align}>{value}</TableCell>
                            </React.Fragment>
                          );
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {usersConfig !== null ? (
                    <>
                      <ButtonsWrapper>
                        <button onClick={() => HandleCreateXlsx()} type="button">
                          <CSVicon src={XlsxIcon} alt="icon" />
                        </button>

                        <button onClick={() => createDataForSendGrid()} type="button">
                          Aktualizuj Sendgrid
                        </button>
                      </ButtonsWrapper>

                      <TablePagination
                        rowsPerPageOptions={[
                          2,
                          6,
                          10,
                          15,
                          25,
                          100,
                          { label: 'All', value: usersConfig.total },
                        ]}
                        count={usersConfig.total * 1}
                        rowsPerPage={usersConfig.per_page}
                        page={usersConfig.current_page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
};

const mapStateToProps = state => {
  return {
    users: state.users.users,
    usersCfg: state.users.usersConfig,
    usersSearchQuery: state.users.usersSearchQuery,
    igCategory: state.igCat.ingredientsCategory,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitUsers: endpoint => dispatch(actionCreators.initUsers(endpoint)),
    onDeleteUsers: id => dispatch(actionCreators.deleteUsers(id)),
    onGenereteDiet: (userId, dietName) => dispatch(actionCreators.genereteDiet(userId, dietName)),
    onUserDiet: (id, dietName, dietLenght) =>
      dispatch(actionCreators.userDiet(id, dietName, dietLenght)),
    onfetchIngredientsCategory: () => dispatch(actionCreators.fetchIngredientsCategory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
