import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';


const URLAPI = process.env.REACT_APP_URL_API;

export const setIngredients = ingredients => {
  return {
    type: actionTypes.SET_INGREDIENTS,
    ingredients,
  };
};

export const fetchIngredientsFailed = () => {
  return {
    type: actionTypes.FETCH_INGREDIENTS_FAILED,
  };
};

export const setIngredientsOptionName = optionName => {
  return {
    type: actionTypes.SET_INGREDIENTS_OPTION_NAME,
    optionName,
  };
};

export const initIngredients = endpoint => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })

      .then(res => {
        const ingredients = res.data.data;
        dispatch(setIngredients(ingredients));
      })
      .catch(error => {
        dispatch(fetchIngredientsFailed(error));
      });
  };
};

export const searchIngredients = url => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then(res => {
        const ingredients = res.data.data;

        dispatch(setIngredients(ingredients));
      })
      .catch(error => {
        dispatch(fetchIngredientsFailed(error));
      });
  };
};

export const setIngredientsQueryAfterSearch = query => {
  return {
    type: actionTypes.SET_INGREDIENTS_QUERY_AFTER_SEARCH,
    query,
  };
};

export const deleteIngredientsSuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_INGREDIENTS_SUCCESS,
    id,
    msg: res
  };
};

export const deleteIngredientsFailed = error => {
  return {
    type: actionTypes.DELETE_INGREDIENTS_FAILED,
    error,
  };
};

export const deleteIngredients = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .delete(`${URLAPI}ingredients/${id}`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then((res) => {
        dispatch(deleteIngredientsSuccess(id, res));
      })
      .catch(error => {
        dispatch(deleteIngredientsFailed(error));
      });
  };
};

export const submitIngredientFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_FORM_ADD_NEW_INGREDIENT_SUCCESS,
    msg: res
  };
};

export const submitIngredientFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_FORM_ADD_NEW_INGREDIENT_FAILED,
    error,
  };
};

export const redirectAfterSubmitForm = () => {
  return {};
};

export const submitIngredientForm = (
  history,
  bodyFormData,
  endpoint,
  currentEndpointForPagination,
  resetForm
) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios({
      method: 'post',
      url: endpoint,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authKey}`
      }
    })
      .then(res => {
        dispatch(initIngredients(currentEndpointForPagination));
        dispatch(submitIngredientFormSuccess(res));
        dispatch(initIngredients());
        if (history !== null) {
          history.push('/ingredients');
        };
        resetForm();

      })
      .catch(error => {
        dispatch(submitIngredientFormFailed(error));
      });
  };
};

export const deletePhotoOfIngredientSuccess = res => {
  return {
    type: actionTypes.DELETE_PHOTO_OF_INGREDIENT_SUCCESS,
    msg: res,
  };
};
export const deletePhotoOfIngredientFailed = error => {
  return {
    type: actionTypes.DELETE_PHOTO_OF_INGREDIENT_FAILED,
    error,
  };
};
export const deletePhotoOfIngredient = endpoint => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios({
      method: 'put',
      url: endpoint,
      data: null,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authKey}`
      }
    })
      .then(res => {
        dispatch(deletePhotoOfIngredientSuccess(res));
      })
      .catch(error => {
        dispatch(deletePhotoOfIngredientFailed(error));
      });
  };
};

// UNITS

export const setUnits = units => {
  return {
    type: actionTypes.SET_UNITS,
    units,
  };
};

export const fetchUnitsFailed = () => {
  return {
    type: actionTypes.FETCH_UNITS_FAILED,
  };
};

export const setUnitsOptions = units => {
  return {
    type: actionTypes.SET_UNITS_OPTIONS,
    units,
  };
};

export const fetchUnits = () => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}units`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      }
      )

      .then(res => {
        const units = res.data.data;

        dispatch(setUnits(units));
        dispatch(setUnitsOptions(units));
      })
      .catch(error => {
        dispatch(fetchUnitsFailed(error));
      });
  };
};

export const initAllIngredientsForOptions = () => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}ingredients?records=all`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then(res => {
        const allIngredients = res.data.data;
        dispatch(setIngredientsOptionName(allIngredients));
      })
      .catch(error => {
        dispatch(fetchUnitsFailed(error));
      });
  };
};
