/* eslint-disable no-else-return */
import React, { useState } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Loading from 'components/atoms/Loading/Loading';
import ConfirmDelete from 'components/atoms/ConfirmDelete/ConfirmDelete';
import EditUsers from 'components/EditUsers/EditUsers';
import { Typography } from '@material-ui/core';

const tableHeaders = [
  { id: 'id', label: 'id', width: '0', align: 'left', type: 'id' },
  { id: 'nr', label: 'Numer', width: '17%', align: 'center', type: 'string' },
  { id: 'price', label: 'Cena', width: '5%', align: 'center', type: 'string' },
  { id: 'vat', label: 'VAT', width: '5%', align: 'center', type: 'string' },
  { id: 'userData', label: 'Dane', width: '17%', align: 'center', type: 'string' },
  { id: 'userAdress', label: 'Adres', width: '17%', align: 'center', type: 'string' },
  { id: 'products', label: 'produkty', width: '17%', align: 'center', type: 'string' },
  { id: 'actions,', label: 'Akcje', width: '20%', align: 'center', type: 'buttons' },
];

const IconsWrapper = styled.div`
  position: relative;
`;

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const useStyles1 = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const InvoicesTable = ({
  invoicesList,
  invoicesConfig,
  isLoading,
  setOpen,
  setDeleteConfirmVisable,
  setInvoicesConfig,
  setRefresh,
  products
}) => {
  const classes = useStyles();
  const createDataFuncVals = tableHeaders.map(el => el.id);

  const createData = (...params) => {
    const obj = {};
    // eslint-disable-next-line no-return-assign
    createDataFuncVals.map((el, index) => (obj[el] = params[index]));
    return obj;
  };

  let data = [];

  if (invoicesList) {
    data = invoicesList.map(el => {
      return {
        id: el.id,
        nr: el.number,
        price: 0,
        vat: el.vat,
        userData: `${el.first_name} ${el.last_name}`,
        userAdress: <>
          <p>{el.street} {el.house_number}</p>
          <p> {el.city} {el.post_code}</p>
          <p> {el.country}</p>
        </>,
        // products: el.products.split(','), filter(productID => products)
      };
    });
  }
  const rows = data.map(el => {
    const funcParams = [];
    Object.keys(el).map(key => {
      funcParams.push(el[key]);
      return funcParams;
    });
    return createData(...funcParams);
  });

  const handleChangePage = (event, newPage) => {
    setInvoicesConfig(state => ({
      ...state,
      current_page: newPage,
    }));
    setRefresh(Math.random());
  };

  const handleChangeRowsPerPage = event => {
    setInvoicesConfig(state => ({
      ...state,
      per_page: event.target.value * 1,
      current_page: 1,
    }));
    setRefresh(Math.random());
  };
  const handleOpen = key => {
    setOpen(open => ({
      ...open,
      [key]: !open[key],
    }));
  };

  // const openDeleteConfirm = key => {
  //   setDeleteConfirmVisable(deleteConfirmVisable => ({
  //     ...deleteConfirmVisable,
  //     [key]: !deleteConfirmVisable[key],
  //   }));
  // };

  if (invoicesConfig === null || products.length === 0) {
    return <Loading />;
  } else {
    return (
      <>
        <Paper className={classes.root}>
          <TableContainer>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map(head => {
                    if (head.type === 'id') {
                      return null;
                    } else {
                      return <React.Fragment key={head.id}>
                        <StyledTableCell align={head.align} style={{ width: head.width }}>
                          {head.label}
                        </StyledTableCell>
                      </React.Fragment>
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => {
                  return (
                    <React.Fragment key={rows.id}>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {tableHeaders.map(head => {
                          const value = row[head.id];
                          const newKey = (row.name + row.id.toString())
                            .split('')
                            .filter(e => e.trim().length)
                            .join('');
                          if (head.type === 'id') {
                            return null

                          } else if (head.type === 'buttons') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>
                                  <IconsWrapper>
                                    {/* <IconButton
                                      aria-label="delete"
                                      // onClick={() => onDeleteUsers(row.id)}
                                      onClick={() => openDeleteConfirm(newKey)}
                                    >
                                      <DeleteIcon />
                                    </IconButton> */}
                                    <IconButton
                                      aria-label="edit"
                                      onClick={() => handleOpen(newKey)}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    {/* <EditUsers
                                      nameValue={row.name}
                                      surname={row.surname}
                                      email={row.email}
                                      id={row.id}
                                      name={row.name}
                                      isOpen={isOpen[newKey]}
                                      handleOpen={handleOpen}
                                      newKey={newKey}
                                      unieqeEmails={unieqeEmails}
                                    /> */}
                                    {/* {deleteConfirmVisable[newKey] && (
                                      <ConfirmDelete
                                        width="500%"
                                        newKey={newKey}
                                        name={row.name}
                                        onDeleteHandler={onDeleteUsers}
                                        id={row.id}
                                        rejectHandler={openDeleteConfirm}
                                        isOpen={deleteConfirmVisable}
                                      >
                                        Czy napewno chcesz skasować
                                      </ConfirmDelete>
                                    )} */}
                                  </IconsWrapper>
                                </TableCell>
                              </React.Fragment>
                            );
                          } else if (head.type === 'price') {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>{value} zł</TableCell>
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <React.Fragment key={head.id}>
                                <TableCell align={head.align}>{value}</TableCell>
                              </React.Fragment>
                            );
                          }
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {isLoading !== true ? (
                    <TablePagination
                      rowsPerPageOptions={[
                        2,
                        6,
                        10,
                        15,
                        25,
                        100,
                        { label: 'All', value: invoicesConfig.total },
                      ]}
                      count={invoicesConfig.total * 1}
                      rowsPerPage={invoicesConfig.per_page}
                      page={invoicesConfig.current_page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  ) : (
                      ''
                    )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
};

export default InvoicesTable;
