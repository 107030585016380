import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';

import OrdersTable from 'components/atoms/Table/OrdersTable';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import SearchOrders from 'views/Orders/SearchOrders/SearchOrders';

const OrdersSection = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  position: relative;
  
`

const initialState = {
  showSearch: true,
};

const Orders = ({
  onFetchAllCoupon,
  allCoupons
}) => {
  const [state, setState] = useState(initialState);
  const [loadingAfterSearch, setLoadingAfterSearch] = useState(false)
  const [couponList, setCoupon] = useState([]);
  const [init, setInit] = useState(false);



  const setElementsForCurrentPage = () => {
    onFetchAllCoupon();
  };

  useEffect(() => {
    if (couponList !== null && init === false) {
      setElementsForCurrentPage();
      setInit(true);
    }
  }, [couponList, init]);


  const visibleForm = name => {
    setState(state => ({
      ...state,
      [name]: !state[name],
    }));
  };

  return (
    <OrdersSection>
      <Link to="/orders/add-orders">
        <IconButton>
          <AddIcon fontSize="large" />
        </IconButton>
      </Link>
      <IconButton onClick={() => visibleForm('showSearch')}>
        <SearchIcon fontSize="large" />
      </IconButton>
      <SearchOrders showSearch={state.showSearch} resetSearchQuery={false} setLoadingAfterSearch={setLoadingAfterSearch} loadingAfterSearch={loadingAfterSearch} />
      <OrdersTable loadingAfterSearch={loadingAfterSearch} allCoupons={allCoupons} />
    </OrdersSection>
  );
};

const mapStateToProps = state => {
  return {
    allCoupons: state.coupons.coupon.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchAllCoupon: () => dispatch(actionCreators.fetchAllCoupon()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
