import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions';
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/atoms/TabPanel/TabPanel';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import Typography from '@material-ui/core/Typography';
import Input from 'components/atoms/Inputs/Input';
import { Validators } from 'components/atoms/Inputs/Validators';
import { useHistory } from 'react-router-dom';

const URLAPI = process.env.REACT_APP_URL_API;

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      outline: 'none',
    },
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    width: '80%',
    height: '50%',
    overflow: 'auto',
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
}));

const Wrapper = styled.div`
  height: 100%;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputsWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const ErrorMsg = styled.div`
  margin-top: 3px;
  margin-left: 14px;
`;

const EditIngredientsCategory = ({
  id,
  isOpen,
  handleOpen,
  name,
  surname,
  email,
  nameValue,
  onSubmitUsersForm,
  onSubmitUsersPasswordForm,
  newKey,
  unieqeEmails,
}) => {
  const [inputsValue, setInputValue] = useState({
    newName: '',
    newSurname: '',
    newEmail: '',
    confirmEmail: '',
    userPassword: '',
    confirmPassword: '',
  });
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [error, setError] = useState(false);
  const history = useHistory();

  const closePopup = () => {
    handleOpen(newKey);
  };

  useEffect(() => {
    setInputValue({
      ...inputsValue,
      newName: nameValue,
      newSurname: surname,
      newEmail: email,
    });
  }, [nameValue, surname, email]);

  const handleSubmitForm = e => {
    e.preventDefault();
    const endpoint = `${URLAPI}users/${id}`;
    const endpointDownload = `${URLAPI}users/admin?page=${1}&per_page=${100}`;
    if (inputsValue.userPassword === inputsValue.confirmPassword) {
      const bodyFormData = new window.FormData();
      bodyFormData.set('first_name', inputsValue.newName);
      bodyFormData.set('last_name', inputsValue.newSurname);
      bodyFormData.set('email', inputsValue.newEmail);
      // bodyFormData.set('password', inputsValue.userPassword);
      // bodyFormData.set('confirm_password', inputsValue.confirmPassword);
      bodyFormData.set('role_id', '87a7c09b-9039-41fd-90ed-7dba2ad2682d');
      bodyFormData.set('phone_prefix', '+00');
      bodyFormData.set('phone_number', 'admin');
      bodyFormData.set('country', 'admin');
      bodyFormData.set('country_iso', 'admin');
      bodyFormData.set('city', 'admin');
      bodyFormData.set('street', 'admin');
      bodyFormData.set('house_number', 'admin');
      bodyFormData.set('post_code', 'admin');
      bodyFormData.set('_method', 'PUT');
      onSubmitUsersForm(history, bodyFormData, endpoint, closePopup, endpointDownload);
    } else {
      setError(true);
    }
  };

  const handleSubmitPasswordForm = e => {
    e.preventDefault();
    const endpoint = `${URLAPI}users/passwordAdmin/${id}`;
    const endpointDownload = `${URLAPI}users/admin?page=${1}&per_page=${100}`;
    if (inputsValue.userPassword === inputsValue.confirmPassword) {
      const bodyFormDataPassword = new window.FormData();
      bodyFormDataPassword.set('password', inputsValue.userPassword);
      bodyFormDataPassword.set('password_confirmation', inputsValue.confirmPassword);
      bodyFormDataPassword.set('_method', 'PUT');
      onSubmitUsersForm(history, bodyFormDataPassword, endpoint, closePopup, endpointDownload);
    } else {
      setError(true);
    }
  };


  const handleInputChange = e => {
    setInputValue({
      ...inputsValue,
      [e.target.name]: e.target.value,
    });
  };

  const a11yProps = index => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  const passwordValidation = () => {
    if (inputsValue.userPassword === inputsValue.confirmPassword) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen || false}
        onClose={() => handleOpen(newKey)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <BtnHolder>
              <CloseButton handleClick={handleOpen} funcVal={newKey} />
            </BtnHolder>
            <Wrapper>
              <div className={classes.root}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChangeTab}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                >
                  <Tab label="Edycja danych" {...a11yProps(0)} />
                  <Tab label="Edycja Hasła" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <StyledForm onSubmit={e => handleSubmitForm(e)}>
                    <InputsWrapper>
                      <Input
                        id="newName"
                        onChange={handleInputChange}
                        value={inputsValue.newName}
                        htmlFor={name}
                        label="Edytuj Imię"
                        name="newName"
                        type="text"
                        validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                      />
                    </InputsWrapper>
                    <InputsWrapper>
                      <Input
                        id="newSurname"
                        onChange={handleInputChange}
                        value={inputsValue.newSurname}
                        oldValue={email}
                        htmlFor={name}
                        label="Edytuj Nazwisko"
                        name="newSurname"
                        type="text"
                        validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                      />
                    </InputsWrapper>
                    <InputsWrapper>
                      <Input
                        id="email"
                        onChange={handleInputChange}
                        uniqueNames={unieqeEmails}
                        value={inputsValue.newEmail}
                        htmlFor={inputsValue.newEmail}
                        label="Edytuj e-mail"
                        name="newEmail"
                        type="email"
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          { check: Validators.email, message: 'podaj poprawny email' },
                          {
                            check: Validators.uniqueEmailEdit,
                            message: 'Podany email jest zajęty',
                          },
                        ]}
                      />
                    </InputsWrapper>
                    <ButtonWrapper>
                      <ButtonPrimary type="submit"> Zatwierdź zmiany</ButtonPrimary>
                    </ButtonWrapper>
                  </StyledForm>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <StyledForm onSubmit={e => handleSubmitPasswordForm(e)}>
                    <InputsWrapper>
                      <Input
                        id="userPassword"
                        onChange={handleInputChange}
                        value={inputsValue.userPassword}
                        htmlFor={name}
                        label="Nowe hasło"
                        name="userPassword"
                        type="password"
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          { check: Validators.length, message: 'minimum 6 znaków' },
                        ]}
                      />
                    </InputsWrapper>
                    <InputsWrapper>
                      <Input
                        id="confirmPassword"
                        onChange={handleInputChange}
                        value={inputsValue.confirmPassword}
                        htmlFor={name}
                        label="Ptowierdź hasło"
                        name="confirmPassword"
                        type="password"
                        handleOnBlur={passwordValidation}
                        validators={[
                          { check: Validators.required, message: 'pole wymagane' },
                          { check: Validators.length, message: 'minimum 6 znaków' },
                        ]}
                      />
                      {error && (
                        <ErrorMsg>
                          {' '}
                          <Typography variant="caption" color="error">
                            Hasła nie są identyczne
                          </Typography>
                        </ErrorMsg>
                      )}
                    </InputsWrapper>
                    <ButtonWrapper>
                      <ButtonPrimary type="submit"> Zatwierdź zmiany</ButtonPrimary>
                    </ButtonWrapper>
                  </StyledForm>
                </TabPanel>
              </div>
            </Wrapper>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmitUsersForm: (history, bodyFormData, endpoint, handleCloseModal, endpointDownload) =>
      dispatch(
        actionCreators.submitUsersForm(
          history,
          bodyFormData,
          endpoint,
          handleCloseModal,
          endpointDownload,
        ),
      ),

  };
}

export default connect(null, mapDispatchToProps)(EditIngredientsCategory);
