import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';


const URLAPI = process.env.REACT_APP_URL_API;
// GET
export const setProducts = products => {
    return {
        type: actionTypes.SET_PRODUCTS,
        products,
    };
};

export const fetchProductsFailed = (error) => {
    return {
        type: actionTypes.FETCH_PRODUCTS_FAILED,
        error
    };
};

export const fetchProducts = () => {
    const authKey = Cookies.getJSON('authKey');
    return dispatch => {
        axios
            .get(`${URLAPI}products`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                }
            })
            .then(res => {
                const products = res.data.data;
                dispatch(setProducts(products));

            })
            .catch(error => {
                dispatch(fetchProductsFailed(error));

            });
    };
};

// GET ONE 
export const setsSingleProduct = product => {
    return {
        type: actionTypes.SET_SINGLE_PRODUCTS,
        singleProducts: product,
    };
};

export const fetchSingleproductFailed = (error) => {
    return {
        type: actionTypes.FETCH_SINGLE_PRODUCTS_FAILED,
        error
    };
};

export const fetchSingleproduct = (id) => {
    const authKey = Cookies.getJSON('authKey');
    return dispatch => {
        axios
            .get(`${URLAPI}products/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                }
            })
            .then(res => {
                const product = res.data.data;

                dispatch(setsSingleProduct(product));
            })
            .catch(error => {
                dispatch(fetchSingleproductFailed(error));
            });
    };
};




// ADD PRODUCTS

export const submitProductsFormSuccess = res => {
    return {
        type: actionTypes.SUBMIT_PRODUCTS_FORM_SUCCESS,
        msg: res
    };
};

export const submitProductsFormFailed = error => {
    return {
        type: actionTypes.SUBMIT_PRODUCTS_FORM_FAILED,
        error,
    };
};


export const addProductsForm = (
    bodyFormData,
    resetForm,
) => {
    const authKey = Cookies.getJSON('authKey');
    return dispatch => {
        axios({
            method: 'post',
            url: `${URLAPI}products`,
            data: bodyFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${authKey}`
            },
        })
            .then(res => {
                dispatch(submitProductsFormSuccess(res));
                dispatch(fetchProducts());
                resetForm();
            })
            .catch(error => {
                dispatch(submitProductsFormFailed(error));
            });
    };
};

// EDIT PRODUCTS

export const submitProductsForm = (
    id,
    bodyFormData,
    resetForm
) => {
    const authKey = Cookies.getJSON('authKey');
    return dispatch => {
        axios({
            method: 'post',
            url: `${URLAPI}products/${id}`,
            data: bodyFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${authKey}`
            },
        })
            .then(res => {
                dispatch(submitProductsFormSuccess(res));
                dispatch(fetchProducts());
                resetForm();
            })
            .catch(error => {
                dispatch(submitProductsFormFailed(error));
            });
    };
};

// DELETE

export const deleteProductsSuccess = (id, res) => {
    return {
        type: actionTypes.DELETE_PRODUCTS_SUCCESS,
        id,
        msg: res
    };
};

export const deleteProductsFailed = error => {
    return {
        type: actionTypes.DELETE_PRODUCTS_FAILED,
        error,

    };
};

export const deleteProducts = id => {
    const authKey = Cookies.getJSON('authKey');
    return dispatch => {
        axios
            .delete(`${URLAPI}products/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                }
            })
            .then((res) => {
                dispatch(deleteProductsSuccess(id, res));
                dispatch(fetchProducts());

            })
            .catch(error => {
                dispatch(deleteProductsFailed(error));
            });
    };
};