import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddShipmentsMethod from 'views/ShipmentsMethod/AddShipmentsMethod/AddShipmentsMethod';
import ShipmentsMethodTable from 'components/atoms/Table/ShipmentsMethodTable'

const URLAPI = process.env.REACT_APP_URL_API;


const Wrapper = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  position: relative;
`
const ShipmentsMethod = ({
    onInitValidators,
    onFetchShipmentsMethod,
    onDeleteShipmentsMethods,
    isLoading,
    shipmentsMethod,
    unieqeShipmentsMethodName,
    onAddShipmentsMethodsForm
}) => {
    const [visible, setVisible] = useState(false)
    const [init, setInit] = useState(false);
    const [shimpmentList, setShipmentsMethod] = useState([]);
    const [isOpen, setOpen] = useState([]);
    const [deleteConfirmVisable, setDeleteConfirmVisable] = useState([]);

    const showAddIgCategory = () => {
        setVisible(!visible)
    }


    useEffect(() => {
        const endpoint = `${URLAPI}shipment-method/name`
        onInitValidators(endpoint, 'shipmentMethod')
    }, [onInitValidators])

    const setElementsForCurrentPage = () => {
        onFetchShipmentsMethod()
    }

    useEffect(() => {
        if (shimpmentList !== null && init === false) {
            setElementsForCurrentPage()
            setInit(true)
        }
    }, [shimpmentList, init])

    useEffect(() => {
        const openArray = {}
        setShipmentsMethod(shipmentsMethod);
        shipmentsMethod.map(el => {
            const key = (el.code + el.name.toString()).split('').filter(e => e.trim().length).join('');
            openArray[key] = false
        })
        setOpen(openArray)
        setDeleteConfirmVisable(openArray)

    }, [shipmentsMethod])


    return (
        <Wrapper>
            <IconButton
                onClick={() => showAddIgCategory()}
            >
                <AddIcon fontSize="large" />
            </IconButton>
            {visible && <AddShipmentsMethod
                setVisible={setVisible}
                unieqeShipmentsMethodName={unieqeShipmentsMethodName}
                onAddShipmentsMethodsForm={onAddShipmentsMethodsForm}

            />}

            <ShipmentsMethodTable
                shipmentsMethod={shipmentsMethod}
                shimpmentList={shimpmentList}
                setShipmentsMethod={setShipmentsMethod}
                unieqeShipmentsMethodName={unieqeShipmentsMethodName}
                setOpen={setOpen}
                isOpen={isOpen}
                deleteConfirmVisable={deleteConfirmVisable}
                setDeleteConfirmVisable={setDeleteConfirmVisable}
                onDeleteShipmentsMethods={onDeleteShipmentsMethods}
            />
        </Wrapper>
    );
}


const mapStateToProps = state => {
    return {
        isLoading: state.error,
        shipmentsMethod: state.shipmentsMethod.shipmentsMethod,
        unieqeShipmentsMethodName: state.validators.validators

    };
};

const mapDispatchToProps = dispatch => {
    return {
        // onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
        onFetchShipmentsMethod: () => dispatch(actionCreators.fetchShipmentsMethod()),
        onDeleteShipmentsMethods: id => dispatch(actionCreators.deleteShipmentsMethods(id)),
        onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),
        onAddShipmentsMethodsForm: (name, description, price, isEnabled, resetForm) => dispatch(actionCreators.addShipmentsMethodsForm(name, description, price, isEnabled, resetForm)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsMethod);
