import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;

export const setMealsCategoryOptions = options => {
  return {
    type: actionTypes.SET_MEALS_CATEGORY_OPTIONS,
    options,
  };
};

export const setMealsCategory = mealsCategory => {
  return {
    type: actionTypes.SET_MEALS_CATEGORY,
    mealsCategory,
  };
};

export const fetchMealsCateogryFailed = () => {
  return {
    type: actionTypes.FETCH_MEALS_CATEGORY_FAILED,
  };
};

export const fetchMealsCategory = () => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}meals-category`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then(res => {
        const mealsCategory = res.data.data;

        dispatch(setMealsCategory(mealsCategory));
        dispatch(setMealsCategoryOptions(mealsCategory));
      })
      .catch(error => {
        dispatch(fetchMealsCateogryFailed(error));
      });
  };
};


export const deleteMealCategorySuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_MEAL_CATEGORY_SUCCESS,
    id,
    msg: res
  };
};

export const deleteMealFailed = error => {
  return {
    type: actionTypes.DELETE_MEAL_CATEGORY_FAILED,
    error,

  };
};


export const deleteMealCategory = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .delete(`${URLAPI}meals-category/${id}`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then((res) => {
        dispatch(deleteMealCategorySuccess(id, res));
      })
      .catch(error => {
        dispatch(deleteMealFailed(error));
      });
  };
};



export const setSingleMealsCategory = mealsCategory => {
  return {
    type: actionTypes.SET_SINGLE_MEALS_CATEGORY,
    mealsCategory,
  };
};

export const fetchSingleMealsCateogryFailed = (error) => {
  return {
    type: actionTypes.FETCH_SINGLE_MEALS_CATEGORY_FAILED,
    error
  };
};



export const fetchSingleMealsCategory = (id) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}meals-category/${id}`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then(res => {
        const mealCategory = res.data.data;

        dispatch(setSingleMealsCategory(mealCategory));
      })
      .catch(error => {
        dispatch(fetchMealsCateogryFailed(error));
      });
  };
};


export const submitMealsCategoryFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_MEALS_CATEGORY_FORM_SUCCESS,
    msg: res
  };
};

export const submitMealsCategoryFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_MEALS_CATEGORY_FORM_FAILED,
    error,
  };
};

export const AddMealsCategoryForm = (
  name,
  resetForm,
) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .post(`${URLAPI}meals-category`, {
        name
      }, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        },
      })
      .then(res => {
        dispatch(submitMealsCategoryFormSuccess(res));
        dispatch(fetchMealsCategory());
        resetForm();

      })
      .catch(error => {
        dispatch(submitMealsCategoryFormFailed(error));
      });
  };
};

// EDIT Meals category

export const submitMealsCategoryForm = (
  name,
  id,
) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .put(`${URLAPI}meals-category/${id}`, {
        name
      }, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        },
      })
      .then(res => {
        dispatch(submitMealsCategoryFormSuccess(res));
        dispatch(fetchMealsCategory());
      })
      .catch(error => {
        dispatch(submitMealsCategoryFormFailed(error));
      });
  };
};


