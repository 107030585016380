import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
    paymentMethods: [],
    paymentMehtodToEdit: null,
};
// GET
const setPaymentMethod = (state, action) => {
    return {
        ...state,
        paymentMethods: action.paymentMethods,
        error: false,
    }
}

// GET ONE 
const setSinglePaymentMethod = (state, action) => {
    return {
        ...state,
        paymentMehtodToEdit: action.paymentMehtods,
        error: false,
    };
};

// DELETE
const deletePaymentMethodSuccess = (state, action) => {
    return {
        ...state,
        paymentMethods: [...state.paymentMethods.filter(method => method.name !== action.name)],
    };
};


const paymentMethodReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PAYMENT_METHOD:
            return setPaymentMethod(state, action);
        case actionTypes.DELETE_PAYMENT_METHOD_SUCCESS:
            return deletePaymentMethodSuccess(state, action);
        case actionTypes.SET_SINGLE_PAYMENT_METHOD:
            return setSinglePaymentMethod(state, action);
        default:
            return state;
    }
};

export default paymentMethodReducer;
