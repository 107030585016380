import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

const HomePage = ({ currentUser }) => {

    return (
        <>
            dashbord
        </>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
    };
};




export default connect(mapStateToProps, null)(HomePage);
