import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
    invoices: null,
    invoicesConfig: null,
};

const setInvoices = (state, action) => {
    return {
        ...state,
        invoices: action.invoices.data,
        invoicesConfig: action.invoices,
        error: false,
    };
};

const fetchInvoicesFailed = state => {
    return {
        ...state,
        error: true,
    };
};

const submitInvoicesFormSuccess = (state, action) => {
    return {
        ...state,
        msg: action.msg,
    };
};

const submitInvoicesFormFailed = (state, action) => {
    return {
        ...state,
        msg: action.error,
    };
};

const getSingleInvoicesToEditSuccess = (state, action) => {
    return {
        ...state,
        invoiceToEdit: action.data,
    };
};

const createInvoicesFailed = (state, action) => {
    return {
        ...state,
        // msg: action.msg,
    }
}
const createInvoicesSuccess = (state, action) => {
    return {
        ...state,
        // msg: action.error,
    }
}

const invoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_INVOICES:
            return setInvoices(state, action);
        case actionTypes.FETCH_INVOICES_FAILED:
            return fetchInvoicesFailed(state);
        case actionTypes.SUBMIT_FORM_ADD_NEW_MEAL_SUCCESS:
            return submitInvoicesFormSuccess(state, action);
        case actionTypes.SUBMIT_INVOICES_FORM_FAILED:
            return submitInvoicesFormFailed(state, action);
        case actionTypes.GET_SINGLE_INVOICES_TO_EDIT_SUCCESS:
            return getSingleInvoicesToEditSuccess(state, action);
        case actionTypes.CREATE_INVOICES_FAILED:
            return createInvoicesFailed(state);
        case actionTypes.CREATE_INVOICES_SUCCESS:
            return createInvoicesSuccess(state, action);
        default:
            return state;
    }
};

export default invoicesReducer;
