/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import 'date-fns';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/atoms/TabPanel/TabPanel';
import EditOrdersProductsTable from 'views/Orders/EditOrders/EditOrdersProductsTable';
import SelectInput from 'components/atoms/Inputs/SelectInput';
import Input from 'components/atoms/Inputs/Input';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import Loading from 'components/atoms/Loading/Loading';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { pl } from 'date-fns/esm/locale';
import EventIcon from '@material-ui/icons/Event';
import { IconButton, InputAdornment, Typography } from '@material-ui/core';

const URLAPI = process.env.REACT_APP_URL_API;

const ModalWrapper = styled.div`
  margin: 16px auto;
  position: relative;
  width: 100%;
  @media screen and (min-width: 992px) {
    width: 80%;
  }
`;

const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const CellWrapper = styled.div`
  width: ${({ widthPosps }) => widthPosps};
`;

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      outline: 'none',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    minHeight: 650,
  },
  button: {
    width: '100%',
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
}));

const FormControl = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: ${({ shorter }) => (shorter ? '50%' : '100%')};
`;

const DataTtile = styled.p`
  font-weight: 700;
`;
const Data = styled.span`
  font-weight: normal;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: 32px;
`;
const TabsWrapper = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  height: 100%;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px;
`;

const initialState = {
  orderToEdit: null,
  userData: { id: '', first_name: '', last_lame: '' },
  paymentMethod: { id: '', name: '' },
  shipmentsMethod: { id: '', name: '' },
  orderType: { id: '', name: '' },
  orderStatus: { id: '', name: '' },
  paymentStatus: { id: '', name: '' },
  paymentDate: '',
  shipmentStatus: { id: '', name: '' },
  shipmentDate: '',
  returnStatus: { id: '', name: '' },
  returnDate: '',
  currency: { id: '', name: '' },
  totalPrice: '',
  notes: '',
  productsList: [],
  users: [],
  isError: {
    userData: null,
  },
  // validationPassed: false,
  lastEdited: '',
};

const SubmitBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const EditOrder = ({
  onFetchSingleorder,
  history,
  order,
  status,
  onSubmitOrdersForm,
  match,
  onFetchPaymentMethod,
  paymentMethod,
  onFetchShipmentsMethod,
  shipmentsMethod,
}) => {
  const [validationPassed, setValidationPassed] = useState(false);
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const [state, setState] = useState(initialState);
  useEffect(() => {
    onFetchSingleorder(match.params.id);
    onFetchPaymentMethod();
    onFetchShipmentsMethod();
  }, []);

  const handlePaymentDateChange = date => {
    setState(state => ({
      ...state,
      paymentDate: date,
    }));
  };

  const handleShipmentDateChange = date => {
    setState(state => ({
      ...state,
      shipmentDate: date,
    }));
  };
  const handleReturnDateChange = date => {
    setState(state => ({
      ...state,
      returnDate: date,
    }));
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const a11yProps = index => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };
  useEffect(() => {
    if (state.order === '' || state.order === undefined) {
      onFetchSingleorder(match.params.id);
      setState(state => ({
        ...state,
        order,
      }));
    }
  }, [match.params.id, order]);

  useEffect(() => {
    if (match) {
      setState(state => ({
        ...state,
        orderId: match.params.id,
      }));
    }
    if (order) {
      const orderToEdit = order[0];
      if (orderToEdit !== null && orderToEdit !== undefined) {
        setState(state => ({
          ...state,
          orderToEdit,
        }));
      }
    } else {
      onFetchSingleorder(match.params.id);
    }
    // }, [state.order])
  }, [order]);

  const isValidationPassed = () => {
    // TODO uruchamianie walidacji przy załadowaniu inputów
    const { isError } = { ...state };
    const isErrorLeft = Object.values(isError).reduce((a, b) => {
      return a === b ? a : NaN;
    });

    if (isErrorLeft === false) {
      setValidationPassed(true);
    } else {
      setValidationPassed(false);
    }
  };

  useEffect(() => {
    isValidationPassed();
  }, [state.isError]);

  const selectValid = key => {
    if (key !== 'selectedOrdersCategory') {
      const curentSelect = state[key];
      const { isError } = { ...state };
      const currentState = isError;
      if (curentSelect !== null) {
        if (curentSelect.value.length === 0) {
          currentState[key] = true;
        } else {
          currentState[key] = false;
        }
        setState({
          ...state,
          isError: currentState,
        });
      } else if (curentSelect === null) {
        currentState[key] = true;
        setState({
          ...state,
          isError: currentState,
        });
      }
    }
  };

  const handleChangeSelect = (inputValue, key) => {
    setState({
      ...state,
      [key]: inputValue,
      lastEdited: key,
    });
  };

  const handleChangeInput = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setState(initialState);
  };

  const getCurrentDate = data => {
    const newDate = new Date(data);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const hour = newDate.getHours();
    const min = newDate.getMinutes();
    const sec = newDate.getSeconds();
    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`
      } ${hour < 10 ? `0${hour}` : `${hour}`}:${min < 10 ? `0${min}` : `${min}`}:${sec < 10 ? `0${sec}` : `${sec}`}`;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const bodyFormData = new window.FormData();
    bodyFormData.append('type', state.orderType.name);
    bodyFormData.append('order_status', state.orderStatus.name);
    bodyFormData.append('items_total', state.itemsTotal);
    bodyFormData.append('payment_method_id', state.paymentMethod.id);
    bodyFormData.append('payment_method_name', state.paymentMethod.name);
    bodyFormData.append('payment_status', state.paymentStatus.name);
    // bodyFormData.append('shipment_total', state.orderToEdit.shipment_total);
    // bodyFormData.append('currency', state.currency.code);
    bodyFormData.append('notes', state.notes);
    // bodyFormData.append('product', state.products);
    if (state.paymentStatus.name === 'PAID') {
      bodyFormData.append('payment_date', getCurrentDate(state.paymentDate));
    }
    bodyFormData.append('shipment_method_id', state.shipmentsMethod.id);
    bodyFormData.append('shipment_method_name', state.shipmentsMethod.name);
    bodyFormData.append('shipment_status', state.shipmentStatus.name);

    if (state.shipmentStatus.name === 'SHIPED') {
      bodyFormData.append('shipment_date', getCurrentDate(state.shipmentDate));
    }
    bodyFormData.append('return_status', state.returnStatus.name);
    if (state.returnStatus.name === 'RETURNDED') {
      bodyFormData.append('return_date', getCurrentDate(state.returnDate));
    }
    bodyFormData.append('_method', 'PUT');
    const endpoint = `${URLAPI}orders/${match.params.id}/edit`;
    onSubmitOrdersForm(bodyFormData, endpoint, history);
  };
  useEffect(() => {
    if (status === 200 || status === 201) {
      resetForm();
      history.push('/orders');
    }
  }, [status]);

  const onCloseHandler = () => {
    resetForm();
    history.goBack();
  };

  // set intilal values
  useEffect(() => {
    setState(state => ({
      ...state,
      paymentMethod: {
        id: state.orderToEdit?.payment_method_id,
        name: state.orderToEdit?.payment_method_name,
      },
      shipmentsMethod: {
        id: state.orderToEdit?.shipment_method_id,
        name: state.orderToEdit?.shipment_method_name,
      },
      notes: state.orderToEdit?.notes,
      orderType: { name: state.orderToEdit?.type },
      orderStatus: { name: state.orderToEdit?.order_status },
      itemsTotal: state.orderToEdit?.items_total,
      currency: { code: state.orderToEdit?.currency },
      paymentStatus: { name: state.orderToEdit?.payment_status },
      paymentDate: new Date(state.orderToEdit?.payment_date),
      shipmentStatus: { name: state.orderToEdit?.shipment_status },
      shipmentDate: new Date(state.orderToEdit?.shipment_date),
      returnStatus: { name: state.orderToEdit?.return_status },
      returnDate: new Date(state.orderToEdit?.return_date || new Date()),
    }));
  }, [order]);

  // TODO przy zianie płatności selektem nie podaje poprawnej daty
  // TODO nie działa zmiana daty platnosci wywala 'access denied' 
  // TODO nie wyswietla ip kklienta

  const OrdersTypes = [
    { id: 1, name: 'internetowe', disabled: 0 },
    { id: 2, name: 'telefoniczne', disabled: 1 },
  ];
  const orderStatus = [
    { id: 1, name: 'CANCELLED', disabled: 0 },
    { id: 2, name: 'NEW', disabled: 0 },
  ];

  const paymentStatus = [
    { id: 1, name: 'PAID', disabled: 0 },
    { id: 2, name: 'NOT_PAID', disabled: 0 },
  ];

  const shipmentStatus = [
    { id: 1, name: 'SHIPED', disabled: 0 },
    { id: 2, name: 'NOT_SHIPED', disabled: 0 },
  ];
  const returnStatus = [
    { id: 1, name: 'RETURNED', disabled: 0 },
    { id: 2, name: 'NOT_RETURNED', disabled: 0 },
  ];

  if (state.orderToEdit === null) {
    return <Loading />;
  }

  return (
    <>
      <ModalWrapper>
        <BtnHolder>
          <CloseButton handleClick={onCloseHandler} />
        </BtnHolder>
        <div className={classes.paper}>
          <FormControl>
            <Typography variant="h5" color="primary">
              Edytuj zamówienie
            </Typography>
          </FormControl>
          <StyledForm onSubmit={e => handleSubmit(e)}>
            <TabsWrapper>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChangeTab}
                aria-label="Vertical tabs example"
                className={classes.tabs}
              >
                <Tab label="Zamówienie" {...a11yProps(0)} />
                <Tab label="Płatności" {...a11yProps(1)} />
                <Tab label="Dostawa" {...a11yProps(2)} />
                <Tab label="Zwroty" {...a11yProps(3)} />
                <Tab label="Klient" {...a11yProps(4)} />
              </Tabs>
              <FormWrapper>
                <TabPanel value={value} index={0}>
                  <RowWrapper>
                    <CellWrapper widthPosps="29%">
                      <FormControl>
                        <DataTtile>
                          Id zamówienia: <Data>{state.orderToEdit.id}</Data>
                        </DataTtile>
                      </FormControl>
                      <FormControl>
                        <DataTtile>
                          Nr zamówienia: <Data>{state.orderToEdit.number}</Data>
                        </DataTtile>
                      </FormControl>
                      <FormControl>
                        <DataTtile>
                          Klient:&nbsp;
                          <Data>
                            {state.orderToEdit.user.first_name} {state.orderToEdit.user.last_name}
                          </Data>
                        </DataTtile>
                      </FormControl>
                      <FormControl>
                        <SelectInput
                          val={state.orderType}
                          options={OrdersTypes}
                          disabledOption={option => option.disabled === 1}
                          optionType="name"
                          noOptionsText="Brak szukanej frazy"
                          label="Zmień typ zamówienia"
                          handleOnChange={handleChangeSelect}
                          onChangeParams={['orderType']}
                          name="orderType"
                          id="orderType"
                          selectValid={selectValid}
                          isError={state.isError.userData}
                          errorMessage="Pole wymagane"
                        />
                      </FormControl>
                      <FormControl>
                        <SelectInput
                          val={state.orderStatus}
                          options={orderStatus}
                          disabledOption={option => option.disabled === 1}
                          optionType="name"
                          noOptionsText="Brak szukanej frazy"
                          label="Zmień status zamówienia"
                          handleOnChange={handleChangeSelect}
                          onChangeParams={['orderStatus']}
                          name="orderStatus"
                          id="orderStatus"
                          selectValid={selectValid}
                          isError={state.isError.userData}
                          errorMessage="Pole wymagane"
                        />
                      </FormControl>

                      <FormControl>
                        <DataTtile>
                          Data zamówienia: <Data>{state.orderToEdit.date_orde}</Data>
                        </DataTtile>
                      </FormControl>
                      <FormControl>
                        <DataTtile>
                          Koszt:{' '}
                          <Data>
                            {state.orderToEdit.total} {state.orderToEdit.currency}
                          </Data>
                        </DataTtile>
                      </FormControl>
                      <FormControl>
                        <DataTtile>
                          Koszt produktów:{' '}
                          <Data>
                            {state.orderToEdit.items_total} {state.orderToEdit.currency}
                          </Data>
                        </DataTtile>
                      </FormControl>
                      <FormControl>
                        <DataTtile>
                          Koszt dostawy:{' '}
                          <Data>
                            {state.orderToEdit.shipment_total} {state.orderToEdit.currency}
                          </Data>
                        </DataTtile>
                      </FormControl>
                    </CellWrapper>
                    <CellWrapper widthPosps="69%">
                      <FormControl>
                        <EditOrdersProductsTable
                          productsList={state.orderToEdit.products}
                          currency={state.orderToEdit.currency}
                          fullPrice={state.orderToEdit.total}
                          itemsTotal={state.itemsTotal}
                        />
                      </FormControl>

                      <FormControl>
                        <Input
                          id="notesMain"
                          onChange={handleChangeInput}
                          value={state.notes}
                          // htmlFor={name}
                          label="Notatka"
                          name="notes"
                          type="text"
                          multiline
                          inputProps={{ rows: 5 }}
                          validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                        />
                      </FormControl>
                    </CellWrapper>
                  </RowWrapper>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <FormControl>
                    <SelectInput
                      val={state.paymentMethod}
                      options={paymentMethod}
                      optionType="name"
                      noOptionsText="Brak szukanej frazy"
                      label="Zmień metodę płatności"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['paymentMethod']}
                      name="paymentMethod"
                      id="paymentMethod"
                      selectValid={selectValid}
                      isError={state.isError.paymentMethod}
                      errorMessage="Pole wymagane"
                    />
                  </FormControl>
                  <FormControl>
                    <SelectInput
                      val={state.paymentStatus}
                      options={paymentStatus}
                      optionType="name"
                      noOptionsText="Brak szukanej frazy"
                      label="Zmień status płatności"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['paymentStatus']}
                      name="paymentStatus"
                      id="paymentStatus"
                      selectValid={selectValid}
                      isError={state.isError.paymentStatus}
                      errorMessage="Pole wymagane"
                    />
                  </FormControl>
                  <FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                      <KeyboardDateTimePicker
                        variant="inline"
                        autoOk
                        ampm={false}
                        // disableFuture
                        value={state.paymentDate}
                        onChange={handlePaymentDateChange}
                        label="Data płatności"
                        showTodayButton
                        format="yyyy MM dd HH:mm"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <FormControl>
                    <SelectInput
                      val={state.shipmentsMethod}
                      options={shipmentsMethod}
                      optionType="name"
                      noOptionsText="Brak szukanej frazy"
                      label="Zmień metodę dostawy"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['shipmentsMethod']}
                      name="shipmentsMethod"
                      id="shipmentsMethod"
                      selectValid={selectValid}
                      isError={state.isError.shipmentsMethod}
                      errorMessage="Pole wymagane"
                    />
                  </FormControl>

                  <FormControl>
                    <SelectInput
                      val={state.shipmentStatus}
                      options={shipmentStatus}
                      optionType="name"
                      noOptionsText="Brak szukanej frazy"
                      label="Zmień status dostawy"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['shipmentStatus']}
                      name="shipmentStatus"
                      id="shipmentStatus"
                      selectValid={selectValid}
                      isError={state.isError.shipmentStatus}
                      errorMessage="Pole wymagane"
                    />
                  </FormControl>
                  <FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                      <KeyboardDateTimePicker
                        variant="inline"
                        autoOk
                        ampm={false}
                        // disableFuture
                        value={state.shipmentDate}
                        onChange={handleShipmentDateChange}
                        label="Zmień date dostawy"
                        showTodayButton
                        format="yyyy MM dd HH:mm"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <FormControl>
                    <SelectInput
                      val={state.returnStatus}
                      options={returnStatus}
                      optionType="name"
                      noOptionsText="Brak szukanej frazy"
                      label="Zmień status dostawy"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['returnStatus']}
                      name="returnStatus"
                      id="returnStatus"
                      selectValid={selectValid}
                      isError={state.isError.returnStatus}
                      errorMessage="Pole wymagane"
                    />
                  </FormControl>
                  <FormControl>
                    {state.returnStatus.name === 'RETURNED' && (
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                        <KeyboardDateTimePicker
                          variant="inline"
                          autoOk
                          ampm={false}
                          // disableFuture
                          value={state.returnDate}
                          onChange={handleReturnDateChange}
                          label="Zmień date dostawy"
                          showTodayButton
                          format="yyyy MM dd HH:mm"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  </FormControl>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <FormControl>
                    <DataTtile>
                      Id klienta: <Data>{state.orderToEdit.user.id}</Data>
                    </DataTtile>
                  </FormControl>
                  <FormControl>
                    <DataTtile>
                      Klient:{' '}
                      <Data>
                        {state.orderToEdit.user.first_name} {state.orderToEdit.user.last_name}
                      </Data>
                    </DataTtile>
                  </FormControl>
                  <FormControl>
                    <DataTtile>
                      E-mail: <Data>{state.orderToEdit.user.email}</Data>
                    </DataTtile>
                  </FormControl>
                  <FormControl>
                    <DataTtile>
                      Nr telefonu: <Data>{state.orderToEdit.user.phone_number}</Data>
                    </DataTtile>
                  </FormControl>
                  <FormControl>
                    <DataTtile>
                      Kraj: <Data>{state.orderToEdit.user.country}</Data>
                    </DataTtile>
                  </FormControl>
                  <FormControl>
                    <DataTtile>
                      Adres:&nbsp;
                      <Data>
                        {state.orderToEdit.user.street} {state.orderToEdit.user.house_number}, {state.orderToEdit.user.post_code}{' '}
                        {state.orderToEdit.user.city}
                      </Data>
                    </DataTtile>
                  </FormControl>
                  <FormControl>
                    <DataTtile>
                      Ip klienta: <Data>{state.orderToEdit.user.user_ip}</Data>
                    </DataTtile>
                  </FormControl>
                  <FormControl>
                    <Input
                      id="notes"
                      onChange={handleChangeInput}
                      value={state.notes}
                      label="Notatka"
                      name="notes"
                      type="text"
                      multiline
                      inputProps={{ rows: 5 }}
                      validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                    />
                  </FormControl>
                </TabPanel>
              </FormWrapper>
            </TabsWrapper>
            <SubmitBtnWrapper>
              <ButtonPrimary validationPassed={validationPassed} type="submit">
                Zapisz Zmany
              </ButtonPrimary>
            </SubmitBtnWrapper>
          </StyledForm>
        </div>
      </ModalWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    order: state.orders.orderToEdit,
    paymentMethod: state.paymentMethods.paymentMethods,
    shipmentsMethod: state.shipmentsMethod.shipmentsMethod,
    status: state.notifications.status,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchSingleorder: id => dispatch(actionCreators.fetchSingleorder(id)),
    onSubmitOrdersForm: (bodyFormData, endpoint, history) =>
      dispatch(actionCreators.submitOrdersForm(bodyFormData, endpoint, history)),
    onFetchPaymentMethod: () => dispatch(actionCreators.fetchPaymentMethod()),
    onFetchShipmentsMethod: () => dispatch(actionCreators.fetchShipmentsMethod()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder);
