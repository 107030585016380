/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions/index';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Input from 'components/atoms/Inputs/Input';
import Button from '@material-ui/core/Button';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import IconButton from '@material-ui/core/IconButton';
import SelectInput from 'components/atoms/Inputs/SelectInput';
import InListButton from 'components/atoms/Buttons/InListButton';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import avatar from 'assets/no-photo.png';
import EditorTxtConfig from 'components/EditorTxtConfig/EditorTxtConfig';
import AddIngredients from 'views/Ingredients/AddIngredients/AddIngredients';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import CurrentMeal from '../components/CurrentMeal/CurrentMeal';

const URLAPI = process.env.REACT_APP_URL_API;
const URLBASE = process.env.REACT_APP_URL_BASE;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      // role="tabpanel"
      hidden={value !== index}
      id={`ig-name-tabpanel-${index}`}
      aria-labelledby={`ig-name-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `ig-name-tab-${index}`,
    'aria-controls': `ig-name-tabpanel-${index}`,
  };
}

const CKEWrapper = styled.div`
  transition: height 1s ease;
  max-height: ${({ isCKEActive }) => (isCKEActive ? 'auto' : '150px')};
  overflow-y: auto;
`;

const CollapseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const ModalWrapper = styled.div`
  margin: 16px auto;
  position: relative;

  width: 100%;
  @media screen and (min-width: 992px) {
    width: 80%;
  }
`;

const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const ImgWrapper = styled.div`
  width: 50%;
`;
const MealImage = styled.img`
  width: 72px;
  height: auto;
  margin: 12px;
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      outline: 'none',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
  },
  button: {
    width: '100%',
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const FormControl = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: ${({ shorter }) => (shorter ? '50%' : '100%')};
  ${({ border }) =>
    border &&
    css`
      border: 1px solid #c4c4c4;
      border-radius: 0 0 4px 4px;
      border-top: 0;
      margin-bottom: 20px;
    `} 
    ${({ noMarginB }) =>
      noMarginB &&
      css`
        margin-bottom: 0;
      `}
      ${({ file }) =>
        file &&
        css`
          width: 210px;
        `}
  ${({ imgBox }) =>
    imgBox &&
    css`
      width: 210px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `}

`;
const SelectWrapper = styled.div`
  width: 50%;
  position: relative;
  margin-bottom: 20px;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
const Units = styled.div`
  padding-left: 8px;
  width: 100%;
  text-transform: uppercase;
`;

const ErrorMsg = styled.div`
  margin-bottom: 8px;
  /* margin-left: 14px; */
`;

const SubmitBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const initialState = {
  mealId: '',
  selectedMealsCategory: { value: '', label: '' },
  selectedMealsCategoryDiets: { value: '', label: '' },
  NameOfMeal: '',
  NameOfMeal_en: '',
  NameOfMeal_cz: '',
  NameOfMeal_es: '',
  NameOfMeal_hu: '',
  NameOfMeal_ro: '',
  NameOfMeal_pt: '',
  NameOfMeal_it: '',
  NameOfMeal_de: '',
  NameOfMeal_bg: '',
  NameOfMeal_sk: '',
  NameOfMeal_fr: '',
  // kcalMin: '',
  textToMealDescription: ' "<p>Przepis dania......</p>"',
  textToMealDescription_en: ' "<p>Przepis dania EN......</p>"',
  textToMealDescription_cz: ' "<p>Przepis dania CZ......</p>"',
  textToMealDescription_es: ' "<p>Przepis dania ES......</p>"',
  textToMealDescription_hu: ' "<p>Przepis dania HU......</p>"',
  textToMealDescription_ro: ' "<p>Przepis dania RO......</p>"',
  textToMealDescription_pt: ' "<p>Przepis dania PT......</p>"',
  textToMealDescription_it: ' "<p>Przepis dania IT......</p>"',
  textToMealDescription_de: ' "<p>Przepis dania DE......</p>"',
  textToMealDescription_bg: ' "<p>Przepis dania BG......</p>"',
  textToMealDescription_sk: ' "<p>Przepis dania SK......</p>"',
  textToMealDescription_fr: ' "<p>Przepis dania FR......</p>"',
  currentlySelectedIngredient: null,
  amountOfSelectedIngredient: '',
  ingredientsUseInMeal: [],
  file: null,
  avatarImg: true,
  temporaryImage: null,
  oldImage: null,
  newImage: null,
  showAddIngredient: false,
  mealsNames: [],
  meal: '',
  isError: {
    NameOfMeal: null,
    NameOfMeal_en: null,
    NameOfMeal_cz: null,
    NameOfMeal_es: null,
    NameOfMeal_hu: null,
    NameOfMeal_ro: null,
    NameOfMeal_pt: null,
    NameOfMeal_it: null,
    NameOfMeal_de: null,
    NameOfMeal_bg: null,
    NameOfMeal_sk: null,
    NameOfMeal_fr: null,
    // kcalMin: null,
    selectedMealsCategory: null,
    selectedMealsCategoryDiets: null,
  },
  isErrorAddIg: {
    currentlySelectedIngredient: null,
    amountOfSelectedIngredient: null,
  },
  noIngredients: null,
  // validationPassed: false,
  igValidationPassed: false,
  lastEdited: '',
};

const AddMeal = ({
  onGetSingleMealsToEdit,
  onFetchMealsCategory,
  onFetchMealsCategoryDiets,
  onInitAllIngredientsForOptions,
  history,
  mealsCatOptions,
  mealsCategoryDietsOptions,
  optionsName,
  mealToEdit,
  status,
  onSubmitMealsForm,
  closeAddMeal,
  match,
}) => {
  const [validationPassed, setValidationPassed] = useState(false);
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const [isCKEActive, setIsCKEActive] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [duplicatedIngredient, setDuplicatedIngredient] = useState(false);

  useEffect(() => {
    if (mealToEdit === null) {
      onGetSingleMealsToEdit(match.params.id);
    }
  }, []);

  useEffect(() => {
    // if (!mealsCatOptions) {
    onFetchMealsCategory();
    onFetchMealsCategoryDiets();
    // }
  }, []);

  useEffect(() => {
    if (optionsName.length === 0) onInitAllIngredientsForOptions();
  }, []);

  useEffect(() => {
    if (state.meal === '' || state.meal === undefined) {
      onGetSingleMealsToEdit(match.params.id);
      setState(state => ({
        ...state,
        meal: mealToEdit,
      }));
    }
  }, [match, mealToEdit]);

  useEffect(() => {
    if (match) {
      setState(state => ({
        ...state,
        mealId: match.params.id,
      }));
    }
    if (mealToEdit !== null) {
      if (mealToEdit !== null && mealToEdit !== undefined) {
        const ingredientsArray = [];
        mealToEdit.ingredients.map(ig =>
          ingredientsArray.push({ ingredient_id: ig.id, quantity: ig.pivot.quantity }),
        );
        console.log(state);
        setState(state => ({
          ...state,
          NameOfMeal: mealToEdit.name,
          NameOfMeal_en: mealToEdit.name_en,
          NameOfMeal_cz: mealToEdit.name_cz,
          NameOfMeal_es: mealToEdit.name_es,
          NameOfMeal_hu: mealToEdit.name_hu,
          NameOfMeal_ro: mealToEdit.name_ro,
          NameOfMeal_pt: mealToEdit.name_pt,
          NameOfMeal_it: mealToEdit.name_it,
          NameOfMeal_de: mealToEdit.name_de,
          NameOfMeal_bg: mealToEdit.name_bg,
          NameOfMeal_sk: mealToEdit.name_sk,
          NameOfMeal_fr: mealToEdit.name_fr,
          // kcalMin: mealToEdit.kcal_min,
          ingredientsUseInMeal: ingredientsArray,
          textToMealDescription: mealToEdit.description,
          textToMealDescription_en: mealToEdit.description_en,
          textToMealDescription_cz: mealToEdit.description_cz,
          textToMealDescription_es: mealToEdit.description_es,
          textToMealDescription_hu: mealToEdit.description_hu,
          textToMealDescription_ro: mealToEdit.description_ro,
          textToMealDescription_pt: mealToEdit.description_pt,
          textToMealDescription_it: mealToEdit.description_it,
          textToMealDescription_de: mealToEdit.description_de,
          textToMealDescription_sk: mealToEdit.description_sk,
          textToMealDescription_bg: mealToEdit.description_bg,
          textToMealDescription_fr: mealToEdit.description_fr,
          selectedMealsCategory:
            mealToEdit.category_meals.length === 0
              ? { value: '', label: '' }
              : {
                  value: mealToEdit.category_meals[0].id,
                  label: mealToEdit.category_meals[0].name,
                },
          selectedMealsCategoryDiets:
            mealToEdit.category_diets.length === 0
              ? { value: '', label: '' }
              : {
                  value: mealToEdit.category_diets[0].id,
                  label: mealToEdit.category_diets[0].name,
                },
          temporaryImage: mealToEdit.image,
          oldImage: mealToEdit.image,
        }));
      }
    } else {
      onGetSingleMealsToEdit(match.params.id);
    }
    // }, [state.meal])
  }, [mealToEdit]);

  const isValidationPassed = () => {
    // TODO uruchamianie walidacji przy załadowaniu inputów
    const { isError } = { ...state };
    const isErrorLeft = Object.values(isError).reduce((a, b) => {
      return a === b ? a : NaN;
    });

    if (isErrorLeft === false) {
      setValidationPassed(true);
    } else {
      setValidationPassed(false);
    }
  };

  useEffect(() => {
    isValidationPassed();
  }, [state.isError]);

  const selectValid = key => {
    if (key !== 'selectedMealsCategory') {
      const curentSelect = state[key];
      const { isError } = { ...state };
      const currentState = isError;
      if (curentSelect !== null) {
        if (curentSelect.value.length === 0) {
          currentState[key] = true;
        } else {
          currentState[key] = false;
        }
        setState({
          ...state,
          isError: currentState,
        });
      } else if (curentSelect === null) {
        currentState[key] = true;
        setState({
          ...state,
          isError: currentState,
        });
      }
    }
  };

  const selectIgValid = key => {
    const curentSelect = state[key];
    const { isErrorAddIg } = { ...state };
    const currentState = isErrorAddIg;
    if (curentSelect !== null) {
      if (curentSelect.value.length === 0) {
        currentState[key] = true;
      } else {
        currentState[key] = false;
      }
      setState({
        ...state,
        isErrorAddIg: currentState,
      });
    } else if (curentSelect === null) {
      currentState[key] = true;
      setState({
        ...state,
        isErrorAddIg: currentState,
      });
    }
  };

  const handleChangeSelect = (inputValue, key) => {
    setState({
      ...state,
      [key]: inputValue,
      lastEdited: key,
    });
  };
  useEffect(() => {
    if (state.lastEdited !== '') {
      if (state.lastEdited !== 'currentlySelectedIngredient') {
        selectValid(state.lastEdited);
      }
      if (state.lastEdited === 'currentlySelectedIngredient') {
        selectIgValid(state.lastEdited);
      }
    }
  }, [state.lastEdited, state.isError, state.isErrorAddIg]);

  // add amountOfSelectedIngredient Input Validation
  useEffect(() => {
    const { isErrorAddIg } = { ...state };
    const currentState = isErrorAddIg;
    if (state.amountOfSelectedIngredient === '' || state.amountOfSelectedIngredient === null) {
      currentState.amountOfSelectedIngredient = true;
    } else {
      currentState.amountOfSelectedIngredient = false;
    }
    setState({
      ...state,
      isErrorAddIg: currentState,
    });
  }, [state.amountOfSelectedIngredient]);

  const handleChangeInput = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const resetInputsAboutIngredients = () => {
    setState({
      ...state,
      amountOfSelectedIngredient: '',
      currentlySelectedIngredient: null,
    });
  };

  const validIngredientsInUse = () => {
    if (state.ingredientsUseInMeal.length === 0) {
      setState({
        ...state,
        noIngredients: true,
      });
    } else {
      setState({
        ...state,
        noIngredients: false,
      });
    }
  };

  const addNewIngredientToList = () => {
    if (state.igValidationPassed) {
      if (
        state.ingredientsUseInMeal.filter(
          item => item.ingredient_id === state.currentlySelectedIngredient.value,
        ).length > 0
      ) {
        setDuplicatedIngredient(true);
      } else {
        setDuplicatedIngredient(false);
        let currentIngredientsUseInMeal = state.ingredientsUseInMeal;
        const newIngredientAndQtyUseInMeal = {
          ingredient_id: state.currentlySelectedIngredient.value,
          quantity: state.amountOfSelectedIngredient,
        };

        currentIngredientsUseInMeal = currentIngredientsUseInMeal.push(
          newIngredientAndQtyUseInMeal,
        );

        setState(() => ({
          ...state,
          ingredientsUseInMeal: currentIngredientsUseInMeal,
          isErrorAddIg: {
            currentlySelectedIngredient: null,
            amountOfSelectedIngredient: null,
          },
          igValidationPassed: false,
        }));
        resetInputsAboutIngredients();
      }
    }
  };
  useEffect(() => {
    validIngredientsInUse();
  }, [state.ingredientsUseInMeal]);

  const deleteIngredientFromList = e => {
    const { ingredientsUseInMeal } = state;

    const ingredientsUseInMealAfterDeleteItem = ingredientsUseInMeal.filter(
      (singleIngredientUseInMeal, index) => {
        return index !== e;
      },
    );
    setState({
      ...state,
      ingredientsUseInMeal: ingredientsUseInMealAfterDeleteItem,
    });
  };

  const handleChangeFile = e => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const dataURL = reader.result;

      setState(state => ({
        ...state,
        temporaryImage: dataURL,
      }));
    };

    setState(state => ({
      ...state,
      file: e.target.files[0],
      avatarImg: false,
    }));
  };

  const resetForm = () => {
    setState(initialState);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (state.ingredientsUseInMeal.length === 0) {
      setState(state => ({
        ...state,
        noIngredients: true,
      }));
    } else {
      setState(state => ({
        ...state,
        noIngredients: false,
      }));

      const bodyFormData = new window.FormData();
      bodyFormData.set('name', state.NameOfMeal);
      bodyFormData.set('name_en', state.NameOfMeal_en);
      bodyFormData.set('name_cz', state.NameOfMeal_cz);
      bodyFormData.set('name_es', state.NameOfMeal_es);
      bodyFormData.set('name_hu', state.NameOfMeal_hu);
      bodyFormData.set('name_ro', state.NameOfMeal_ro);
      bodyFormData.set('name_pt', state.NameOfMeal_pt);
      bodyFormData.set('name_it', state.NameOfMeal_it);
      bodyFormData.set('name_de', state.NameOfMeal_de);
      bodyFormData.set('name_bg', state.NameOfMeal_bg);
      bodyFormData.set('name_sk', state.NameOfMeal_sk);
      bodyFormData.set('name_fr', state.NameOfMeal_fr);
      // bodyFormData.set('kcal_min', state.kcalMin);
      bodyFormData.set('description', state.textToMealDescription);
      bodyFormData.set('description_en', state.textToMealDescription_en);
      bodyFormData.set('description_cz', state.textToMealDescription_cz);
      bodyFormData.set('description_es', state.textToMealDescription_es);
      bodyFormData.set('description_hu', state.textToMealDescription_hu);
      bodyFormData.set('description_ro', state.textToMealDescription_ro);
      bodyFormData.set('description_pt', state.textToMealDescription_pt);
      bodyFormData.set('description_it', state.textToMealDescription_it);
      bodyFormData.set('description_de', state.textToMealDescription_de);
      bodyFormData.set('description_bg', state.textToMealDescription_bg);
      bodyFormData.set('description_sk', state.textToMealDescription_sk);
      bodyFormData.set('description_fr', state.textToMealDescription_fr);
      bodyFormData.set('category_meal_id', state.selectedMealsCategory.value);
      bodyFormData.set('category_diet_id', state.selectedMealsCategoryDiets.value);
      bodyFormData.set('ingredients', JSON.stringify(state.ingredientsUseInMeal));
      bodyFormData.set('_method', 'PUT');

      if (state.file !== null) {
        bodyFormData.append('image', state.file);
      }
      const endpoint = `${URLAPI}meals/${state.mealId}`;
      onSubmitMealsForm(history, bodyFormData, endpoint, resetForm);
      if (status === 200 || status === 201) {
        resetForm();
        closeAddMeal('showAddMeals');
      }
    }
  };

  const handleShowIngredients = () => {
    const { showAddIngredient } = state;
    if (showAddIngredient) {
      setState(state => ({
        ...state,
        showAddIngredient: false,
      }));
    } else {
      setState(state => ({
        ...state,
        showAddIngredient: true,
      }));
    }
  };

  const closeAddIngredients = () => {
    setState(state => ({
      ...state,
      showAddIngredient: false,
    }));
  };

  const inputValidation = (error, name) => {
    const { isError } = { ...state };
    const currentState = isError;
    if (error) {
      currentState[name] = error.error;
    } else {
      currentState[name] = error;
    }
    setState({
      ...state,
      isError: currentState,
    });
  };

  const isIgValidationPassed = () => {
    const { isErrorAddIg } = { ...state };
    const isErrorAddIgLeft = Object.values(isErrorAddIg).reduce((a, b) => {
      return a === b;
    });
    if (isErrorAddIgLeft === true && state.currentlySelectedIngredient !== null) {
      setState({
        ...state,
        igValidationPassed: true,
      });
    } else {
      setState({
        ...state,
        igValidationPassed: false,
      });
    }
  };

  useEffect(() => {
    isIgValidationPassed();
  }, [
    state.amountOfSelectedIngredient,
    state.currentlySelectedIngredient,
    state.ingredientsUseInMeal,
  ]);

  const removeImg = () => {
    setState({
      ...state,
      temporaryImage: null,
      oldImage: null,
      file: null,
    });
  };

  const onCloseHandler = () => {
    resetForm();
    history.goBack();
  };

  const createMarkup = text => {
    return { __html: text };
  };

  const handleOpenCKEditor = () => {
    setIsCKEActive(!isCKEActive);
  };

  const currentPhoto = () => {
    let image = '';
    if (state.file !== null) {
      image = <MealImage src={URL.createObjectURL(state.file)} alt="product" />;
    } else if (state.oldImage !== null) {
      image = <MealImage src={`${URLBASE}storage/${state.oldImage}`} alt="old" />;
    } else {
      image = <MealImage src={avatar} alt="avatar" />;
    }
    return image;
  };

  const currentMealLangComponent = () => {
    let currentMealComponent = '';
    if (tabValue === 0) {
      currentMealComponent = (
        <CurrentMeal
          lang="PL"
          NameOfMeal={state.NameOfMeal}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 1) {
      currentMealComponent = (
        <CurrentMeal
          lang="EN"
          NameOfMeal={state.NameOfMeal_en}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_en}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 2) {
      currentMealComponent = (
        <CurrentMeal
          lang="CZ"
          NameOfMeal={state.NameOfMeal_cz}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_cz}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 3) {
      currentMealComponent = (
        <CurrentMeal
          lang="ES"
          NameOfMeal={state.NameOfMeal_es}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_es}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 4) {
      currentMealComponent = (
        <CurrentMeal
          lang="HU"
          NameOfMeal={state.NameOfMeal_hu}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_hu}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 5) {
      currentMealComponent = (
        <CurrentMeal
          lang="RO"
          NameOfMeal={state.NameOfMeal_ro}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_ro}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 6) {
      currentMealComponent = (
        <CurrentMeal
          lang="PT"
          NameOfMeal={state.NameOfMeal_pt}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_pt}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 7) {
      currentMealComponent = (
        <CurrentMeal
          lang="IT"
          NameOfMeal={state.NameOfMeal_it}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_it}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 8) {
      currentMealComponent = (
        <CurrentMeal
          lang="DE"
          NameOfMeal={state.NameOfMeal_de}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_de}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 9) {
      currentMealComponent = (
        <CurrentMeal
          lang="BG"
          NameOfMeal={state.NameOfMeal_bg}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_bg}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 10) {
      currentMealComponent = (
        <CurrentMeal
          lang="SK"
          NameOfMeal={state.NameOfMeal_sk}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_sk}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    } else if (tabValue === 11) {
      currentMealComponent = (
        <CurrentMeal
          lang="FR"
          NameOfMeal={state.NameOfMeal_fr}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription_fr}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    
    } else {
      currentMealComponent = (
        <CurrentMeal
          lang="PL"
          NameOfMeal={state.NameOfMeal}
          // kcalMin={state.kcalMin}
          createMarkup={createMarkup}
          description={state.textToMealDescription}
          selectedMealsCategory={state.selectedMealsCategory}
          noIngredients={state.noIngredients}
          optionsName={optionsName}
          ingredientsInMeal={state.ingredientsUseInMeal}
          deleteIngredientFromList={deleteIngredientFromList}
          runValidation={isValidationPassed}
        />
      );
    }

    return currentMealComponent;
  };

  return (
    <>
      <ModalWrapper>
        <BtnHolder>
          <CloseButton handleClick={onCloseHandler} />
        </BtnHolder>
        <div className={classes.paper}>
          <FormControl>
            <Typography variant="h5" color="primary">
              Edytuj posiłek
            </Typography>
          </FormControl>
          <form onSubmit={e => handleSubmit(e)}>
            <FormControl>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="simple tabs example"
                variant="scrollable"
              >
                <Tab label="PL" {...a11yProps(0)} />
                <Tab label="EN" {...a11yProps(1)} />
                <Tab label="CZ" {...a11yProps(2)} />
                <Tab label="ES" {...a11yProps(3)} />
                <Tab label="HU" {...a11yProps(4)} />
                <Tab label="RO" {...a11yProps(5)} />
                <Tab label="PT" {...a11yProps(6)} />
                <Tab label="IT" {...a11yProps(7)} />
                <Tab label="DE" {...a11yProps(8)} />
                <Tab label="BG" {...a11yProps(9)} />
                <Tab label="SK" {...a11yProps(10)} />
                <Tab label="FR" {...a11yProps(11)} />
              </Tabs>

              <TabPanel value={tabValue} index={0}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal}
                  id="name"
                  onChange={handleChangeInput}
                  htmlFor="name"
                  label="Nazwa - PL"
                  name="NameOfMeal"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>

                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_en}
                  id="name_en"
                  onChange={handleChangeInput}
                  htmlFor="name_en"
                  label="Nazwa - EN"
                  name="NameOfMeal_en"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_en"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_en}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_en: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_cz}
                  id="name_cz"
                  onChange={handleChangeInput}
                  htmlFor="name_cz"
                  label="Nazwa - CZ"
                  name="NameOfMeal_cz"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu CZ:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_en"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_cz}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_cz: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={3}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_es}
                  id="name_es"
                  onChange={handleChangeInput}
                  htmlFor="name_es"
                  label="Nazwa - ES"
                  name="NameOfMeal_es"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu ES:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_en"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_es}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_es: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={4}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_hu}
                  id="name_hu"
                  onChange={handleChangeInput}
                  htmlFor="name_hu"
                  label="Nazwa - HU"
                  name="NameOfMeal_hu"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu HU:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_hu"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_hu}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_hu: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={5}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_ro}
                  id="name_ro"
                  onChange={handleChangeInput}
                  htmlFor="name_ro"
                  label="Nazwa - RO"
                  name="NameOfMeal_ro"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu RO:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_ro"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_ro}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_ro: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={6}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_pt}
                  id="name_pt"
                  onChange={handleChangeInput}
                  htmlFor="name_pt"
                  label="Nazwa - PT"
                  name="NameOfMeal_pt"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu PT:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_pt"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_pt}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_pt: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={7}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_it}
                  id="name_it"
                  onChange={handleChangeInput}
                  htmlFor="name_it"
                  label="Nazwa - IT"
                  name="NameOfMeal_it"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu IT:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_it"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_it}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_it: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={8}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_de}
                  id="name_de"
                  onChange={handleChangeInput}
                  htmlFor="name_de"
                  label="Nazwa - DE"
                  name="NameOfMeal_de"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu DE:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_de"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_de}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_de: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={9}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_bg}
                  id="name_bg"
                  onChange={handleChangeInput}
                  htmlFor="name_bg"
                  label="Nazwa - BG"
                  name="NameOfMeal_bg"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu BG:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_bg"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_bg}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_bg: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={10}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_sk}
                  id="name_sk"
                  onChange={handleChangeInput}
                  htmlFor="name_sk"
                  label="Nazwa - SK"
                  name="NameOfMeal_sk"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu SK:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_sk"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_sk}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_sk: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>
              
              <TabPanel value={tabValue} index={11}>
                <Input
                  isError={state.isError}
                  value={state.NameOfMeal_fr}
                  id="name_fr"
                  onChange={handleChangeInput}
                  htmlFor="name_fr"
                  label="Nazwa - FR"
                  name="NameOfMeal_fr"
                  type="text"
                  inputValidation={inputValidation}
                  handleOnBlur={isValidationPassed}
                  validators={[{ check: Validators.required, message: 'pole wymagane' }]}
                />

                <CollapseWrapper>
                  <h4>Opis produktu FR:</h4>
                  <IconButton onClick={() => handleOpenCKEditor()}>
                    {isCKEActive ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CollapseWrapper>
                <CKEWrapper isCKEActive={isCKEActive}>
                  <CKEditor
                    id="description_fr"
                    config={EditorTxtConfig}
                    editor={ClassicEditor}
                    data={state.textToMealDescription_fr}
                    configtoolbar={null}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      // eslint-disable-next-line no-console
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // eslint-disable-next-line no-console
                      // console.log({ event, editor, data });

                      setState(state => ({
                        ...state,
                        textToMealDescription_fr: data,
                      }));
                    }}
                    onBlur={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // eslint-disable-next-line no-console
                      // console.log('Focus.', editor);
                    }}
                  />
                </CKEWrapper>
              </TabPanel>
            </FormControl>
            <ImgWrapper>
              <FormControl noMarginB file>
                <label styles={{ width: '100%' }} htmlFor="mealFile">
                  <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="mealFile"
                    multiple
                    type="file"
                    onChange={event => handleChangeFile(event)}
                  />
                  <Button variant="outlined" component="span" className={classes.button} full-width>
                    Dodaj zdjęcie
                  </Button>
                </label>
              </FormControl>
              <FormControl file imgBox border>
                {currentPhoto()}
                {state.oldImage || state.temporaryImage !== null ? (
                  <CloseButton handleClick={removeImg} />
                ) : (
                  ''
                )}
              </FormControl>
            </ImgWrapper>
            <FormControl>
              <SelectWrapper>
                <SelectInput
                  val={state.selectedMealsCategory}
                  options={mealsCatOptions}
                  noOptionsText="Nie znaleziono"
                  label="Wybierz kategorię"
                  handleOnChange={handleChangeSelect}
                  onChangeParams={['selectedMealsCategory']}
                  name="selectedMealsCategory"
                  id="selectedMealsCategory"
                  selectValid={selectValid}
                  isError={state.isError.selectedMealsCategory}
                  errorMessage="Pole wymagane"
                />
              </SelectWrapper>
            </FormControl>
            <FormControl>
              <SelectWrapper>
                <SelectInput
                  val={state.selectedMealsCategoryDiets}
                  options={mealsCategoryDietsOptions}
                  noOptionsText="Nie znaleziono"
                  label="Przypisz rodzaj diety"
                  handleOnChange={handleChangeSelect}
                  onChangeParams={['selectedMealsCategoryDiets']}
                  name="selectedMealsCategoryDiets"
                  id="selectedMealsCategoryDiets"
                  selectValid={selectValid}
                  isError={state.isError.selectedMealsCategoryDiets}
                  errorMessage="Pole wymagane"
                />
              </SelectWrapper>
            </FormControl>
            <FormControl>
              <SelectWrapper>
                <SelectInput
                  val={state.currentlySelectedIngredient}
                  options={optionsName}
                  noOptionsText={
                    <InListButton handleOnClick={handleShowIngredients}>
                      Dodaj brakujący składnik
                    </InListButton>
                  }
                  label="Dodaj składniki do swojego dania"
                  handleOnChange={handleChangeSelect}
                  onChangeParams={['currentlySelectedIngredient']}
                  name="currentlySelectedIngredient"
                  id="currentlySelectedIngredient"
                  selectValid={selectIgValid}
                  isError={state.isErrorAddIg.currentlySelectedIngredient}
                  errorMessage="Pole wymagane"
                />
              </SelectWrapper>
            </FormControl>
            <FormControl>
              <FlexBox>
                <Input
                  handleOnBlur={isIgValidationPassed}
                  isError={state.isErrorAddIg.amountOfSelectedIngredient}
                  label="ilość"
                  type="number"
                  id="amountOfSelectedIngredient"
                  value={state.amountOfSelectedIngredient}
                  name="amountOfSelectedIngredient"
                  htmlFor="amountOfSelectedIngredient"
                  onChange={handleChangeInput}
                  validators={[
                    { check: Validators.required, message: 'pole wymagane' },
                    { check: Validators.number, message: 'tylko liczby' },
                  ]}
                />
                <Units>
                  <Typography variant="h6">
                    {state.currentlySelectedIngredient === null
                      ? ''
                      : state.currentlySelectedIngredient.unit}
                  </Typography>
                </Units>
              </FlexBox>
            </FormControl>
            <FormControl>
              {duplicatedIngredient && (
                <ErrorMsg>
                  <Typography fontWeight={800} variant="caption" color="error">
                    Składnik jest już na liście
                  </Typography>
                </ErrorMsg>
              )}

              <ButtonPrimary
                validationPassed={!state.igValidationPassed}
                handleClick={addNewIngredientToList}
              >
                DODAJ SKŁADNIK
              </ButtonPrimary>
            </FormControl>
            {currentMealLangComponent()}
            {/* {tabValue === 0 ? (
              <CurrentMeal
                lang="PL"
                NameOfMeal={state.NameOfMeal}
                // kcalMin={state.kcalMin}
                createMarkup={createMarkup}
                description={state.textToMealDescription}
                selectedMealsCategory={state.selectedMealsCategory}
                noIngredients={state.noIngredients}
                optionsName={optionsName}
                ingredientsInMeal={state.ingredientsUseInMeal}
                deleteIngredientFromList={deleteIngredientFromList}
                runValidation={isValidationPassed}
              />
            ) : {tabValue === 1 } ? (
              <CurrentMeal
                lang="EN"
                NameOfMeal={state.NameOfMeal_en}
                createMarkup={createMarkup}
                description={state.textToMealDescription_en}
                selectedMealsCategory={state.selectedMealsCategory}
                noIngredients={state.noIngredients}
                optionsName={optionsName}
                ingredientsInMeal={state.ingredientsUseInMeal}
                deleteIngredientFromList={deleteIngredientFromList}
                runValidation={isValidationPassed}
              />
            ) : ()} */}
            <FormControl>
              <SubmitBtnWrapper>
                <ButtonPrimary isDisabled={validationPassed} type="submit">
                  zapisz zmiany
                </ButtonPrimary>
              </SubmitBtnWrapper>
            </FormControl>
          </form>
        </div>
      </ModalWrapper>
      <AddIngredients
        open={state.showAddIngredient}
        handleClick={handleShowIngredients}
        closeAddIngredients={closeAddIngredients}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    // mealsCat: state.mealsCat.mealsCategory,
    mealsCatOptions: state.mealsCat.mealsCategoryOptions,
    mealsCategoryDietsOptions: state.mealsCategoryDiets.mealsCategoryDietsOptions,
    optionsName: state.ig.optionsNameForSearch,
    // ig: state.ig.ingredients,
    // msg: state.msg,
    mealToEdit: state.meals.mealToEdit,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSingleMealsToEdit: id => dispatch(actionCreators.getSingleMealsToEdit(id)),
    onFetchMealsCategory: () => dispatch(actionCreators.fetchMealsCategory()),
    onFetchMealsCategoryDiets: () => dispatch(actionCreators.fetchMealsCategoryDiets()),
    onSubmitMealsForm: (history, bodyFormData, endpoint, resetForm) =>
      dispatch(actionCreators.submitMealsForm(history, bodyFormData, endpoint, resetForm)),
    onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMeal);
