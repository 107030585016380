import * as actionTypes from 'store/actions/actionsTypes';

import axios from 'axios';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;
// GET
export const setOrders = orders => {
  return {
    type: actionTypes.SET_ORDERS,
    orders,
  };
};

export const fetchOrdersFailed = error => {
  return {
    type: actionTypes.FETCH_ORDERS_FAILED,
    error,
  };
};

export const initOrders = endpoint => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const orders = res.data.data;

        dispatch(setOrders(orders));
      })
      .catch(error => {
        dispatch(fetchOrdersFailed(error));
      });
  };
};

export const fetchOrders = () => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}orders`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const orders = res.data.data;
        dispatch(setOrders(orders));
      })
      .catch(error => {
        dispatch(fetchOrdersFailed(error));
      });
  };
};

// GET ONE
export const setSingleOrder = order => {
  return {
    type: actionTypes.SET_SINGLE_ORDERS,
    order,
  };
};

export const fetchSingleOrderFailed = error => {
  return {
    type: actionTypes.FETCH_SINGLE_ORDERS_FAILED,
    error,
  };
};

export const fetchSingleorder = (id) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}orders/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const order = res.data.data;
        dispatch(setSingleOrder(order));
      })
      .catch(error => {
        dispatch(fetchSingleOrderFailed(error));
      });
  };
};

// ADD Order

export const submitOrdersFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_ORDERS_FORM_SUCCESS,
    msg: res,
  };
};

export const submitOrdersFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_ORDERS_FORM_FAILED,
    error,
  };
};

export const addOrdersForm = (
  userId,
  notes,
  currency,
  paymentMethodId,
  paymentMethodName,
  shipmentMethodId,
  shipmentMethodName,
  shipmentTotal,
  productsArray,
  resetForm,
) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .post(
        `${URLAPI}orders`,
        {
          user_id: userId,
          notes,
          currency,
          payment_method_id: paymentMethodId,
          payment_method_name: paymentMethodName,
          shipment_method_id: shipmentMethodId,
          shipment_method_name: shipmentMethodName,
          shipment_total: shipmentTotal,
          products: productsArray,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(submitOrdersFormSuccess(res));
        dispatch(fetchOrders());
        resetForm();
      })
      .catch(error => {
        dispatch(submitOrdersFormFailed(error));
      });
  };
};

// EDIT Orders

export const submitOrdersForm = (bodyFormData, endpoint, history) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios({
      method: 'post',
      url: endpoint,
      data: bodyFormData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authKey}`,
      },
    })
      .then(res => {
        dispatch(submitOrdersFormSuccess(res));
        dispatch(fetchOrders());
        if (history !== null) {
          history.push('/orders/');
        }
      })
      .catch(error => {
        dispatch(submitOrdersFormFailed(error));
      });
  };
};

// DELETE

export const deleteOrdersSuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_ORDERS_SUCCESS,
    id,
    msg: res,
  };
};

export const deleteOrdersFailed = error => {
  return {
    type: actionTypes.DELETE_ORDERS_FAILED,
    error,
  };
};

export const setOrdersQueryAfterSearch = query => {
  return {
    type: actionTypes.SET_ORDERS_QUERY_AFTER_SEARCH,
    query,
  };
};


export const deleteOrders = (id, endpointSearch) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .delete(`${URLAPI}orders/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        dispatch(deleteOrdersSuccess(id, res));
        dispatch(setOrdersQueryAfterSearch(endpointSearch));
      })
      .catch(error => {
        dispatch(deleteOrdersFailed(error));
      });
  };
};



// DELETE MANY

export const deleteManyOrdersSuccess = (ids, res) => {
  return {
    type: actionTypes.DELETE_ORDERS_MANY_SUCCESS,
    ids,
    msg: res,
  };
};

export const deleteManyOrdersFailed = error => {
  return {
    type: actionTypes.DELETE_ORDERS_FAILED,
    error,
  };
};

export const setOrdersManyQueryAfterSearch = query => {
  return {
    type: actionTypes.SET_ORDERS_QUERY_AFTER_SEARCH,
    query,
  };
};


export const deleteManyOrders = (bodyFormData, endpointSearch) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios.delete(`${URLAPI}order/deleteMany`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authKey}`,
      },
      data: {
        order_id: bodyFormData
      }
    })

      .then(res => {
        dispatch(deleteManyOrdersSuccess(bodyFormData, res));
        dispatch(setOrdersQueryAfterSearch(endpointSearch));
      })
      .catch(error => {
        dispatch(deleteManyOrdersFailed(error));
      });
  };
};




export const updateStatusSuccess = res => {
  return {
    type: actionTypes.UPDATE_STATUS_SUCCESS,
    msg: res,
  };
};

export const updateStatusFailed = error => {
  return {
    type: actionTypes.UPDATE_STATUS_FAILED,
    error,
  };
};


export const updateStatus = (endpoint, data, endpointSearch) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .put(
        endpoint,
        {
          ...data,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(updateStatusSuccess(res));
        // dispatch(setOrders());
        dispatch(setOrdersQueryAfterSearch(endpointSearch));
      })
      .catch(error => {
        dispatch(updateStatusFailed(error));
      });
  };
};
