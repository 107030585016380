import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;
// GET
export const setShipmentsMethod = shipmentsMethod => {
  return {
    type: actionTypes.SET_SHIPMENTS_METHOD,
    shipmentsMethod,
  };
};

export const fetchShipmentsMethodFailed = error => {
  return {
    type: actionTypes.FETCH_SHIPMENTS_METHOD_FAILED,
    error,
  };
};

export const fetchShipmentsMethod = () => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .get(`${URLAPI}shipment-methods`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })

      .then(res => {
        const shipmentsMethod = res.data.data;
        dispatch(setShipmentsMethod(shipmentsMethod));
      })
      .catch(error => {
        dispatch(fetchShipmentsMethodFailed(error));
      });
  };
};

// GET ONE
export const setsSingleShipmentsMethod = ShipmentsMethod => {
  return {
    type: actionTypes.SET_SINGLE_SHIPMENTS_METHOD,
    ShipmentsMethod,
  };
};

export const fetchSingleShipmentsMethodFailed = error => {
  return {
    type: actionTypes.FETCH_SINGLE_SHIPMENTS_METHOD_FAILED,
    error,
  };
};

export const fetchSingleShipmentsMethod = () => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .get(`${URLAPI}shipment-methods`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })

      .then(res => {
        const ShipmentsMethod = res.data.data;

        dispatch(setsSingleShipmentsMethod(ShipmentsMethod));
      })
      .catch(error => {
        dispatch(fetchSingleShipmentsMethodFailed(error));
      });
  };
};

// ADD SHIPMENT METHOD

export const submitShipmentsMethodsFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_SHIPMENTS_METHOD_FORM_SUCCESS,
    msg: res,
  };
};

export const submitShipmentsMethodsFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_SHIPMENTS_METHOD_FORM_FAILED,
    error,
  };
};

export const addShipmentsMethodsForm = (name, description, price, isEnabled, resetForm) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .post(
        `${URLAPI}shipment-methods`,
        {
          name,
          description,
          price,
          is_enabled: isEnabled,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(submitShipmentsMethodsFormSuccess(res));
        dispatch(fetchShipmentsMethod());
        resetForm();
      })
      .catch(error => {
        dispatch(submitShipmentsMethodsFormFailed(error));
      });
  };
};

// EDIT ShipmentsMethods

export const submitShipmentsMethodsForm = (id, name, description, newPrice, isEnabled) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .put(
        `${URLAPI}shipment-methods/${id}`,
        {
          name,
          description,
          price: newPrice,
          is_enabled: isEnabled,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(submitShipmentsMethodsFormSuccess(res));
        dispatch(fetchShipmentsMethod());
      })
      .catch(error => {
        dispatch(submitShipmentsMethodsFormFailed(error));
      });
  };
};

// DELETE

export const deleteShipmentsMethodsSuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_SHIPMENTS_METHOD_SUCCESS,
    id,
    msg: res,
  };
};

export const deleteShipmentsMethodsFailed = error => {
  return {
    type: actionTypes.DELETE_SHIPMENTS_METHOD_FAILED,
    error,
  };
};

export const deleteShipmentsMethods = id => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .delete(`${URLAPI}shipment-methods/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        dispatch(deleteShipmentsMethodsSuccess(id, res));
        dispatch(fetchShipmentsMethod());
      })
      .catch(error => {
        dispatch(deleteShipmentsMethodsFailed(error));
      });
  };
};
