import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div` 
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  background: rgb(255, 255, 255);
  overflow: hidden;
`



const ContentWrapper = props => {
  const { children } = props;
  return <Wrapper> {children}</Wrapper>;
};

export default ContentWrapper;
