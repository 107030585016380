import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;

export const setMeals = meals => {
  return {
    type: actionTypes.SET_MEALS,
    meals,
  };
};

export const fetchMealsFailed = () => {
  return {
    type: actionTypes.FETCH_MEALS_FAILED,
  };
};

export const initMeals = endpoint => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const meals = res.data.data;
        dispatch(setMeals(meals));
      })
      .catch(error => {
        dispatch(fetchMealsFailed(error));
      });
  };
};

// DELETE
export const deleteMealsSuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_MEALS_SUCCESS,
    id,
    msg: res,
  };
};

export const deleteMealsFailed = error => {
  return {
    type: actionTypes.DELETE_MEALS_FAILED,
    error,
  };
};

export const deleteMeals = (id, endpoint,) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .delete(`${URLAPI}meals/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        dispatch(deleteMealsSuccess(id, res));
        dispatch(initMeals(endpoint))
      })
      .catch(error => {
        dispatch(deleteMealsFailed(error));
      });
  };
};

// FORM
export const submitMealsFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_FORM_ADD_NEW_MEAL_SUCCESS,
    msg: res,
  };
};

export const submitMealsFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_FORM_ADD_NEW_MEAL_FAILED,
    error,
  };
};

export const submitMealsForm = (history, bodyFormData, endpoint, resetForm) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios({
      method: 'post',
      url: endpoint,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authKey}`
      }
    })
      .then(res => {
        // dispatch(initMeals(`${URLAPI}meals`));
        dispatch(submitMealsFormSuccess(res));
        if (history !== null) {
          history.push('/meals');
        }
        resetForm();
      })
      .catch(error => {
        dispatch(submitMealsFormFailed(error));
      });
  };
};

// EDIT MEALS

const getSingleMealsToEditSuccess = data => {
  return {
    type: actionTypes.GET_SINGLE_MEALS_TO_EDIT_SUCCESS,
    data,
  };
};
const getSingleMealsToEditFailed = error => {
  return {
    type: actionTypes.SUBMIT_FORM_ADD_NEW_MEAL_FAILED,
    error,
  };
};

export const getSingleMealsToEdit = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}meals/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const data = res.data.data[0];
        dispatch(getSingleMealsToEditSuccess(data));
      })
      .catch(error => {
        dispatch(getSingleMealsToEditFailed(error));
      });
  };
};

export const searchMeals = url => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const meals = res.data.data;

        dispatch(setMeals(meals));
      })
      .catch(error => {
        dispatch(fetchMealsFailed(error));
      });
  };
};

export const setMealsQueryAfterSearch = query => {
  return {
    type: actionTypes.SET_MEALS_QUERY_AFTER_SEARCH,
    query,
  };
};

export const setMealsOptionName = optionName => {
  return {
    type: actionTypes.SET_MEALS_OPTION_NAME,
    optionName,
  };
};

export const initAllMealsForOptions = () => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}meals?records=all`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const allMeals = res.data.data;
        dispatch(setMealsOptionName(allMeals));
      })
      .catch(error => {
        dispatch(fetchMealsFailed(error));
      });
  };
};
