import React, { useState } from 'react';
import styled from 'styled-components';
import UsersTable from 'components/atoms/Table/UsersTable';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SearchUsers from 'views/Users/SearchUsers/SearchUsers';
import { Typography } from '@material-ui/core';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import CustomersTable from 'components/atoms/Table/CustomersTable';

const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const UsersSection = styled.section`
  min-height: 100vh;
  width: 100%;
  position: relative;
`;

const initialState = {
  showSearch: false,
};

const ChoseUser = ({ history }) => {
  const [state, setState] = useState(initialState);

  const visibleForm = name => {
    setState(state => ({
      ...state,
      [name]: !state[name],
    }));
  };

  const onCloseHandler = () => {
    // resetForm();
    history.goBack();
  };

  return (
    <UsersSection>
      <BtnHolder>
        <CloseButton handleClick={onCloseHandler} />
      </BtnHolder>
      <Typography variant="h5" color="primary">
        Wybierz użytkownika
      </Typography>
      <IconButton onClick={() => visibleForm('showSearch')}>
        <SearchIcon fontSize="large" />
      </IconButton>
      <SearchUsers
        showSearch={state.showSearch}
        visibleForm={visibleForm}
        resetSearchQuery={false}
      />

      <CustomersTable />
    </UsersSection>
  );
};

export default ChoseUser;
