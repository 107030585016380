import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  /* min-height: 100vh; */
  display: flex;
  padding-top: 64px;
  padding-bottom: 32px;
 /* overflow: hidden; */

`

const MainWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default MainWrapper;
