import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
    countries: [],
};
// GET
const setCountries = (state, action) => {
    return {
        ...state,
        countries: action.countries,
        error: false,
    }
}

// GET ONE 
const setSingleCountry = (state, action) => {
    return {
        ...state,
        singleCountry: action.singleCountry,
        error: false,
    };
};


const countriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_COUNTRIES:
            return setCountries(state, action);
        case actionTypes.SET_SINGLE_COUNTRY:
            return setSingleCountry(state, action);
        default:
            return state;
    }
};

export default countriesReducer;
