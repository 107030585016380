import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;
// GET
export const setPackages = packages => {
  return {
    type: actionTypes.SET_PACKAGES,
    packages,
  };
};

export const fetchPackagesFailed = error => {
  return {
    type: actionTypes.FETCH_PACKAGES_FAILED,
    error,
  };
};

export const fetchPackages = () => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}packages`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const packages = res.data.data;
        dispatch(setPackages(packages));
      })
      .catch(error => {
        dispatch(fetchPackagesFailed(error));
      });
  };
};

// GET ONE
export const setsSinglePackage = packages => {
  return {
    type: actionTypes.SET_SINGLE_PACKAGE,
    singlePackage: packages,
  };
};

export const fetchSinglePackageFailed = error => {
  return {
    type: actionTypes.FETCH_SINGLE_PACKAGE_FAILED,
    error,
  };
};

export const fetchSinglePackage = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}packages/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        const packages = res.data.data;

        dispatch(setsSinglePackage(packages));
      })
      .catch(error => {
        dispatch(fetchSinglePackageFailed(error));
      });
  };
};

// ADD PACKAGE

export const submitPackageFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_PACKAGE_FORM_SUCCESS,
    msg: res,
  };
};

export const submitPackageFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_PACKAGE_FORM_FAILED,
    error,
  };
};

export const addPackageForm = (name, mealsArray, categoryDietId, history) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .post(
        `${URLAPI}packages`,
        {
          name,
          meals: mealsArray,
          category_diet_id: categoryDietId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(submitPackageFormSuccess(res));
        dispatch(fetchPackages());
        history.push(`/packages`);
      })
      .catch(error => {
        dispatch(submitPackageFormFailed(error));
      });
  };
};

// EDIT PACKAGE

export const submitPackageForm = (id, name, meals, categoryDietId, history) => {
  return dispatch => {
    const authKey = Cookies.getJSON('authKey');

    axios
      .put(
        `${URLAPI}packages/${id}`,
        {
          name,
          meals,
          category_diet_id: categoryDietId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authKey}`,
          },
        },
      )
      .then(res => {
        dispatch(submitPackageFormSuccess(res));
        dispatch(fetchPackages());
        history.push(`/packages`);
      })
      .catch(error => {
        dispatch(submitPackageFormFailed(error));
      });
  };
};

// DELETE

export const deletePackageSuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_PACKAGE_SUCCESS,
    id,
    msg: res,
  };
};

export const deletePackageFailed = error => {
  return {
    type: actionTypes.DELETE_PACKAGE_FAILED,
    error,
  };
};

export const deletePackage = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .delete(`${URLAPI}packages/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        dispatch(deletePackageSuccess(id, res));
        dispatch(fetchPackages());
      })
      .catch(error => {
        dispatch(deletePackageFailed(error));
      });
  };
};

export const setMealsPackages = meals => {
  return {
    type: actionTypes.SET_MEALS_PACKAGES,
    mealsPackages: meals,
  };
};

export const fetchMealsPackagesFailed = () => {
  return {
    type: actionTypes.FETCH_MEALS_PACKAGES_FAILED,
  };
};

export const initMealsPackages = endpoint => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authKey}`,
        },
      })
      .then(res => {
        dispatch(setMealsPackages(res.data.data));
      })
      .catch(error => {
        dispatch(fetchMealsPackagesFailed(error));
      });
  };
};
