import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddPaymentMethods from 'views/PaymentMethods/AddPaymentMethods/AddPaymentMethods';
import PaymentMethodsTable from 'components/atoms/Table/PaymentMethodsTable'
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions';

const URLAPI = process.env.REACT_APP_URL_API;


const Wrapper = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  position: relative;
`
const PaymentMethods = ({
    countries,
    onFetchCountries,
    onInitValidators,
    onFetchPaymentMethod,
    paymentMethod,
    uniquePaymentMethodsNames,
    onAddPaymentMethodsForm,
    onDeletePaymentMethods

}) => {
    const [visible, setVisible] = useState(false)
    const [initCountries, setInitCountries] = useState(false);
    const [paymentMehtodsList, setPaymentMehtodsList] = useState([]);
    const [init, setInit] = useState(false);
    const showAddIgCategory = () => {
        setVisible(!visible)
    }

    useEffect(() => {
        const endpoint = `${URLAPI}payment-method/name`
        onInitValidators(endpoint, 'paymentMethods')
    }, [])


    const setElementsForCurrentPageCountries = () => {
        onFetchCountries()
    }

    const setElementsForAddPaymentMethods = () => {
        onFetchPaymentMethod()
    }

    useEffect(() => {
        if (init === false) {
            setElementsForAddPaymentMethods()
            setInit(true)
        }
    }, [paymentMethod])

    const setElementsForCurrentPage = () => {
        onFetchPaymentMethod()
    }

    useEffect(() => {
        if (paymentMehtodsList !== null && init === false) {
            setElementsForCurrentPage()
            setInit(true)
        }
    }, [paymentMehtodsList])

    useEffect(() => {
        if (initCountries === false) {
            setElementsForCurrentPageCountries()
            setInitCountries(true)
        }
    }, [countries])



    return (
        <Wrapper>
            <IconButton
                onClick={() => showAddIgCategory()}
            >
                <AddIcon fontSize="large" />
            </IconButton>
            {visible && <AddPaymentMethods
                setVisible={setVisible}
                countries={countries}
                uniquePaymentMethodsNames={uniquePaymentMethodsNames}
                paymentMethod={paymentMethod}
                onAddPaymentMethodsForm={onAddPaymentMethodsForm}
            />}

            <PaymentMethodsTable
                countries={countries}
                setPaymentMehtodsList={setPaymentMehtodsList}
                paymentMehtodsList={paymentMehtodsList}
                paymentMethod={paymentMethod}
                onDeletePaymentMethods={onDeletePaymentMethods}

            />
        </Wrapper>
    );
}

// export default PaymentMethods;

const mapStateToProps = state => {
    return {
        countries: state.countries.countries,
        isLoading: state.error,
        paymentMethod: state.paymentMethods.paymentMethods,
        uniquePaymentMethodsNames: state.validators.validators,
        unieqeCurrencyCode: state.validators.validators
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCountries: () => dispatch(actionCreators.fetchCountries()),
        onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),
        onFetchPaymentMethod: () => dispatch(actionCreators.fetchPaymentMethod()),
        onDeletePaymentMethods: id => dispatch(actionCreators.deletePaymentMethods(id)),
        onAddPaymentMethodsForm: (bodyFormData, resetForm) => dispatch(actionCreators.addPaymentMethodsForm(bodyFormData, resetForm)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);