import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

function TabPanel({ children, value, index, ...other }) {
    return (
        <Wrapper
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </Wrapper>
    );
}

export default TabPanel

