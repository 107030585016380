import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
  users: [],
  usersConfig: null,
  userToEdit: null,
  usersOptionsNameForSearch: [],
  userInfo: null,
  userOrders: null,
};

const setUsers = (state, action) => {
  return {
    ...state,
    users: action.users.data,
    usersConfig: action.users,
    error: false,
  };
};

const fetchUsersFailed = state => {
  return {
    ...state,
    error: true,
  };
};

const deleteUsersSuccess = (state, action) => {
  return {
    ...state,
    users: [...state.users.filter(user => user.id !== action.id)],
  };
};

const submitUsersFormSuccess = (state, action) => {
  return {
    ...state,
    msg: action.msg,
  };
};

const submitUsersFormFailed = (state, action) => {
  return {
    ...state,
    msg: action.error,
  };
};

const getSingleUsersSuccess = (state, action) => {
  return {
    ...state,
    userToEdit: action.data,
  };
};

const getUserDiet = (state, action) => {
  return {
    ...state,
    userDiet: action.data,
  };
};

const setUsersQueryAfterSearch = (state, action) => {
  return {
    ...state,
    usersSearchQuery: action.query,
  };
};

const setUsersOptionName = (state, action) => {
  return {
    ...state,
    usersOptionsNameForSearch: action.optionName,
  };
};

const getUserInfoSuccess = (state, action) => {
  return {
    ...state,
    userInfo: action.data,
  };
};
const getUserOrdersSuccess = (state, action) => {
  return {
    ...state,
    userOrders: action.data,
  };
};
const submitExcludeFormFailed = (state, action) => {
  return {
    ...state,
    msg: action.error,
  };
};

export const submitExcludeSuccess = res => {
  return {
    type: actionTypes.SUBMIT_EXCLUDE_FORM,
    msg: res,
  };
};

const submitEmailFormFailed = (state, action) => {
  return {
    ...state,
    msg: action.error,
  };
};

export const submitEmailSuccess = res => {
  return {
    type: actionTypes.SUBMIT_KCAL_FORM,
    msg: res,
  };
};

const submitKcalFormFailed = (state, action) => {
  return {
    ...state,
    msg: action.error,
  };
};

export const submitKcalSuccess = res => {
  return {
    type: actionTypes.SUBMIT_KCAL_FORM,
    msg: res,
  };
};

const submitStartDietFormFailed = (state, action) => {
  return {
    ...state,
    msg: action.error,
  };
};

export const submitStartDietSuccess = res => {
  return {
    type: actionTypes.SUBMIT_START_DIET_FORM,
    msg: res,
  };
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_EXCLUDE_FORM:
      return submitExcludeSuccess(state, action);
    case actionTypes.SUBMIT_EXCLUDE_FORM_FAILED:
      return submitExcludeFormFailed(state, action);


    case actionTypes.SUBMIT_EMAIL_FORM:
      return submitEmailSuccess(state, action);
    case actionTypes.SUBMIT_EMAIL_FORM_FAILED:
      return submitEmailFormFailed(state, action);

    case actionTypes.SUBMIT_KCAL_FORM:
      return submitKcalSuccess(state, action);
    case actionTypes.SUBMIT_KCAL_FORM_FAILED:
      return submitKcalFormFailed(state, action);

    case actionTypes.SUBMIT_START_DIET_FORM:
      return submitStartDietSuccess(state, action);
    case actionTypes.SUBMIT_START_DIET_FORM_FAILED:
      return submitStartDietFormFailed(state, action);

    case actionTypes.SET_USERS:
      return setUsers(state, action);
    case actionTypes.FETCH_USERS_FAILED:
      return fetchUsersFailed(state);
    case actionTypes.DELETE_USERS_SUCCESS:
      return deleteUsersSuccess(state, action);
    case actionTypes.SUBMIT_FORM_ADD_NEW_USERS_SUCCESS:
      return submitUsersFormSuccess(state, action);
    case actionTypes.SUBMIT_USERS_FORM_FAILED:
      return submitUsersFormFailed(state, action);
    case actionTypes.GET_SINGLE_USERS:
      return getSingleUsersSuccess(state, action);
    case actionTypes.SET_USERS_QUERY_AFTER_SEARCH:
      return setUsersQueryAfterSearch(state, action);
    case actionTypes.SET_USERS_OPTION_NAME:
      return setUsersOptionName(state, action);
    case actionTypes.GET_SINGLE_USERS_ORDER:
      return getUserOrdersSuccess(state, action);
    case actionTypes.USER_DIET_SUCCESS:
      return getUserDiet(state, action);
    case actionTypes.GET_SINGLE_USERS_INFO:
      return getUserInfoSuccess(state, action);
    default:
      return state;
  }
};

export default usersReducer;
