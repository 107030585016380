import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';
import MealsTable from 'components/atoms/Table/MealsTable';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import SearchMeals from 'views/Meals/SearchMeals/SearchMeals';

const URLAPI = process.env.REACT_APP_URL_API;

const MealsSection = styled.section`
  min-height: 100vh;
  width: 100%;
`;

const initialState = {
  showSearch: false,
};

const Meals = ({
  meals,
  mealsCfg,
  mealsSearchQuery,
  onInitMeals,
  onDeleteMeals,
  onInitAllMealsForOptions,
  onfetchMealsCategory,
  mealsCategory,
  onSetMealsQueryAfterSearch,
  optionsName,
}) => {
  const [state, setState] = useState(initialState);
  const [init, setInit] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [mealsList, setMalsList] = useState(null);
  const [descriptionVisable, setDescriptionVisable] = useState([]);
  const [deleteConfirmVisable, setDeleteConfirmVisable] = useState([]);

  const visibleForm = name => {
    setState(state => ({
      ...state,
      [name]: !state[name],
    }));
  };

  useEffect(() => {
    if (mealsCfg !== null) {
      setMalsList(mealsCfg.data);
    }
  }, [mealsCfg, meals]);

  useEffect(() => {
    const openArray = {};
    if (mealsList !== null && mealsList !== undefined) {
      mealsList.map(el => {
        const key = (el.name + el.id.toString())
          .split('')
          .filter(e => e.trim().length)
          .join('');
        openArray[key] = false;
      });

      setDescriptionVisable(openArray);
      setDeleteConfirmVisable(openArray);
    }
  }, [mealsList]);

  const [mealsConfig, setMealsConfig] = useState({
    per_page: 500,
    current_page: 1,
    jumpToPage: null,
    mealsSearchQuery: '',
    // total: -1,
    data: [],
  });

  useEffect(() => {
    setMealsConfig(mealsCfg);
  }, [mealsCfg]);

  const setElementsForCurrentPage = () => {
    setInit(true);
    const endpoint = `${URLAPI}meals?page=${
      mealsConfig !== null ? mealsConfig.current_page : 1
    }&per_page=${mealsConfig !== null ? mealsConfig.per_page : 500}${
      mealsSearchQuery === undefined ? '' : mealsSearchQuery
    }`;

    // const endpoint = `${URLAPI}meals?page=1&per_page=${mealsConfig.per_page}${mealsSearchQuery}`;

    onInitMeals(endpoint);
  };

  // useEffect(() => {
  //   if (mealsConfig !== null && init === false) {
  //     setElementsForCurrentPage();
  //     setInit(true);
  //   }
  // }, [mealsConfig]);

  // useEffect(() => {
  //   setElementsForCurrentPage();
  // }, [refresh]);

  useEffect(() => {
    setElementsForCurrentPage();
  }, [mealsSearchQuery]);

  useEffect(() => {
    onInitAllMealsForOptions();
  }, []);

  useEffect(() => {
    if (!mealsCategory || mealsCategory.length === 0) {
      onfetchMealsCategory();
    }
  }, []);

  return (
    <MealsSection>
      <Link to="/meals/add-meals">
        <IconButton>
          <AddIcon fontSize="large" />
        </IconButton>
      </Link>
      <IconButton onClick={() => visibleForm('showSearch')}>
        <SearchIcon fontSize="large" />
      </IconButton>
      <SearchMeals
        showSearch={state.showSearch}
        visibleForm={visibleForm}
        resetSearchQuery={false}
        mealsCategory={mealsCategory}
        onSetMealsQueryAfterSearch={onSetMealsQueryAfterSearch}
        optionsName={optionsName}
      />

      <MealsTable
        onDeleteMeals={onDeleteMeals}
        mealsSearchQuery={mealsSearchQuery}
        setMealsConfig={setMealsConfig}
        setRefresh={setRefresh}
        mealsConfig={mealsConfig}
        mealsList={mealsList}
        descriptionVisable={descriptionVisable}
        deleteConfirmVisable={deleteConfirmVisable}
        setDescriptionVisable={setDescriptionVisable}
        setDeleteConfirmVisable={setDeleteConfirmVisable}
      />
    </MealsSection>
  );
};

const mapStateToProps = state => {
  return {
    // meals: state.meals.meals,
    mealsCfg: state.meals.mealsConfig,
    mealsSearchQuery: state.meals.mealsSearchQuery,
    optionsName: state.meals.mealsOptionsNameForSearch,
    mealsCategory: state.mealsCat.mealsCategory,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitMeals: endpoint => dispatch(actionCreators.initMeals(endpoint)),
    onDeleteMeals: (id, endpoint) => dispatch(actionCreators.deleteMeals(id, endpoint)),
    onSetMealsQueryAfterSearch: query => dispatch(actionCreators.setMealsQueryAfterSearch(query)),
    onInitAllMealsForOptions: () => dispatch(actionCreators.initAllMealsForOptions()),
    onfetchMealsCategory: () => dispatch(actionCreators.fetchMealsCategory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Meals);
