/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Loading from 'components/atoms/Loading/Loading';
import ConfirmDelete from 'components/atoms/ConfirmDelete/ConfirmDelete'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { Link } from 'react-router-dom';

const tableHeaders = [
    { id: 'id', label: 'id', width: '4%', align: 'left', type: 'id' },
    { id: 'name', label: 'Nazwa', width: '15%', align: 'center', type: 'string' },
    { id: 'breakfast', label: 'Śniadanie', width: '15%', align: 'center', type: 'meals' },
    { id: 'secondBreakfast', label: 'II Śniadanie', width: '15%', align: 'center', type: 'meals' },
    { id: 'dinner', label: 'Obiad', width: '15%', align: 'center', type: 'meals' },
    { id: 'supper', label: 'Kolacja', width: '15%', align: 'center', type: 'meals' },
    { id: 'dairy', label: 'Nabiał', width: '2%', align: 'center', type: 'boolean' },
    { id: 'fish', label: 'Ryby', width: '2%', align: 'center', type: 'boolean' },
    { id: 'gluten', label: 'Gluten', width: '2%', align: 'center', type: 'boolean' },
    { id: 'lactose', label: 'Laktoza', width: '2%', align: 'center', type: 'boolean' },
    { id: 'meat', label: 'Mięso', width: '2%', align: 'center', type: 'boolean' },
    { id: 'seafood', label: 'Owoce morza', width: '2%', align: 'center', type: 'boolean' },
    { id: 'actions,', label: 'Akcje', width: '19%', align: 'center', type: 'buttons' }
]

const IconsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
`

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },

});

const useStyles1 = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage)}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const PackagesTable = ({
    onFetchPackages,
    packages,
    onDeletePackage,
    isLoading,
    search
}) => {
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(100)
    const [page, setPage] = useState(0)
    const [init, setInit] = useState(false);
    const [packagesList, setPackagesList] = useState(null);
    const [isOpen, setOpen] = useState([]);
    const [deleteConfirmVisable, setDeleteConfirmVisable] = useState([]);

    const createDataFuncVals = tableHeaders.map(el =>
        el.id
    )

    useEffect(() => {
        const openArray = {}
        setPackagesList(packages);
        if (packages) {
            packages.map(el => {
                const key = (el.name + el.id.toString()).split('').filter(e => e.trim().length).join('');
                openArray[key] = false
            })
            setOpen(openArray)
            setDeleteConfirmVisable(openArray)
        }
    }, [packages])

    const setElementsForCurrentPage = () => {
        onFetchPackages()
    }

    useEffect(() => {
        if (packagesList === null) {
            setElementsForCurrentPage()
            // setInit(true)
        }
    }, [packages])

    const handleOpen = (key) => {
        setOpen(open => ({
            ...open,
            [key]: !open[key]
        }))
    }

    const createData = (...params) => {
        const obj = {}
        // eslint-disable-next-line no-return-assign
        createDataFuncVals.map((el, index) =>
            obj[el] = params[index]
        )
        return obj
    }

    let data = []
    if (packagesList) {
        data = packagesList.map(el => {
            return {
                id: el.id,
                name: el.name,
                breakfast: el.meals.find(meal => meal.category_meals[0].id === '58e42f4b-d7aa-471c-a4bc-e4f5e59a57c9'),
                secondBreakfast: el.meals.find(meal => meal.category_meals[0].id === '70150f29-d2ef-4d2a-b676-1e6abbf61959'),
                dinner: el.meals.find(meal => meal.category_meals[0].id === 'a26f8326-65d5-46ff-ad29-52f3354d1537'),
                supper: el.meals.find(meal => meal.category_meals[0].id === '13500b18-93c0-4a4a-bb31-46c5754fa94b'),
                dairy: el.dairy,
                fish: el.fish,
                gluten: el.gluten,
                lactose: el.lactose,
                meat: el.meat,
                seafood: el.seafood,
            }
        })
    }

    const filtredPackagesByName = data.filter(el =>
        el.name.toLowerCase().includes(search.name)
    )

    const filtredPackagesByMeat = () => {
        if (search.meat === null) {
            return filtredPackagesByName
        } else {
            return filtredPackagesByName.filter(el =>
                +el.meat === search.meat?.value
            )
        }
    }

    const filtredPackagesByFish = () => {
        if (search.fish === null) {
            return filtredPackagesByMeat()
        } else {
            return filtredPackagesByMeat().filter(el =>
                +el.fish === search.fish?.value
            )
        }
    }

    const filtredPackagesByLactose = () => {
        if (search.lactose === null) {
            return filtredPackagesByFish()
        } else {
            return filtredPackagesByFish().filter(el =>
                +el.lactose === search.lactose?.value
            )
        }
    }

    const filtredPackagesByGluten = () => {
        if (search.gluten === null) {
            return filtredPackagesByLactose()
        } else {
            return filtredPackagesByLactose().filter(el =>
                +el.gluten === search.gluten?.value
            )
        }
    }

    const filtredPackagesBySeafood = () => {
        if (search.seafood === null) {
            return filtredPackagesByGluten()
        } else {
            return filtredPackagesByGluten().filter(el =>
                +el.seafood === search.seafood?.value
            )
        }
    }

    const filtredPackagesByDairy = () => {
        if (search.dairy === null) {
            return filtredPackagesBySeafood()
        } else {
            return filtredPackagesBySeafood().filter(el =>
                +el.dairy === search.dairy?.value
            )
        }
    }

    const rows = filtredPackagesByDairy().map((el) => {
        const funcParams = [];
        Object.keys(el).map((key) => {
            funcParams.push(el[key])
            return funcParams
        })
        return createData(...funcParams)
    });




    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const openDeleteConfirm = (key) => {
        setDeleteConfirmVisable(deleteConfirmVisable => ({
            ...deleteConfirmVisable,
            [key]: !deleteConfirmVisable[key]
        }))
    }

    const allMeals = [];
    if (packages) {
        packages.map(singlePackage => singlePackage.meals.map(meal => allMeals.push(meal)))
    }

    const uniqueMealsNames = [...new Set(allMeals.map(item => item.name))];
    const getSumOfTotals = () => {
        const totalPayments = [];

        for (let i = 0; i < uniqueMealsNames.length; i++) {
            const filtred = allMeals.filter(meal => meal.name === uniqueMealsNames[i]);
            // filtred.map(order => order
            totalPayments.push({
                [uniqueMealsNames[i]]: filtred.length
            });
        }
        return totalPayments;
    };

    if (isLoading === true || packages === null) {
        return <Loading />
    } else {

        return (
            <>
                <Paper className={classes.root}>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            stickyHeader
                            aria-label="sticky table"
                        >
                            <TableHead>
                                <TableRow>
                                    {tableHeaders.map(head => {
                                        if (head.type === 'id') {
                                            return null
                                        } else {
                                            return (
                                                <React.Fragment key={head.id}>
                                                    <StyledTableCell
                                                        align={head.align}
                                                        style={{ width: head.width }}
                                                    >
                                                        {head.label}
                                                    </StyledTableCell>
                                                </React.Fragment>
                                            )
                                        }
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const newKey = (row.name + row.id.toString()).split('').filter(e => e.trim().length).join('')
                                    return (
                                        <React.Fragment key={row.id}>
                                            <TableRow hover role="checkbox" tabIndex={-1} >
                                                {tableHeaders.map((head) => {
                                                    const value = row[head.id];

                                                    if (head.type === 'id') {
                                                        return null
                                                    }
                                                    if (head.type === 'buttons') {
                                                        return (
                                                            <React.Fragment key={head.id}>
                                                                <TableCell align={head.align}>
                                                                    <IconsWrapper>
                                                                        <IconButton aria-label="delete"
                                                                            // onClick={() => onDeletePackage(row.id)}
                                                                            onClick={() => openDeleteConfirm(newKey)}
                                                                        >

                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                        <Link to={`/packages/edit/${row.id}`}>
                                                                            <IconButton aria-label="edit"
                                                                            // onClick={() => handleOpen(newKey)}
                                                                            >
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        </Link>
                                                                        {deleteConfirmVisable[newKey] &&
                                                                            <ConfirmDelete
                                                                                width='850%'
                                                                                newKey={newKey}
                                                                                name={row.name}
                                                                                onDeleteHandler={onDeletePackage}
                                                                                id={row.id}
                                                                                rejectHandler={openDeleteConfirm}
                                                                                isOpen={deleteConfirmVisable}
                                                                            >Czy napewno chcesz skasować</ConfirmDelete>}
                                                                    </IconsWrapper>
                                                                </TableCell>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    if (head.type === 'boolean') {
                                                        return (
                                                            <React.Fragment key={head.id}>
                                                                <TableCell align={head.align}>
                                                                    {value && <CheckOutlinedIcon color='secondary' />}
                                                                    {!value && <CloseOutlinedIcon color='error' />}
                                                                </TableCell>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    if (head.type === 'meals') {
                                                        return (
                                                            <React.Fragment key={head.id}>
                                                                <TableCell align={head.align}>
                                                                    {value?.name}
                                                                </TableCell>
                                                            </React.Fragment>
                                                        )
                                                    }

                                                    else {
                                                        return (
                                                            <React.Fragment key={head.id}>
                                                                <TableCell align={head.align}>
                                                                    {value}
                                                                </TableCell>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                })}
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    {isLoading !== true &&
                                        packagesList !== null ?
                                        <TablePagination
                                            rowsPerPageOptions={[2, 6, 10, 15, 25, 100, { label: 'All', value: packagesList }]}
                                            count={packagesList.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                        : ''}
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
                <ul>
                    {getSumOfTotals().map(el =>
                        <li>{Object.keys(el)} : {Object.values(el)}</li>
                    )}
                </ul>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.error,
        packages: state.packages.packages,
        meals: state.meals.meals,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        // onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
        onFetchPackages: () => dispatch(actionCreators.fetchPackages()),
        onDeletePackage: id => dispatch(actionCreators.deletePackage(id)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackagesTable);

