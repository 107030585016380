import React from 'react';
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  display: table;
  border-spacing: 0;
`;
const Thead = styled.thead`
    background-color: ${({ theme }) => theme.palette.common.black};
    color: ${({ theme }) => theme.palette.common.white};
    /* tr, th{
    border: 1px solid ${({ theme }) => theme.palette.common.black};
    } */
`;

const Tbody = styled.tbody`
    border: 1px solid ${({ theme }) => theme.palette.common.black};
    td{
        border-bottom: 1px solid #e0e0e0:
        padding: 8px;
    }
`;

const PriceTable = ({ products }) => {
  return (
    <>
      <Table>
        <Thead>
          <tr>
            <th>nazwa</th>
            <th>cena</th>
          </tr>
        </Thead>
        <Tbody>
          {products[0].map(product => (
            <tr key={product.id}>
              <td>
                {product.name} - {product.language}
              </td>
              <td>
                {product.product_order.price} {products[1]}{' '}
              </td>
            </tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default PriceTable;
