import React from 'react';
import styled from 'styled-components';

import IngredientsUseInMeal from 'components/atoms/Meals/ingredientsUseInMeal';

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: rgb(241, 241, 241);
  div {
    border-bottom: 1px solid black;
    padding: 10px 0px 10px 5px;
  }
  /* span {
    padding: 10px 0px 10px 0;
    border-right: 1px solid black;
  } */
  p {
    padding: 10px 5px 10px 5px;
    text-align: center;
  }
`;
const Coll = styled.span`
  padding: 10px 0px 10px 0;
  border-right: 1px solid black;
  word-wrap: break-word;
`;

const Col20 = styled(Coll)`
  width: 20%;
`;
const Col10 = styled(Coll)`
  width: 10%;
`;
const Col40 = styled(Coll)`
  width: 40%;
`;
const Col30P0 = styled(Coll)`
  width: 30%;
  /* padding: 0; */
`;
const Bold = styled.div`
  font-weight: bold;
`;

const Error = styled.p`
  width: 100%;
  color: #ff0000;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 12px;
  top: 60px;
  left: 0;
  text-align: right;
`;

const CurrentMeal = ({
  NameOfMeal,
  // kcalMin,
  createMarkup,
  selectedMealsCategory,
  noIngredients,
  optionsName,
  ingredientsInMeal,
  deleteIngredientFromList,
  runValidation,
  lang,
  description,
}) => {
  return (
    <Wrapper>
      <Title>Twój nowy posiłek - {lang}:</Title>
      <StyledDiv>
        <Col20>
          <Bold>Nazwa dania: </Bold>
          <p>{NameOfMeal}</p>
        </Col20>

        <Col10>
          <Bold>Kategoria: </Bold>
          <p>{selectedMealsCategory === null ? '' : selectedMealsCategory.label}</p>
        </Col10>
        <Col40>
          <Bold>Przepis:</Bold>
          <span dangerouslySetInnerHTML={createMarkup(description)} />
        </Col40>

        <Col30P0>
          <Bold>Użyte składniki:</Bold>
          {ingredientsInMeal && (
            <IngredientsUseInMeal
              lang={lang}
              optionsName={optionsName}
              ingredientsInMeal={ingredientsInMeal}
              deleteIngredientFromList={deleteIngredientFromList}
              runValidation={runValidation}
            />
          )}
        </Col30P0>
      </StyledDiv>
      {noIngredients && <Error>Dodaj conajmniej jeden składnik</Error>}
    </Wrapper>
  );
};

export default CurrentMeal;
