import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddPackages from 'views/Packages/AddPackages/AddPackages';
import PackagesTable from 'components/atoms/Table/PackagesTable';
import SearchIcon from '@material-ui/icons/Search';
import SearchPackages from 'views/Packages/SearchPackages/SearchPackages'

const SectionPackages = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  position: relative;
`
const Packages = () => {
  const [visible, setVisible] = useState(false)
  const [visibleSearch, setVisibleSearch] = useState(false)

  const [search, setSearch] = useState({
    name: '',
    dairy: null,
    fish: null,
    gluten: null,
    lactose: null,
    meat: null,
    seafood: null,
  });


  const visableSearch = () => {
    setVisibleSearch(!visibleSearch)
  }

  return (
    <SectionPackages>
      <Link to='/packages/add'>
        <IconButton
        >
          <AddIcon fontSize="large" />
        </IconButton>
      </Link>
      <IconButton
        onClick={() => visableSearch()}
      >
        <SearchIcon fontSize="large" />
      </IconButton>
      {visible && <AddPackages setVisible={setVisible} />}
      {visibleSearch && <SearchPackages setSearch={setSearch} search={search} />}
      <PackagesTable search={search} />
    </SectionPackages>
  );
}

export default Packages;

