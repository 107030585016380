/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions';
import { connect } from 'react-redux';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import Typography from '@material-ui/core/Typography';
import Input from 'components/atoms/Inputs/Input';
import { Validators } from 'components/atoms/Inputs/Validators';
import SelectImput from 'components/atoms/Inputs/SelectInput';
import Loading from 'components/atoms/Loading/Loading';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';

const URLAPI = process.env.REACT_APP_URL_API;

const ModalWrapper = styled.div`
  margin: 16px auto;
  position: relative;
  width: 80%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[5]};
  padding: ${({ theme }) => theme.spacing(2, 4, 3)};
  border-radius: 5px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputsWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
const SelectWrapper = styled.div`
  width: 49%;
  margin-bottom: 16px;
`;
const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const Col = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterWrapper = styled.div`
  width: 80%;
  margin-left: auto;
`;

const initialState = {
  packageName: '',
  breakfast: null,
  secondBreakfast: null,
  dinner: null,
  supper: null,
  dairy: null,
  fish: null,
  gluten: null,
  lactose: null,
  meat: null,
  seafood: null,
  selectedDietsCategory: { value: '', label: '' },
  categoryDiets: [],
};

const EditPackages = ({
  history,
  mealsListData,
  onInitMeals,
  onInitValidators,
  uniquePackagesNames,
  onAddPackageForm,
  status,
  onFetchMealsCategoryDiets,
  mealsCategoryDietsOptions,
  mealsCategoryDiets,
}) => {
  console.log('props', mealsCategoryDietsOptions);
  const [mealsList, setMealsList] = useState([]);
  const [init, setInit] = useState(false);
  // const [state, setState] = useState(initialState);
  useEffect(() => {
    const endpoint = `${URLAPI}package/name`;
    onInitValidators(endpoint, 'packages');
  }, [mealsListData]);

  const setElementsForCurrentPage = () => {
    const endpoint = `${URLAPI}meals?records=all`;
    // onInitAllMealsForOptions()
    onInitMeals(endpoint);
    onFetchMealsCategoryDiets();
  };

  useEffect(() => {
    setMealsList(mealsListData);
  }, []);

  useEffect(() => {
    setMealsList(mealsListData);
  }, [mealsListData]);

  useEffect(() => {
    if (mealsList !== null && init === false) {
      setElementsForCurrentPage();
      setInit(true);
    }
  }, [mealsList]);

  const [inputsValue, setInputValue] = useState(initialState);

  const handleInputChange = e => {
    setInputValue({
      ...inputsValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeSelect = (e, key) => {
    setInputValue({
      ...inputsValue,
      [key]: e,
    });
  };

  // eslint-disable-next-line consistent-return
  const filtredMealsOptions = mealsCategoryId => {
    const { dairy, fish, gluten, lactose, meat, seafood } = inputsValue;
    if (mealsList?.length === 0 || mealsList !== null) {
      const filtredByCategory = mealsList.filter(el =>
        el.category_meals.every(() => el.category_meals.some(x => x.id === mealsCategoryId)),
      );

      // DAIRY
      const filtredByDairy = () => {
        if (dairy !== null) {
          return filtredByCategory.filter(el => el.dairy === dairy?.value);
        } else {
          return filtredByCategory;
        }
      };

      // FISH
      const filterByFish = () => {
        if (fish !== null) {
          return filtredByDairy().filter(el => el.fish === fish?.value);
        } else {
          return filtredByDairy();
        }
      };

      // GLUTEN
      const filterByGluten = () => {
        if (gluten !== null) {
          return filterByFish().filter(el => el.gluten === gluten?.value);
        } else {
          return filterByFish();
        }
      };

      //  LACTOSE
      const filterByLactose = () => {
        if (lactose !== null) {
          return filterByGluten().filter(el => el.lactose === lactose?.value);
        } else {
          return filterByGluten();
        }
      };

      // MEAT
      const filterByMeat = () => {
        if (meat !== null) {
          return filterByLactose().filter(el => el.meat === meat?.value);
        } else {
          return filterByLactose();
        }
      };

      // SEAFOOD
      const filterBySeafood = () => {
        if (seafood !== null) {
          return filterByMeat().filter(el => el.seafood === seafood?.value);
        } else {
          return filterByMeat();
        }
      };
      return filterBySeafood();
    }
  };
  const resetForm = () => {
    setInputValue(initialState);
  };

  const handleClose = () => {
    resetForm();
    history.push('/packages');
  };

  const handleSubmitForm = e => {
    e.preventDefault();
    const mealsArray = [
      { meal_id: inputsValue.breakfast.id },
      { meal_id: inputsValue.secondBreakfast.id },
      { meal_id: inputsValue.dinner.id },
      { meal_id: inputsValue.supper.id },
    ];

    const categoryDietId = inputsValue.selectedDietsCategory.value;

    onAddPackageForm(inputsValue.packageName, mealsArray, categoryDietId, history);
  };

  useEffect(() => {
    if (status === 200 || status === 201) {
      resetForm();
    }
  }, [status]);

  const filterOptions = [
    { value: 0, label: 'Nie zawiera' },
    { value: 1, label: 'Zawiera' },
  ];

  console.log('asf', filtredMealsOptions('58e42f4b-d7aa-471c-a4bc-e4f5e59a57c9'));

  if (mealsListData === null || mealsList?.length === 0) {
    return <Loading />;
  } else {
    return (
      <ModalWrapper>
        <BtnHolder>
          <CloseButton handleClick={handleClose} />
        </BtnHolder>
        <StyledForm onSubmit={e => handleSubmitForm(e)}>
          <InputsWrapper>
            <Typography variant="h5" color="primary">
              Dodaj pakiet
            </Typography>
          </InputsWrapper>
          <InputsWrapper>
            <Input
              id="packageName"
              uniqueNames={uniquePackagesNames}
              onChange={handleInputChange}
              value={inputsValue.packageName}
              label="Nazwa pakietu"
              name="packageName"
              type="text"
              validators={[
                { check: Validators.required, message: 'pole wymagane' },
                { check: Validators.uniqueName, message: 'Składnik o podanej nazwie już istnieje' },
              ]}
            />
          </InputsWrapper>
          <Box>
            <Col>
              <Row>
                <InputsWrapper>
                  <SelectImput
                    options={mealsCategoryDietsOptions}
                    noOptionsText="Nieprawidłowa wartość"
                    label="Przypisz pakiet do rodzaju diety"
                    handleOnChange={handleChangeSelect}
                    onChangeParams={['selectedDietsCategory']}
                    id="selectedDietsCategory"
                    name="selectedDietsCategory"
                  />
                </InputsWrapper>
              </Row>
              <Row>
                <InputsWrapper>
                  <SelectImput
                    options={filtredMealsOptions('58e42f4b-d7aa-471c-a4bc-e4f5e59a57c9')}
                    optionType="name"
                    val={inputsValue.breakfast}
                    noOptionsText="Nieprawidłowa wartość"
                    label="Śniadania"
                    handleOnChange={handleChangeSelect}
                    onChangeParams={['breakfast']}
                    id="breakfast"
                  />
                </InputsWrapper>
              </Row>
              <Row>
                <InputsWrapper>
                  <SelectImput
                    options={filtredMealsOptions('70150f29-d2ef-4d2a-b676-1e6abbf61959')}
                    val={inputsValue.secondBreakfast}
                    optionType="name"
                    noOptionsText="Nieprawidłowa wartość"
                    label="II Śniadania"
                    handleOnChange={handleChangeSelect}
                    onChangeParams={['secondBreakfast']}
                    id="secondBreakfast"
                  />
                </InputsWrapper>
              </Row>
              <Row>
                <InputsWrapper>
                  <SelectImput
                    options={filtredMealsOptions('a26f8326-65d5-46ff-ad29-52f3354d1537')}
                    val={inputsValue.dinner}
                    optionType="name"
                    noOptionsText="Nieprawidłowa wartość"
                    label="Obiady"
                    handleOnChange={handleChangeSelect}
                    onChangeParams={['dinner']}
                    id="dinner"
                  />
                </InputsWrapper>
              </Row>
              <Row>
                <InputsWrapper>
                  <SelectImput
                    options={filtredMealsOptions('13500b18-93c0-4a4a-bb31-46c5754fa94b')}
                    val={inputsValue.supper}
                    optionType="name"
                    noOptionsText="Nieprawidłowa wartość"
                    label="Kolacje"
                    handleOnChange={handleChangeSelect}
                    onChangeParams={['supper']}
                    id="supper"
                  />
                </InputsWrapper>
              </Row>
            </Col>
            <Col>
              <FilterWrapper>
                <InputsWrapper>
                  <Typography variant="h6" color="primary">
                    Filtruj dania
                  </Typography>
                </InputsWrapper>

                <Row>
                  <SelectWrapper>
                    <SelectImput
                      options={filterOptions}
                      noOptionsText="Nieprawidłowa wartość"
                      label="Mięso"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['meat']}
                      id="meat"
                    />
                  </SelectWrapper>
                  <SelectWrapper>
                    <SelectImput
                      options={filterOptions}
                      noOptionsText="Nieprawidłowa wartość"
                      label="Nabiał"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['dairy']}
                      id="dairy"
                    />
                  </SelectWrapper>
                </Row>
                <Row>
                  <SelectWrapper>
                    <SelectImput
                      options={filterOptions}
                      noOptionsText="Nieprawidłowa wartość"
                      label="Ryby"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['fish']}
                      id="fish"
                    />
                  </SelectWrapper>
                  <SelectWrapper>
                    <SelectImput
                      options={filterOptions}
                      noOptionsText="Nieprawidłowa wartość"
                      label="Owoce morza"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['seafood']}
                      id="seafood"
                    />
                  </SelectWrapper>
                </Row>
                <Row>
                  <SelectWrapper>
                    <SelectImput
                      options={filterOptions}
                      noOptionsText="Nieprawidłowa wartość"
                      label="Gluten"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['gluten']}
                      id="gluten"
                    />
                  </SelectWrapper>
                  <SelectWrapper>
                    <SelectImput
                      options={filterOptions}
                      noOptionsText="Nieprawidłowa wartość"
                      label="Laktoza"
                      handleOnChange={handleChangeSelect}
                      onChangeParams={['lactose']}
                      id="lactose"
                    />
                  </SelectWrapper>
                </Row>
              </FilterWrapper>
            </Col>
          </Box>
          <ButtonWrapper>
            <ButtonPrimary type="submit">Dodaj Pakiet</ButtonPrimary>
          </ButtonWrapper>
        </StyledForm>
      </ModalWrapper>
    );
  }
};

const mapStateToProps = state => {
  console.log('state', state);
  return {
    packageToEdit: state.packages.singlePackage,
    mealsListData: state.packages.meals,
    mealsCategoryDiets: state.mealsCategoryDiets.mealsCategoryDiets,
    mealsCategoryDietsOptions: state.mealsCategoryDiets?.mealsCategoryDietsOptions,
    uniquePackagesNames: state.validators.validators,
    status: state.notifications.status,
    responseError: state.notifications.responseError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onInitAllMealsForOptions: () => dispatch(actionCreators.initAllMealsForOptions()),
    onInitMeals: endpoint => dispatch(actionCreators.initMealsPackages(endpoint)),
    onInitValidators: endpoint => dispatch(actionCreators.initValidators(endpoint)),
    onFetchMealsCategoryDiets: () => dispatch(actionCreators.fetchMealsCategoryDiets()),
    onAddPackageForm: (name, meals, categoryDietId, history) =>
      dispatch(actionCreators.addPackageForm(name, meals, categoryDietId, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPackages);
