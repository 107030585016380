import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
  mealsCategory: [],
  mealsCategoryOptions: [],
};

const setMealsCategoryOptions = (state, action) => {
  const mealsCategoryOptions = action.options.map(option => {
    return { value: option.id, label: option.name };
  });

  return {
    ...state,
    mealsCategoryOptions,
    error: false,
  };
};

const setMealsCategory = (state, action) => {
  return {
    ...state,
    mealsCategory: action.mealsCategory,
    error: false,
  };
};

const deleteMealsCategorySuccess = (state, action) => {
  return {
    ...state,
    mealsCategory: [...state.mealsCategory.filter(mealCategory => mealCategory.id !== action.id)],
  };
};

const mealsCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MEALS_CATEGORY:
      return setMealsCategory(state, action);
    case actionTypes.SET_MEALS_CATEGORY_OPTIONS:
      return setMealsCategoryOptions(state, action);
    case actionTypes.DELETE_MEAL_CATEGORY_SUCCESS:
      return deleteMealsCategorySuccess(state, action);
    default:
      return state;
  }
};

export default mealsCategoryReducer;
