import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;

export const setPermissionsOptions = options => {
  return {
    type: actionTypes.SET_PERMISSIONS_OPTIONS,
    options,
  };
};

export const setPermissions = permissions => {
  return {
    type: actionTypes.SET_PERMISSIONS,
    permissions,
  };
};

export const fetchPermissionsFailed = () => {
  return {
    type: actionTypes.FETCH_PERMISSIONS_FAILED,
  };
};

export const fetchPermissions = () => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}roles`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then(res => {
        const permissions = res.data.data;

        dispatch(setPermissions(permissions));
        dispatch(setPermissionsOptions(permissions));
      })
      .catch(error => {
        dispatch(fetchPermissionsFailed(error));
      });
  };
};


export const deletePermissionsSuccess = (id, res) => {
  return {
    type: actionTypes.DELETE_PERMISSIONS_SUCCESS,
    id,
    msg: res
  };
};

export const deletePermissionsFailed = error => {
  return {
    type: actionTypes.DELETE_PERMISSIONS_FAILED,
    error,

  };
};


export const deletePermissions = id => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .delete(`${URLAPI}roles/${id}`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then((res) => {
        dispatch(deletePermissionsSuccess(id, res));
      })
      .catch(error => {
        dispatch(deletePermissionsFailed(error));
      });
  };
};



export const setSinglePermissions = permissions => {
  return {
    type: actionTypes.SET_SINGLE_PERMISSIONS,
    permissions,
  };
};

export const fetchSinglePermissionsFailed = (error) => {
  return {
    type: actionTypes.FETCH_SINGLE_PERMISSIONS_FAILED,
    error
  };
};



export const fetchSinglePermissions = (id) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .get(`${URLAPI}roles/${id}`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        }
      })
      .then(res => {
        const permissions = res.data.data;

        dispatch(setSinglePermissions(permissions));
      })
      .catch(error => {
        dispatch(fetchPermissionsFailed(error));
      });
  };
};


export const submitPermissionsFormSuccess = res => {
  return {
    type: actionTypes.SUBMIT_PERMISSIONS_FORM_SUCCESS,
    msg: res
  };
};

export const submitPermissionsFormFailed = error => {
  return {
    type: actionTypes.SUBMIT_PERMISSIONS_FORM_FAILED,
    error,
  };
};

export const AddPermissionsForm = (
  bodyFormData,
  resetForm,
) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios({
      method: 'post',
      url: `${URLAPI}roles`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authKey}`
      }
    })
      .then(res => {
        dispatch(submitPermissionsFormSuccess(res));
        dispatch(fetchPermissions());
        resetForm();

      })
      .catch(error => {
        dispatch(submitPermissionsFormFailed(error));
      });
  };
};

// EDIT Permissionss category

export const submitPermissionsForm = (
  name,
  id,
) => {
  const authKey = Cookies.getJSON('authKey');
  return dispatch => {
    axios
      .put(`${URLAPI}roles/${id}`, {
        name
      }, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${authKey}`
        },
      })
      .then(res => {
        dispatch(submitPermissionsFormSuccess(res));
        dispatch(fetchPermissions());
      })
      .catch(error => {
        dispatch(submitPermissionsFormFailed(error));
      });
  };
};


