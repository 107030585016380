import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { pl } from 'date-fns/esm/locale';
import EventIcon from '@material-ui/icons/Event';
import { IconButton, InputAdornment, Typography } from '@material-ui/core';
import * as actionCreators from 'store/actions';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import SelectInput from 'components/atoms/Inputs/SelectInput';
import SelectInputIcon from 'components/atoms/Inputs/SelectInputIcon';
import Input from 'components/atoms/Inputs/Input';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';

const Wrapper = styled.div`
  height: auto;
  width: 100%;
`;

const Form = styled.form`
  display: ${props => (props.showSearch === true ? 'block' : 'none')};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;
const Col = styled.div`
  width: 49%;
  display: flex;
  justify-content: space-between;
`;

const DataPickerWrapper = styled.div`
  width: 49%;
`;

const TwoImputs = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  &:first-child {
    align-items: flex-start;
  }
  &:last-child {
    align-items: flex-end;
  }
  @media screen and (min-width: 992px) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const InputWrapper = styled.div`
  width: 49%;
  margin-bottom: 16px;
`;

const InputWrapperSM = styled(InputWrapper)`
  width: 32%;
`;

const ButtonWrapper = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
margin-bottom: 16px;

`

const SingleButton = styled.div`
  margin-right: 16px;
`


const useStyles = makeStyles(theme => ({
  root: {
    Rowor: '#000000',
    label: 'Rowor: #000000',
  },
  formControl: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: 120,
    width: '100%',
    height: 'auto',
    padding: theme.spacing(2),
    paddingBottom: 1,
    borderRadius: 5,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const initialState = {
  orderNumber: '',
  orderType: { value: '', label: '' },
  currency: [],
  paymentMethod: [],
  paymentStatus: [],

  paymentDateStart: null,
  paymentDateEnd: null,
  shipmentMethod: [],

  returnStatus: { value: '', label: '' },
  returnDateFrom: null,
  returnDateTo: null,
  productName: [],
  userName: '',
  userLastName: '',
  userEmail: '',
  country: [],
  orderDateFrom: null,
  orderDateTo: null,
  subscriptionStatus: [],
  packagesLenght: [],
  subscriptionStartFrom: null,
  subscriptionStartTo: null,
  shipmentDateTo: null,
  shipmentDateFrom: null,
}

const SearchOrders = ({
  onSetOrdersQueryAfterSearch,
  showSearch,
  onFetchCountries,
  countries,
  onFetchPaymentMethod,
  paymentMethods,
  onFetchShipmentsMethod,
  shipmentsMethod,
  onFetchProducts,
  products,
  setLoadingAfterSearch,
}) => {
  useEffect(() => {
    onFetchCountries();
    onFetchPaymentMethod();
    onFetchShipmentsMethod();
    onFetchProducts();
  }, []);

  const [state, setState] = useState(
    initialState
  );

  const handlePaymentDateChangeFrom = date => {
    setState(state => ({
      ...state,
      paymentDateStart: date,
    }));
  };

  const handleOrderDateChangeFrom = date => {
    setState(state => ({
      ...state,
      orderDateFrom: date,
    }));
  };

  const handleOrderDateChangeTo = date => {
    setState(state => ({
      ...state,
      orderDateTo: date,
    }));
  };

  const handlePaymentDateChangeTo = date => {
    setState(state => ({
      ...state,
      paymentDateEnd: date,
    }));
  };

  const handleShipmnetDateChangeFrom = date => {
    setState(state => ({
      ...state,
      shipmentDateFrom: date,
    }));
  };

  const handleShipmentDateChangeTo = date => {
    setState(state => ({
      ...state,
      shipmentDateTo: date,
    }));
  };

  const handleReturnDateChangeFrom = date => {
    setState(state => ({
      ...state,
      returnDateFrom: date,
    }));
  };

  const handleReturnDateChangeTo = date => {
    setState(state => ({
      ...state,
      returnDateTo: date,
    }));
  };


  const handleSubscriptionStartFrom = date => {
    setState(state => ({
      ...state,
      subscriptionStartFrom: date,
    }));
  };

  const handleSubscriptionStartTo = date => {
    setState(state => ({
      ...state,
      subscriptionStartTo: date,
    }));
  };

  const handleSearch = (inputValue, key, type) => {
    let value = '';
    if (inputValue) {
      if (type === 'text' || type === 'number') {
        value = inputValue.target.value;
      }
      if (type === 'select') {
        value = inputValue;
      }
      setState(state => ({
        ...state,
        [key]: value,
      }));
    } else {
      setState(state => ({
        ...state,
        [key]: { value: '' },
      }));
      return null;
    }
  };

  const getCurrentDate = date => {
    const currentDate = new Date(date)
    const yr = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()
    return `${yr}-${month}-${day}`
  };

  const stringGenerator = (array, key) => {
    if (array.length < 1) {
      return array.reduce((previousValue, currentValue) => {
        return `${previousValue}${currentValue[key]},`;
      }, '');
    }
    return array[0][key]
  }

  const fakeLoading = () => {
    setLoadingAfterSearch(true)
    setTimeout(() => {
      setLoadingAfterSearch(false)
    }, 1000)
  }

  const onHandleSearch = () => {
    let query = '';
    const nameQuery = state.userName !== '' ? `&first_name=${state.userName}` : '';
    const userLastName = state.userLastName !== '' ? `&last_name=${state.userLastName}` : '';
    const userEmail = state.userEmail !== '' ? `&email=${encodeURIComponent(state.userEmail)}` : '';
    const orderNumber = state.orderNumber !== '' ? `&number=${state.orderNumber}` : '';
    const orderType = state.orderType.value !== '' ? `&type=${state.orderType.label}` : '';
    const currency = state.currency.length !== 0 ? `&currency=${stringGenerator(state.currency, 'currency_code')}` : '';
    const paymentMethod = state.paymentMethod.length !== 0 ? `&payment_method_name=${stringGenerator(state.paymentMethod, 'name')}` : ''
    const paymentStatus = state.paymentStatus.length !== 0 ? `&payment_status=${stringGenerator(state.paymentStatus, 'label')}` : '';


    const paymentDateStart = state.paymentDateStart !== null ? `&payment_date_start=${getCurrentDate(state.paymentDateStart)} 00:00:00&payment_status=PAID` : ''

    const paymentDateEnd = state.paymentDateEnd !== null ? `&payment_date_end=${getCurrentDate(state.paymentDateEnd)} 23:59:59&payment_status=PAID` : '';

    const shipmentMethod = state.shipmentMethod.length !== 0 ? `&shipment_method_name=${stringGenerator(state.shipmentMethod, 'name')}` : ''

    const shipmentDateFrom = state.shipmentDateFrom !== null ? `&shipment_date_start=${getCurrentDate(state.shipmentDateFrom)} 00:00:00` : '';

    const shipmentDateTo = state.shipmentDateTo !== null ? `&shipment_date_end=${getCurrentDate(state.shipmentDateTo)} 23:59:59` : ''

    const returnStatus = state.returnStatus.value !== '' ? `&return_status=${state.returnStatus.label}` : ''

    const returnDateFrom = state.returnDateFrom !== null ? `&return_date_start=${getCurrentDate(state.returnDateFrom)} 00:00:00` : ''

    const returnDateTo = state.returnDateTo !== null ? `&return_date_end=${getCurrentDate(state.returnDateTo)} 23:59:59` : ''

    const orderDateFrom = state.orderDateFrom !== null ? `&date_order_start=${getCurrentDate(state.orderDateFrom)} 00:00:00` : ''

    const orderDateTo = state.orderDateTo !== null ? `&date_order_end=${getCurrentDate(state.orderDateTo)} 23:59:59` : ''

    const productName = state.productName.length !== 0 ? `&product_name=${stringGenerator(state.productName, 'name')}` : ''

    const country = state.country.length !== 0 ? `&country=${stringGenerator(state.country, 'iso_3166_2')}` : ''

    const subscriptionStatus = state.subscriptionStatus.length !== 0 ? `&subscription_status=${state.subscriptionStatus.value}` : '';

    const packagesLenght = state.packagesLenght.length !== 0 ? `&packages=${state.packagesLenght.value}` : '';

    const subscriptionStartFrom = state.subscriptionStartFrom !== null ? `&subscription_last_start_date_start=${getCurrentDate(state.subscriptionStartFrom)} 00:00:00` : '';

    const subscriptionStartTo = state.subscriptionStartTo !== null ? `&subscription_last_start_date_end=${getCurrentDate(state.subscriptionStartTo)} 23:59:59` : '';


    query = `${orderNumber}${orderType}${currency}${paymentMethod}${paymentStatus}${paymentDateStart}${paymentDateEnd}${shipmentMethod}${shipmentDateFrom}${shipmentDateTo}${returnStatus}${returnDateFrom}${returnDateTo}${userLastName}${userEmail}${country}${productName}${nameQuery}${orderDateFrom}${orderDateTo}${subscriptionStatus}${packagesLenght}${subscriptionStartFrom}${subscriptionStartTo}`;
    onSetOrdersQueryAfterSearch(`${query}`);
    fakeLoading();
  };

  const resetForm = () => {
    onSetOrdersQueryAfterSearch();
    setState(initialState)
    fakeLoading();
  }

  const classes = useStyles();

  const orderTypeOptions = [
    { id: 1, label: 'Internetowe' },
    { id: 2, label: 'Telefoniczne' },
  ];

  const paymentStatusOptions = [
    { value: 1, label: 'PAID' },
    { value: 2, label: 'NOT_PAID' },
    { value: 3, label: 'FAILED' },
    { value: 4, label: 'CANCELLED' },
  ];

  const returnStatusOptions = [
    { value: 1, label: 'RETURNED' },
    { value: 2, label: 'NOT_RETURNED' },
  ];

  const subscriptionStatusOptions = [
    { value: 1, label: 'Aktywna' },
    { value: 2, label: 'Nieaktywna' },

  ];

  const packagesLenghtOptions = [
    { value: 1, label: '1 miesiąc' },
    { value: 2, label: '2 miesiące' },
    { value: 3, label: '3 miesiące' },
    { value: 6, label: '6 miesiący' },

  ];
  return (
    <Wrapper>
      <Form showSearch={showSearch} autocomplete="off">
        <FormControl className={classes.formControl}>
          <Typography variant="h6">Zamówienia: </Typography>
          <Row>
            <Col>
              <InputWrapper htmlFor="orderNumber">
                <Input
                  label="Numer zamówienia"
                  name="orderNumber"
                  type="number"
                  id="orderNumber"
                  onChange={handleSearch}
                  onChangeParams={['orderNumber', 'text']}
                  value={state.orderNumber}
                />
              </InputWrapper>

              <InputWrapper>
                <SelectInput
                  options={orderTypeOptions}
                  val={state.orderType}
                  noOptionsText="Nie znaleziono"
                  label="Typ zamówienia"
                  handleOnChange={handleSearch}
                  onChangeParams={['orderType', 'select']}
                  // value={orderType}
                  id="orderType"
                />
              </InputWrapper>
            </Col>
            <Col>

              <InputWrapperSM>
                <SelectInput
                  multiple
                  options={products}
                  optionType="name"
                  noOptionsText="Nie znaleziono"
                  label="Zawiera produkty"
                  handleOnChange={handleSearch}
                  onChangeParams={['productName', 'select']}
                  // value={orderType}
                  id="productName"
                />
              </InputWrapperSM>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                <InputWrapperSM>
                  <KeyboardDatePicker
                    disableFuture

                    autoOk
                    ampm={false}
                    // disableFuture
                    value={state.orderDateFrom}
                    onChange={handleOrderDateChangeFrom}
                    label="Data zamówienia od:"
                    showTodayButton
                    format="yyyy/MM/dd"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputWrapperSM>
                <InputWrapperSM>
                  <KeyboardDatePicker
                    disableFuture

                    autoOk
                    ampm={false}
                    // disableFuture
                    minDate={state.orderDateFrom}

                    value={state.orderDateTo}
                    onChange={handleOrderDateChangeTo}
                    label="Data zamówienia do:"
                    showTodayButton
                    format="yyyy/MM/dd"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputWrapperSM>
              </MuiPickersUtilsProvider>
            </Col>

          </Row>
          <Typography variant="h6">Płatności:</Typography>
          <Row>
            <Col>
              <InputWrapper>
                <SelectInput
                  multiple
                  options={paymentMethods}
                  optionType="name"
                  noOptionsText="Nie znaleziono"
                  label="Metody płatności"
                  handleOnChange={handleSearch}
                  onChangeParams={['paymentMethod', 'select']}
                  // value={orderType}
                  id="paymentMethod"
                />
              </InputWrapper>
              <InputWrapper>
                <SelectInputIcon
                  multiple
                  options={countries}
                  optionType="currency_code"
                  disabledOption={option =>
                    option.currency === '' ||
                    option.currency_code === '' ||
                    option.currency === null
                  }
                  noOptionsText="Nie znaleziono"
                  label="Waluta"
                  handleOnChange={handleSearch}
                  onChangeParams={['currency', 'select']}
                  name="currency1"
                  id="currency"
                />
              </InputWrapper>
            </Col>
            <Col>
              <InputWrapperSM>
                <SelectInput
                  multiple
                  options={paymentStatusOptions}
                  noOptionsText="Nie znaleziono"
                  label="Status płatnośći"
                  handleOnChange={handleSearch}
                  onChangeParams={['paymentStatus', 'select']}
                  // value={orderType}
                  id="paymentStatus"
                />
              </InputWrapperSM>

              {/* <DataPickerWrapper> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                <InputWrapperSM>
                  <KeyboardDatePicker
                    disableFuture

                    autoOk
                    ampm={false}
                    // disableFuture
                    value={state.paymentDateStart}
                    onChange={handlePaymentDateChangeFrom}
                    label="Data płatności od:"
                    showTodayButton
                    format="yyyy/MM/dd"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputWrapperSM>
                <InputWrapperSM>
                  <KeyboardDatePicker
                    disableFuture

                    autoOk
                    ampm={false}
                    // disableFuture
                    minDate={state.paymentDateStart}

                    value={state.paymentDateEnd}
                    onChange={handlePaymentDateChangeTo}
                    label="Data płatności do:"
                    showTodayButton
                    format="yyyy/MM/dd"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputWrapperSM>
              </MuiPickersUtilsProvider>
              {/* </DataPickerWrapper> */}
            </Col>
          </Row>
          <Typography variant="h6">Dostawa:</Typography>
          <Row>
            <InputWrapper>
              <SelectInput
                multiple
                options={shipmentsMethod}
                optionType="name"
                noOptionsText="Nie znaleziono"
                label="Metody dostawy"
                handleOnChange={handleSearch}
                onChangeParams={['shipmentMethod', 'select']}
                // value={orderType}
                id="shipmentMethod"
              />
            </InputWrapper>

            <DataPickerWrapper>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                <TwoImputs>
                  <InputWrapper>
                    <KeyboardDatePicker
                      disableFuture

                      autoOk
                      ampm={false}
                      // disableFuture
                      value={state.shipmentDateFrom}
                      onChange={handleShipmnetDateChangeFrom}
                      label="Data dostawy od:"
                      showTodayButton
                      format="yyyy/MM/dd"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <KeyboardDatePicker
                      disableFuture

                      autoOk
                      ampm={false}
                      minDate={state.shipmentDateFrom}
                      // disableFuture
                      value={state.shipmentDateTo}
                      onChange={handleShipmentDateChangeTo}
                      label="Data dostawy do:"
                      showTodayButton
                      format="yyyy/MM/dd"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </InputWrapper>
                </TwoImputs>
              </MuiPickersUtilsProvider>
            </DataPickerWrapper>
          </Row>

          <Typography variant="h6">Zwroty:</Typography>
          <Row>
            <InputWrapper>
              <SelectInput
                options={returnStatusOptions}
                optionType="label"
                val={state.returnStatus}
                noOptionsText="Nie znaleziono"
                label="Status zwrotu"
                handleOnChange={handleSearch}
                onChangeParams={['returnStatus', 'select']}
                // value={orderType}
                id="returnStatus"
              />
            </InputWrapper>

            <DataPickerWrapper>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                <TwoImputs>
                  <InputWrapper>
                    <KeyboardDatePicker
                      disableFuture
                      autoOk
                      ampm={false}
                      // disableFuture
                      value={state.returnDateFrom}
                      onChange={handleReturnDateChangeFrom}
                      label="Data zwrotu od:"
                      showTodayButton
                      format="yyyy/MM/dd"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <KeyboardDatePicker
                      disableFuture

                      autoOk
                      ampm={false}
                      // disableFuture
                      value={state.returnDateTo}
                      minDate={state.returnDateFrom}

                      onChange={handleReturnDateChangeTo}
                      label="Data zwrotu do:"
                      showTodayButton
                      format="yyyy/MM/dd"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </InputWrapper>
                </TwoImputs>
              </MuiPickersUtilsProvider>
            </DataPickerWrapper>
          </Row>
          <Typography variant="h6">Klient:</Typography>
          <Row>
            <Col>
              <InputWrapper htmlFor="userName">
                <Input
                  label="Imię"
                  name="userName"
                  type="text"
                  id="userName"
                  onChange={handleSearch}
                  onChangeParams={['userName', 'text']}
                  value={state.userName}
                />
              </InputWrapper>
              <InputWrapper htmlFor="userLastName">
                <Input
                  label="Nazwisko"
                  name="userLastName"
                  type="text"
                  id="userLastName"
                  onChange={handleSearch}
                  onChangeParams={['userLastName', 'text']}
                  value={state.userLastName}
                />
              </InputWrapper>
            </Col>
            <Col>
              <InputWrapper htmlFor="userEmail">
                <Input
                  label="E-mail"
                  name="userEmail"
                  type="text"
                  id="userEmail"
                  onChange={handleSearch}
                  onChangeParams={['userEmail', 'text']}
                  value={state.userEmail}
                />
              </InputWrapper>
              <InputWrapper>
                <SelectInputIcon
                  multiple
                  options={countries}
                  optionType="country"
                  disabledOption={option => option.name === ''}
                  noOptionsText="Nie znaleziono"
                  label="Kraj"
                  handleOnChange={handleSearch}
                  onChangeParams={['country', 'select']}
                  name="country"
                  id="country"
                />
              </InputWrapper>
            </Col>
          </Row>



          <Typography variant="h6">Subskrypcja:</Typography>
          <Row>
            <Col>
              <InputWrapper>
                <SelectInput
                  options={subscriptionStatusOptions}
                  noOptionsText="Nie znaleziono"
                  label="Status subskrypcji"
                  handleOnChange={handleSearch}
                  onChangeParams={['subscriptionStatus', 'select']}
                  // value={orderType}
                  id="subscriptionStatus"
                />
              </InputWrapper>
              <InputWrapper>
                <SelectInput
                  options={packagesLenghtOptions}
                  noOptionsText="Nie znaleziono"
                  label="Wybrany pakiet"
                  handleOnChange={handleSearch}
                  onChangeParams={['packagesLenght', 'select']}
                  // value={orderType}
                  id="packagesLenght"
                />
              </InputWrapper>
            </Col>

            <DataPickerWrapper>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                <TwoImputs>
                  <InputWrapper>
                    <KeyboardDatePicker
                      disableFuture
                      autoOk
                      ampm={false}
                      // disableFuture
                      value={state.subscriptionStartFrom}
                      onChange={handleSubscriptionStartFrom}
                      label="Rozpoczęcie susbskrypcji od:"
                      showTodayButton
                      format="yyyy/MM/dd"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <KeyboardDatePicker
                      disableFuture
                      autoOk
                      ampm={false}
                      value={state.subscriptionStartTo}
                      minDate={state.subscriptionStartFrom}

                      onChange={handleSubscriptionStartTo}
                      label="Rozpoczęcie susbskrypcji do:"
                      showTodayButton
                      format="yyyy/MM/dd"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </InputWrapper>
                </TwoImputs>
              </MuiPickersUtilsProvider>
            </DataPickerWrapper>

          </Row>


          <Row>
            {/* <Col> */}


            <ButtonWrapper>
              <SingleButton>
                <ButtonPrimary
                  type="button"
                  handleClick={resetForm}>
                  Resetuj wyszukiwanie
                </ButtonPrimary>
              </SingleButton>
              <ButtonPrimary
                type="button"
                handleClick={onHandleSearch}>
                Szukaj
                </ButtonPrimary>

            </ButtonWrapper>
            {/* </Col> */}
          </Row>
        </FormControl>


      </Form>
    </Wrapper>
  );
};

const mapStateToProps = state => {
  return {
    countries: state.countries.countries,
    paymentMethods: state.paymentMethods.paymentMethods,
    shipmentsMethod: state.shipmentsMethod.shipmentsMethod,
    products: state.products.products,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchCountries: () => dispatch(actionCreators.fetchCountries()),
    onFetchPaymentMethod: () => dispatch(actionCreators.fetchPaymentMethod()),
    onFetchShipmentsMethod: () => dispatch(actionCreators.fetchShipmentsMethod()),
    onSetOrdersQueryAfterSearch: query => dispatch(actionCreators.setOrdersQueryAfterSearch(query)),
    onFetchProducts: () => dispatch(actionCreators.fetchProducts()),

  };
};

// SearchOrders.propTypes = {
//     onSetOrdersQueryAfterSearch: PropTypes.func.isRequired,

//     mealsCategory: PropTypes.arrayOf(PropTypes.object).isRequired,
//     showSearch: PropTypes.bool.isRequired,
// };

export default connect(mapStateToProps, mapDispatchToProps)(SearchOrders);
