import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CouponTable from 'components/atoms/Table/CouponsTable';

const URLAPI = process.env.REACT_APP_URL_API;

const Wrapper = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  position: relative;
`;
const Coupon = ({
  onInitValidators,
  onFetchCoupon,
  onDeleteCoupons,
  coupon,
  products,
  unieqeCouponName,
  onFetchProducts,
  couponCounter,
  couponCfg
}) => {
  const [init, setInit] = useState(false);
  const [couponList, setCoupon] = useState([]);
  const [isOpen, setOpen] = useState([]);
  const [deleteConfirmVisable, setDeleteConfirmVisable] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [couponsConfig, setCouponsConfig] = useState({
    per_page: 15,
    current_page: 1,
    jumpToPage: null,
    couponsSearchQuery: '',
    // total: -1,
    data: [],
  });

  useEffect(() => {
    setCouponsConfig(coupon);
  }, [coupon]);

  useEffect(() => {
    const endpoint = `${URLAPI}coupon-create`;
    onInitValidators(endpoint, 'coupons');
  }, [coupon]);

  const setElementsForCurrentPage = () => {
    const endpoint = `coupon-create?page=${couponsConfig !== null ? couponsConfig.current_page : 1}&per_page=${couponsConfig !== null ? couponsConfig.per_page : 15}`;
    // ${ couponsConfig.couponsSearchQuery === undefined ? '' : couponsConfig.couponsSearchQuery }
    onFetchCoupon(endpoint);
    onFetchProducts();
  };

  useEffect(() => {
    if (couponList !== null && init === false && products.length === 0) {
      setElementsForCurrentPage();
      setInit(true);
    }
  }, [coupon]);

  useEffect(() => {
    setElementsForCurrentPage();
  }, [refresh]);

  useEffect(() => {
    const openArray = {};
    if (coupon.data === undefined) {
      return;
    }
    setCoupon(coupon.data);
    coupon.data.map(el => {
      const key = el.id
        .toString()
        .split('')
        .filter(e => e.trim().length)
        .join('');
      openArray[key] = false;
    });
    setOpen(openArray);
    setDeleteConfirmVisable(openArray);

  }, [coupon]);

  return (
    <Wrapper>
      <Link to="/discount-coupons/add-coupon">
        <IconButton>
          <AddIcon fontSize="large" />
        </IconButton>
      </Link>

      <CouponTable
        coupon={coupon}
        couponList={couponList}
        setCoupon={setCoupon}
        unieqeCouponName={unieqeCouponName}
        setOpen={setOpen}
        products={products}
        isOpen={isOpen}
        deleteConfirmVisable={deleteConfirmVisable}
        setDeleteConfirmVisable={setDeleteConfirmVisable}
        onDeleteCoupons={onDeleteCoupons}
        couponCounter={couponCounter}
        setRefresh={setRefresh}
        couponsConfig={couponsConfig}
        setCouponsConfig={setCouponsConfig}
      />
    </Wrapper>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.error,
    coupon: state.coupons.coupon,
    couponCounter: state.coupons.couponCounter,
    unieqeCouponName: state.validators.validators,
    products: state.products.products,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
    onFetchCoupon: (endpoint) => dispatch(actionCreators.fetchCoupon(endpoint)),
    onDeleteCoupons: id => dispatch(actionCreators.deleteCoupons(id)),
    onFetchProducts: () => dispatch(actionCreators.fetchProducts()),
    onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),
    onAddCouponsForm: (name, description, price, isEnabled, resetForm) =>
      dispatch(actionCreators.addCouponsForm(name, description, price, isEnabled, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
