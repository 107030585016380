import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
  coupon: [],
  singleCoupon: [],
};
// GET
const setCoupon = (state, action) => {
  return {
    ...state,
    coupon: action.coupon,
    couponCounter: action.couponCounter,
    error: false,
  };
};

// GET ONE
const setSingleCoupon = (state, action) => {
  return {
    ...state,
    singleCoupon: action.singleCoupon,
    error: false,
  };
};

// DELETE
const deleteCouponSuccess = (state, action) => {
  return {
    ...state,
    coupon: [...state.coupon.data.filter(method => method.code !== action.code)],
  };
};

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COUPON:
      return setCoupon(state, action);
    case actionTypes.DELETE_COUPON_SUCCESS:
      return deleteCouponSuccess(state, action);
    case actionTypes.SET_SINGLE_COUPON:
      return setSingleCoupon(state, action);
    default:
      return state;
  }
};

export default couponReducer;
