import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    color:rgb(46, 45, 65);
    font-size: 1rem;
    width: 100%;
    height: 100%;
    font-weight: bold;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    outline: 0;
    padding: 6px 0 6px 0;
   

`

const InListButton = ({ children, handleOnClick }) => {

    return (
        <Button
            onMouseDown={e => handleOnClick(e)}
        >
            {children}
        </Button>
    );
}

export default InListButton;