import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddIngredientCategory from 'views/IngredientsCategory/AddIngredientCategory/AddIngredientCategory';
import IngredientsCategoryTable from 'components/atoms/Table/IngredientsCategoryTable'
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';

const URLAPI = process.env.REACT_APP_URL_API;


const SectionAddCategory = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  position: relative;
`
const IngredientsCategory = ({
  onfetchIngredientsCategory,
  igCategory,
  onDeleteIngredientCategory,
  onInitValidators,
  unieqeIngredientsCategoryNames,
  status,
  responseError,
  onSubmitAddIngredientCategoryForm

}) => {
  const [visible, setVisible] = useState(false)
  const [igCat, setIgCat] = useState([]);
  const [isOpen, setOpen] = useState([]);
  const [deleteConfirmVisable, setDeleteConfirmVisable] = useState([]);
  const [init, setInit] = useState(false);
  const [initEdit, setInitEdit] = useState(false);

  const showAddIgCategory = () => {
    setVisible(!visible)
  }

  const setElementsForCurrentPage = () => {
    onfetchIngredientsCategory()
  }

  useEffect(() => {
    if (igCat !== null && init === false) {
      setElementsForCurrentPage()
      setInit(true)
    }
  }, [igCat])

  useEffect(() => {
    const endpoint = `${URLAPI}ingredient-category/name`
    onInitValidators(endpoint, 'ingredientsCategory')
  }, [])

  useEffect(() => {
    const openArray = {}
    setIgCat(igCategory);

    igCategory.map(el => {
      const key = (el.name + el.id.toString()).split('').filter(e => e.trim().length).join('');
      openArray[key] = false
    })
    setOpen(openArray)
    setDeleteConfirmVisable(openArray)

  }, [igCategory])

  const setElementsForAddIngredients = () => {
    onfetchIngredientsCategory()
  }

  useEffect(() => {
    if (initEdit === false) {
      setElementsForAddIngredients()
      setInitEdit(true)
    }
  }, [igCategory])

  useEffect(() => {
    const endpoint = `${URLAPI}ingredient-category/name`
    onInitValidators(endpoint, 'ingredientsCategory')
  }, [igCategory])

  return (
    <SectionAddCategory>
      <IconButton
        onClick={() => showAddIgCategory()}
      >
        <AddIcon fontSize="large" />
      </IconButton>
      {visible && <AddIngredientCategory
        setVisible={setVisible}
        igCategory={igCategory}
        status={status}
        responseError={responseError}
        unieqeIngredientsCategoryNames={unieqeIngredientsCategoryNames}
        onSubmitAddIngredientCategoryForm={onSubmitAddIngredientCategoryForm}
      />}

      <IngredientsCategoryTable
        isOpen={isOpen}
        setOpen={setOpen}
        deleteConfirmVisable={deleteConfirmVisable}
        setDeleteConfirmVisable={setDeleteConfirmVisable}
        igCat={igCat}
        unieqeIngredientsCategoryNames={unieqeIngredientsCategoryNames}
        onDeleteIngredientCategory={onDeleteIngredientCategory}
      />
    </SectionAddCategory>
  );
}

const mapStateToProps = state => {
  return {
    igCategory: state.igCat.ingredientsCategory,
    unieqeIngredientsCategoryNames: state.validators.validators,
    status: state.notifications.status,
    responseError: state.notifications.responseError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onfetchIngredientsCategory: () => dispatch(actionCreators.fetchIngredientsCategory()),
    onDeleteIngredientCategory: id => dispatch(actionCreators.deleteIngredientCategory(id)),
    onInitValidators: (endpoint, key) => dispatch(actionCreators.initValidators(endpoint, key)),
    onSubmitAddIngredientCategoryForm: (name, resetForm) => dispatch(actionCreators.submitAddIngredientCategoryForm(name, resetForm)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientsCategory);

