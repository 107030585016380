import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
    shipmentsMethod: [],
};
// GET
const setShipmentsMethod = (state, action) => {
    return {
        ...state,
        shipmentsMethod: action.shipmentsMethod,
        error: false,
    }
}

// GET ONE 
const setSingleShipmentsMethod = (state, action) => {
    return {
        ...state,
        singleShipmentsMethod: action.singleShipmentsMethod,
        error: false,
    };
};

// DELETE
const deleteShipmentsMethodSuccess = (state, action) => {
    return {
        ...state,
        shipmentsMethod: [...state.shipmentsMethod.filter(method => method.name !== action.name)],
    };
};


const shipmentsMethodReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SHIPMENTS_METHOD:
            return setShipmentsMethod(state, action);
        case actionTypes.DELETE_SHIPMENTS_METHOD_SUCCESS:
            return deleteShipmentsMethodSuccess(state, action);
        case actionTypes.SET_SINGLE_SHIPMENTS_METHOD:
            return setSingleShipmentsMethod(state, action);
        default:
            return state;
    }
};

export default shipmentsMethodReducer;
