import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions/index';
import styled from 'styled-components';
import NotificationElement from 'components/Notifications/NotificationElement'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 16px;
    top: 16px;
    /* background-color: blue; */
    /* width:  500px;
    height:500px; */
    z-index:1000;
`

const NotificationsList = ({ notifications, onRemoveUnusedNotifiaction }) => {
  useEffect(() => {
    if (notifications != null && notifications.length > 1) {
      const timeout = setTimeout(() => {
        onRemoveUnusedNotifiaction()

      }, 10000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [notifications])

  return (
    <Wrapper>
      {notifications != null ? notifications.map(el =>
        <NotificationElement key={el?.id} notification={el} />) : null
      }
    </Wrapper>

  );
}

// export default NotificationsList;

const mapStateToProps = state => {
  return {
    notifications: state.notifications.notifications,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRemoveUnusedNotifiaction: () => dispatch(actionCreators.removeUnusedNotifiaction())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);

