import Cookies from 'js-cookie';

function deleteCookie(cname) {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

const currentUser = Cookies.getJSON('currentUser');
if (currentUser) {
  if (currentUser.tokenExpires < currentUser.tokenCreated) {
    deleteCookie('authKey');
    deleteCookie('currentUser');
  }
}
const initialState =
  currentUser && currentUser.tokenExpires > currentUser.tokenCreated
    ? { loggedIn: true, currentUser }
    : {
        loggedIn: false,
        currentUser: {},
      };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loggedIn: true,
        currentUser: action.currentUser,
      };

    case 'LOGIN_FAILED':
      return {
        ...state,
        loggedIn: false,
        currentUser: initialState,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        loggedIn: false,
        currentUser: {},
      };
    default:
      return state;
  }
}
