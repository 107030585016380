import React from 'react';
import styled from 'styled-components';
import Input from 'components/atoms/Inputs/Input';
import SelectImput from 'components/atoms/Inputs/SelectInput'

const Wrapper = styled.div`
  display: block;
  margin: 16px auto 32px auto;
  min-width: 120px;
  width: 100%;
  height:auto;
  padding: 32px;
  padding-bottom: 1px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
`;



const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const SelectWrapper = styled.div`
  width: 15%;
  margin-bottom: 16px;
`;

const SearchOrders = ({
  setSearch,
  search
}) => {

  const handleChangeInput = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    })
  };

  const handleChangeSelect = (e, key) => {
    setSearch({
      ...search,
      [key]: e
    })
  }

  const selectOptions = [
    { value: 0, label: 'Nie zawiera' },
    { value: 1, label: 'Zawiera' },
  ];

  return (
    <Wrapper>
      <InputWrapper>
        <Input
          label="Nazwa pakietu"
          name="name"
          type="text"
          id="name"
          onChange={handleChangeInput}
          onChangeParams={['name']}
        // value={state.userEmail}
        />
      </InputWrapper>
      <Row>
        <SelectWrapper>
          <SelectImput
            options={selectOptions}
            noOptionsText="Nieprawidłowa wartość"
            label="Nabiał"
            handleOnChange={handleChangeSelect}
            onChangeParams={['dairy']}
            id="dairy"
          />
        </SelectWrapper>

        <SelectWrapper>
          <SelectImput
            options={selectOptions}
            noOptionsText="Nieprawidłowa wartość"
            label="Ryby"
            handleOnChange={handleChangeSelect}
            onChangeParams={['fish']}
            id="fish"
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectImput
            options={selectOptions}
            noOptionsText="Nieprawidłowa wartość"
            label="Gluten"
            handleOnChange={handleChangeSelect}
            onChangeParams={['gluten']}
            id="gluten"
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectImput
            options={selectOptions}
            noOptionsText="Nieprawidłowa wartość"
            label="Laktoza"
            handleOnChange={handleChangeSelect}
            onChangeParams={['lactose']}
            id="lactose"
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectImput
            options={selectOptions}
            noOptionsText="Nieprawidłowa wartość"
            label="Mięso"
            handleOnChange={handleChangeSelect}
            onChangeParams={['meat']}
            id="meat"
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectImput
            options={selectOptions}
            noOptionsText="Nieprawidłowa wartość"
            label="Owoce morza"
            handleOnChange={handleChangeSelect}
            onChangeParams={['seafood']}
            id="seafood"
          />
        </SelectWrapper>


      </Row>
    </Wrapper >
  );
};

export default SearchOrders
