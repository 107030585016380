/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ConfirmDelete from 'components/atoms/ConfirmDelete/ConfirmDelete';
import Loading from 'components/atoms/Loading/Loading';
import PriceTable from 'components/atoms/PriceTable/PriceTable';
import EurIcon from 'assets/EUR.png';
import UsdIcon from 'assets/USD.png';
import Checkbox from '@material-ui/core/Checkbox';
import XlsxIcon from 'assets/xlsx.png';

import Xlsx from 'json-as-xlsx';

const URLAPI = process.env.REACT_APP_URL_API;
const URLBASE = process.env.REACT_APP_URL_BASE;

const CSVicon = styled.img`
  width: 32px;
  height: 32px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -16px;
`;
const ButtonsWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  width: 200px;
`;

const TableWrapper = styled.div`
  overflow: scroll;
  overflow-y: auto;
`;
const Scrolable = styled.div`
  width: 100%;
`;

const PagnationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
`;

const ColorInfo = styled.div`
  color: ${({ theme, type }) => theme.palette[type].main};
`;

const IconsWrapper = styled.div`
  position: relative;
`;

const FlagIcon = styled.img`
  margin-right: 8px;
`;

const Bubble = styled.div`
  padding: 8px;
  position: absolute;
  opacity: 0;
  z-index: 20;
  bottom: 25px;
  left: 50%;
  color: ${({ theme }) => theme.palette.common.white};
  width: 300px;
  transition: opacity 0.4s ease-in-out;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 5px 5px 5px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  &:after {
  }
`;
const BublePointer = styled.div`
  position: absolute;
  z-index: 19;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  bottom: 15px;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: rgba(46, 45, 65, 1);
  border-bottom: 0;
  border-left: 0;
`;

const NotesShortText = styled.div`
  width: 130px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const NotesWrapper = styled.div`
  position: relative;
  &:hover ${Bubble} {
    opacity: 1;
  }
  &:hover ${BublePointer} {
    border-top-color: rgba(46, 45, 65, 1);
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
  }
`;

const Info = styled.div`
  margin: 16px 0;
  font-weight: 700;
`;

const Adress = styled.p`
  margin-bottom: 5px;
`;

const CurrencyWrapper = styled.div`
  display: inline-block;
  font-weight: 700;
  margin: 0 16px 16px 0;
`;

const FW400 = styled.span`
  font-weight: 400;
`;

const CouponCode = styled.span`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
`;

const CouponDescription = styled.span`
  font-weight: 700;
`;

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const ButtonError = withStyles(theme => ({
  root: {
    width: `130px`,
    cursor: 'pointer',
    boxShadow: 'none',
    textTransform: 'uppercase',
    border: '1px solid',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
      boxShadow: 'none',
      cursor: 'pointer',
    },
    '&:disabled': {
      coursor: 'notAllowed',
    },
  },
}))(Button);

const ButtonSucces = withStyles(theme => ({
  root: {
    width: '130px',
    boxShadow: 'none',
    textTransform: 'uppercase',
    border: '1px solid',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,

    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.success.main,
      boxShadow: 'none',
    },
  },
}))(Button);

const tableStyles = makeStyles({
  root: {
    width: '2500px',
  },
  container: {
    maxHeight: '80vh',
  },
  table: {
    minWidth: 700,
  },
});

const tablePaginationStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = tablePaginationStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 1);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage)));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const tableHeaders = [
  { id: 'id', label: 'id', width: '1%', align: 'center', type: 'id' }, //
  { id: 'number', label: 'Numer zamówienia', width: '7%', align: 'center', type: 'orderNumber' }, //
  { id: 'userName', label: 'Dane klienta', width: '5.4%', align: 'center', type: 'string' },
  {
    id: 'userAdress',
    label: 'Adres klienta',
    width: '10%',
    align: 'center',
    type: 'userAdress',
  }, //
  { id: 'userEmail', label: 'E-mail', width: '4%', align: 'center', type: 'string' },
  { id: 'phoneNumber', label: 'Telefon', width: '5.4%', align: 'center', type: 'string' },
  { id: 'type', label: 'Typ zamówienia', width: '5.4%', align: 'center', type: 'string' },
  { id: 'products', label: 'Produkty', width: '8.4%', align: 'center', type: 'products' },
  { id: 'totalPrice', label: 'Wartość zamówienia', width: '5.4%', align: 'center', type: 'string' },
  { id: 'coupon', label: 'Kupon', width: '5.4%', align: 'center', type: 'string' },
  { id: 'orderDate', label: 'Data zamówienia', width: '5.4%', align: 'center', type: 'string' },
  // {
  //   id: 'orderStatus',
  //   label: 'Status zamówienia',
  //   width: '5.4%',
  //   align: 'center',
  //   type: 'orderStatus',
  // },
  { id: 'notes', label: 'Notatka', width: '5.4%', align: 'center', type: 'notes' },
  {
    id: 'paymentMethod',
    label: 'Metoda płatności',
    width: '5.4%',
    align: 'center',
    type: 'string',
  },
  { id: 'shipmentMehtod', label: 'Metoda dostawy', width: '5.4%', align: 'center', type: 'string' },
  {
    id: 'shipmentStatus',
    label: 'Status dostawy',
    width: '5.4%',
    align: 'center',
    type: 'shipmentStatus',
  },
  {
    id: 'paymentStatus',
    label: 'Status płatności',
    width: '5.4%',
    align: 'center',
    type: 'paymentStatus',
  },
  {
    id: 'returnStatus',
    label: 'Status zwrotu',
    width: '5.4%',
    align: 'center',
    type: 'returnStatus',
  },
  { id: 'paymentDate', label: 'Data płatności', width: '0%', align: 'center', type: 'hidden' },
  { id: 'transactionNo', label: 'nr transakcji', width: '0%', align: 'center', type: 'hidden' },
  {
    id: 'transactionNo_stripe_id',
    label: 'nr transakcji stripe',
    width: '0%',
    align: 'center',
    type: 'hidden',
  },
  {
    id: 'transactionNo_paypal_id',
    label: 'nr transakcji paypal',
    width: '0%',
    align: 'center',
    type: 'hidden',
  },
  { id: 'mid', label: 'przelicznik', width: '0%', align: 'center', type: 'hidden' },
  { id: 'actions,', label: 'Akcje', width: '8%', align: 'center', type: 'buttons' },
];

const TableComponent = ({
  ordersCfg,
  orders,
  onInitOrders,
  ordersSearchQuery,
  onUpdateStatus,
  onDeleteOrders,
  onFetchCountries,
  countries,
  loadingAfterSearch,
  onDeleteManyOrders,
  allCoupons,
}) => {
  const classes = tableStyles();
  const [refresh, setRefresh] = useState(0);
  const [init, setInit] = useState(false);
  const [ordersList, setOrdersList] = useState([]);
  const [deleteConfirmVisable, setDeleteConfirmVisable] = useState([]);
  const [selected, setSelected] = useState([]);
  // const [csvData, setCsvData] = useState([]);
  const [uniqueCurrencies, setUniqueCurrencies] = useState([]);
  const [uniqueCoupons, setUniqueCoupons] = useState([]);
  const [cuponsUsed, setCuponsUsed] = useState([]);
  const [ordersConfig, setOrdersConfig] = useState({
    per_page: 100,
    current_page: 1,
    jumpToPage: null,
    ordersSearchQuery: '',
    // total: -1,
    data: [],
  });
  const [countryIso, setCountryIso] = useState([]);
  const createDataFuncVals = tableHeaders.map(el => el.id);
  useEffect(() => {
    setOrdersConfig(ordersCfg);
  }, [ordersCfg]);

  useEffect(() => {
    onFetchCountries();
  }, []);

  useEffect(() => {
    const openArray = {};
    setOrdersList(orders);
    if (orders && ordersCfg) {
      orders.map(el => {
        const key = el.number.toString();
        openArray[key] = false;
      });
      setDeleteConfirmVisable(openArray);
    }
  }, [orders, ordersCfg]);

  const setElementsForCurrentPage = () => {
    const endpoint = `${URLAPI}orders?page=${ordersConfig?.current_page}&per_page=${ordersConfig?.per_page}${ordersSearchQuery}`;
    onInitOrders(endpoint);
  };

  useEffect(() => {
    if (ordersConfig !== null && init === false) {
      setElementsForCurrentPage();
      setInit(true);
    }
  }, [ordersCfg, orders]);

  const createData = (...params) => {
    const obj = {};
    // eslint-disable-next-line no-return-assign
    createDataFuncVals.map((el, index) => (obj[el] = params[index]));
    return obj;
  };

  const findCuponNameTable = ids => {
    const splitedIds = ids.split(',');
    const fitredCupon = splitedIds.map(id => allCoupons?.filter(coupon => coupon.id === id)[0]);
    if (fitredCupon) {
      return fitredCupon.map(coupon => coupon?.description);
    } else {
      return null;
    }
  };

  let data = [];
  if (ordersList.length > 0) {
    data = ordersList.map(el => {
      const paymentDate = el.payment_date !== null ? el.payment_date.split(' ') : ['', ''];

      return {
        id: el.id,
        number: el.number,
        userName: `${el?.user?.first_name} ${el?.user?.last_name}`,
        userAdress: `${el?.user?.street} ${el?.user?.house_number} ,${el?.user?.post_code} ${el?.user?.city},${el?.user?.country}`,
        userEmail: el?.user?.email,
        phoneNumber: el?.user?.phone_number,
        type: el.type,
        products: [el.products, el.currency],
        totalPrice: (
          <div>
            <p>
              {el.total} {el.currency}
            </p>
            {el.mid && <p>{(el.total * el.mid * 1).toFixed(2)}&nbsp;zł</p>}
          </div>
        ),
        coupon: el.coupon != null ? findCuponNameTable(el.coupon) : '',
        orderDate: el.date_order,
        notes: el.notes,
        paymentMethod: el.payment_method_name,
        shipmentMehtod: el.shipment_method_name,
        shipmentStatus: el.shipment_status,
        paymentStatus: el.payment_status,
        returnStatus: el.return_status,
        paymentDate: paymentDate[0],
        transactionNo: el.transaction_number,
        transactionNo_stripe_id: el?.user?.subscription_customer_id,
        transactionNo_paypal_id: el?.user?.subscription_paypal_payer_id,
        mid: el.mid * 1,
      };
    });
  }

  const rows = data.map(el => {
    const funcParams = [];
    Object.keys(el).map(key => {
      funcParams.push(el[key]);
      return funcParams;
    });
    return createData(...funcParams);
  });

  const handleChangePage = (event, newPage) => {
    setOrdersConfig(state => ({
      ...state,
      current_page: newPage,
    }));
    setRefresh(Math.random());
  };

  const handleChangeRowsPerPage = event => {
    setOrdersConfig(state => ({
      ...state,
      per_page: event.target.value * 1,
      current_page: 1,
    }));
    setRefresh(Math.random());
  };

  useEffect(() => {
    setElementsForCurrentPage();
  }, [refresh]);

  useEffect(() => {
    setElementsForCurrentPage();
  }, [ordersSearchQuery]);

  const openDeleteConfirm = key => {
    setDeleteConfirmVisable(deleteConfirmVisable => ({
      ...deleteConfirmVisable,
      [key]: !deleteConfirmVisable[key],
    }));
  };

  const getCurrentDate = () => {
    const newDate = new Date();
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const hour = newDate.getHours();
    const min = newDate.getMinutes();
    const sec = newDate.getSeconds();
    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`} ${
      hour < 10 ? `0${hour}` : `${hour}`
    }:${min < 10 ? `0${min}` : `${min}`}:${sec < 10 ? `0${sec}` : `${sec}`}`;
  };

  const handleChangeStatus = (id, status) => {
    const endpointSearch = `${URLAPI}orders?page=${ordersConfig?.current_page}&per_page=${ordersConfig?.per_page}${ordersSearchQuery}`;
    const currentData = { shipment_date: getCurrentDate() };
    if (status === 'NOT_SHIPPED') {
      const endpoint = `${URLAPI}orders/${id}/shipments/shipped`;
      onUpdateStatus(endpoint, currentData, endpointSearch);
    } else {
      const endpoint = `${URLAPI}orders/${id}/shipments/not-shipped`;
      onUpdateStatus(endpoint, currentData, endpointSearch);
    }
  };

  const handleChangePaymentStatus = (id, status) => {
    const endpointSearch = `${URLAPI}orders?page=${ordersConfig?.current_page}&per_page=${ordersConfig?.per_page}${ordersSearchQuery}`;
    const currentData = { payment_date: getCurrentDate() };
    if (status === 'NOT_PAID') {
      const endpoint = `${URLAPI}orders/${id}/payments/paid`;
      onUpdateStatus(endpoint, currentData, endpointSearch);
    } else {
      const endpoint = `${URLAPI}orders/${id}/payments/not-paid`;
      onUpdateStatus(endpoint, currentData, endpointSearch);
    }
  };

  const handleChangeReturnStatus = (id, status) => {
    const endpointSearch = `${URLAPI}orders?page=${ordersConfig?.current_page}&per_page=${ordersConfig?.per_page}${ordersSearchQuery}`;
    const currentData = { return_date: getCurrentDate() };
    if (status === 'NOT_RETURNED') {
      const endpoint = `${URLAPI}orders/${id}/returns/returned`;
      onUpdateStatus(endpoint, currentData, endpointSearch);
    } else {
      const endpoint = `${URLAPI}orders/${id}/returns/not-returned`;
      onUpdateStatus(endpoint, currentData, endpointSearch);
    }
  };

  useEffect(() => {
    if (orders) {
      setUniqueCurrencies([...new Set(orders.map(item => item.currency))]);
    }
  }, [orders]);

  const getSumOfTotalsCurrencies = () => {
    const totalPayments = [];
    for (let i = 0; i < uniqueCurrencies.length; i++) {
      const filtred = orders.filter(order => order.currency === uniqueCurrencies[i]);
      totalPayments.push({
        [uniqueCurrencies[i]]: filtred.reduce((a, b) => ({ total: a.total * 1 + b.total * 1 }), {
          total: 0,
        }),
      });
    }
    return totalPayments;
  };

  useEffect(() => {
    const couponsUsed = [];

    if (orders) {
      orders.map(order => {
        if (order.coupon !== null) {
          order.coupon.split(',').map(el => couponsUsed.push(el));
        }
      });
    }
    setUniqueCoupons([...new Set(couponsUsed.map(item => item))]);
    setCuponsUsed(couponsUsed);
  }, [orders]);

  const getSumOfTotalsCupons = () => {
    const totalCupons = [];

    for (let i = 0; i < uniqueCoupons.length; i++) {
      const filtred = cuponsUsed.filter(coupon => coupon === uniqueCoupons[i]);
      totalCupons.push({
        [uniqueCoupons[i]]: filtred.length,
      });
    }
    return totalCupons;
  };

  const isSelected = name => selected.indexOf(name) !== -1;
  const handleSelectRow = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const findCuponName = id => {
    const fitredCupon = allCoupons.filter(coupon => coupon.id === id)[0];
    if (fitredCupon !== undefined) {
      return (
        <>
          <CouponDescription>{fitredCupon?.description}</CouponDescription>
          {' - '}
          <CouponCode>{fitredCupon?.code}</CouponCode>
        </>
      );
    } else {
      return null;
    }
  };

  const HandleCreateXlsx = () => {
    const settings = {
      fileName: 'Orders',
      extraLength: 3,
      writeOptions: {},
    };
    const download = true;
    const columns = [
      { label: 'number', value: 'number' },
      { label: 'userName', value: 'userName' },
      { label: 'userAdress', value: 'userAdress' },
      { label: 'userIp', value: 'userIp' },
      { label: 'country', value: 'country' },
      { label: 'userEmail', value: 'userEmail' },
      { label: 'phoneNumber', value: 'phoneNumber' },
      { label: ' type', value: ' type' },
      { label: 'products', value: 'products' },
      { label: 'totalPrice', value: 'totalPrice' },
      { label: 'coupon', value: 'coupon' },
      { label: 'currency', value: 'currency' },
      { label: 'orderDate', value: 'orderDate' },
      { label: 'orderDateTime', value: 'orderDateTime' },
      { label: 'notes', value: ' notess' },
      { label: 'paymentMethod', value: 'paymentMethod' },
      { label: 'shipmentMehtod', value: 'shipmentMehtod' },
      { label: 'shipmentStatus', value: 'shipmentStatus' },
      { label: 'paymentStatus', value: 'paymentStatus' },
      { label: 'returnStatus', value: 'returnStatus' },
      { label: 'paymentDate', value: 'paymentDate' },
      { label: 'paymentDateTime', value: 'paymentDateTime' },
      { label: 'transactionNumber', value: 'transactionNumber' },
      { label: 'login date', value: 'login_date' },
      { label: 'status subskrypcji', value: 'subscription_status' },
      { label: 'data rozpoczęcia subskrypcji', value: 'subscription_last_start_date' },
      { label: 'czas rozpoczęcia subskrypcji', value: 'subscription_last_start_time' },
    ];

    const dataCsv = ordersList.map(el => {
      const orderDate = el.date_order.split(' ');
      const paymentDate = el.payment_date !== null ? el.payment_date.split(' ') : ['', ''];
      const productNames = el.products.map(product => product.name).toString();
      const subscriptionDate =
        el.user?.subscription_last_start_date !== null
          ? el.user?.subscription_last_start_date.split(' ')
          : ['brak danych', 'brak danych'];
      return {
        number: el.number,
        userName: `${el.user?.first_name} ${el.user?.last_name}`,
        userAdress: `${el.user?.street} ${el.user?.house_number} ,${el.user?.post_code} ${el.user?.city},${el.user?.country}`,
        userIp: el.user_ip,
        country: el.user?.country,
        userEmail: el.user?.email,
        phoneNumber: `${el.user?.phone_prefix} ${el.user?.phone_number}`,
        type: el.type,
        products: productNames,
        totalPrice: (el.total * 1).toFixed(0) * 1,
        coupon: el.coupon != null ? findCuponNameTable(el.coupon) : '',
        notess: el.notes,
        currency: el.currency,

        orderDate: orderDate[0].split('-').join('.'),
        orderDateTime: orderDate[1].split(':').join('.'),
        paymentMethod: el.payment_method_name,
        shipmentMehtod: el.shipment_method_name,
        shipmentStatus: el.shipment_status,
        paymentStatus: el.payment_status,
        returnStatus: el.return_status,
        paymentDate: paymentDate[0].split('-').join('.'),
        paymentDateTime: paymentDate[1].split(':').join('.'),
        transactionNumber: el?.transaction_number,
        login_date: el.login_date.join(';'),
        subscription_status: el.user?.subscription_status,
        subscription_last_start_date: subscriptionDate ? subscriptionDate[0] : '0',
        subscription_last_start_time: subscriptionDate ? subscriptionDate[1] : '0',
      };
    });
    return Xlsx(columns, dataCsv, settings, download);
  };

  const EwidencjaSprzedazy = async () => {
    const vat = 0.23;
    const settings = {
      fileName: 'Ewidencja Sprzedazy',
      extraLength: 3,
      writeOptions: {},
    };
    const download = true;
    const columns = [
      { value: 'number', label: 'numer zamówienia' },
      { value: 'userName', label: 'Imię i nazwisko' },
      { value: 'userAdress', label: 'adres zamieszkania' },
      { value: 'country', label: 'kraj' },
      { value: 'countryCode', label: 'Skrót kraju' },
      { value: 'userEmail', label: 'e-mail' },
      { value: 'phoneNumber', label: 'nr telefonu' },
      { value: 'products', label: 'produkt' },
      { value: row => `${row.vat} %`, label: 'stawka VAT' },
      { value: 'gross', label: 'wartość brutto' },
      { value: 'net', label: 'wartość netto' },
      { value: 'tax', label: 'wartość VAT' },
      { value: 'currency', label: 'waluta' },
      { value: 'exchange', label: 'kurs' },
      { value: 'grossPL', label: 'wartość brutto przeliczona do PLN' },
      { value: 'netPL', label: 'wartość netto przeliczona do PLN' },
      { value: 'taxPL', label: 'wartość VAT przeliczona do PLN' },
      { value: 'orderDate', label: 'data zamówienia' },
      { value: 'paymentMethod', label: 'metoda płatności' },
      { value: 'paymentDate', label: 'data patności' },
      { value: 'transactionNumber', label: 'Numer transkacji' },
      { label: 'userIp', value: 'userIp' },
    ];

    const getDataUserInfo = async () => {
      return ordersList.map(async el => {
        const productNames = el.products.map(product => product.name).toString();

        const netPL = (el.total * el.mid - (el.total * vat * el.mid) / (1 + vat)).toFixed(2);
        const taxPL = ((el.total * vat * el.mid) / (1 + vat)).toFixed(2);

        const net = (el.total - (el.total * vat) / (1 + vat)).toFixed(2);
        const tax = ((el.total * vat) / (1 + vat)).toFixed(2);

        const grossPL = (el.total * 1 * (el.mid * 1)).toFixed(2);

        const orderDate = el.date_order.split(' ');
        const paymentDate = el.payment_date !== null ? el.payment_date.split(' ') : ['', ''];

        const ip = el.user_ip;
        const url = `https://api.ipstack.com/${ip}?access_key=deb72477ea37efd3308c957ef3dbe79b&fields=country_code`;
        const result = await fetch(url);
        const resp = await result.json();
        const country = resp?.country_code ?? '-';

        return {
          number: el.number,
          userName: `${el.user?.first_name} ${el.user?.last_name}`,
          userAdress: `${el.user?.street} ${el.user?.house_number} ,${el.user?.post_code} ${el.user?.city},${el.user?.country}`,
          country: `${el.user?.country}`,
          countryCode: country,
          userEmail: `${el.user?.email}`,
          phoneNumber: `${el.user?.phone_prefix} ${el.user?.phone_number}`,
          products: productNames,
          vat: vat * 100,
          gross: el.total * 1,
          net: net * 1,
          tax: tax * 1,
          grossPL: el.mid === null ? '' : grossPL * 1,
          netPL: el.mid === null ? '' : netPL * 1,
          taxPL: el.mid === null ? '' : taxPL * 1,
          currency: el.currency,
          exchange: el.mid === null ? '' : el.mid * 1,
          orderDate: orderDate[0],
          paymentMethod: el.payment_method_name,
          paymentDate: paymentDate[0].split('-').join('.'),
          transactionNumber: el?.transaction_number,
          userIp: el.user_ip,
        };
      });
    };

    const dataCsv = await getDataUserInfo();
    const getDataCsv = await Promise.all(dataCsv);
    return Xlsx(columns, getDataCsv, settings, download);
  };

  const handleDeleteMany = () => {
    const endpointSearch = `${URLAPI}orders?page=${ordersConfig?.current_page}&per_page=${ordersConfig?.per_page}${ordersSearchQuery}`;
    onDeleteManyOrders(selected, endpointSearch);
  };

  const flagGenerator = el => {
    if (Object.keys(el)[0] === 'EUR') {
      return <FlagIcon src={EurIcon} alt="EUR" />;
    } else if (Object.keys(el)[0] === 'USD') {
      return <FlagIcon src={UsdIcon} alt="USD" />;
    }
    return (
      <FlagIcon
        src={`${URLBASE}storage/flags/${
          countries.length !== 0
            ? countries.find(country => country.currency_code === Object.keys(el)[0])?.flag
            : ''
        }`}
        alt={Object.keys(el)[0]}
      />
    );
  };

  if (ordersConfig === null || loadingAfterSearch || orders === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        <Info>
          <Typography>
            Znalezionych: <FW400>{orders.length}</FW400>
          </Typography>
          <Typography>Sumy:</Typography>
          {orders &&
            getSumOfTotalsCurrencies().map(el => {
              return (
                <CurrencyWrapper key={Object.keys(el)}>
                  {flagGenerator(el)}
                  {Object.keys(el)}:{' '}
                  <FW400>
                    {el[Object.keys(el)].total.toLocaleString('fi-FI', {
                      minimumFractionDigits: 2,
                    })}
                  </FW400>
                </CurrencyWrapper>
              );
            })}
          <Typography>Kupony:</Typography>
          <ul>
            {orders &&
              getSumOfTotalsCupons().map(coupon => {
                return (
                  <>
                    {allCoupons && (
                      <p>
                        {findCuponName(...Object.keys(coupon))} : {Object.values(coupon)}{' '}
                      </p>
                    )}
                  </>
                );
              })}
          </ul>
        </Info>
        <TableWrapper>
          <Scrolable>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map(head => {
                        if (head.type === 'orderNumber') {
                          return (
                            <React.Fragment key={head.id}>
                              <StyledTableCell align={head.align} style={{ width: head.width }}>
                                <Wrapper>
                                  <Checkbox onChange={e => handleSelectAllClick(e)} />
                                  {head.label}
                                </Wrapper>
                              </StyledTableCell>
                            </React.Fragment>
                          );
                        }
                        if (head.type === 'id' || head.type === 'hidden') {
                          return null;
                        } else {
                          return (
                            <React.Fragment key={head.id}>
                              <StyledTableCell align={head.align} style={{ width: head.width }}>
                                {head.label}
                              </StyledTableCell>
                            </React.Fragment>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <React.Fragment key={row.id}>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            onClick={event => handleSelectRow(event, row.id)}
                            aria-checked={isItemSelected}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            {tableHeaders.map(head => {
                              const value = row[head.id];
                              const newKey = row.number.toString();

                              if (head.type === 'id') {
                                return null;
                              }

                              if (head.type === 'orderNumber') {
                                return (
                                  <>
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                      {value}
                                    </TableCell>
                                  </>
                                );
                              }

                              if (head.type === 'userAdress') {
                                return (
                                  <>
                                    <TableCell align="left">
                                      <Adress>{value.split(',')[0]}</Adress>
                                      <Adress>{value.split(',')[1]}</Adress>
                                      <Adress>{value.split(',')[2]}</Adress>
                                    </TableCell>
                                  </>
                                );
                              }
                              if (head.type === 'notes') {
                                return (
                                  <>
                                    <TableCell align={head.align}>
                                      <NotesWrapper>
                                        <NotesShortText>{value}</NotesShortText>
                                        <Bubble>{value}</Bubble>
                                        <BublePointer />
                                      </NotesWrapper>
                                    </TableCell>
                                  </>
                                );
                              }
                              if (head.type === 'products') {
                                return (
                                  <>
                                    <TableCell align={head.align}>
                                      <PriceTable products={value} currency={row[head.currency]} />
                                    </TableCell>
                                  </>
                                );
                              }

                              if (head.type === 'shipmentStatus') {
                                if (value === 'NOT_SHIPPED') {
                                  return (
                                    <>
                                      <TableCell align={head.align}>
                                        <ButtonError
                                          disabled={row.orderStatus === 'CANCELLED'}
                                          variant="contained"
                                          onClick={() => handleChangeStatus(row.id, value)}
                                        >
                                          {value}
                                        </ButtonError>
                                      </TableCell>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <TableCell align={head.align}>
                                        <ButtonSucces
                                          disabled={row.orderStatus === 'CANCELLED'}
                                          variant="contained"
                                          onClick={() => handleChangeStatus(row.id, value)}
                                        >
                                          {value}
                                        </ButtonSucces>
                                      </TableCell>
                                    </>
                                  );
                                }
                              }
                              if (head.type === 'paymentStatus') {
                                if (value === 'NOT_PAID') {
                                  return (
                                    <>
                                      <TableCell align={head.align}>
                                        <ButtonError
                                          disabled={row.orderStatus === 'CANCELLED'}
                                          variant="contained"
                                          onClick={() => handleChangePaymentStatus(row.id, value)}
                                        >
                                          {value}
                                        </ButtonError>
                                      </TableCell>
                                    </>
                                  );
                                }
                                if (value === 'PAID') {
                                  return (
                                    <>
                                      <TableCell align={head.align}>
                                        <ButtonSucces
                                          disabled={row.orderStatus === 'CANCELLED'}
                                          variant="contained"
                                          onClick={e => handleChangePaymentStatus(row.id, value)}
                                        >
                                          {value}
                                        </ButtonSucces>
                                        <p>
                                          <Typography variant="caption">
                                            {row.paymentDate && `${row.paymentDate}`}
                                          </Typography>
                                        </p>
                                        <p>
                                          <Typography variant="caption">
                                            {row.transactionNo && `${row.transactionNo}`}
                                          </Typography>
                                        </p>

                                        <p>
                                          <Typography variant="caption">
                                            {row.transactionNo_stripe_id != null &&
                                              `${row.transactionNo_stripe_id}`}
                                          </Typography>
                                        </p>

                                        <p>
                                          <Typography variant="caption">
                                            {row.transactionNo_paypal_id != null &&
                                              `${row.transactionNo_paypal_id}`}
                                          </Typography>
                                        </p>
                                      </TableCell>
                                    </>
                                  );
                                }
                                if (value === 'CANCELLED') {
                                  return (
                                    <>
                                      <TableCell align={head.align}>
                                        <ColorInfo type="warning">
                                          <Typography variant="body2" color="inherit">
                                            {value}
                                          </Typography>
                                        </ColorInfo>
                                      </TableCell>
                                    </>
                                  );
                                }
                                if (value === 'FAILED') {
                                  return (
                                    <>
                                      <TableCell align={head.align}>
                                        <ColorInfo type="error">
                                          <Typography variant="body2" color="inherit">
                                            {value}
                                          </Typography>
                                        </ColorInfo>
                                      </TableCell>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <TableCell align={head.align}>
                                        <Typography variant="body2">{value}</Typography>
                                      </TableCell>
                                    </>
                                  );
                                }
                              }
                              if (head.type === 'returnStatus') {
                                if (value === 'RETURNED') {
                                  return (
                                    <>
                                      <TableCell align={head.align}>
                                        <ButtonError
                                          disabled={row.orderStatus === 'CANCELLED'}
                                          variant="contained"
                                          onClick={() => handleChangeReturnStatus(row.id, value)}
                                        >
                                          {value}
                                        </ButtonError>
                                      </TableCell>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <TableCell align={head.align}>
                                        <ButtonSucces
                                          disabled={row.orderStatus === 'CANCELLED'}
                                          variant="contained"
                                          onClick={() => handleChangeReturnStatus(row.id, value)}
                                        >
                                          {value}
                                        </ButtonSucces>
                                      </TableCell>
                                    </>
                                  );
                                }
                              }
                              if (head.type === 'buttons') {
                                return (
                                  <>
                                    <TableCell align={head.align}>
                                      <IconsWrapper>
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => openDeleteConfirm(newKey)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                        <Link to={`/orders/${row.id}`}>
                                          <IconButton aria-label="edit">
                                            <EditIcon />
                                          </IconButton>
                                        </Link>
                                        {deleteConfirmVisable[newKey] && (
                                          <ConfirmDelete
                                            width="550%"
                                            newKey={newKey}
                                            name={row.name}
                                            onDeleteHandler={onDeleteOrders}
                                            id={row.id}
                                            rejectHandler={openDeleteConfirm}
                                            isOpen={deleteConfirmVisable}
                                            endpointSearch={`${URLAPI}orders?page=${ordersConfig?.current_page}&per_page=${ordersConfig?.per_page}${ordersSearchQuery}`}
                                          >
                                            Czy napewno chcesz skasować
                                          </ConfirmDelete>
                                        )}
                                      </IconsWrapper>
                                    </TableCell>
                                  </>
                                );
                              }
                              if (head.type === 'hidden') {
                                return null;
                              }
                              return (
                                <>
                                  <TableCell align={head.align}>{value}</TableCell>
                                </>
                              );
                            })}
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Scrolable>
        </TableWrapper>
        <Paper>
          <PagnationWrapper>
            {ordersConfig !== null ? (
              <>
                <ButtonsWrapper>
                  <button type="button" onClick={() => HandleCreateXlsx()}>
                    <CSVicon src={XlsxIcon} alt="icon" />
                  </button>
                  <button type="button" onClick={() => EwidencjaSprzedazy()}>
                    Ewidencja Sprzedaży
                    <CSVicon src={XlsxIcon} alt="icon" />
                  </button>
                  {selected.length > 0 && (
                    <>
                      <IconButton aria-label="delete" onClick={() => handleDeleteMany()}>
                        <DeleteIcon />
                        <Typography>Skasuj ({selected.length})</Typography>
                      </IconButton>
                    </>
                  )}
                </ButtonsWrapper>
                <TablePagination
                  rowsPerPageOptions={[50, 100, { label: 'All', value: ordersConfig.total }]}
                  count={ordersConfig.total * 1}
                  rowsPerPage={ordersConfig.per_page}
                  page={ordersConfig.current_page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </>
            ) : (
              ''
            )}
          </PagnationWrapper>
        </Paper>
      </>
    );
  }
};

const mapStateToProps = state => {
  return {
    orders: state.orders.orders,
    ordersCfg: state.orders.ordersConfig,
    ordersSearchQuery: state.orders.ordersSearchQuery,
    countries: state.countries.countries,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitOrders: endpoint => dispatch(actionCreators.initOrders(endpoint)),
    onUpdateStatus: (
      endpoint,
      currentDate,
      endpointSearch, // TODO przekazac endpint z parametrami paginacji do update i w akcji zmienic funkcje na set orders
    ) => dispatch(actionCreators.updateStatus(endpoint, currentDate, endpointSearch)),
    onDeleteOrders: (id, endpointSearch) =>
      dispatch(actionCreators.deleteOrders(id, endpointSearch)),
    onFetchCountries: () => dispatch(actionCreators.fetchCountries()),
    onDeleteManyOrders: (bodyFormData, endpointSearch) =>
      dispatch(actionCreators.deleteManyOrders(bodyFormData, endpointSearch)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
