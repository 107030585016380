import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import * as actionCreators from 'store/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Input from 'components/atoms/Inputs/Input';
import { Validators } from 'components/atoms/Inputs/Validators';
import SelectInputIcon from 'components/atoms/Inputs/SelectInputIcon';
import CheckBox from 'components/atoms/CheckBox/CheckBox';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import { makeStyles } from '@material-ui/core/styles';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import Button from '@material-ui/core/Button';
import avatar from 'assets/no-photo.png';

const URLBASE = process.env.REACT_APP_URL_BASE;
const URLAPI = process.env.REACT_APP_URL_API;

const ModalWrapper = styled.div`
  position: relative;
  margin: 16px auto;
  width: 100%;
  @media screen and (min-width: 992px) {
    width: 80%;
  }
`;

const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const SingleInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const SelectWrapper = styled.div`
  width: 59%;
  margin-bottom: 20px;
`;
const CheckboxWrapper = styled.div`
  width: 39%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const HeadingWrapper = styled.div`
  margin-bottom: 32px;
`;

const AddIgRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      outline: 'none',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
  },
  button: {
    width: '100%',
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const FormControl = styled.div`
  position: relative;
  width: ${props => (props.shorter ? '50%' : '100%')};
  ${({ border }) =>
    border &&
    css`
      border: 1px solid #c4c4c4;
      border-radius: 0 0 4px 4px;
      border-top: 0;
      margin-bottom: 20px;
    `} 
    ${({ noMarginB }) =>
    noMarginB &&
    css`
        margin-bottom: 0;
      `}
      ${({ file }) =>
    file &&
    css`
          width: 210px;
        `}
  ${({ imgBox }) =>
    imgBox &&
    css`
      width: 210px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-color: #c4c4c4;
    `}
`;

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const MealImage = styled.img`
  width: 72px;
  height: auto;
  margin: 12px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Col = styled.div`
  width: 49%;
`;

const EditPaymentMethods = ({
  value,
  onSubmitPaymentMethodsForm,
  descValue,
  isEnabledValue,
  uniquePaymentMethodsNames,
  countries,
  onFetchCountries,
  onFetchSinglepaymentMehtods,
  match,
  paymentMehtodToEdit,
  onInitValidators,
}) => {
  const [newName, setNewName] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [selectedCountrues, setSelectedCountrues] = useState([]);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [init, setInit] = useState(false);
  const [file, setFile] = useState({
    file: '',
    avatarImg: true,
    oldImage: null,
  });
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    const endpoint = `${URLAPI}payment-method/name`
    onInitValidators(endpoint, 'payment-method')
  }, [match])

  useEffect(() => {
    onFetchSinglepaymentMehtods(match.params.id);
  }, [match]);

  useEffect(() => {
    if (paymentMehtodToEdit !== null) {
      setNewName(paymentMehtodToEdit.name);
      setNewDescription(paymentMehtodToEdit.description);
      setSelectedCountrues(paymentMehtodToEdit.countries);
      setFile({
        file: '',
        avatarImg: true,
        oldImage: paymentMehtodToEdit.icon,
      });
    }
  }, [paymentMehtodToEdit]);

  const setElementsForCurrentPageCountries = () => {
    onFetchCountries();
  };

  useEffect(() => {
    if (init === false) {
      setElementsForCurrentPageCountries();
      setInit(true);
    }
  }, [countries]);

  useEffect(() => {
    setNewName(value);
    setNewDescription(descValue);
  }, [value, descValue, isEnabledValue]);

  const handleChange = e => {
    setNewName(e.target.value);
  };
  const handleChangeDescription = e => {
    setNewDescription(e.target.value);
  };
  const handleChangeSelect = inputValue => {
    setSelectedCountrues(inputValue);
  };

  useEffect(() => {
    if (selectedCountrues.length !== countries.length) {
      setCheckboxChecked(false);
    } else {
      setCheckboxChecked(true);
    }
  }, [selectedCountrues]);

  const handleSelectAll = event => {
    setCheckboxChecked(event.target.checked);
    if (event.target.checked) {
      setSelectedCountrues(countries);
    } else {
      setSelectedCountrues([]);
    }
  };

  const resetForm = () => {
    setNewName('');
    setNewDescription('');
    setSelectedCountrues([]);
    setFile({
      file: '',
      avatarImg: true,
      oldImage: null,
    });
    history.push('/payment-methods');
  };

  const onCloseHandler = () => {
    resetForm();
  };

  const removeImg = () => {
    setFile({
      ...file,
      file: '',
      avatarImg: true,
      oldImage: null,
    });
  };
  const currentPhoto = () => {
    let image = '';
    if (file.file !== '') {
      image = <MealImage src={URL.createObjectURL(file.file)} alt="new" />;
    } else if (file.oldImage !== null) {
      image = <MealImage src={`${URLBASE}storage/${file.oldImage}`} alt="old" />;
    } else {
      image = <MealImage src={avatar} alt="no-img" />;
    }
    return image;
  };

  const handleChangeFile = e => {
    setFile(file => ({
      ...file,
      file: e.target.files[0],
      avatarImg: false,
    }));
    // };
  };

  const handleSubmit = e => {
    e.preventDefault();

    const countriesList = [];
    selectedCountrues.map(country => countriesList.push({ id: country.id }));

    e.preventDefault();

    const bodyFormData = new window.FormData();
    bodyFormData.set('name', newName);
    bodyFormData.set('description', newDescription);
    bodyFormData.set('countries', JSON.stringify(countriesList));
    bodyFormData.set('_method', 'PUT');
    if (file.file !== '') {
      bodyFormData.append('icon', file.file);
    }

    onSubmitPaymentMethodsForm(paymentMehtodToEdit.id, bodyFormData, resetForm);
  };

  return (
    <>
      <ModalWrapper>
        <BtnHolder>
          <CloseButton handleClick={onCloseHandler} />
        </BtnHolder>
        <div className={classes.paper}>
          <HeadingWrapper>
            <AddIgRow>
              <h2>Edytuj metodę płatności</h2>
            </AddIgRow>
          </HeadingWrapper>
          <form onSubmit={e => handleSubmit(e)}>
            <Row>
              <Col>
                <SingleInputWrapper>
                  <Input
                    id="name"
                    onChange={handleChange}
                    uniqueNames={uniquePaymentMethodsNames}
                    value={newName}
                    oldValue={paymentMehtodToEdit?.name}
                    htmlFor="name"
                    label="Nowa nazwa"
                    name="name"
                    type="text"
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      {
                        check: Validators.uniqueNameEdit,
                        message: 'Waluta o podanej nazwie już istnieje',
                      },
                    ]}
                  />
                </SingleInputWrapper>
                <SingleInputWrapper>
                  <Input
                    id="description"
                    onChange={handleChangeDescription}
                    value={newDescription}
                    htmlFor="description"
                    label="Nowy opis"
                    name="description"
                    type="text"
                    validators={[
                      { check: Validators.required, message: 'pole wymagane' },
                      // { check: Validators.uniqueName, message: 'Waluta o podanej nazwie już  istnieje' },
                    ]}
                  />
                </SingleInputWrapper>

                <SingleInputWrapper>
                  {countries && (
                    <SelectWrapper>
                      <SelectInputIcon
                        multiple
                        options={countries}
                        val={selectedCountrues}
                        optionType="currency_code"
                        disabledOption={option =>
                          option.currency === '' ||
                          option.currency_code === '' ||
                          option.currency === null
                        }
                        noOptionsText="Nie znaleziono"
                        label="Dostępne w krajach:"
                        handleOnChange={handleChangeSelect}
                        onChangeParams={['currency', 'select']}
                        name="currency1"
                        id="currency"
                      />
                    </SelectWrapper>
                  )}

                  <CheckboxWrapper>
                    <CheckBox
                      name="selectAllCountries"
                      handleChange={handleSelectAll}
                      checked={checkboxChecked}
                      id="hasLactose"
                      label="Zaznacz wszystkie kraje"
                    />
                  </CheckboxWrapper>
                </SingleInputWrapper>
              </Col>
              <Col>
                <ImgWrapper>
                  <FormControl file>
                    <label styles={{ width: '100%' }} htmlFor="raised-button-file">
                      <input
                        accept="image/*"
                        className={classes.input}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={event => handleChangeFile(event)}
                      />
                      <Button
                        variant="outlined"
                        component="span"
                        className={classes.button}
                        full-width
                      >
                        Dodaj zdjęcie
                      </Button>
                    </label>
                  </FormControl>
                  <FormControl file imgBox border>
                    {currentPhoto()}
                    {file.oldImage || file.temporaryImage !== null ? (
                      <CloseButton handleClick={removeImg} />
                    ) : (
                        ''
                      )}
                  </FormControl>
                </ImgWrapper>
              </Col>
            </Row>
            <ButtonWrapper>
              <ButtonPrimary type="submit">Zapisz zmiany</ButtonPrimary>
            </ButtonWrapper>
          </form>
        </div>
      </ModalWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    countries: state.countries.countries,
    paymentMehtodToEdit: state.paymentMethods.paymentMehtodToEdit,
    uniquePaymentMethodsNames: state.validators.validators,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitValidators: endpoint => dispatch(actionCreators.initValidators(endpoint)),
    onFetchCountries: () => dispatch(actionCreators.fetchCountries()),
    onFetchSinglepaymentMehtods: id => dispatch(actionCreators.fetchSinglepaymentMehtods(id)),
    onSubmitPaymentMethodsForm: (id, bodyFormData, resetForm) =>
      dispatch(actionCreators.submitPaymentMethodsForm(id, bodyFormData, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentMethods);
