import React from 'react';
import styled from 'styled-components';
import CloseButton from 'components/atoms/Buttons/CloseButton'
import { Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

const URLBASE = process.env.REACT_APP_URL_BASE;


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            justifyContent: 'center',
            border: 0,
            outline: 'none'
        }

    },
    paper: {
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 5,
        width: '80%',
        height: '50%',
        overflow: 'auto',

    },
}));

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const BtnHolder = styled.div`
    position: absolute;
    top: 0;
    right: 0;
 `

const DescriptionnWrapper = styled.div`
    margin-top: 16px;
`

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:59px;
`
const CollLeft = styled.div`
    width: 80%;
`

const CollRight = styled.div`
    width: 18%;
`

const Img = styled.img`
    width: 100px;
    margin-bottom: 16px;
`
const StyledUl = styled.ul`
    margin-left: 32px;
`

const DescriptionPopup = ({ onCloseHandler, funcVal, open, productInfo, }) => {
    const classes = useStyles();
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => onCloseHandler(funcVal)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <BtnHolder>
                        <CloseButton
                            handleClick={onCloseHandler}
                            funcVal={funcVal}
                        />
                    </BtnHolder>
                    {productInfo && <Wrapper>
                        <CollLeft>
                            <Typography
                                variant='h5'
                                color="primary">
                                {productInfo.name} - {productInfo.category_meals.map(el => el.name)}
                            </Typography>

                            <DescriptionnWrapper dangerouslySetInnerHTML={{ __html: productInfo.description }} />

                        </CollLeft>

                        <CollRight>
                            <Img
                                src={`${URLBASE}storage/${productInfo.image}`}
                                alt={productInfo.name}
                            />
                            <Typography color="primary">Składniki:</Typography>
                            <StyledUl>
                                {productInfo.ingredients.map(ig =>
                                    <li key={(Math.random() * 1000).toString()}>{ig.name} - {ig.pivot.quantity} {ig.unit}</li>)}
                            </StyledUl>
                        </CollRight>


                    </Wrapper>}
                </div>

            </Fade>
        </Modal >
    );
}

export default DescriptionPopup;