import 'date-fns';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/atoms/TabPanel/TabPanel';
import EditOrdersProductsTable from 'views/Orders/EditOrders/EditOrdersProductsTable';
import SelectInput from 'components/atoms/Inputs/SelectInput';
import Input from 'components/atoms/Inputs/Input';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import Loading from 'components/atoms/Loading/Loading';
import DateFnsUtils from '@date-io/date-fns';
import { pl } from 'date-fns/esm/locale';
import EventIcon from '@material-ui/icons/Event';
import { IconButton, InputAdornment, Typography } from '@material-ui/core';
import CheckBox from 'components/atoms/CheckBox/CheckBox';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import QuizAnswers from 'views/Users/PreviewUser/QuizAnswers';
import Cookies from 'js-cookie';

const URLAPI = process.env.REACT_APP_URL_API;

const DataPickerWrapper = styled.div`
  width: 49%;
`;

const InputWrapper = styled.div`
  width: 49%;
  margin-bottom: 16px;
`;

const ModalWrapper = styled.div`
  margin: 16px auto;
  position: relative;
  width: 100%;
  @media screen and (min-width: 992px) {
    width: 80%;
  }
`;

const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      outline: 'none',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    minHeight: 650,
  },
  button: {
    width: '100%',
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
}));

const FormControl = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: ${({ shorter }) => (shorter ? '50%' : '100%')};
`;

const DataTtile = styled.p`
  font-weight: 700;
`;
const Data = styled.span`
  font-weight: normal;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: 32px;
`;
const TabsWrapper = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  height: 100%;
`;
const FormsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px;
  width: 70%;
`;

const MainWrapper = styled.div`
  display: flex;
`;

const UserInfoWrapper = styled.div`
  width: 30%;
  padding-left: 30px;
`;

const getCurrentDate = data => {
  const newDate = new Date(data);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const hour = newDate.getHours();
  const min = newDate.getMinutes();
  const sec = newDate.getSeconds();
  return `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`} ${
    hour < 10 ? `0${hour}` : `${hour}`
  }:${min < 10 ? `0${min}` : `${min}`}:${sec < 10 ? `0${sec}` : `${sec}`}`;
};

const EditUser = ({
  match,
  history,
  getUserOrders,
  getUserInfo,
  userInfo,
  editStartDiet,
  onfetchIngredientsCategory,
  igCategory,
  onGetSingleUsers,
  userOrders,
  editExclude,
  editEmail,
  editKcal,
}) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [exclude, setExclude] = useState({
    meat: false,
    fish: false,
    lactose: false,
    gluten: false,
    dairy: false,
    seafood: false,
    clear: false,
  });
  const [email, setEmail] = useState('');
  const [kcal, setKcal] = useState('');
  const [startDiet, setStartDiet] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const a11yProps = index => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    getUserOrders(match.params.userId);
    getUserInfo(match.params.userId);
    onGetSingleUsers(match.params.userId);
    onfetchIngredientsCategory();
  }, []);

  // console.log(userInfo, 'userInfo')
  useEffect(() => {
    if (userOrders != null) {
      const initialStartDiet = userOrders?.orders.find(order =>
        order.products.find(
          product => product.category_product_id === 'fde18974-b712-4eb8-a650-1b848f4e015a',
        ),
      )?.start_diet;

      const initalOrderId = userOrders?.orders.find(order =>
        order.products.find(
          product => product.category_product_id === 'fde18974-b712-4eb8-a650-1b848f4e015a',
        ),
      )?.id;
      setOrderId(initalOrderId != null ? initalOrderId : null);
      setStartDiet(initialStartDiet != null ? new Date(initialStartDiet) : null);
    }
  }, [userOrders]);

  useEffect(() => {
    if (userInfo != null) {
      setEmail(userInfo.email);
      setKcal(userInfo.profiles.calories);
    }
  }, [userInfo, userOrders]);

  const onCloseHandler = () => {
    history.goBack();
  };
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSubmit = e => {
    e.preventDefault();
  };
  const handleClearExclude = () => {
    setExclude({
      meat: false,
      fish: false,
      lactose: false,
      gluten: false,
      dairy: false,
      seafood: false,
      clear: true,
    });
  };

  const handleChangeExclude = (e, toReset) => {
    const { name } = e.target;
    setExclude(prevState => ({
      ...prevState,
      [name]: !prevState[name],
      [toReset]: false,
      resetCategory: false,
      clear: false,
    }));
  };

  const setToInitialState = () => {
    setExclude({
      meat: false,
      fish: false,
      lactose: false,
      gluten: false,
      dairy: false,
      seafood: false,
      clear: false,
    });
  };

  const handleSubmitExclude = e => {
    e.preventDefault();
    editExclude(exclude, match.params.userId, setToInitialState);
  };

  const handleChangeEmail = e => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleSubmitEmail = e => {
    e.preventDefault();
    editEmail(match.params.userId, email);
  };
  const handleChangeKcal = e => {
    const { value } = e.target;
    setKcal(value);
  };

  const handleSubmitKcal = e => {
    e.preventDefault();
    editKcal(match.params.userId, kcal);
  };
  const handleChangeStartDiet = date => {
    date.setHours(0);
    date.setMinutes(0);
    setStartDiet(date);
  };

  const handleSubmitStartDiet = e => {
    e.preventDefault();
    editStartDiet(orderId, getCurrentDate(startDiet));
    // console.log(getCurrentDate(startDiet));
  };

  const handleSubmitResetPassword = e => {
    e.preventDefault();
    console.log(match.params.userId);
    fetch('https://api.lifeswitcher.com/api/users/setDefaultPassword', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.getJSON('authKey')}`,
      },
      body: JSON.stringify({
        userId: match.params.userId,
      }),
    });
    // console.log(getCurrentDate(startDiet));
  };

  return (
    <>
      {console.log(orderId)}
      <ModalWrapper>
        <BtnHolder>
          <CloseButton handleClick={onCloseHandler} />
        </BtnHolder>
        <div className={classes.paper}>
          <FormControl>
            <Typography variant="h5" color="primary">
              Edytuj użytkownika
            </Typography>
          </FormControl>
          <MainWrapper>
            <FormsWrapper onSubmit={e => handleSubmit(e)}>
              <TabsWrapper>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={tabValue}
                  onChange={handleChangeTab}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                >
                  <Tab label="Wykluczenia" {...a11yProps(0)} />
                  <Tab label="email" {...a11yProps(1)} />
                  <Tab label="KCAL" {...a11yProps(2)} />
                  <Tab label="Data rozpoczęcia diety" {...a11yProps(3)} />
                  <Tab label="Hasło" {...a11yProps(4)} />
                  {/* <Tab label="Klient" {...a11yProps(4)} /> */}
                </Tabs>
                <Wrapper>
                  <TabPanel value={tabValue} index={0}>
                    <form onSubmit={e => handleSubmitExclude(e)}>
                      <FormControl>
                        <Typography color="primary">Zaznacz wykluczenia:</Typography>

                        <CheckBox
                          name="meat"
                          handleChange={handleChangeExclude}
                          toReset="fish"
                          checked={exclude.meat}
                          id="meat"
                          label="mięso"
                        />

                        <CheckBox
                          name="fish"
                          handleChange={handleChangeExclude}
                          checked={exclude.fish}
                          toReset="meat"
                          id="fish"
                          label="ryby"
                        />
                      </FormControl>
                      <FormControl>
                        <CheckBox
                          name="lactose"
                          handleChange={handleChangeExclude}
                          checked={exclude.lactose}
                          id="lactose"
                          label="laktoza"
                        />
                        <CheckBox
                          name="gluten"
                          handleChange={handleChangeExclude}
                          checked={exclude.gluten}
                          id="gluten"
                          label="gluten"
                        />
                        <CheckBox
                          name="dairy"
                          handleChange={handleChangeExclude}
                          checked={exclude.dairy}
                          id="dairy"
                          label="nabiał"
                        />
                        <CheckBox
                          name="seafood"
                          handleChange={handleChangeExclude}
                          checked={exclude.seafood}
                          id="seafood"
                          label="owoce morza"
                        />
                      </FormControl>
                      <FormControl>
                        <CheckBox
                          name="clresetCategoryear"
                          handleChange={handleClearExclude}
                          checked={exclude.clresetCategoryear}
                          id="clresetCategoryear"
                          label="Wyczyść wykluczenia"
                        />
                      </FormControl>
                      <ButtonWrapper>
                        <ButtonPrimary type="submit">Zmień wykluczenia</ButtonPrimary>
                      </ButtonWrapper>
                    </form>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <Typography color="primary">Nowy email:</Typography>
                    <form onSubmit={e => handleSubmitEmail(e)}>
                      <FormControl>
                        <InputWrapper>
                          <Input
                            value={email}
                            id="email"
                            onChange={handleChangeEmail}
                            htmlFor="email"
                            label="email"
                            name="email"
                            type="text"
                          />
                        </InputWrapper>
                      </FormControl>

                      <ButtonWrapper>
                        <ButtonPrimary type="submit">Zmień email</ButtonPrimary>
                      </ButtonWrapper>
                    </form>
                  </TabPanel>

                  <TabPanel value={tabValue} index={2}>
                    <Typography color="primary">Nowa kaloryczność:</Typography>
                    <form onSubmit={e => handleSubmitKcal(e)}>
                      <FormControl>
                        <InputWrapper>
                          <Input
                            value={kcal}
                            id="kcal"
                            onChange={handleChangeKcal}
                            htmlFor="kcal"
                            label="kcal"
                            name="kcal"
                            type="number"
                          />
                        </InputWrapper>
                      </FormControl>

                      <ButtonWrapper>
                        <ButtonPrimary type="submit">Zmień kalorie</ButtonPrimary>
                      </ButtonWrapper>
                    </form>
                  </TabPanel>

                  <TabPanel value={tabValue} index={3}>
                    <Typography color="primary">Start diety:</Typography>
                    <form onSubmit={e => handleSubmitStartDiet(e)}>
                      <FormControl>
                        <DataPickerWrapper>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                            <InputWrapper>
                              <KeyboardDatePicker
                                disableFuture
                                autoOk
                                ampm={false}
                                value={startDiet}
                                onChange={handleChangeStartDiet}
                                label="Data startuu diety:"
                                showTodayButton
                                format="yyyy/MM/dd"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton>
                                        <EventIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </InputWrapper>
                          </MuiPickersUtilsProvider>
                        </DataPickerWrapper>
                      </FormControl>
                      <ButtonWrapper>
                        {orderId === null ? <Typography color="error">brak diety </Typography> : ''}
                        <ButtonPrimary type="submit" isDisabled={orderId === null}>
                          Zmień start diety
                        </ButtonPrimary>
                      </ButtonWrapper>
                    </form>
                  </TabPanel>

                  <TabPanel value={tabValue} index={4}>
                    <Typography color="primary">Hasło:</Typography>
                    <form onSubmit={e => handleSubmitResetPassword(e)}>
                      <ButtonWrapper>
                        <ButtonPrimary type="submit">Resetuj hasło (test1234)</ButtonPrimary>
                      </ButtonWrapper>
                    </form>
                  </TabPanel>
                </Wrapper>
              </TabsWrapper>
            </FormsWrapper>

            <UserInfoWrapper>
              <Typography variant="h5" color="primary">
                Quiz:
              </Typography>
              {userInfo != null && igCategory.length > 0 && (
                <QuizAnswers userInfo={userInfo} igCategory={igCategory} startDiet={startDiet} />
              )}
            </UserInfoWrapper>
          </MainWrapper>
        </div>
      </ModalWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.users.userToEdit,
    userInfo: state.users.userInfo,
    igCategory: state.igCat.ingredientsCategory,
    userOrders: state.users.userOrders,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSingleUsers: id => dispatch(actionCreators.getSingleUsers(id)),
    getUserOrders: id => dispatch(actionCreators.getUserOrders(id)),
    getUserInfo: id => dispatch(actionCreators.getUserInfo(id)),
    onfetchIngredientsCategory: () => dispatch(actionCreators.fetchIngredientsCategory()),
    editExclude: (exclude, userId, setToInitialState) =>
      dispatch(actionCreators.editExclude(exclude, userId, setToInitialState)),
    editEmail: (userId, email) => dispatch(actionCreators.editEmail(userId, email)),
    editKcal: (userId, kcal) => dispatch(actionCreators.editKcal(userId, kcal)),
    editStartDiet: (orderId, startDiet) =>
      dispatch(actionCreators.editStartDiet(orderId, startDiet)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
