import React, { useState } from 'react';
import * as actionCreators from 'store/actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import Input from 'components/atoms/Inputs/Input'
import { Validators } from 'components/atoms/Inputs/Validators'
import Typography from '@material-ui/core/Typography';

const ModalWrapper = styled.div`
    margin: 16px auto;
    position: relative;
    width: 80%;
    background-color: ${({ theme }) => theme.palette.background.paper};
    box-shadow:  ${({ theme }) => theme.shadows[5]};
    padding:${({ theme }) => theme.spacing(2, 4, 3)};
    border-radius: 5px;
`
const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

const InputsWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const Login = ({
  onLogin,
  history
}) => {
  const [userData, setUserData] = useState({
    userLogin: '',
    userPassword: '',
  })

  const handleInputChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onLogin(userData.userPassword, userData.userLogin, history)
  }

  return (
    <ModalWrapper>
      <InputsWrapper>
        <Typography variant="h5" color="primary">
          Logowanie</Typography>
      </InputsWrapper>
      <form onSubmit={e => handleSubmit(e)}>
        <InputsWrapper>
          <Input
            id="userLogin"
            onChange={handleInputChange}
            value={userData.userLogin}
            label="Login"
            name="userLogin"
            type='text'
            validators={[
              { check: Validators.required, message: 'pole wymagane' },
            ]}
          />
        </InputsWrapper>
        <InputsWrapper>
          <Input
            id="userPassword"
            onChange={handleInputChange}
            value={userData.userPassword}
            label="Hasło"
            name="userPassword"
            type='password'
            validators={[
              { check: Validators.required, message: 'pole wymagane' },
            ]}
          />
        </InputsWrapper>

        <ButtonWrapper>
          <ButtonPrimary type="submit">Zaloguj</ButtonPrimary>
        </ButtonWrapper>
      </form>

    </ModalWrapper>
  );
};



const mapDispatchToProps = dispatch => {
  return {
    onLogin: (password, email, history) => dispatch(actionCreators.login(password, email, history)),
  };
};


export default connect(null, mapDispatchToProps)(Login);
