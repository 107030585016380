/* eslint-disable react/no-did-update-set-state */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SearchIngredients from 'views/Ingredients/SearchIngredients/SearchIngredients';
import * as actionCreators from 'store/actions/index';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import AddIngredients from 'views/Ingredients/AddIngredients/AddIngredients';
import IngredietnsTable from 'components/atoms/Table/IngredientnsTable';

const URLAPI = process.env.REACT_APP_URL_API;

const Ingredients = ({
  onDeleteIngredients,
  igAll,
  onInitIngredients,
  ingredientsConfig,
  ig,
  searchQuery,
}) => {
  const [refresh, setRefresh] = useState(0);
  const [showAddIngredient, setShowAddIngredient] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [init, setInit] = useState(false);
  const [igConfig, setIgConfig] = useState({
    per_page: 100,
    current_page: 0,
    jumpToPage: null,
    searchQuery: '',
    // total: -1,
    data: [],
  });

  useEffect(() => {
    setIgConfig(ingredientsConfig);
  }, [ingredientsConfig]);

  const setElementsForCurrentPage = () => {
    setInit(true);
    const endpoint = `${URLAPI}ingredients?page=${igConfig.current_page}&per_page=${igConfig.per_page}${searchQuery}`;
    onInitIngredients(endpoint);
  };

  useEffect(() => {
    if (igConfig !== null && init === false) {
      setElementsForCurrentPage();
    }
  }, [ig]);

  const handleChangePage = (event, newPage) => {
    setIgConfig(state => ({
      ...state,
      current_page: newPage,
    }));
    setRefresh(Math.random());
  };

  const handleChangeRowsPerPage = event => {
    setIgConfig(state => ({
      ...state,
      per_page: event.target.value * 1,
      current_page: 1,
    }));
    setRefresh(Math.random());
  };

  useEffect(() => {
    setElementsForCurrentPage();
  }, [refresh]);

  useEffect(() => {
    setElementsForCurrentPage();
  }, [searchQuery]);

  const handleShowIngredients = () => {
    setShowAddIngredient(!showAddIngredient);
  };

  const handleShowSearch = () => {
    setShowSearch(!showSearch);
  };

  const closeAddIngredients = () => {
    setShowAddIngredient(false);
  };

  return (
    <>
      <IconButton onClick={() => handleShowIngredients()}>
        <AddIcon fontSize="large" />
      </IconButton>

      <IconButton onClick={() => handleShowSearch()}>
        <SearchIcon fontSize="large" />
      </IconButton>

      <section className="contentContainer">
        {showSearch && <SearchIngredients showAddIngredients={handleShowIngredients} />}
        <AddIngredients open={showAddIngredient} closeAddIngredients={closeAddIngredients} />

        <IngredietnsTable
          onDeleteIngredients={onDeleteIngredients}
          ig={ig}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          igConfig={igConfig}
        />
      </section>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ig: state.ig.ingredients,
    ingredientsConfig: state.ig.ingredientsConfig,
    searchQuery: state.ig.searchQuery,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIngredients: endpoint => dispatch(actionCreators.initIngredients(endpoint)),
    onfetchIngredientsCategory: () => dispatch(actionCreators.fetchIngredientsCategory()),
    onDeleteIngredients: id => dispatch(actionCreators.deleteIngredients(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ingredients);
