import React, { useState } from 'react';
import styled from 'styled-components';
import Input from 'components/atoms/Inputs/Input';
import Typography from '@material-ui/core/Typography';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators';
import { useHistory } from 'react-router-dom';

const URLAPI = process.env.REACT_APP_URL_API;

const Wrapper = styled.div`
  display: block;
  margin: 16px auto;
  min-width: 120px;
  width: 100%;
  height: auto;
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
`;

const FormWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  background-color: #ffffff;
  color: #fff;
  z-index: 1;
  width: 100%;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const SingleInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const ErrorMsg = styled.div`
  margin-top: 3px;
  margin-left: 14px;
`;

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  userPassword: '',
  confirmPassword: '',
};

const AddUsers = ({ onAddUsersForm, setVisible, unieqeEmails }) => {
  const [inputsValue, setInputValue] = useState(initialState);
  const [error, setError] = useState(false);

  const history = useHistory();

  const handleInputChange = e => {
    setInputValue({
      ...inputsValue,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setVisible(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const endpoint = `${URLAPI}users/add`;
    const endpointDownload = `${URLAPI}users/admin?page=${1}&per_page=${100}`;

    if (inputsValue.userPassword === inputsValue.confirmPassword) {
      const bodyFormData = new window.FormData();
      bodyFormData.set('first_name', inputsValue.firstName);
      bodyFormData.set('last_name', inputsValue.lastName);
      bodyFormData.set('email', inputsValue.email);
      bodyFormData.set('password', inputsValue.userPassword);
      bodyFormData.set('password_confirmation', inputsValue.confirmPassword);
      bodyFormData.set('role_id', '87a7c09b-9039-41fd-90ed-7dba2ad2682d'); // TODO zmienic na selekta

      bodyFormData.set('_method', 'POST');
      onAddUsersForm(history, bodyFormData, endpoint, resetForm, endpointDownload);
    } else {
      setError(true);
    }
  };

  const passwordValidation = () => {
    if (inputsValue.userPassword === inputsValue.confirmPassword) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <Wrapper>
      <Typography variant="h5" color="primary">
        Dodaj użytkownika
      </Typography>
      <FormWrapper>
        <StyledForm onSubmit={e => handleSubmit(e)}>
          <SingleInputWrapper>
            <Input
              id="firstName"
              onChange={handleInputChange}
              value={inputsValue.firstName}
              htmlFor="firstName"
              label="Imię"
              name="firstName"
              type="text"
              validators={[{ check: Validators.required, message: 'pole wymagane' }]}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <Input
              id="lastName"
              onChange={handleInputChange}
              value={inputsValue.lastName}
              htmlFor="lastName"
              label="Nazwisko"
              name="lastName"
              type="text"
              validators={[{ check: Validators.required, message: 'pole wymagane' }]}
            />
          </SingleInputWrapper>
          <SingleInputWrapper>
            <Input
              id="email"
              uniqueNames={unieqeEmails}
              onChange={handleInputChange}
              value={inputsValue.email}
              htmlFor={inputsValue.email}
              label="E-mail"
              name="email"
              type="email"
              validators={[
                { check: Validators.required, message: 'pole wymagane' },
                { check: Validators.email, message: 'podaj poprawny email' },
                { check: Validators.uniqueEmail, message: 'Podany email jest zajęty' },
              ]}
            />
          </SingleInputWrapper>

          <SingleInputWrapper>
            <Input
              id="userPassword"
              onChange={handleInputChange}
              value={inputsValue.userPassword}
              htmlFor="userPassword"
              label="Nowe hasło"
              name="userPassword"
              type="password"
              validators={[
                { check: Validators.required, message: 'pole wymagane' },
                { check: Validators.length, message: 'minimum 6 znaków' },
              ]}
            />
          </SingleInputWrapper>

          <SingleInputWrapper>
            <Input
              id="confirmPassword"
              onChange={handleInputChange}
              value={inputsValue.confirmPassword}
              htmlFor="confirmPassword"
              label="Ptowierdź hasło"
              name="confirmPassword"
              type="password"
              handleOnBlur={passwordValidation}
              validators={[
                { check: Validators.required, message: 'pole wymagane' },
                { check: Validators.length, message: 'minimum 6 znaków' },
              ]}
            />
            {error && (
              <ErrorMsg>
                {' '}
                <Typography variant="caption" color="error">
                  Hasła nie są identyczne
                </Typography>
              </ErrorMsg>
            )}
          </SingleInputWrapper>

          <ButtonWrapper>
            <ButtonPrimary type="submit"> Dodaj Użytkownika</ButtonPrimary>
          </ButtonWrapper>
        </StyledForm>
      </FormWrapper>
    </Wrapper>
  );
};

export default AddUsers;
