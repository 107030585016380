import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const Wrapper = styled.div`
    position: absolute;
    width: ${({ width }) => width || '450%'};
    background-color: #ffffff;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 32px;
    &:hover {
        background-color: #f5f5f5;
    }
`

const ConfirmDelete = ({
    onDeleteHandler,
    name,
    children,
    id,
    rejectHandler,
    newKey,
    width,
    endpointSearch
}) => {

    return (
        <Wrapper width={width}>
            <Typography>{children} {name}</Typography>
            <IconButton onClick={() => onDeleteHandler(id, endpointSearch)}>
                <CheckIcon fontSize="large" color="secondary" />
            </IconButton>
            <IconButton onClick={() => rejectHandler(newKey)}>
                <CloseIcon fontSize="large" color="error" />
            </IconButton>
        </Wrapper>

    );
}

export default ConfirmDelete;