import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
  notifications: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.REMOVE_UNUSED_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications.splice(0, 1)],
      };

    // MEALS

    // FORM
    case actionTypes.SUBMIT_FORM_ADD_NEW_MEAL_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_FORM_ADD_NEW_MEAL_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // DELETE
    case actionTypes.DELETE_MEALS_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.DELETE_MEALS_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // INGREDIENTS
    // DELETE
    case actionTypes.DELETE_INGREDIENTS_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.DELETE_INGREDIENTS_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };
    // CATEGORY INGREDIENTS
    // DELETE

    case actionTypes.DELETE_INGREDIENT_CATEGORY_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.DELETE_INGREDIENT_CATEGORY_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // FORM
    case actionTypes.SUBMIT_INGREDIENTS_CATEGORY_FORM_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_INGREDIENTS_CATEGORY_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // FORM

    case actionTypes.SUBMIT_FORM_ADD_NEW_INGREDIENT_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_FORM_ADD_NEW_INGREDIENT_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // CURRENCIES
    // DELETE

    case actionTypes.DELETE_CURRENCY_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.DELETE_CURRENCY_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // FORM
    case actionTypes.SUBMIT_CURRENCY_FORM_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_CURRENCY_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // Payment Methods
    // DELETE

    case actionTypes.DELETE_PAYMENT_MEHTODS_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.DELETE_PAYMENT_MEHTODS_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // FORM
    case actionTypes.SUBMIT_PAYMENT_MEHTODS_FORM_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_PAYMENT_MEHTODS_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // MEALS CATEGORY
    // DELETE

    case actionTypes.DELETE_MEAL_CATEGORY_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.DELETE_MEAL_CATEGORY_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // FORM
    case actionTypes.SUBMIT_MEALS_CATEGORY_FORM_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_MEALS_CATEGORY_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // Shipments methods
    case actionTypes.DELETE_SHIPMENTS_METHOD_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.DELETE_SHIPMENTS_METHOD_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // FORM
    case actionTypes.SUBMIT_SHIPMENTS_METHOD_FORM_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_SHIPMENTS_METHOD_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // Porducts methods
    case actionTypes.DELETE_PRODUCTS_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.DELETE_PRODUCTS_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // FORM
    case actionTypes.SUBMIT_PRODUCTS_FORM_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_PRODUCTS_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // // users
    // case actionTypes.DELETE_USERS_SUCCESS:
    //   return {
    //     ...state,
    //     notifications: [
    //       ...state.notifications,
    //       {
    //         msg: [action.msg?.data?.message],
    //         status: action.msg?.status,
    //         id: Math.random() * 1000,
    //       }
    //     ]
    //   }
    // case actionTypes.DELETE_USERS_FAILED:
    //   return {
    //     ...state,
    //     notifications: [
    //       ...state.notifications,
    //       {
    //         msg: Object.values(action.error.response.data.message),
    //         status: action.error.response.status,
    //         id: Math.random() * 1000,
    //         responseError: action.error.response.data.message,
    //       }
    //     ]
    //   }

    // // FORM
    // case actionTypes.SUBMIT_USERS_FORM_SUCCESS:
    //   return {
    //     ...state,
    //     notifications: [
    //       ...state.notifications,
    //       {
    //         msg: [action.msg?.data?.message],
    //         status: action.msg?.status,
    //         id: Math.random() * 1000,
    //       }
    //     ]
    //   }
    // case actionTypes.SUBMIT_USERS_FORM_FAILED:
    //   return {
    //     ...state,
    //     notifications: [
    //       ...state.notifications,
    //       {
    //         msg: Object.values(action.error.response.data.message),
    //         status: action.error.response.status,
    //         id: Math.random() * 1000,
    //         responseError: action.error.response.data.message,
    //       }
    //     ]
    //   };

    // users
    case actionTypes.SUBMIT_EXCLUDE_FORM:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_EXCLUDE_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };


    case actionTypes.SUBMIT_EMAIL_FORM:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_EMAIL_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_START_DIET_FORM:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_START_DIET_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_KCAL_FORM:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_KCAL_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };

    case actionTypes.DELETE_USERS_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };

    case actionTypes.DELETE_USERS_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // FORM
    case actionTypes.SUBMIT_FORM_ADD_NEW_USERS_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_FORM_ADD_NEW_USERS_FAILED:
      return {
        // ...state,
        // notifications: [
        //   ...state.notifications,
        //   {
        //     msg: Object.values(action.error.response.data.message),
        //     status: action.error.response.status,
        //     id: Math.random() * 1000,
        //     responseError: action.error.response.data.message,
        //   }
        // ]
      };

    // GENERETE DIETS

    case actionTypes.USER_DIET_EXIST:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: ['User has already a diet'],
            status: 'warning',
            id: Math.random() * 1000,
          },
        ],
      };

    case actionTypes.GENERETE_DIET_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: ['Sukces'],
            status: 'sukces',
            id: Math.random() * 1000,
          },
        ],
      };

    case actionTypes.USER_DIET_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values('fail'),
            status: 'fail',
            id: Math.random() * 1000,
            responseError: 'fail',
          },
        ],
      };

    // // Orders
    case actionTypes.DELETE_ORDERS_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.DELETE_ORDERS_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // FORM
    case actionTypes.SUBMIT_ORDERS_FORM_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.SUBMIT_ORDERS_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // // Packages
    case actionTypes.DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: Math.random() * 1000,
          },
        ],
      };
    case actionTypes.DELETE_PACKAGE_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    // FORM
    case actionTypes.SUBMIT_PACKAGE_FORM_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.msg?.data?.message],
            status: action.msg?.status,
            id: (Math.random() * 1000).toFixed(0) * action.status,
          },
        ],
      };
    case actionTypes.SUBMIT_PACKAGE_FORM_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    case actionTypes.ADD_MESSAGE:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.statusText],
            status: action.status,
            id: Math.random() * 1000,
          },
        ],
      };

    // LOGIN
    case actionTypes.LOGIN_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };

    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.message],
            status: 200,
            id: (Math.random() * 1000).toFixed(0) * action.status,
          },
        ],
      };

    // LOGOUT
    case actionTypes.LOGOUT_FAILED:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: Object.values(action.error.response.data.message),
            status: action.error.response.status,
            id: Math.random() * 1000,
            responseError: action.error.response.data.message,
          },
        ],
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            msg: [action.message],
            status: 200,
            id: (Math.random() * 1000).toFixed(0) * action.status,
          },
        ],
      };
    default:
      return state;
  }
}
