import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions';
import Loading from 'components/atoms/Loading/Loading';
import Typography from '@material-ui/core/Typography';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import UserOrdersTable from 'views/Users/UserOrdersTable/UserOrdersTable';
import QuizAnswers from 'views/Users/PreviewUser/QuizAnswers';

const ModalWrapper = styled.div`
  margin: 16px auto;
  position: relative;
  width: 80%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[5]};
  padding: ${({ theme }) => theme.spacing(2, 4, 3)};
  border-radius: 5px;
`;

const BtnHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Col = styled.div`
  width: 49%;
`;

const DataTtile = styled.p`
  font-weight: 700;
  padding-bottom: 8px;
`;
const Data = styled.span`
  font-weight: normal;
`;

const FormControl = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: ${({ shorter }) => (shorter ? '50%' : '100%')};
`;

const PreviewUser = ({ match, history, user, getUserOrders, getUserInfo, userInfo, onfetchIngredientsCategory, igCategory }) => {
  useEffect(() => {
    getUserOrders(match.params.userId);
    getUserInfo(match.params.userId);
    onfetchIngredientsCategory();
  }, []);

  const onCloseHandler = () => {
    history.goBack();
  };

  if (user === null || user === undefined || userInfo === null) {
    return <Loading />;
  }
  return (
    <>
      <ModalWrapper>
        <BtnHolder>
          <CloseButton handleClick={onCloseHandler} />
        </BtnHolder>
        <FormControl>
          <Typography variant="h5" color="primary">
            Dane klienta
          </Typography>
        </FormControl>
        <Row>
          <Col>
            <DataTtile>
              id: <Data>{user.id}</Data>
            </DataTtile>
            <DataTtile>
              Klient:{' '}
              <Data>
                {user.first_name} {user.last_name}
              </Data>
            </DataTtile>
            <DataTtile>
              E-mail: <Data>{user.email}</Data>
            </DataTtile>
            <DataTtile>
              Nr telefonu: <Data>{user.phone_number}</Data>
            </DataTtile>
            <DataTtile>
              Kraj: <Data>{user.country}</Data>
            </DataTtile>
            <DataTtile>
              Adres:{' '}
              <Data>
                {user.street} {user.house_number}, {user.post_code} {user.city}{' '}
              </Data>
            </DataTtile>
            <Typography variant="h5" color="primary">
              Quiz:
            </Typography>

            {userInfo !== null && userInfo !== undefined && igCategory.length > 0 && <QuizAnswers userInfo={userInfo} igCategory={igCategory} />}
          </Col>
          <Col>
            <UserOrdersTable ordersList={user.orders} />
          </Col>
        </Row>
      </ModalWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.users.userToEdit,
    userInfo: state.users.userInfo,
    igCategory: state.igCat.ingredientsCategory,


  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSingleUsers: id => dispatch(actionCreators.getSingleUsers(id)),
    getUserOrders: id => dispatch(actionCreators.getUserOrders(id)),
    getUserInfo: id => dispatch(actionCreators.getUserInfo(id)),
    onfetchIngredientsCategory: () => dispatch(actionCreators.fetchIngredientsCategory()),


  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewUser);
