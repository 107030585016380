import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';


const URLAPI = process.env.REACT_APP_URL_API;
// GET
export const setCurrencies = currencies => {
    return {
        type: actionTypes.SET_CURRENCIES,
        currencies,
    };
};

export const fetchCurrenciesFailed = (error) => {
    return {
        type: actionTypes.FETCH_CURRENCIES_FAILED,
        error
    };
};

export const fetchCurrencies = () => {
    return dispatch => {
        const authKey = Cookies.getJSON('authKey');
        axios
            .get(`${URLAPI}currencies`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                }
            })
            .then(res => {
                const currencies = res.data.data;
                dispatch(setCurrencies(currencies));

            })
            .catch(error => {
                dispatch(fetchCurrenciesFailed(error));

            });
    };
};

// GET ONE 
export const setsSinglecurrency = currency => {
    return {
        type: actionTypes.SET_SINGLE_CURRENCY,
        singleCurrency: currency,
    };
};

export const fetchSinglecurrencyFailed = (error) => {
    return {
        type: actionTypes.FETCH_SINGLE_CURRENCY_FAILED,
        error
    };
};

export const fetchSinglecurrency = (id) => {
    return dispatch => {
        const authKey = Cookies.getJSON('authKey');
        axios
            .get(`${URLAPI}currencies/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                }
            })
            .then(res => {
                const currency = res.data.data;

                dispatch(setsSinglecurrency(currency));
            })
            .catch(error => {
                dispatch(fetchSinglecurrencyFailed(error));
            });
    };
};




// ADD CURRENCY

export const submitCurrencyFormSuccess = res => {
    return {
        type: actionTypes.SUBMIT_CURRENCY_FORM_SUCCESS,
        msg: res
    };
};

export const submitCurrencyFormFailed = error => {
    return {
        type: actionTypes.SUBMIT_CURRENCY_FORM_FAILED,
        error,
    };
};


export const addCurrencyForm = (
    code,
    resetForm
) => {
    return dispatch => {
        const authKey = Cookies.getJSON('authKey');
        axios
            .post(`${URLAPI}currencies`, {
                code
            }, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                },
            })
            .then(res => {
                dispatch(submitCurrencyFormSuccess(res));
                dispatch(fetchCurrencies());
                resetForm();
            })
            .catch(error => {
                dispatch(submitCurrencyFormFailed(error));
            });
    };
};

// EDIT CURRENCY

export const submitCurrencyForm = (
    code,
    id,
) => {
    return dispatch => {
        const authKey = Cookies.getJSON('authKey');
        axios
            .put(`${URLAPI}currencies/${id}`, {
                code
            }, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                },
            })
            .then(res => {
                dispatch(submitCurrencyFormSuccess(res));
                dispatch(fetchCurrencies());
            })
            .catch(error => {
                dispatch(submitCurrencyFormFailed(error));
            });
    };
};

// DELETE

export const deleteCurrencySuccess = (id, res) => {
    return {
        type: actionTypes.DELETE_CURRENCY_SUCCESS,
        id,
        msg: res
    };
};

export const deleteCurrencyFailed = error => {
    return {
        type: actionTypes.DELETE_CURRENCY_FAILED,
        error,

    };
};

export const deleteCurrency = id => {
    return dispatch => {
        const authKey = Cookies.getJSON('authKey');
        axios
            .delete(`${URLAPI}currencies/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                }
            })
            .then((res) => {
                dispatch(deleteCurrencySuccess(id, res));
                dispatch(fetchCurrencies());

            })
            .catch(error => {
                dispatch(deleteCurrencyFailed(error));
            });
    };
};