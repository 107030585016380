import * as actionTypes from 'store/actions/actionsTypes';
import axios from 'axios';
import Cookies from 'js-cookie';



export const setValidators = (validators, key) => {
    return {
        type: actionTypes.SET_VALIDATORS,
        validators,

    };
};

export const fetchValidatorsFailed = () => {
    return {
        type: actionTypes.FETCH_VALIDATORS_FAILED,
    };
};

export const initValidators = (endpoint, key) => {
    const authKey = Cookies.getJSON('authKey');

    return dispatch => {
        axios
            .get(endpoint, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authKey}`
                }
            })
            .then(res => {
                const validators = res.data.data;

                dispatch(setValidators(validators, key));
            })
            .catch(error => {
                dispatch(fetchValidatorsFailed(error));
            });
    };
};