import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
  meals: [],
  mealsConfig: null,
  mealToEdit: null,
};

const setMeals = (state, action) => {
  return {
    ...state,
    meals: action.meals.data,
    mealsConfig: action.meals,
    error: false,
  };
};

const fetchMealsFailed = state => {
  return {
    ...state,
    error: true,
  };
};

const deleteMealsSuccess = (state, action) => {
  return {
    ...state,
    meals: [...state.meals.filter(meal => meal.id !== action.id)],
  };
};

const submitMealsFormSuccess = (state, action) => {
  return {
    ...state,
    msg: action.msg,
  };
};

const submitMealsFormFailed = (state, action) => {
  return {
    ...state,
    msg: action.error,
  };
};

const getSingleMealsToEditSuccess = (state, action) => {
  return {
    ...state,
    mealToEdit: action.data,
  };
};


const setMealsQueryAfterSearch = (state, action) => {
  return {
    ...state,
    mealsSearchQuery: action.query,
  };
};

const setMealsOptionName = (state, action) => {

  const optionsNameSearch = action.optionName.map(option => {
    return { value: option.id, label: option.name, unit: option.unit, mealsCategory: option.category_meals };
  });
  return {
    ...state,
    mealsOptionsNameForSearch: optionsNameSearch,
  };
};

const mealsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MEALS:
      return setMeals(state, action);
    case actionTypes.FETCH_MEALS_FAILED:
      return fetchMealsFailed(state);
    case actionTypes.DELETE_MEALS_SUCCESS:
      return deleteMealsSuccess(state, action);
    case actionTypes.SUBMIT_FORM_ADD_NEW_MEAL_SUCCESS:
      return submitMealsFormSuccess(state, action);
    case actionTypes.SUBMIT_MEALS_FORM_FAILED:
      return submitMealsFormFailed(state, action);
    case actionTypes.GET_SINGLE_MEALS_TO_EDIT_SUCCESS:
      return getSingleMealsToEditSuccess(state, action);
    case actionTypes.SET_MEALS_QUERY_AFTER_SEARCH:
      return setMealsQueryAfterSearch(state, action);
    case actionTypes.SET_MEALS_OPTION_NAME:
      return setMealsOptionName(state, action);
    default:
      return state;
  }
};

export default mealsReducer;
