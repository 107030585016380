import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
    currencies: [],
};
// GET
const setCurrencies = (state, action) => {
    return {
        ...state,
        currencies: action.currencies,
        error: false,
    }
}

// GET ONE 
const setSingleCurrency = (state, action) => {
    return {
        ...state,
        singleCurrency: action.singleCurrency,
        error: false,
    };
};

// DELETE
const deleteCurrencySuccess = (state, action) => {
    return {
        ...state,
        currencies: [...state.currencies.filter(currency => currency.code !== action.code)],
    };
};


const currenciesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CURRENCIES:
            return setCurrencies(state, action);
        case actionTypes.DELETE_CURRENCY_SUCCESS:
            return deleteCurrencySuccess(state, action);
        case actionTypes.SET_SINGLE_CURRENCY:
            return setSingleCurrency(state, action);
        default:
            return state;
    }
};

export default currenciesReducer;
