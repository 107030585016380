import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
// import Select from 'react-select';
import PropTypes from 'prop-types';

import * as actionCreators from 'store/actions';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import SelectImput from 'components/atoms/Inputs/SelectInput'
import InListButton from 'components/atoms/Buttons/InListButton'
import Input from 'components/atoms/Inputs/Input'

const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`


const Form = styled.form`
  display: ${props => props.showSearch === true ? "block" : "none"};
`

const ContainerForFewInputs = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }

`
const TwoImputs = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  &:first-child {
  align-items: flex-start;
  }
  &:last-child {
  align-items: flex-end;
  }
  @media screen and (min-width: 992px) {
    align-items: flex-start;
    flex-direction: row;
    flex-basis: 49%;
    justify-content: space-between;
}
`

const SingleInputWrapper = styled.div`
  width: 98%;
  margin-bottom: 16px;
  flex-basis: 48%;
  @media screen and (min-width: 992px) {
    flex-basis: 49%;
}
`

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#000000',
        label: 'color: #000000'
    },
    formControl: {
        display: 'block',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        minWidth: 120,
        width: '100%',
        height: 'auto',
        padding: theme.spacing(2),
        paddingBottom: 1,
        borderRadius: 5,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),

    },
}));

const SearchMeals = ({
    onSetMealsQueryAfterSearch,
    optionsName,
    mealsCategory,
    showSearch,
    visibleForm,
}) => {
    const [state, setState] = useState({
        minCaloriesSearchMeals: '',
        maxCaloriesSearchMeals: '',
        nameSearch: { value: '', label: '' },
        categorySearch: { value: '' },
        hasLactoseSearch: { value: '' },
        hasGlutenSearch: { value: '' },
        categorySearchOptions: []
    })

    useEffect(() => {
        const newOptions = [];
        if (state.categorySearchOptions.length === 0 && mealsCategory !== null) {
            mealsCategory.map(el =>
                newOptions.push({ value: el.id, label: el.name })
            )
        }
        setState(state => ({
            ...state,
            categorySearchOptions: newOptions
        }))

    }, [mealsCategory])

    const handleSearch = (inputValue, key, type) => {
        let value = '';
        if (inputValue) {
            if (type === 'text' || type === 'number') {
                value = (inputValue.target.value).toLowerCase();

            } else if (type === 'select') {
                value = inputValue;
            } else {
                value = inputValue;
            }
            setState(state => ({
                ...state,
                [key]: value,
            }))
        } else {
            setState(state => ({
                ...state,
                [key]: { value: '' },
            }))
            return null
        };
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            let query = '';

            const nameQuery = `${state.nameSearch.value !== '' ? `&name=${state.nameSearch.label}` : ``}`;
            const minCaloriesQuery = `${state.minCaloriesSearchMeals !== '' ? `&kcal_min_from=${state.minCaloriesSearchMeals}` : ''
                }`;
            const maxCaloriesQuery = `${state.maxCaloriesSearchMeals !== '' ? `&kcal_min_to=${state.maxCaloriesSearchMeals}` : ''
                }`;
            const categoryQuery = `${state.categorySearch.value !== '' ? `&category_meals=${state.categorySearch.label}` : ''
                }`;
            const hasGlutenQuery = `${state.hasGlutenSearch.value !== '' ? `&ingriedients_gluten=${state.hasGlutenSearch.value}` : ''
                }`;
            const hasLactoseQuery = `${state.hasLactoseSearch.value !== '' ? `&ingriedients_lactose=${state.hasLactoseSearch.value}` : ''
                }`;
            const nameToLowerCase = nameQuery.toLowerCase();
            const categoryToLowerCase = categoryQuery.toLowerCase();
            query = `${nameToLowerCase}${minCaloriesQuery}${maxCaloriesQuery}${categoryToLowerCase}${hasGlutenQuery}${hasLactoseQuery}`;
            onSetMealsQueryAfterSearch(`${query}`);
            // }
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [state])


    const classes = useStyles();

    const lactoseOption = [
        { value: '', label: 'bez znaczenia' },
        { value: 0, label: 'Nie zawiera laktozy' },
        { value: 1, label: 'Zawiera laktozę' },
    ];

    const glutenOption = [
        { value: '', label: 'bez znaczenia' },
        { value: 0, label: 'Nie zawiera glutenu' },
        { value: 1, label: 'Zawiera gluten' },
    ];

    return (
        <>
            <Form showSearch={showSearch} >
                <FormControl className={classes.formControl}
                >
                    <SelectWrapper>
                        <SelectImput
                            options={optionsName}
                            noOptionsText={
                                <InListButton handleOnClick={() => visibleForm('showAddIngredient')}>Dodaj brakujący składnik</InListButton>}
                            label="Name"
                            handleOnChange={handleSearch}
                            onChangeParams={['nameSearch', 'select']}
                            // value={nameSearch}
                            id="nameSearch"

                        />
                    </SelectWrapper>
                    <ContainerForFewInputs>

                        <TwoImputs>
                            <SingleInputWrapper htmlFor="caloriesMin">
                                <Input
                                    label='Min calories'
                                    name="minCaloriesSearchMeals"
                                    type="number"
                                    id="caloriesMin"
                                    onChange={handleSearch}
                                    onChangeParams={['minCaloriesSearchMeals', 'text']}
                                    value={state.minCaloriesSearchMeals}
                                />
                            </SingleInputWrapper>
                            <SingleInputWrapper htmlFor="maxCaloriesSearchMeals">
                                <Input
                                    label='Max calories'
                                    name="maxCaloriesSearchMeals"
                                    id="maxCaloriesSearchMeals"
                                    type="number"
                                    onChange={handleSearch}
                                    onChangeParams={['maxCaloriesSearchMeals', 'text']}
                                    value={state.maxCaloriesSearchMeals}

                                />
                            </SingleInputWrapper>
                        </TwoImputs>
                        <TwoImputs>

                            <SingleInputWrapper>
                                <SelectImput
                                    options={lactoseOption}
                                    noOptionsText='Nie znaleziono'
                                    label="Has lactos"
                                    handleOnChange={handleSearch}
                                    onChangeParams={['hasLactoseSearch', 'select']}
                                    // value={hasLactoseSearch}
                                    id='hasLactoseSearch'
                                />
                            </SingleInputWrapper>
                            <SingleInputWrapper>
                                <SelectImput
                                    options={glutenOption}
                                    noOptionsText='Nie znaleziono'
                                    label="Has gluten"
                                    handleOnChange={handleSearch}
                                    onChangeParams={['hasGlutenSearch', 'select']}
                                    // value={hasGlutenSearch}
                                    id='hasGlutenSearch'
                                />
                            </SingleInputWrapper>
                        </TwoImputs>

                    </ContainerForFewInputs>
                    <SelectWrapper>
                        <SelectImput
                            options={state.categorySearchOptions}
                            noOptionsText='Nie znaleziono kategorii'
                            label="Category"
                            handleOnChange={handleSearch}
                            onChangeParams={['categorySearch', 'select']}
                            // value={categorySearch}
                            id='categorySearch'

                        />
                    </SelectWrapper>

                </FormControl>
            </Form>
        </>
    );
}

export default SearchMeals;
