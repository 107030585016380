import React, { useState, useEffect } from 'react';
import * as actionCreators from 'store/actions/index';
import { connect } from 'react-redux';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddCurrency from 'views/Currencies/AddCurrency/AddCurrency';
import CurrenciesTable from 'components/atoms/Table/CurrenciesTable'

const Wrapper = styled.section`
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  position: relative;
  /* width: 70%;
  margin: 0 auto; */
`
const Currencies = ({
    onFetchCurrencies,
    onFetchCountries,
    onDeleteCurrency,
    currencies,
    isLoading,
    countries

}) => {
    const [visible, setVisible] = useState(false)
    const [init, setInit] = useState(false);
    const [currenciesList, setCurrenciesList] = useState([]);

    const showAddIgCategory = () => {
        setVisible(!visible)
    }

    const setElementsForCurrentPage = () => {
        onFetchCurrencies();
        onFetchCountries();
    };

    useEffect(() => {
        if (currenciesList !== null && init === false) {
            setElementsForCurrentPage();
            setInit(true);
        }
    }, [currenciesList]);


    return (
        <Wrapper>
            <IconButton
                onClick={() => showAddIgCategory()}
            >
                <AddIcon fontSize="large" />
            </IconButton>
            {visible && <AddCurrency setVisible={setVisible} />}

            <CurrenciesTable
                isLoading={isLoading}
                currenciesList={currenciesList}
                setCurrenciesList={setCurrenciesList}
                currencies={currencies}
                countries={countries}
                onDeleteCurrency={onDeleteCurrency}
            />
        </Wrapper>
    );
}

const mapStateToProps = state => {
    return {
        currencies: state.currencies.currencies,
        isLoading: state.currencies.error,
        countries: state.countries.countries,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // onInitAllIngredientsForOptions: () => dispatch(actionCreators.initAllIngredientsForOptions()),
        onFetchCurrencies: () => dispatch(actionCreators.fetchCurrencies()),
        onDeleteCurrency: id => dispatch(actionCreators.deleteCurrency(id)),
        onFetchCountries: () => dispatch(actionCreators.fetchCountries()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Currencies);

