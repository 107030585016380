import * as actionTypes from 'store/actions/actionsTypes';

const initialState = {
    packages: null,
    meals: null,
};
// GET
const setPackages = (state, action) => {
    return {
        ...state,
        packages: action.packages,
        error: false,
    }
}

// GET ONE 
const setSinglePackage = (state, action) => {
    return {
        ...state,
        singlePackage: action.singlePackage,
        error: false,
    };
};

// DELETE
const deletePackageSuccess = (state, action) => {
    return {
        ...state,
        packages: [...state.packages.filter(currency => currency.code !== action.code)],
    };
};

// MEALS PACKAGES
const setMealsPackages = (state, action) => {
    return {
        ...state,
        meals: action.mealsPackages,
        error: false,
    };
};

const fetchMealsPackagesFailed = state => {
    return {
        ...state,
        error: true,
    };
};


const packagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PACKAGES:
            return setPackages(state, action);
        case actionTypes.DELETE_PACKAGE_SUCCESS:
            return deletePackageSuccess(state, action);
        case actionTypes.SET_SINGLE_PACKAGE:
            return setSinglePackage(state, action);
        case actionTypes.SET_MEALS_PACKAGES:
            return setMealsPackages(state, action);
        case actionTypes.FETCH_MEALS_PACKAGES_FAILED:
            return fetchMealsPackagesFailed(state);
        default:
            return state;
    }
};

export default packagesReducer;
